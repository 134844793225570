import { GET_META_DATA_REQUEST, GET_META_DATA_SUCCESS } from "./type"

export const getMetaDataRequest = data => ({
  type: GET_META_DATA_REQUEST,
  data
})

export const getMetaDataSuccess = data => ({
  type: GET_META_DATA_SUCCESS,
  data
})
