// Redux
import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
import {
  postForgotRequestSuccess,
  postForgotRequestFailure,
  forgotOTPVerificationSuccess,
  forgotOTPVerificationFailure
} from "./action"

import { FORGOT_OTP_VERIFICATION, POST_FORGOT_PASSWORD } from './type'
// Component
import toast from "react-hot-toast";
import { displayOTPModal } from "../../SignUp/redux/action";
import { forgotPasswordOTPInfo } from "../../ConfirmPassword/redux/action";

async function postForgotAPI(data) {
  const URL = `${BASE_URL}api/v1/forgot-password/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* postForgotInfo({ data, history }) {
  try {
    const response = yield call(postForgotAPI, data)
    yield put(postForgotRequestSuccess(response.data))
    toast.success(response?.data?.message);
    yield put(displayOTPModal(true))
  } catch (e) {
    const { response } = e
    yield put(postForgotRequestFailure({email: response?.data?.error_message}))
  }
}

async function forgotOTPVerification(data) {
  const URL = `${BASE_URL}api/v1/forgot-password/verify_otp/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* otpVerification({data, history}) {
  try {
    const response = yield call(forgotOTPVerification, data);
    yield put(forgotOTPVerificationSuccess(response?.data));
    toast.success('OTP Verified Successfully', {
      id: 'card fields'
    })
    yield put(forgotPasswordOTPInfo(response?.data));
    history.push('/auth/confirm_password')
  } catch(e) {
    const { response } = e
    yield put(forgotOTPVerificationFailure(response?.data));
    toast.error(response?.data?.error_message, {
      id: 'card fields'
    })
  }
}


export default all([
  takeLatest(POST_FORGOT_PASSWORD, postForgotInfo),
  takeLatest(FORGOT_OTP_VERIFICATION, otpVerification)
])