import React from "react"

// Components
import Modal from "react-bootstrap/Modal"
import Image from "../Image"
import { Images } from "../../theme/Images"
import ProfileCertificate from "./components/ProfileCertificate"
import ProfileEducation from "./components/ProfileEducation"
import ProfileExperience from "./components/ProfileExperience"
import ProfileHobbies from "./components/ProfileHobbies"
import ProfileInfo from "./components/ProfileInfo"
import ProfileMandatoryInfo from './components/ProfileMandatoryInfo'
import ProfileLanguage from "./components/ProfileLanguage"
import ProfileProjects from "./components/ProfileProjects"
import ProfileSkills from "./components/ProfileSkills"
import JobsFilter from "./components/JobsFilter"
import ApplyJob from "./components/ApplyJob"
import SendFeedback from "./components/SendFeedback"
import PaymentTerms from "./components/PaymentTerms"
import ApplyFilter from "./components/ApplyFilter"

// Style
import "./style.scss"

const EditModal = ({
  showModal,
  setShowModal,
  currentModal,
  editData,
  selectedId,
  handleOnSubmit,
  setUploadedResume,
  uploadedResume
}) => {
  const action = selectedId ? "Edit" : "Add"
  const modalHeader = {
    "profile-info": "Profile Information",
    'profile-mandatory-info': 'Required information to apply',
    "payment-terms": "Payment Terms",
    "profile-skills": `${action} Skill`,
    "profile-experience": `${action} Experience`,
    "profile-projects": `${action} Projects`,
    "profile-certificate": `${action} Certifications`,
    "profile-language": `${action} Language`,
    "profile-hobbies": "Hobbies And Interests",
    "profile-education": `${action} Education`,
    "profile-salary": "Salary",
    "jobs-filter": "Apply Filters",
    "apply-job": "",
    feedback: "Send Feedback",
    "apply-filter": "Apply Filters"
  }

  const closeModal = () => setShowModal(false)

  return (
    <Modal show={showModal} centered className="edit-modal">
      <Modal.Header>
        <Modal.Title>
          <h1>{modalHeader[currentModal] || ""}</h1>
          <div className="close-icon" onClick={closeModal}>
            <Image src={Images.closeIcon} />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {currentModal === "profile-info" && (
          <ProfileInfo
            editedPersonalInfoData={editData}
            selectedId={selectedId}
          />
        )}
        {currentModal === "profile-mandatory-info" && (
          <ProfileMandatoryInfo
            editedPersonalInfoData={editData}
            selectedId={selectedId}
            uploadedResume={uploadedResume}
            setUploadedResume={setUploadedResume}
            closeModal={closeModal}
          />
        )}
        {currentModal === "profile-skills" && (
          <ProfileSkills
            editedSkillsData={editData}
            selectedId={selectedId}
            handleOnSubmit={handleOnSubmit}
            closeModal={closeModal}
          />
        )}
        {currentModal === "profile-projects" && (
          <ProfileProjects
            setShowModal={setShowModal}
            selectedId={selectedId}
            editProfileProject={editData}
          />
        )}
        {currentModal === "profile-certificate" && (
          <ProfileCertificate
            setShowModal={setShowModal}
            selectedId={selectedId}
            editProfileCertificate={editData}
          />
        )}
        {currentModal === "profile-language" && (
          <ProfileLanguage
            setShowModal={setShowModal}
            selectedId={selectedId}
            editProfileLanguages={editData}
          />
        )}
        {currentModal === "profile-hobbies" && (
          <ProfileHobbies
            editedHobbiesData={editData}
            setShowModal={setShowModal}
            selectedId={selectedId}
          />
        )}
        {currentModal === "profile-education" && (
          <ProfileEducation
            setShowModal={setShowModal}
            selectedId={selectedId}
            editProfileEducation={editData}
          />
        )}
        {currentModal === "profile-experience" && (
          <ProfileExperience
            setShowModal={setShowModal}
            selectedId={selectedId}
            editedProfileExperience={editData}
          />
        )}
        {currentModal === "jobs-filter" && <JobsFilter />}
        {currentModal === "apply-job" && (
          <ApplyJob setShowModal={setShowModal} jobData={editData} />
        )}
        {currentModal === "feedback" && <SendFeedback />}
        {currentModal === "payment-terms" && (
          <PaymentTerms
            selectedId={selectedId}
            editedProfileSalary={editData}
            setShowModal={setShowModal}
          />
        )}
        {currentModal === "apply-filter" && <ApplyFilter />}
      </Modal.Body>
    </Modal>
  )
}

export default EditModal
