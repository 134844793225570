import React, { useState } from "react"
import UInput from "../../UInput"
import Button from "../../Button"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import GitHubIcon from "@mui/icons-material/GitHub"

import { ReactComponent as StackOverflowIcon } from "../../../assets/images/stackoverflow-icon.svg"
import { isURL } from "../../../utils/helper"

import "../style.scss"
import { useEffect } from "react"

const SocialMediaCard = ({
  isEdited,
  socialMediaInfo,
  onClick,
  isCandidate,
  requesting
}) => {
  const [socialMediaLinks, setSocialMediaLinks] = useState(
    socialMediaInfo || {}
  )
  const [error, setError] = useState({})

  useEffect(() => {
    socialMediaInfo && setSocialMediaLinks({ ...socialMediaInfo })
  }, [socialMediaInfo])

  const handleOnClick = id => () => {
    onClick({ ...socialMediaLinks, id })
  }

  const handleOnChange = field => e => {
    setSocialMediaLinks({ ...socialMediaLinks, [field]: e?.target?.value })
    if (e?.target?.value && !isURL(e?.target?.value)) {
      setError({ ...error, [field]: "Please enter valid url" })
    } else {
      setError({ ...error, [field]: "" })
    }
  }

  const clearError = () => {}

  const isDisabled = () => {
    const urlType = ["linkedin_url", "github_url", "stack_url"]
    return (
      Object.keys(error).some(field => error[field]) ||
      !urlType?.some(
        type =>
          (!socialMediaInfo && socialMediaLinks[type]) ||
          (socialMediaInfo && socialMediaLinks[type] !== socialMediaInfo[type])
      )
    )
  }

  return (
    <>
      {isEdited && (
        <div className="profile-details-card__body border-0">
          {isCandidate && (
            <div className="profile-details-card__body__social-media">
              <div className="profile-details-card__body__social-media__content">
                <div className="profile-details-card__body__social-media__content__title">
                  LinkedIn URL:
                </div>
                <div className="profile-details-card__body__social-media__content__input-text">
                  <UInput
                    value={socialMediaLinks.linkedin_url || ""}
                    onChange={handleOnChange("linkedin_url")}
                    errorText={error?.linkedin_url}
                    errorClass={error?.linkedin_url ? "opacity-1" : ""}
                    onFocus={clearError}
                    inputError={error?.linkedin_url}
                  />
                </div>
              </div>
              <div className="profile-details-card__body__social-media__content">
                <div className="profile-details-card__body__social-media__content__title">
                  Github:
                </div>
                <div className="profile-details-card__body__social-media__content__input-text">
                  <UInput
                    value={socialMediaLinks.github_url || ""}
                    onChange={handleOnChange("github_url")}
                    errorText={error?.github_url}
                    errorClass={error?.github_url ? "opacity-1" : ""}
                    onFocus={clearError}
                    inputError={error?.github_url}
                  />
                </div>
              </div>
              <div className="profile-details-card__body__social-media__content">
                <div className="profile-details-card__body__social-media__content__title">
                  Stack:
                </div>
                <div className="profile-details-card__body__social-media__content__input-text">
                  <UInput
                    value={socialMediaLinks.stack_url || ""}
                    onChange={handleOnChange("stack_url")}
                    errorText={error?.stack_url}
                    errorClass={error?.stack_url ? "opacity-1" : ""}
                    onFocus={clearError}
                    inputError={error?.stack_url}
                  />
                </div>
              </div>
              <div className="profile-details-card__body__social-media__action">
                <Button
                  title="Save"
                  onClick={handleOnClick(socialMediaInfo?.id)}
                  disabled={isDisabled() ? true : false}
                  showSpinner={requesting}
                  className={`save-btn ${isDisabled() ? "disabled-btn" : ""}`}
                />
              </div>
            </div>
          )}
          {!isCandidate && socialMediaLinks?.id && (
            <div className="profile-details-card__body__social-media-logo">
              {socialMediaLinks?.linkedin_url && (
                <a
                  class="profile-details-card__body__social-media-logo__icon linked-in"
                  href={socialMediaLinks?.linkedin_url}
                  rel="noreferrer"
                  target="_blank"
                >
                  <LinkedInIcon />
                </a>
              )}
              {socialMediaLinks?.github_url && (
                <a
                  className="profile-details-card__body__social-media-logo__icon github"
                  href={socialMediaLinks?.github_url}
                  rel="noreferrer"
                  target="_blank"
                >
                  <GitHubIcon />
                </a>
              )}
              {socialMediaLinks?.stack_url && (
                <a
                  className="profile-details-card__body__social-media-logo__icon stack-overflow"
                  href={socialMediaLinks?.stack_url}
                  rel="noreferrer"
                  target="_blank"
                >
                  <StackOverflowIcon />
                </a>
              )}
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default SocialMediaCard
