import React from "react"

const ChartLegends = ({ legends }) => {
  return (
    <div className="chart-legends">
      {legends.map(item => (
        <span className="legends" key={item.key}>
          <span className={`legend-title chart-label-${item.color}`}>
            {item.label}
          </span>
          <span className="legend-value">{item.value || 0}%</span>
        </span>
      ))}
    </div>
  )
}

export default ChartLegends
