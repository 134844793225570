import React from "react"
import Modal from "react-bootstrap/Modal"
import { Images } from "../../theme/Images"
import Image from "../Image"

import PrivacyPolicy from "./PrivacyPolicy"

import "./style.scss"

const PrivacyTermsModal = ({ show, setShow, currentData }) => {
  const closeModal = () => setShow(false)

  return (
    <Modal
      show={show}
      setShow={setShow}
      centered
      className="privacy-terms-modal"
    >
      <Modal.Header>
        <Modal.Title>
          {currentData === "privacy" && "Privacy Policy"}
          {currentData === "terms" && "Terms & Conditions"}
          <div className="close-icon" onClick={closeModal}>
            <Image src={Images.closeIcon} />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="main-text">Welcome to the Experis Cross Border Community!</p>
        {currentData === "privacy" && <PrivacyPolicy />}
        {/* {currentData === "terms" && <TermsConditions />} */}
      </Modal.Body>
    </Modal>
  )
}

export default PrivacyTermsModal
