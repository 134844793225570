import {
  GET_LOCATION_LIST_REQUEST,
  GET_LOCATION_LIST_SUCCESS,
  GET_LOCATION_INFO_REQUEST,
  GET_LOCATION_INFO_SUCCESS
} from "./type"

export const getLocationListRequest = data => ({
  type: GET_LOCATION_LIST_REQUEST,
  data
})

export const getLocationListSuccess = data => ({
  type: GET_LOCATION_LIST_SUCCESS,
  data
})

export const getLocationInfoRequest = data => ({
  type: GET_LOCATION_INFO_REQUEST,
  data
})

export const getLocationInfoSuccess = data => ({
  type: GET_LOCATION_INFO_SUCCESS,
  data
})
