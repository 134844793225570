import React from "react"

// Components
import Header from "../../components/Header"
import SidebarMenu from "../../components/SidebarMenu"

// Style
import "./style.scss"
import { useMetaData } from "../../hooks"

const HomeLayout = props => {
  useMetaData()

  return (
    <section className="dashboard-wrapper">
      <div className="main-dashboard-sidebar-menu">
        <SidebarMenu />
      </div>
      <div className="main-dashboard-section">
        <div className="main-dashboard-header">
          <Header className={props.className} />
        </div>
        <div className="main-content-section">{props.children}</div>
      </div>
    </section>
  )
}

export default HomeLayout
