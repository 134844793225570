import AddIcon from "@mui/icons-material/Add"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import dayjs from "dayjs"
import React from "react"

import { Images } from "../../../theme/Images"
import IconButton from "../../IconButton"
import Image from "../../Image"

import "../style.scss"

const ExperienceCard = ({
  isEdited,
  getExperienceInfo,
  isCandidate,
  onClick
}) => {
  const handleOnClick = id => event => {
    event.stopPropagation()
    onClick(id)
  }

  return (
    <>
      {isEdited ? (
        <div className="profile-details-card__body column-flex">
          {getExperienceInfo &&
            getExperienceInfo?.map((item, index) => (
              <div
                className="profile-details-card__body__experience"
                key={index}
              >
                <div className="profile-details-card__body__experience__icon">
                  <Image src={Images.workIcon} />
                </div>

                <div className="profile-details-card__body__experience__content column-flex">
                  <div className="profile-details-card__body__experience__content__title flex-justify-between">
                    <div className="profile-details-card__body__experience__content__title__text">
                      {item?.position || "-"}
                    </div>
                    {isCandidate && (
                      <div
                        className="profile-details-card__body__experience__content__title__edit"
                        onClick={handleOnClick(item?.id)}
                      >
                        <EditOutlinedIcon />
                        &nbsp;&nbsp;Edit
                      </div>
                    )}
                  </div>
                  <div className="profile-details-card__body__experience__content__sub-title">
                    <span className="profile-details-card__body__experience__content__sub-title__company">
                      {item?.company || "-"}
                    </span>
                    <span className="profile-details-card__body__experience__content__sub-title__date">
                      <span className="profile-details-card__body__experience__content__sub-title__date__start">
                        {item?.start_date &&
                          dayjs(item?.start_date).format("MMM YYYY ")}
                      </span>
                      <span className="profile-details-card__body__experience__content__sub-title__date__end">
                        {item?.currently_working
                          ? " - Present"
                          : item?.end_date &&
                            ` - ${dayjs(item?.end_date).format("MMM YYYY ")}`}
                      </span>
                    </span>
                  </div>
                  <div className="profile-details-card__body__experience__content__description">
                    {Boolean(item?.description) && item.description}
                  </div>
                </div>
              </div>
            ))}
        </div>
      ) : (
        <div className="profile-details-card__info-text">
          Please include your employment history, role descriptions, and
          accomplishments in the role. Minimum of 1 requested.
        </div>
      )}
      {isCandidate && (
        <div className="profile-details-card__action">
          <IconButton
            icon={<AddIcon />}
            label="Add Experience"
            onClick={handleOnClick()}
          />
        </div>
      )}
    </>
  )
}

export default ExperienceCard
