import {
  GET_CANDIDATE_APPLY_JOBS_REQUEST,
  GET_CANDIDATE_APPLY_JOBS_SUCCESS,
  GET_CANDIDATE_APPLY_JOBS_FAILURE,
  POST_CANDIDATE_APPLY_JOB_REQUEST,
  POST_CANDIDATE_APPLY_JOB_FAILURE,
  POST_CANDIDATE_APPLY_JOB_SUCCESS,
  GET_CANDIDATE_SAVED_JOBS_REQUEST,
  GET_CANDIDATE_SAVED_JOBS_SUCCESS,
  GET_CANDIDATE_SAVED_JOBS_FAILURE,
  POST_CANDIDATE_SAVED_JOB_REQUEST,
  POST_CANDIDATE_SAVED_JOB_SUCCESS,
  POST_CANDIDATE_SAVED_JOB_FAILURE,
  GET_ALL_CANDIDATE_JOBS,
  GET_ALL_CANDIDATE_JOBS_SUCCESS,
  GET_ALL_CANDIDATE_JOBS_FAILURE,
  GET_CANDIDATE_ARCHIVED_JOBS_REQUEST,
  GET_CANDIDATE_ARCHIVED_JOBS_SUCCESS,
  GET_CANDIDATE_ARCHIVED_JOBS_FAILURE,
  GET_CANDIDATE_INTERVIEWING_JOBS_REQUEST,
  GET_CANDIDATE_INTERVIEWING_JOBS_SUCCESS,
  GET_CANDIDATE_INTERVIEWING_JOBS_FAILURE,
  GET_CANDIDATE_OFFERED_JOBS_REQUEST,
  GET_CANDIDATE_HIRED_JOBS_REQUEST,
  GET_CANDIDATE_REJECTED_JOBS_REQUEST,
  GET_CANDIDATE_OFFERED_JOBS_SUCCESS,
  GET_CANDIDATE_HIRED_JOBS_SUCCESS,
  GET_CANDIDATE_REJECTED_JOBS_SUCCESS,
  GET_CANDIDATE_OFFERED_JOBS_FAILURE,
  GET_CANDIDATE_HIRED_JOBS_FAILURE,
  GET_CANDIDATE_REJECTED_JOBS_FAILURE,
  GET_JOB_DATA_BY_ID_REQUEST,
  GET_JOB_DATA_BY_ID_REQUEST_SUCCESS,
  GET_JOB_DATA_BY_ID_REQUEST_FAILURE,
  DISPLAY_JOBS_MODAL
} from "./type"
const initialState = {
  savedJobsData: false,
  requesting: false,
  applyJobsData: false,
  postApplyJobInfo: false,
  postSavedJobInfo: false,
  filterRequesting: false,
  savedJobRequesting: false,
  applyJobRequesting: false,
  archivedJobRequesting: false,
  interviewingJobRequesting: false,
  offeredJobRequesting: false,
  hiredJobRequesting: false,
  rejectedJobRequesting: false,
  allJobsData: false,
  interviewingJobsData: false,
  archivedJobsData: false,
  offeredJobsData: false,
  hiredJobsData: false,
  rejectedJobsData: false
}

const candidateJobsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CANDIDATE_JOBS:
      return {
        ...state,
        requesting: true
      }
    case GET_ALL_CANDIDATE_JOBS_SUCCESS:
      return {
        ...state,
        requesting: false,
        allJobsData: action.data
      }
    case GET_ALL_CANDIDATE_JOBS_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
    case GET_CANDIDATE_SAVED_JOBS_REQUEST:
      return {
        ...state,
        savedJobRequesting: true
      }

    case GET_CANDIDATE_SAVED_JOBS_SUCCESS:
      return {
        ...state,
        savedJobRequesting: false,
        savedJobsData: action.data
      }
    case GET_CANDIDATE_SAVED_JOBS_FAILURE:
      return {
        ...state,
        savedJobRequesting: false,
        error: action.data
      }
    case GET_CANDIDATE_APPLY_JOBS_REQUEST:
      return {
        ...state,
        applyJobRequesting: true
      }
    case GET_CANDIDATE_ARCHIVED_JOBS_REQUEST:
      return {
        ...state,
        archivedJobRequesting: true
      }
    case GET_CANDIDATE_INTERVIEWING_JOBS_REQUEST:
      return {
        ...state,
        interviewingJobRequesting: true
      }
    case GET_CANDIDATE_OFFERED_JOBS_REQUEST:
      return {
        ...state,
        offeredJobRequesting: true
      }
    case GET_CANDIDATE_HIRED_JOBS_REQUEST:
      return {
        ...state,
        hiredJobRequesting: true
      }
    case GET_CANDIDATE_REJECTED_JOBS_REQUEST:
      return {
        ...state,
        rejectedJobRequesting: true
      }

    case GET_CANDIDATE_APPLY_JOBS_SUCCESS:
      return {
        ...state,
        applyJobRequesting: false,
        applyJobsData: action.data
      }
    case GET_CANDIDATE_INTERVIEWING_JOBS_SUCCESS:
      return {
        ...state,
        interviewingJobRequesting: false,
        interviewingJobsData: action.data
      }
    case GET_CANDIDATE_ARCHIVED_JOBS_SUCCESS:
      return {
        ...state,
        archivedJobRequesting: false,
        archivedJobsData: action.data
      }
    case GET_CANDIDATE_OFFERED_JOBS_SUCCESS:
      return {
        ...state,
        offeredJobRequesting: false,
        offeredJobsData: action.data
      }
    case GET_CANDIDATE_HIRED_JOBS_SUCCESS:
      return {
        ...state,
        hiredJobRequesting: false,
        hiredJobsData: action.data
      }
    case GET_CANDIDATE_REJECTED_JOBS_SUCCESS:
      return {
        ...state,
        rejectedJobRequesting: false,
        rejectedJobsData: action.data
      }

    case GET_CANDIDATE_APPLY_JOBS_FAILURE:
      return {
        ...state,
        applyJobRequesting: false,
        error: action.data
      }
    case GET_CANDIDATE_ARCHIVED_JOBS_FAILURE:
      return {
        ...state,
        archivedJobRequesting: false,
        error: action.data
      }
    case GET_CANDIDATE_INTERVIEWING_JOBS_FAILURE:
      return {
        ...state,
        interviewingJobRequesting: false,
        error: action.data
      }
    case GET_CANDIDATE_OFFERED_JOBS_FAILURE:
      return {
        ...state,
        offeredJobRequesting: false,
        error: action.data
      }
    case GET_CANDIDATE_HIRED_JOBS_FAILURE:
      return {
        ...state,
        hiredJobRequesting: false,
        error: action.data
      }
    case GET_CANDIDATE_REJECTED_JOBS_FAILURE:
      return {
        ...state,
        rejectedJobRequesting: false,
        error: action.data
      }

    // Apply Job
    case POST_CANDIDATE_APPLY_JOB_REQUEST:
      return {
        ...state,
        applyJobRequesting: true
      }
    case POST_CANDIDATE_APPLY_JOB_SUCCESS:
      return {
        ...state,
        applyJobRequesting: false,
        postApplyJobInfo: action.data
      }

    case POST_CANDIDATE_APPLY_JOB_FAILURE:
      return {
        ...state,
        applyJobRequesting: false,
        error: action.data
      }

    // Post Favorite Job
    case POST_CANDIDATE_SAVED_JOB_REQUEST:
      return {
        ...state,
        savedJobRequesting: true
      }
    case POST_CANDIDATE_SAVED_JOB_SUCCESS:
      return {
        ...state,
        savedJobRequesting: false,
        postSavedJobInfo: action.data
      }

    case POST_CANDIDATE_SAVED_JOB_FAILURE:
      return {
        ...state,
        savedJobRequesting: false,
        error: action.data
      }
    case GET_JOB_DATA_BY_ID_REQUEST:
      return {
        ...state,
        requesting: true
      }
    case GET_JOB_DATA_BY_ID_REQUEST_SUCCESS:
      return {
        ...state,
        requesting: false,
        jobInformation: action.data
      }
    case GET_JOB_DATA_BY_ID_REQUEST_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
    case DISPLAY_JOBS_MODAL:
      return {
        ...state,
        showModal: action.data
      }

    default:
      return state
  }
}

export default candidateJobsReducer
