// Apply Job

export const GET_CANDIDATE_APPLY_JOBS_REQUEST =
  "GET_CANDIDATE_APPLY_JOBS_REQUEST"
export const GET_CANDIDATE_APPLY_JOBS_SUCCESS =
  "GET_CANDIDATE_APPLY_JOBS_SUCCESS"
export const GET_CANDIDATE_APPLY_JOBS_FAILURE =
  "GET_CANDIDATE_APPLY_JOBS_FAILURE"

export const POST_CANDIDATE_APPLY_JOB_REQUEST =
  "POST_CANDIDATE_APPLY_JOB_REQUEST"
export const POST_CANDIDATE_APPLY_JOB_SUCCESS =
  "POST_CANDIDATE_APPLY_JOB_SUCCESS"
export const POST_CANDIDATE_APPLY_JOB_FAILURE =
  "POST_CANDIDATE_APPLY_JOB_FAILURE"

// Saved Job
export const GET_CANDIDATE_SAVED_JOBS_REQUEST =
  "GET_CANDIDATE_SAVED_JOBS_REQUEST"
export const GET_CANDIDATE_SAVED_JOBS_SUCCESS =
  "GET_CANDIDATE_SAVED_JOBS_SUCCESS"
export const GET_CANDIDATE_SAVED_JOBS_FAILURE =
  "GET_CANDIDATE_SAVED_JOBS_FAILURE"
export const POST_CANDIDATE_SAVED_JOB_REQUEST =
  "POST_CANDIDATE_SAVED_JOB_REQUEST"
export const POST_CANDIDATE_SAVED_JOB_SUCCESS =
  "POST_CANDIDATE_SAVED_JOB_SUCCESS"
export const POST_CANDIDATE_SAVED_JOB_FAILURE =
  "POST_CANDIDATE_SAVED_JOB_FAILURE"

// Candidate All Jobs New
export const GET_ALL_CANDIDATE_JOBS = "GET_ALL_CANDIDATE_JOBS"
export const GET_ALL_CANDIDATE_JOBS_SUCCESS = "GET_ALL_CANDIDATE_JOBS_SUCCESS"
export const GET_ALL_CANDIDATE_JOBS_FAILURE = "GET_ALL_CANDIDATE_JOBS_FAILURE"

// GET CANDIDATE ARCHIVED JOBS
export const GET_CANDIDATE_ARCHIVED_JOBS_REQUEST =
  "GET_CANDIDATE_ARCHIVED_JOBS_REQUEST"
export const GET_CANDIDATE_ARCHIVED_JOBS_SUCCESS =
  "GET_CANDIDATE_ARCHIVED_JOBS_SUCCESS"
export const GET_CANDIDATE_ARCHIVED_JOBS_FAILURE =
  "GET_CANDIDATE_ARCHIVED_JOBS_FAILURE"

// INTERVIEWING
export const GET_CANDIDATE_INTERVIEWING_JOBS_REQUEST =
  "GET_CANDIDATE_INTERVIEWING_JOBS_REQUEST"
export const GET_CANDIDATE_INTERVIEWING_JOBS_SUCCESS =
  "GET_CANDIDATE_INTERVIEWING_JOBS_SUCCESS"
export const GET_CANDIDATE_INTERVIEWING_JOBS_FAILURE =
  "GET_CANDIDATE_INTERVIEWING_JOBS_FAILURE"

// OFFERED
export const GET_CANDIDATE_OFFERED_JOBS_REQUEST =
  "GET_CANDIDATE_OFFERED_JOBS_REQUEST"
export const GET_CANDIDATE_OFFERED_JOBS_SUCCESS =
  "GET_CANDIDATE_OFFERED_JOBS_SUCCESS"
export const GET_CANDIDATE_OFFERED_JOBS_FAILURE =
  "GET_CANDIDATE_OFFERED_JOBS_FAILURE"

// HIRED
export const GET_CANDIDATE_HIRED_JOBS_REQUEST =
  "GET_CANDIDATE_HIRED_JOBS_REQUEST"
export const GET_CANDIDATE_HIRED_JOBS_SUCCESS =
  "GET_CANDIDATE_HIRED_JOBS_SUCCESS"
export const GET_CANDIDATE_HIRED_JOBS_FAILURE =
  "GET_CANDIDATE_HIRED_JOBS_FAILURE"

// REJECTED
export const GET_CANDIDATE_REJECTED_JOBS_REQUEST =
  "GET_CANDIDATE_REJECTED_JOBS_REQUEST"
export const GET_CANDIDATE_REJECTED_JOBS_SUCCESS =
  "GET_CANDIDATE_REJECTED_JOBS_SUCCESS"
export const GET_CANDIDATE_REJECTED_JOBS_FAILURE =
  "GET_CANDIDATE_REJECTED_JOBS_FAILURE"

// FETCH JOB DATA
export const GET_JOB_DATA_BY_ID_REQUEST = "GET_JOB_DATA_BY_ID_REQUEST"
export const GET_JOB_DATA_BY_ID_REQUEST_SUCCESS =
  "GET_JOB_DATA_BY_ID_REQUEST_SUCCESS"
export const GET_JOB_DATA_BY_ID_REQUEST_FAILURE =
  "GET_JOB_DATA_BY_ID_REQUEST_FAILURE"

export const DISPLAY_JOBS_MODAL = "DISPLAY_JOBS_MODAL"

export const GENERATE_FINGERPRINT_REQUEST = "GENERATE_FINGERPRINT_REQUEST"
