import { connect } from "react-redux"
import React, { useEffect, useState } from "react"
import OTPInput from "otp-input-react"

import Button from "../../../components/Button"
import { useTimer } from "../../../hooks"
import { displayOTPModal } from "../SignUp/redux/action"

// Styles

const OTPScreen = props => {
  const {
    email,
    history,
    closeOTPModalDispatch,
    handleResendOTP,
    handleVerifyOTP,
    showOTPModal,
    requesting
  } = props

  const [minutes, seconds, setTimer] = useTimer(4, 30)
  const [otp, setOTP] = useState({
    value: "",
    error: "",
    errorClass: ""
  })

  useEffect(() => {
    return () => {
      closeOTPModalDispatch(false)
    }
  }, [closeOTPModalDispatch])

  useEffect(() => {
    if (showOTPModal) setTimer(4, 30)
  }, [showOTPModal, setTimer])

  const handleOTP = event => {
    if (isNaN(event)) {
      setOTP({
        value: "",
        error: "Invalid OTP",
        errorClass: "otp-error"
      })
    } else {
      setOTP({
        value: event,
        error: "",
        errorClass: ""
      })
    }
  }

  const validateOTPFunc = async () => {
    otp?.value && (await handleVerifyOTP({ otp: otp?.value, email }, history))
  }

  const resendOTP = async () => {
    setTimer(4, 30)
    await handleResendOTP({ email })
  }

  return (
    <>
      <div className="auth-form-wrapper column-flex right-inside">
        <div className="first-wrapper">
          <div className="auth-welcome-text">
            <h1>Enter Verification Code</h1>
            <p>
              Enter the 6 digit OTP that was sent to <br />{" "}
              <span className="email">{email}</span>
            </p>
          </div>
        </div>
        <div className="second-wrapper-otp">
          <OTPInput
            value={otp?.value}
            onChange={handleOTP}
            autoFocus
            OTPLength={6}
            otpType="number"
            disabled={false}
            secure
          />
        </div>
        <Button
          title="Verify"
          showSpinner={requesting}
          className={otp.value.length === 6 ? "full-width" : "disabled-btn"}
          onClick={validateOTPFunc}
        />
        <div className="countdown-txt">
          Didn't Receive Anything?&nbsp;
          <span
            className={seconds > 0 || minutes > 0 ? "disabled" : "enabled"}
            onClick={seconds > 0 || minutes > 0 ? undefined : resendOTP}
          >
            Resend Code
          </span>
          &nbsp; ({minutes}:{seconds})
        </div>
      </div>
    </>
  )
}

const mapDispatchToProps = dispatch => ({
  closeOTPModalDispatch: data => dispatch(displayOTPModal(data))
})

export default connect(null, mapDispatchToProps)(OTPScreen)
