import React from "react"

// Components
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"

const UCheckbox = ({
  label = "",
  value,
  onChange,
  defaultChecked,
  checked,
  className
}) => {
  return (
    <div className="checkbox-wrapper">
      <FormControlLabel
        control={
          <Checkbox
            sx={{
              color: "#77787D",
              "&.Mui-checked": {
                color: "#1D8EE6"
              },
              "&:hover": {
                backgroundColor: "transparent",
                color: "#1D8EE6"
              }
            }}
            value={value}
            onChange={onChange}
            defaultChecked={defaultChecked}
            checked={checked}
            className={className}
            disableRipple
          />
        }
        label={label}
      />
    </div>
  )
}

export default UCheckbox
