import {
  GET_MY_JOBS_REQUEST,
  GET_MY_JOBS_SUCCESS,
  GET_MY_JOBS_FAILURE,
  GET_ALL_JOBS_REQUEST,
  GET_ALL_JOBS_SUCCESS,
  GET_ALL_JOBS_FAILURE,
  DELETE_JOB_REQUEST,
  DELETE_JOB_SUCCESS,
  CLOSE_JOB_REQUEST,
  CLOSE_JOB_SUCCESS
} from "./type"

export const getMyJobsRequest = data => ({
  type: GET_MY_JOBS_REQUEST,
  data
})
export const getMyJobsSuccess = data => ({
  type: GET_MY_JOBS_SUCCESS,
  data
})
export const getMyJobsFailure = data => ({
  type: GET_MY_JOBS_FAILURE,
  data
})

export const getAllJobsRequest = data => ({
  type: GET_ALL_JOBS_REQUEST,
  data
})

export const getAllJobsSuccess = data => ({
  type: GET_ALL_JOBS_SUCCESS,
  data
})
export const getAllJobsFailure = data => ({
  type: GET_ALL_JOBS_FAILURE,
  data
})

export const deleteJobRequest = data => ({
  type: DELETE_JOB_REQUEST,
  data
})

export const deleteJobSuccess = data => ({
  type: DELETE_JOB_SUCCESS,
  data
})

export const closeJobRequest = data => ({
  type: CLOSE_JOB_REQUEST,
  data
})

export const closeJobSuccess = data => ({
  type: CLOSE_JOB_SUCCESS,
  data
})
