import React, { useCallback, useEffect, useState } from "react";

// Style
import "./style.scss";

const StrengthMeter = (props) => {
  const [strength, setStrength] = useState('');
  const pwdValidate = props.password;
  const actions = props.actions
  const initPwdChecker = useCallback(() => {
    let pwdCheck = 0;
    let validateRegex = ["[A-Z]", "[a-z]", "[0-9]", "\\W"];
    validateRegex.forEach((regex, i) => {
      if (new RegExp(regex).test(pwdValidate) && pwdValidate.length >= 8) {
        pwdCheck += 1;
      }
    });
    setStrength(pwdCheck);
    switch (pwdCheck) {
      case 0:
        return {
          strength: 0,
          val: "",
        };
      case 1:
        return {
          strength: 1,
          val: "weak",
        };
      case 2:
        return {
          strength: 2,
          val: "fair",
        };
      case 3:
        return {
          strength: 3,
          val: "good",
        };
      case 4:
        return {
          strength: 4,
          val: "strong",
        };
      default:
        return null;
    }
  }, [pwdValidate]);
  const strengthString = {
    0 : 'Poor',
    1: 'Weak',
    2: 'Fair',
    3: 'Good',
    4: 'Strong'
};

  const strengthStringColor = {
    0: 'darkred',
    1: 'orangered',
    2: 'orange',
    3: 'yellowgreen',
    4: 'green'
  }

  useEffect(()=> {
      actions(initPwdChecker().val)
  }, [initPwdChecker, actions])

  const strengthClass = ['strength-meter mt-2', props?.password?.length > 0 ? 'visible' : 'invisible'].join(' ').trim();
  return (
    <>
      <div className={strengthClass}>
        <div className="strength-meter-fill" data-strength={strength}></div>
      </div>
      <div className="strength-value" style={{color: strengthStringColor[strength]}}>{ pwdValidate.length > 0 && strengthString[strength]}</div>
    </>
  );
};
export default StrengthMeter;
