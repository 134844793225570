import { all, call, put, takeLatest } from "redux-saga/effects"

import { BASE_URL } from "../../../config/app"
import { GET_LOCATION_INFO_REQUEST, GET_LOCATION_LIST_REQUEST } from "./type"
import { getLocationInfoSuccess, getLocationListSuccess } from "./action"
import XHR from "../../../utils/XHR"

const getLocationListDataAPI = async data => {
  const URL = `${BASE_URL}api/v1/uiba-api/locations/?location=${data?.location}&type=${data?.type}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getLocationListData({ data }) {
  try {
    const response = yield call(getLocationListDataAPI, data)
    yield put(getLocationListSuccess(response.data?.predictions || []))
  } catch (e) {
    yield put(getLocationListSuccess([]))
  }
}

const getLocationInfoAPI = async data => {
  const URL = `${BASE_URL}api/v1/uiba-api/placedetails/?place_id=${data?.placeId}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getLocationInfoById({ data }) {
  try {
    const response = yield call(getLocationInfoAPI, data)
    yield put(getLocationInfoSuccess(response.data?.results))
  } catch (e) {
    yield put(getLocationInfoSuccess([]))
  }
}

export default all([
  takeLatest(GET_LOCATION_LIST_REQUEST, getLocationListData),
  takeLatest(GET_LOCATION_INFO_REQUEST, getLocationInfoById)
])
