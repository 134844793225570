import React, { useEffect, useState } from "react"
import Stack from "@mui/material/Stack"
import { connect } from "react-redux"

import Button from "../../../Button"
import USelect from "../../../USelect"
import RangeSlider from "../../../RangeSlider"
import {
  profileLanguageFailure,
  profileLanguageRequest,
  profileLanguageSuccess,
  deleteProfileLanguageRequest
} from "../../../../pages/CandidateScreens/Profile/redux/action"
import { useMetaData, useForm } from "../../../../hooks"
import { Images } from "../../../../theme/Images"
import Image from "../../../Image"
import { sortByLabel } from "../../../../utils/helper"
import { languageList } from "../../../../utils/constants"

const ProfileLanguage = props => {
  const {
    BeError,
    profileLanguageRequest,
    profileLanguageFailure,
    requesting,
    editProfileLanguages,
    selectedId,
    deleteProfileLanguage,
    deleteRequesting
  } = props
  const { languageProficiencyLevels } = useMetaData()

  const selectedLanguage =
    editProfileLanguages?.find(item => item?.id === selectedId) || {}

  const [proficiency, setProficiency] = useState(
    selectedLanguage?.proficiency
      ? languageProficiencyLevels?.find(
          item => item?.code === selectedLanguage?.proficiency
        )?.value
      : 0
  )

  const [experience, setExperience] = useState(
    parseInt(selectedLanguage?.experience || 0, 10)
  )

  const stateSchema = {
    language: {
      value: selectedLanguage?.language
        ? [
            {
              key: selectedLanguage?.language,
              label: selectedLanguage?.language
            }
          ]
        : null,
      error: ""
    }
  }

  const validationStateSchema = {
    language: {
      required: true
    }
  }

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  useEffect(() => {
    profileLanguageFailure(false)
  }, [profileLanguageFailure])

  const handleProficiencyChange = (event, value) => {
    setProficiency(value)
    profileLanguageFailure(false)
  }

  const handleExperienceChange = (event, value) => {
    setExperience(value)
    profileLanguageFailure(false)
  }

  const Submit = () => {
    const languages = state.language.value?.map(item => item?.label)
    const data = {
      id: selectedId,
      language: selectedId ? languages[0] : languages,
      proficiency: languageProficiencyLevels?.find(
        item => item?.value === proficiency
      )?.code,
      experience
    }

    profileLanguageRequest(data)
  }

  const handleInputOnChange = field => (e, value) => {
    if (!value) return false

    value = typeof value === "string" ? { label: value, key: value } : value

    if (
      state?.language?.value?.find(
        item => item?.key?.toLowerCase() === value?.key?.toLowerCase()
      )
    ) {
      return false
    }

    if (!selectedId && editProfileLanguages?.length) {
      if (
        editProfileLanguages?.find(
          item => item?.language?.toLowerCase() === value?.key?.toLowerCase()
        )
      ) {
        setState({
          ...state,
          language: {
            ...state?.language,
            error: "Language is already added. Please add some other language"
          }
        })
        return false
      }
    }

    const selected = state?.language?.value?.length
      ? [...state?.language?.value, value]
      : [value]

    handleOnChange(field, selected)
    clearError()
  }

  const handleDelete = val => () => {
    const selected = state.language.value?.filter(
      item => item?.key !== val?.key
    )

    handleOnChange("language", selected?.length > 0 ? selected : null)
  }

  const clearError = () => profileLanguageFailure(false)

  const deleteLanguage = () => {
    deleteProfileLanguage({ id: selectedId })
  }

  return (
    <div className="profile-body-input-wrapper language-input-wrapper">
      <div className="input-wrapper">
        <div className="info-text">
          Please include any Languages you speak, read, or write. Minimum of 1
          requested.
        </div>
        <USelect
          placeholderLabel="Type a language"
          freeSolo={true}
          onChange={handleInputOnChange("language")}
          options={sortByLabel(languageList)}
          className={
            BeError?.language || state?.language?.error
              ? "error-field-wrapper"
              : ""
          }
          autoSelect
          disabled={selectedId ? true : false}
          value={
            selectedId && state.language.value?.length
              ? state.language.value[0]
              : undefined
          }
        />
        <div
          className={`error-message ${
            BeError?.language || state.language.error ? "opacity-1" : ""
          }`}
          style={{ marginLeft: "24px" }}
        >
          {BeError?.language || state.language.error}
        </div>
        <Stack direction="row" flexWrap={"wrap"} spacing={1} overflow="auto">
          {state.language.value?.length > 0 &&
            state.language.value.map((option, index) => (
              <div
                className="chip-tag flex-justify-center"
                key={`${option?.label}-${index}`}
                title={option?.label?.length > 40 ? option?.label : ""}
              >
                <div className="chip-tag__title">{option?.label}</div>
                {!selectedId && (
                  <div className="chip-tag__edit-img flex-justify-center">
                    <Image
                      src={Images.bgDelIcon}
                      onClick={handleDelete(option)}
                    />
                  </div>
                )}
              </div>
            ))}
        </Stack>
        <div className="language-slider">
          <h2>Proficiency:</h2>
          <RangeSlider
            value={proficiency}
            marks={languageProficiencyLevels}
            onChange={handleProficiencyChange}
            step={null}
          />
          <div
            className={`error-message ${
              BeError?.proficiency ? "opacity-1" : ""
            }`}
          >
            {BeError?.proficiency}
          </div>
        </div>
        <div className="language-slider">
          <h2>Years of Experience</h2>
          <RangeSlider
            value={experience}
            onChange={handleExperienceChange}
            max={10}
          />
          <div
            className={`error-message ${
              BeError?.years_of_experience ? "opacity-1" : ""
            }`}
          >
            {BeError?.years_of_experience}
          </div>
        </div>
      </div>
      <div className="button-wrapper">
        {selectedId && (
          <Button
            title="Delete"
            className="delete-btn"
            onClick={deleteLanguage}
            showSpinner={deleteRequesting}
          />
        )}
        <Button
          title="Save"
          onClick={Submit}
          className={`${disable ? "disabled-btn" : ""}`}
          showSpinner={requesting}
          disabled={disable ? true : false}
        />
      </div>
    </div>
  )
}
const mapStateToProps = state => ({
  requesting: state.profile.requesting,
  BeError: state.profile.error,
  deleteRequesting: state.profile.deleteRequesting
})

const mapDispatchToProps = dispatch => ({
  profileLanguageRequest: data => dispatch(profileLanguageRequest(data)),
  profileLanguageFailure: data => dispatch(profileLanguageFailure(data)),
  profileLanguageSuccess: data => dispatch(profileLanguageSuccess(data)),
  deleteProfileLanguage: data => dispatch(deleteProfileLanguageRequest(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileLanguage)
