import {
    PUT_CHANGE_PASSWORD_REQUEST,
    PUT_CHANGE_PASSWORD_REQUEST_SUCCESS,
    PUT_CHANGE_PASSWORD_REQUEST_FAILURE
} from './type'

export const putChangePasswordRequest = (data) => ({
    type: PUT_CHANGE_PASSWORD_REQUEST,
    data
})

export const putChangePasswordRequestSuccess = (data) => ({
    type: PUT_CHANGE_PASSWORD_REQUEST_SUCCESS,
    data
})

export const putChangePasswordRequestFailure = (data) => ({
    type: PUT_CHANGE_PASSWORD_REQUEST_FAILURE,
    data
})