import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";

dayjs.extend(updateLocale);

dayjs.updateLocale("en", {
  weekStart: 1
});

class DayJsDateAdapter extends AdapterDayjs {
  constructor(options) {
    super(options)
  }
}

export default DayJsDateAdapter
