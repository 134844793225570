import React, { useCallback } from "react"
import { Images } from "../../theme/Images"
import Image from "../Image"

// import { LoginSocialGoogle } from "reactjs-social-login"
import MicrosoftLogin from "../MicrosoftLogin"
import { MICROSOFT_CLIENT_ID, REDIRECT_URI } from "../../config/app"

const SocialLogin = ({ className, onLoginSuccess, onloginError, pretext, userType }) => {
  const onLoginSuccessCallback = useCallback(
    provider =>
      ({ data }) => {
        const active = localStorage.getItem("active")
        const userData = {
          user_type: active ? active : userType,
          access_token: data.access_token
        }
        onLoginSuccess && onLoginSuccess(provider, userData)
      },
    [onLoginSuccess, userType]
  )

  const onLoginErrorCallback = useCallback(
    provider => error => {
      onloginError && onloginError(provider, error)
    },
    [onloginError]
  )

  return (
    <div className={`flex-justify-center ${className}`}>
      <MicrosoftLogin
        client_id={MICROSOFT_CLIENT_ID}
        onResolve={onLoginSuccessCallback("microsoft")}
        onReject={onLoginErrorCallback}
        isOnlyGetToken
        redirect_uri={REDIRECT_URI}
      >
        <div className="flex-justify-center option">
          <Image src={Images.microsoftIcon} /><span className="social-login">{pretext} with Microsoft</span>
        </div>
      </MicrosoftLogin>
    </div>
  )
}

export default SocialLogin
