import React from "react"

// Componets
import { Slider } from "@mui/material"

// Style
import "./style.scss"

const RangeSlider = props => {
  const { onChange, value, max, min = 0, defaultValue = 0, step = 1 } = props
  const marks = props.marks?.length
    ? props.marks
    : Array.from({ length: max + 1 })?.reduce((acc, value, index) => {
        return index >= min
          ? [
              ...acc,
              { label: index === max ? index + "+" : index, value: index }
            ]
          : acc
      }, [])

  const valueLabelFormat = value => {
    return marks?.find(mark => mark.value === value)?.label
  }

  return (
    <div className="range-slider-wrapper">
      <Slider
        defaultValue={defaultValue}
        valueLabelDisplay="auto"
        step={step}
        min={min}
        max={max}
        onChange={onChange}
        value={value}
        marks={marks}
        valueLabelFormat={valueLabelFormat}
      />
    </div>
  )
}

export default RangeSlider
