import { all, call, put, takeLatest } from "redux-saga/effects"

// config
import { BASE_URL } from "../../../../config/app"
// utils
import XHR from "../../../../utils/XHR"

import {
  GET_ADMIN_DASHBOARD_REQUEST,
  GET_ACCOUNT_CREATION_ACTIVITY_REQUEST,
  GET_LOGIN_ACTIVITY_REQUEST,
  GET_APPLICATION_ACTIVITY_REQUEST,
  GET_REVIEW_ACTIVITY_REQUEST,
  GET_AGGREGATE_COHORT_REQUEST
} from "./type"

import {
  getAdminDashboardRequestSuccess,
  getAdminDashboardRequestFailure,
  getAccountCreationActivitySuccess,
  getAccountCreationActivityFailure,
  getLoginActivitySuccess,
  getLoginActivityFailure,
  getApplicationActivitySuccess,
  getApplicationActivityFailure,
  getReviewActivitySuccess,
  getReviewActivityFailure,
  getAggregateCohortSuccess,
  getAggregateCohortFailure
} from "./action"

const getAdminDashboardData = async () => {
  const URL = `${BASE_URL}api/v1/admin/dashboard/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* fetchDashboardInfo() {
  try {
    const response = yield call(getAdminDashboardData)
    yield put(getAdminDashboardRequestSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(getAdminDashboardRequestFailure(response?.data))
  }
}

const getAccountCreationActivityAPI = data => {
  const URL = `${BASE_URL}api/v1/admin/account&profileactivities/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* getAccountCreationActivityData({ data }) {
  try {
    const response = yield call(getAccountCreationActivityAPI, data)
    yield put(getAccountCreationActivitySuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(getAccountCreationActivityFailure(response?.data))
  }
}

const getLoginActivityAPI = data => {
  const URL = `${BASE_URL}api/v1/admin/loginactivities/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* getLoginActivityData({ data }) {
  try {
    const response = yield call(getLoginActivityAPI, data)
    yield put(getLoginActivitySuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(getLoginActivityFailure(response?.data))
  }
}

const getApplicationActivityAPI = data => {
  const URL = `${BASE_URL}api/v1/admin/jobapplicationactivities/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* getApplicationActivityData({ data }) {
  try {
    const response = yield call(getApplicationActivityAPI, data)
    yield put(getApplicationActivitySuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(getApplicationActivityFailure(response?.data))
  }
}

const getReviewActivityAPI = data => {
  const URL = `${BASE_URL}api/v1/admin/reviewctivities/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* getReviewActivityData({ data }) {
  try {
    const response = yield call(getReviewActivityAPI, data)
    yield put(getReviewActivitySuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(getReviewActivityFailure(response?.data))
  }
}
const getAggregateCohortAPI = data => {
  const URL = `${BASE_URL}api/v1/admin/userOverview/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* getAggregateCohortData({ data }) {
  try {
    const response = yield call(getAggregateCohortAPI, data)
    yield put(getAggregateCohortSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(getAggregateCohortFailure(response?.data))
  }
}

export default all([
  takeLatest(GET_ADMIN_DASHBOARD_REQUEST, fetchDashboardInfo),
  takeLatest(
    GET_ACCOUNT_CREATION_ACTIVITY_REQUEST,
    getAccountCreationActivityData
  ),
  takeLatest(GET_LOGIN_ACTIVITY_REQUEST, getLoginActivityData),
  takeLatest(GET_APPLICATION_ACTIVITY_REQUEST, getApplicationActivityData),
  takeLatest(GET_REVIEW_ACTIVITY_REQUEST, getReviewActivityData),
  takeLatest(GET_AGGREGATE_COHORT_REQUEST, getAggregateCohortData)
])
