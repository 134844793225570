import AddIcon from "@mui/icons-material/Add"
import React from "react"

import { getLanguageProficiencyLevel } from "../../../utils/helper"
import { Images } from "../../../theme/Images"
import IconButton from "../../IconButton"
import Image from "../../Image"
import LevelBar from "../../LevelBar"

// Style
import "../style.scss"

const LanguageCard = ({ isEdited, getLanguageInfo, isCandidate, onClick }) => {
  const handleOnClick = id => () => {
    onClick(id)
  }

  return (
    <>
      {isEdited ? (
        <div className="profile-details-card__body">
          {Boolean(getLanguageInfo?.length) && (
            <ul className="profile-details-card__body__language flex-align-center">
              {getLanguageInfo.map((item, index) => {
                const proficiencyLevel = getLanguageProficiencyLevel(
                  item?.proficiency
                )
                return (
                  <li
                    className="profile-details-card__body__language__tag flex-align-center"
                    key={item.id}
                  >
                    <div className="profile-details-card__body__language__tag__title">
                      {item?.language}
                    </div>
                    <div className="profile-details-card__body__language__tag__rating flex-align-center">
                      <LevelBar
                        level={proficiencyLevel > 0 ? proficiencyLevel : 1}
                      />
                      <span>
                        {parseInt(item?.experience || 0, 10)}y
                      </span>
                    </div>
                    {isCandidate && (
                      <div className="profile-details-card__body__language__tag__edit-img flex-justify-center">
                        <Image
                          src={Images.bgEditIcon}
                          onClick={handleOnClick(item.id)}
                        />
                      </div>
                    )}
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      ) : (
        <div className="profile-details-card__info-text">
          Please include any languages you speak, read, or write. Minimum of 1
          requested.
        </div>
      )}
      {isCandidate && (
        <div className="profile-details-card__action">
          <IconButton
            icon={<AddIcon />}
            label="Add Language"
            onClick={handleOnClick()}
          />
        </div>
      )}
    </>
  )
}

export default LanguageCard
