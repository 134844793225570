export const firstLetterCapital = value => {
  const format = value ? value.charAt(0).toUpperCase() + value.slice(1) : ''
  return format
}

export const titleCase = str => {
  return str.toLowerCase().split(" ").map(firstLetterCapital).join(" ")
}

export const convertToMilliSeconds = (value, duration) => {
  switch (duration) {
    case "days":
      return value * 24 * 60 * 60 * 1000

    case "hours":
      return value * 60 * 60 * 1000

    case "minutes":
      return value * 60 * 1000

    case "seconds":
      return value * 1000

    default:
      return value
  }
}

/**
 * @function sortByKey
 * sort the array of objects by given key
 *
 * @param   {Object[]}    data          - Array of json objects with key value pair
 * @param   {string}      sortBy        - key on which array of objects should be sorted
 * @param   {1|-1}        sortDirection - sort direction 1 for ascending, -1 for descending
 * @returns {Object[]}
 */
export const sortByKey = (sortBy, sortDirection = 1, data = [], sortType) => {
  return data.sort((a, b) => {
    let aValue
    let bValue
    if (sortType && sortType === "date") {
      aValue = a[sortBy] ? Date.parse(a[sortBy]) : ""
      bValue = b[sortBy] ? Date.parse(b[sortBy]) : ""
    } else {
      aValue =
        typeof a[sortBy] === "string" ? a[sortBy]?.toLowerCase() : a[sortBy]
      bValue =
        typeof b[sortBy] === "string" ? b[sortBy]?.toLowerCase() : b[sortBy]
    }

    if (aValue > bValue) return sortDirection
    if (aValue < bValue) return sortDirection * -1
    return 0
  })
}

export const formatBytes = (bytes, decimals) => {
  var SIZES = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  let i = 0
  let r
  let b
  for (i = 0, r = bytes, b = 1024; r > b; i++) {
    r /= b
  }

  return `${parseFloat(r.toFixed(decimals))} ${SIZES[i]}`
}

export const isArray = data => {
  return data && Array.isArray(data)
}

/**
 * Utility to download file from blob API response
 *
 * @param {Blob}    data        blob data from API response
 * @param {Object}  config      configuration for the file
 */
export const downloadExcelFile = (data, config = {}) => {
  const { contentTypeHeader, fileName } = config
  const downloadLink = window.document.createElement("a")
  downloadLink.href = URL.createObjectURL(
    new Blob([data], { type: contentTypeHeader })
  )
  downloadLink.download = fileName
  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
}

export const hexToRgb = hex => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null
}
