// GET LOGIN
export const POST_REQUEST_LOGIN = "POST_REQUEST_LOGIN"
export const POST_REQUEST_LOGIN_SUCCESS = "POST_REQUEST_LOGIN_SUCCESS"
export const POST_REQUEST_LOGIN_FAILURE = "POST_REQUEST_LOGIN_FAILURE"
export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN"
export const SET_USER_TYPE = "SET_USER_TYPE"
export const GET_USER_TYPE = "GET_USER_TYPE"
export const LOGOUT = "LOGOUT"
export const LOGIN_REQUEST_GOOGLE = "LOGIN_REQUEST_GOOGLE"
export const LOGIN_REQUEST_GOOGLE_SUCCESS = "LOGIN_REQUEST_GOOGLE_SUCCESS"
export const LOGIN_REQUEST_GOOGLE_FAILURE = "LOGIN_REQUEST_GOOGLE_FAILURE"
export const USER_STATE = "USER_STATE"

//MICROSOFT LOGIN
export const LOGIN_REQUEST_MICROSOFT = "LOGIN_REQUEST_MICROSOFT"
export const LOGIN_REQUEST_MICROSOFT_SUCCESS = "LOGIN_REQUEST_MICROSOFT_SUCCESS"
export const LOGIN_REQUEST_MICROSOFT_FAILURE = "LOGIN_REQUEST_MICROSOFT_FAILURE"

//DELETE ACCOUNT
export const POST_DELETE_ACCOUNT_REQUEST = "POST_DELETE_ACCOUNT_REQUEST"
export const POST_DELETE_ACCOUNT_REQUEST_SUCCESS =
  "POST_DELETE_ACCOUNT_REQUEST_SUCCESS"
export const POST_DELETE_ACCOUNT_REQUEST_FAILURE =
  "POST_DELETE_ACCOUNT_REQUEST_FAILURE"
