import AddIcon from "@mui/icons-material/Add"
import React from "react"

import Image from "../../Image"
import { Images } from "../../../theme/Images"
import IconButton from "../../IconButton"

import "../style.scss"

const CertificateCard = ({
  isEdited,
  getCertificateInfo,
  isCandidate,
  onClick
}) => {

  const addCertificate = () => {
    onClick()
  }

  return (
    <>
      {isEdited ? (
        <div className="profile-details-card__body">
          {Boolean(getCertificateInfo?.length) && (
            <ul className="profile-details-card__body__certificate flex-align-center">
              {getCertificateInfo.map((item, index) => (
                <CertificateTag
                  key={item.id}
                  isCandidate={isCandidate}
                  item={item}
                  onClick={onClick}
                ></CertificateTag>
              ))}
            </ul>
          )}
        </div>
      ) : (
        <div className="profile-details-card__info-text">
          Please include any certifications you have achieved.
        </div>
      )}
      {isCandidate && (
        <div className="profile-details-card__action">
          <IconButton
            icon={<AddIcon />}
            label="Add Certificate"
            onClick={addCertificate}
          />
        </div>
      )}
    </>
  )
}

const CertificateTag = props => {
  const {isCandidate, item, onClick } = props
  const handleOnClick = () => {
    onClick(item.id)
  }
  return (
    <>
      <li
          className="profile-details-card__body__certificate__tag flex-align-center"
        >
          <div className="profile-details-card__body__certificate__tag__title">
            {item?.certification_name?.title}
          </div>
          {isCandidate && (
            <div className="profile-details-card__body__certificate__tag__edit-img flex-justify-center">
              <Image
                src={Images.bgDelIcon}
                onClick={handleOnClick}
              />
            </div>
          )}
        </li>
    </>
  )
}

export default CertificateCard
