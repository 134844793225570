import Modal from "react-bootstrap/Modal"
import React, { useCallback, useEffect, useState } from "react"
import ResumeUpload from '../ResumeUpload'

import "./style.scss"

const UploadFile = ({ open, handleClose, loadProfileData }) => {
  const [modalOpen, setModelOpen] = useState(open || false)
  const closeModal = useCallback(() => {
    setModelOpen(false)
    handleClose && handleClose()
  }, [handleClose])

  useEffect(() => {
    setModelOpen(open)
  }, [open])

  return (
    <Modal
      className="upload-resume-modal"
      show={modalOpen}
      onHide={closeModal}
      centered
    >
      <Modal.Header className="upload-resume-modal__header">
        <span className="upload-resume-modal__header__upload-text">
          Upload your recent resume or CV
        </span>
      </Modal.Header>
      <Modal.Body>
        <ResumeUpload loadProfileData={loadProfileData} open={open} setModelOpen={setModelOpen} closeModal={closeModal} withParser={true}/>
      </Modal.Body>
    </Modal>
  )
}

export default UploadFile
