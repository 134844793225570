import {
  POST_CREATE_NEW_ROLE,
  POST_CREATE_NEW_ROLE_SUCCESS,
  POST_CREATE_NEW_ROLE_FAILURE,
  GET_ASSIGNEES_SUCCESS,
  GET_SKILLS_SCORE_REQUEST,
  GET_SKILLS_SCORE_SUCCESS
} from "./type"

const initialState = {
  postCreateRoleInfo: [],
  requesting: false,
  error: false,
  getCurrencyInfo: false,
  getJobInfo: false,
  assignees: [],
  skillsScoreRequesting: false,
  skillsScore: []
}

const createRoleReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_CREATE_NEW_ROLE:
      return {
        ...state,
        requesting: action?.data?.status
      }
    case POST_CREATE_NEW_ROLE_SUCCESS:
      return {
        ...state,
        requesting: false,
        postCreateRoleInfo: action.data
      }
    case POST_CREATE_NEW_ROLE_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
    case GET_ASSIGNEES_SUCCESS:
      return {
        ...state,
        assignees: action.data
      }

    case GET_SKILLS_SCORE_REQUEST:
      return {
        ...state,
        skillsScoreRequesting: true
      }

    case GET_SKILLS_SCORE_SUCCESS:
      return {
        ...state,
        skillsScore: action.data,
        skillsScoreRequesting: false
      }

    default:
      return state
  }
}

export default createRoleReducer
