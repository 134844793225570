const isDevEnv = process.env.NODE_ENV === "development"

const cleanUrl = function (url) {
  // Remove double slashes from url
  return url?.replace(/([^:]\/)\/+/g, "$1")
}

export const BASE_URL =
  process.env.REACT_APP_BASE_URL ||
  (isDevEnv
    ? `http://localhost:8000/`
    : "https://uiba-37655-staging.botics.co/"
  )
// export const BASE_CLIENT_URL = isDevEnv ? 'http://localhost:3000/' : process.env.REACT_APP_BASE_URL
export const BASE_CLIENT_URL = process.env.REACT_APP_BASE_URL
export const REDIRECT_URI = cleanUrl(`${BASE_CLIENT_URL}/auth/microsoft`)
export const GOOGLE_CLIENT_ID =
  process.env.REACT_APP_GOOGLE_CLIENT_ID ||
  "879959671065-ud0fe014li6da6nu5fnfejmalsl7jq54.apps.googleusercontent.com"
export const MICROSOFT_CLIENT_ID =
  process.env.REACT_APP_AZURE_CLIENT_ID
