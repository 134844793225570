export const GET_ADMIN_DASHBOARD_REQUEST = "GET_ADMIN_DASHBOARD_REQUEST"
export const GET_ADMIN_DASHBOARD_REQUEST_SUCCESS =
  "GET_ADMIN_DASHBOARD_REQUEST_SUCCESS"
export const GET_ADMIN_DASHBOARD_REQUEST_FAILURE =
  "GET_ADMIN_DASHBOARD_REQUEST_FAILURE"
export const GET_ACCOUNT_CREATION_ACTIVITY_REQUEST =
  "GET_ACCOUNT_CREATION_ACTIVITY_REQUEST"
export const GET_ACCOUNT_CREATION_ACTIVITY_SUCCESS =
  "GET_ACCOUNT_CREATION_ACTIVITY_SUCCESS"
export const GET_ACCOUNT_CREATION_ACTIVITY_FAILURE =
  "GET_ACCOUNT_CREATION_ACTIVITY_FAILURE"
export const GET_LOGIN_ACTIVITY_REQUEST = "GET_LOGIN_ACTIVITY_REQUEST"
export const GET_LOGIN_ACTIVITY_SUCCESS = "GET_LOGIN_ACTIVITY_SUCCESS"
export const GET_LOGIN_ACTIVITY_FAILURE = "GET_LOGIN_ACTIVITY_FAILURE"
export const GET_APPLICATION_ACTIVITY_REQUEST =
  "GET_APPLICATION_ACTIVITY_REQUEST"
export const GET_APPLICATION_ACTIVITY_SUCCESS =
  "GET_APPLICATION_ACTIVITY_SUCCESS"
export const GET_APPLICATION_ACTIVITY_FAILURE =
  "GET_APPLICATION_ACTIVITY_FAILURE"
export const GET_REVIEW_ACTIVITY_REQUEST = "GET_REVIEW_ACTIVITY_REQUEST"
export const GET_REVIEW_ACTIVITY_SUCCESS = "GET_REVIEW_ACTIVITY_SUCCESS"
export const GET_REVIEW_ACTIVITY_FAILURE = "GET_REVIEW_ACTIVITY_FAILURE"
export const GET_AGGREGATE_COHORT_REQUEST = "GET_AGGREGATE_COHORT_REQUEST"
export const GET_AGGREGATE_COHORT_SUCCESS = "GET_AGGREGATE_COHORT_SUCCESS"
export const GET_AGGREGATE_COHORT_FAILURE = "GET_AGGREGATE_COHORT_FAILURE"
