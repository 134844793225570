import { useEffect, useState, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"
import { debounce } from "@mui/material/utils"

import { getConceptListRequest } from "./redux/action"

const useConcept = props => {
  const { collection } = props || { concept: 'skills'}
  const conceptList = useSelector(state => {
    return state.conceptData.conceptList
  })
  const conceptListRequesting = useSelector(
    state => state.conceptData.conceptListRequesting
  )
  const [conceptOptions, setConceptOptions] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    setConceptOptions(conceptList)
  }, [conceptList])

  const fetchConceptList = useMemo(
    () =>
      debounce(
        searchText =>
          dispatch(getConceptListRequest({ input: searchText, collection })),
        500
      ),
    [dispatch, collection]
  )

  return {
    conceptOptions,
    setConceptOptions,
    conceptListRequesting,
    fetchConceptList
  }
}

export default useConcept
