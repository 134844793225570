import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getMetaDataRequest } from "./redux/action"
import {
  OnboardingTypes,
  workTimezones,
  skillProficiency,
  languageProficiencyLevels
} from "../../utils/constants"

const useMetaData = () => {
  const metaDataItems = useSelector(state => state.metaData.metaDataItems)
  const accessToken = useSelector(state => state.login.accessToken)
  const dispatch = useDispatch()
  const {
    countryList,
    phoneCodeList,
    hobbiesList,
    skillsList,
    degreesList,
    certificatesList,
    fillabilityLevelList,
    jobCategoryList,
    employmentTypeList,
    experienceLevelList,
    currencyList,
    compensationPeriodList
  } = metaDataItems || {}

  useEffect(() => {
    if (accessToken) {
      !(metaDataItems && metaDataItems?.rawData) &&
        dispatch(getMetaDataRequest())
    }
  }, [metaDataItems, accessToken, dispatch])

  return {
    countryList,
    phoneCodeList,
    hobbiesList,
    skillsList,
    degreesList,
    certificatesList,
    fillabilityLevelList,
    jobCategoryList,
    employmentTypeList,
    experienceLevelList,
    currencyList,
    compensationPeriodList,
    OnboardingTypes,
    workTimezones,
    skillProficiency,
    languageProficiencyLevels
  }
}

export default useMetaData
