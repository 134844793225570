import { all } from "redux-saga/effects"
import login from "../pages/AuthScreens/Login/redux/saga"
import signup from "../pages/AuthScreens/SignUp/redux/saga"
import forgotPassword from "../pages/AuthScreens/ForgotPassword/redux/saga"
import confirmPassword from "../pages/AuthScreens/ConfirmPassword/redux/saga"
import profile from "../pages/CandidateScreens/Profile/redux/saga"
import candidateJobs from "../pages/CandidateScreens/Home/redux/saga"
import recruiterprofile from "../pages/RecruiterScreen/RecruiterEditProfile/redux/saga"
import getprofileData from "../pages/RecruiterScreen/RecruiterProfile/redux/saga"
import selectedProfile from "../pages/CommonScreens/SelectedProfile/redux/saga"
import createRole from "../pages/CommonScreens/CreateRole/redux/saga"
import jobDetails from "../pages/CommonScreens/JobDetails/redux/saga"
import getJobsData from "../pages/CommonScreens/JobsList/redux/saga"
import candidateList from "../pages/RecruiterScreen/CandidateList/redux/saga"
import changePassword from "../pages/CommonScreens/ChangePassword/redux/saga"
import adminTeams from "../pages/AdminScreens/Teams/redux/saga"
import metaData from "../hooks/MetaData/redux/saga"
import adminDashboard from "../pages/AdminScreens/Dashboard/redux/saga"
import adminUsers from "../pages/AdminScreens/Users/redux/saga"
import adminTeamMembers from "../pages/AdminScreens/TeamMembers/redux/saga"
import locationData from "../hooks/Location/redux/saga"
import conceptData from "../hooks/Concept/redux/saga"

export function* rootSaga() {
  yield all([
    login,
    signup,
    forgotPassword,
    confirmPassword,
    profile,
    recruiterprofile,
    getprofileData,
    selectedProfile,
    createRole,
    jobDetails,
    getJobsData,
    candidateJobs,
    candidateList,
    changePassword,
    metaData,
    adminTeams,
    adminDashboard,
    adminUsers,
    adminTeamMembers,
    locationData,
    conceptData
  ])
}
