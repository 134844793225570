// Candidate Profile
export const GET_SELECTED_PROFILE_REQUEST = "GET_SELECTED_PROFILE_REQUEST"
export const GET_SELECTED_PROFILE_SUCCESS = "GET_SELECTED_PROFILE_SUCCESS"
export const GET_SELECTED_PROFILE_FAILURE = "GET_SELECTED_PROFILE_FAILURE"
export const GET_JOBS_APPLIED_REQUEST = "GET_JOBS_APPLIED_REQUEST"
export const GET_JOBS_APPLIED_SUCCESS = "GET_JOBS_APPLIED_SUCCESS"
export const GET_JOBS_APPLIED_FAILURE = "GET_JOBS_APPLIED_FAILURE"
export const POST_JOB_STATUS_REQUEST = "POST_JOB_STATUS_REQUEST"
export const POST_JOB_STATUS_SUCCESS = "POST_JOB_STATUS_SUCCESS"
export const POST_JOB_STATUS_FAILURE = "POST_JOB_STATUS_FAILURE"
export const GET_DOWNLOAD_PROFILE_REQUEST = "GET_DOWNLOAD_PROFILE_REQUEST"
export const GET_DOWNLOAD_PROFILE_SUCCESS = "GET_DOWNLOAD_PROFILE_SUCCESS"
export const POST_CANDIDATE_TYPE_REQUEST = "POST_CANDIDATE_TYPE_REQUEST"
export const POST_CANDIDATE_TYPE_SUCCESS = "POST_CANDIDATE_TYPE_SUCCESS"
export const POST_CALL_NOTES_REQUEST = "POST_CALL_NOTES_REQUEST"
export const GET_CALL_NOTES_REQUEST = "GET_CALL_NOTES_REQUEST"
export const CALL_NOTES_SUCCESS = "CALL_NOTES_SUCCESS"
export const CALL_NOTES_FAILURE = "CALL_NOTES_FAILURE"
export const GET_CANDIDATE_APPLICATION_REQUEST = 'GET_CANDIDATE_APPLICATION_REQUEST'
export const GET_CANDIDATE_APPLICATION_SUCCESS = 'GET_CANDIDATE_APPLICATION_SUCCESS'
export const GET_CANDIDATE_APPLICATION_FAILURE = 'GET_CANDIDATE_APPLICATION_FAILURE'
