import {
  POST_FORGOT_PASSWORD,
  POST_FORGOT_PASSWORD_SUCCESS,
  POST_FORGOT_PASSWORD_FAILURE,
  FORGOT_OTP_VERIFICATION,
  FORGOT_OTP_VERIFICATION_SUCCESS,
  FORGOT_OTP_VERIFICATION_FAILURE
} from "./type"

const initialState = {
  requesting: false,
  forgotInfo: false,
  error: false,
}
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case POST_FORGOT_PASSWORD:
      return {
        ...state,
        requesting: true,
      }
    case POST_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        requesting: false,
        forgotInfo: action.data
      }
    case POST_FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action?.error
      }
    case FORGOT_OTP_VERIFICATION:
      return {
        ...state,
        requesting: true
      }
    case FORGOT_OTP_VERIFICATION_SUCCESS:
      return {
        ...state,
        data: action.data,
        requesting: false
      }
    case FORGOT_OTP_VERIFICATION_FAILURE:
      return {
        ...state,
        error: action.error,
        requesting: false
      }
    default:
      return state
  }
}
