import React, { useState, useEffect, useCallback } from "react"
import dayjs from "dayjs"
import { connect } from "react-redux"

import Button from "../../../Button"
import UCheckbox from "../../../UCheckbox"
import UDatePicker from "../../../UDatePicker"
import UInput from "../../../UInput"
import {
  profileExperienceFailure,
  profileExperienceRequest,
  profileExperienceSuccess,
  deleteProfileExperienceRequest
} from "../../../../pages/CandidateScreens/Profile/redux/action"
import { useForm } from "../../../../hooks"
import validator from "../../../../utils/validation"
import { validateStartEndDate } from "../../../../utils/helper"

const ProfileExperience = props => {
  const {
    profileExperienceFailure,
    profileExperienceRequest,
    setShowModal,
    profileExperienceSuccess,
    BeError,
    requesting,
    profileExperienceData,
    selectedId,
    editedProfileExperience,
    deleteProfileExperience,
    deleteRequesting
  } = props

  const selectedExperience = editedProfileExperience?.find(
    item => item.id === selectedId
  )
  const [currentlyWorking, setCurrentlyWorking] = useState(
    selectedExperience ? !selectedExperience?.end_date : undefined
  )

  const closeModal = useCallback(() => {
    setShowModal(false)
    profileExperienceSuccess(false)
  }, [profileExperienceSuccess, setShowModal])

  useEffect(() => {
    profileExperienceFailure(false)
  }, [profileExperienceFailure])

  useEffect(() => {
    if (profileExperienceData) {
      closeModal()
    }
  }, [profileExperienceData, closeModal])

  const stateSchema = {
    position: {
      value: selectedExperience?.position,
      error: ""
    },
    company: {
      value: selectedExperience?.company,
      error: ""
    },
    description: {
      value: selectedExperience?.description,
      error: ""
    },
    startDate: {
      value: selectedExperience?.start_date,
      error: ""
    },
    endDate: {
      value: selectedExperience?.end_date,
      error: ""
    }
  }

  const validationStateSchema = {
    position: {
      required: true
    },
    company: {
      required: true
    },
    description: {
      required: true
    },
    startDate: {
      required: true
    },
    endDate: {
      required: false,
      validator: validator.requiredIf(() => {
        return currentlyWorking === false
      })
    }
  }
  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  useEffect(() => {
    if (
      !currentlyWorking &&
      !validateStartEndDate(state.startDate?.value, state.endDate.value)
    ) {
      setState(prevState => ({
        ...prevState,
        startDate: {
          value: state?.startDate.value,
          error: "Start date must be earlier than end date"
        }
      }))
    } else {
      setState(prevState => ({
        ...prevState,
        startDate: {
          value: state?.startDate.value,
          error: ""
        }
      }))
    }
  }, [state?.startDate.value, state.endDate.value, setState, currentlyWorking])

  useEffect(() => {
    handleDateChange("endDate")(state?.endDate?.value)
  }, [currentlyWorking])

  const handleInputOnChange = field => e => {
    handleOnChange(field, e.target.value)
  }

  const handleDateChange = field => newValue => {
    handleOnChange(field, newValue)
  }

  const handleCheckboxChange = e => {
    setCurrentlyWorking(e.target.checked)
  }

  const Submit = () => {
    const data = {
      id: selectedId,
      position: state.position.value,
      company: state.company.value,
      description: state.description.value || null,
      start_date: `${dayjs(state.startDate.value).format("YYYY-MM-DD")}`,
      end_date: currentlyWorking
        ? undefined
        : dayjs(state.endDate.value).format("YYYY-MM-DD"),
      currently_working: currentlyWorking || false
    }

    profileExperienceRequest(data)
  }

  const deleteExperience = () => {
    deleteProfileExperience({ id: selectedId })
  }

  const clearError = () => profileExperienceFailure(false)

  return (
    <div className="profile-body-input-wrapper profile-experience-input-wrapper">
      <div className="input-wrapper">
        <div className="info-text">
          Please include your employment history, role descriptions, and
          accomplishments in the role. Minimum of 1 requested.
        </div>
        <UInput
          placeholder="Company"
          onChange={handleInputOnChange("company")}
          onFocus={clearError}
          errorText={BeError?.company || state.company.error}
          errorClass={
            BeError?.company || state.company.error ? "opacity-1" : ""
          }
          inputError={BeError?.company || state.company.error}
          value={state.company.value}
        />
        <UInput
          placeholder="Role"
          onChange={handleInputOnChange("position")}
          onFocus={clearError}
          errorText={BeError?.position || state.position.error}
          errorClass={
            BeError?.position || state.position.error ? "opacity-1" : ""
          }
          inputError={BeError?.position || state.position.error}
          value={state.position.value}
        />
        <UDatePicker
          placeholderValue={"Start Date"}
          value={state?.startDate?.value || null}
          onChange={handleDateChange("startDate")}
          maxDate={dayjs()}
          views={["year", "month"]}
          inputFormat="MMM, YYYY"
        />
        <div
          className={`error-message
            ${
              BeError?.start_date || state?.startDate?.error
                ? "opacity-1"
                : currentlyWorking && "display-hidden"
            }`}
        >
          {BeError?.start_date || state?.startDate?.error}
        </div>
        {!currentlyWorking && (
          <>
            <UDatePicker
              placeholderValue={"End Date"}
              value={state?.endDate?.value || null}
              onChange={handleDateChange("endDate")}
              maxDate={dayjs()}
              views={["year", "month"]}
              inputFormat="MMM, YYYY"
            />
            <div
              className={`error-message
            ${
              BeError?.end_date || state?.endDate?.error
                ? "opacity-1"
                : "display-hidden"
            }`}
            >
              {BeError?.end_date || state?.endDate?.error}
            </div>
          </>
        )}
        <UCheckbox
          label="Use Current"
          onChange={handleCheckboxChange}
          checked={currentlyWorking}
          value={true}
        />
        <UInput
          placeholder="Description"
          minRows={1}
          multiline
          maxRows={6}
          errorText={BeError?.position || state.description.error}
          errorClass={
            BeError?.position || state.description.error ? "opacity-1" : ""
          }
          inputError={BeError?.description || state.description.error}
          value={state.description.value}
          onChange={handleInputOnChange("description")}
        />
      </div>
      <div className="button-wrapper">
        {selectedId && (
          <Button
            title="Delete"
            className="delete-btn"
            onClick={deleteExperience}
            showSpinner={deleteRequesting}
          />
        )}
        <Button
          onClick={Submit}
          title="Save"
          className={`${disable ? "disabled-btn" : ""}`}
          showSpinner={requesting}
          disabled={disable ? true : false}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  requesting: state.profile.requesting,
  BeError: state.profile.error,
  deleteRequesting: state.profile.deleteRequesting
})
const mapDispatchToProps = dispatch => ({
  profileExperienceRequest: data => dispatch(profileExperienceRequest(data)),
  profileExperienceFailure: data => dispatch(profileExperienceFailure(data)),
  profileExperienceSuccess: data => dispatch(profileExperienceSuccess(data)),
  deleteProfileExperience: data =>
    dispatch(deleteProfileExperienceRequest(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileExperience)
