import {
    GET_ALL_USERS_REQUEST,
    GET_ALL_USERS_REQUEST_SUCCESS,
    GET_ALL_USERS_REQUEST_FAILURE,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILURE
} from './type';

export const getAllUsers = data => ({
    type: GET_ALL_USERS_REQUEST,
    data
})

export const getAllUsersSuccess = data => ({
    type: GET_ALL_USERS_REQUEST_SUCCESS,
    data
})

export const getAllUsersFailure = data => ({
    type: GET_ALL_USERS_REQUEST_FAILURE,
    data
})

export const deleteUserRequest = data => ({
    type: DELETE_USER_REQUEST,
    data
})

export const deleteUserSuccess = data => ({
    type: DELETE_USER_SUCCESS,
    data
})

export const deleteUserFailure = data => ({
    type: DELETE_USER_FAILURE,
    data
})