import React from "react"

// Components
import FormControlLabel from "@mui/material/FormControlLabel"
import Radio from "@mui/material/Radio"

const URadio = ({
  label = "",
  value,
  onChange,
  defaultChecked,
  selectedValue,
  className,
  name,
  error
}) => {
  return (
    <div className="radio-wrapper">
      <FormControlLabel
        control={
          <Radio
            sx={{
              color: error ? "#B70000" : "#77787D",

              "&.Mui-checked": {
                color: error ? "#B70000" : "#1D8EE6"
              },
              "&:hover": {
                backgroundColor: "transparent",
                color: "#1D8EE6"
              }
            }}
            checked={selectedValue === value}
            onChange={onChange}
            value={value}
            name={name}
            color={error ? "error" : "secondary"}
            defaultChecked={defaultChecked}
            className={className}
            disableRipple
          />
        }
        label={label}
      />
    </div>
  )
}

export default URadio
