import {
    GET_ALL_TEAM_MEMBERS_REQUEST,
    GET_ALL_TEAM_MEMBERS_REQUEST_SUCCESS,
    GET_ALL_TEAM_MEMBERS_REQUEST_FAILURE,
    DELETE_TEAM_MEMBER_REQUEST,
    DELETE_TEAM_MEMBER_REQUEST_SUCCESS,
    DELETE_TEAM_MEMBER_REQUEST_FAILURE,
    ADD_TEAM_MEMBER_REQUEST,
    ADD_TEAM_MEMBER_REQUEST_SUCCESS,
    ADD_TEAM_MEMBER_REQUEST_FAILURE,
    DISPLAY_ADD_MEMBER_MODAL
} from './type'

const initialState = {
    membersInfo: [],
    error: false,
    requesting: false,
    deleteRequesting: false
}

const adminTeamMembersReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_TEAM_MEMBERS_REQUEST:
            return {
                ...state,
                requesting: true
            }
        case GET_ALL_TEAM_MEMBERS_REQUEST_SUCCESS: 
            return {
                ...state,
                membersInfo: action.data,
                requesting: false
            }
        case GET_ALL_TEAM_MEMBERS_REQUEST_FAILURE:
            return {
                ...state,
                requesting: false
            }
        case DELETE_TEAM_MEMBER_REQUEST:
            return {
                ...state,
                requesting: true,
                deleteRequesting: true
            }
        case DELETE_TEAM_MEMBER_REQUEST_SUCCESS:
            return {
                ...state,
                requesting: false,
                deleteRequesting: false
            }
        case DELETE_TEAM_MEMBER_REQUEST_FAILURE:
            return {
                ...state,
                requesting: false,
                deleteRequesting: false
            }
        case ADD_TEAM_MEMBER_REQUEST:
            return {
                ...state,
                requesting: true
            }
        case ADD_TEAM_MEMBER_REQUEST_SUCCESS:
            return {
                ...state,
                requesting: false
            }
        case ADD_TEAM_MEMBER_REQUEST_FAILURE:
            return {
                ...state,
                requesting: false,
                error: action.data
            }
        case DISPLAY_ADD_MEMBER_MODAL:
            return {
                ...state,
                displayModal: action.data
            }
        default:
            return state;
    }
}

export default adminTeamMembersReducer;