import React from "react"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import { Pie } from "react-chartjs-2"
import { chartJsNoDataPlugin } from "../../utils/helper"

ChartJS.register(ArcElement, Tooltip, Legend)
ChartJS.defaults.font.family = "Inter, sans-serif"

const PieChart = ({ labels, datasets }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false
      }
    }
  }

  const data = {
    labels,
    datasets
  }

  return <Pie options={options} data={data} plugins={[chartJsNoDataPlugin]} />
}

export default PieChart
