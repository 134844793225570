import {
  GET_CANDIDATE_APPLY_JOBS_REQUEST,
  GET_CANDIDATE_APPLY_JOBS_SUCCESS,
  GET_CANDIDATE_APPLY_JOBS_FAILURE,
  POST_CANDIDATE_APPLY_JOB_REQUEST,
  POST_CANDIDATE_APPLY_JOB_FAILURE,
  POST_CANDIDATE_APPLY_JOB_SUCCESS,
  GET_CANDIDATE_SAVED_JOBS_REQUEST,
  GET_CANDIDATE_SAVED_JOBS_SUCCESS,
  GET_CANDIDATE_SAVED_JOBS_FAILURE,
  POST_CANDIDATE_SAVED_JOB_REQUEST,
  POST_CANDIDATE_SAVED_JOB_SUCCESS,
  POST_CANDIDATE_SAVED_JOB_FAILURE,
  GET_ALL_CANDIDATE_JOBS,
  GET_ALL_CANDIDATE_JOBS_SUCCESS,
  GET_ALL_CANDIDATE_JOBS_FAILURE,
  GET_CANDIDATE_ARCHIVED_JOBS_REQUEST,
  GET_CANDIDATE_ARCHIVED_JOBS_SUCCESS,
  GET_CANDIDATE_ARCHIVED_JOBS_FAILURE,
  GET_CANDIDATE_INTERVIEWING_JOBS_REQUEST,
  GET_CANDIDATE_INTERVIEWING_JOBS_SUCCESS,
  GET_CANDIDATE_INTERVIEWING_JOBS_FAILURE,
  GET_CANDIDATE_OFFERED_JOBS_REQUEST,
  GET_CANDIDATE_OFFERED_JOBS_SUCCESS,
  GET_CANDIDATE_OFFERED_JOBS_FAILURE,
  GET_CANDIDATE_HIRED_JOBS_REQUEST,
  GET_CANDIDATE_HIRED_JOBS_SUCCESS,
  GET_CANDIDATE_HIRED_JOBS_FAILURE,
  GET_CANDIDATE_REJECTED_JOBS_REQUEST,
  GET_CANDIDATE_REJECTED_JOBS_SUCCESS,
  GET_CANDIDATE_REJECTED_JOBS_FAILURE,
  GET_JOB_DATA_BY_ID_REQUEST,
  GET_JOB_DATA_BY_ID_REQUEST_SUCCESS,
  GET_JOB_DATA_BY_ID_REQUEST_FAILURE,
  DISPLAY_JOBS_MODAL,
  GENERATE_FINGERPRINT_REQUEST
} from "./type"

export const getAllCandidateJobs = data => ({
  type: GET_ALL_CANDIDATE_JOBS,
  data
})

export const getAllCandidateJobsSuccess = data => ({
  type: GET_ALL_CANDIDATE_JOBS_SUCCESS,
  data
})

export const getAllCandidateJobsFailure = data => ({
  type: GET_ALL_CANDIDATE_JOBS_FAILURE,
  data
})

// saved jobs
export const getSavedJobsRequest = data => ({
  type: GET_CANDIDATE_SAVED_JOBS_REQUEST,
  data
})
export const getSavedJobsSuccess = data => ({
  type: GET_CANDIDATE_SAVED_JOBS_SUCCESS,
  data
})
export const getSavedJobsFailure = data => ({
  type: GET_CANDIDATE_SAVED_JOBS_FAILURE,
  data
})

export const postSavedJobRequest = data => ({
  type: POST_CANDIDATE_SAVED_JOB_REQUEST,
  data
})
export const postSavedJobSuccess = data => ({
  type: POST_CANDIDATE_SAVED_JOB_SUCCESS,
  data
})

export const postSavedJobFailure = data => ({
  type: POST_CANDIDATE_SAVED_JOB_FAILURE,
  data
})

// apply jobs
export const getApplyJobsRequest = data => ({
  type: GET_CANDIDATE_APPLY_JOBS_REQUEST,
  data
})
export const getApplyJobsSuccess = data => ({
  type: GET_CANDIDATE_APPLY_JOBS_SUCCESS,
  data
})
export const getApplyJobsFailure = data => ({
  type: GET_CANDIDATE_APPLY_JOBS_FAILURE,
  data
})

export const postApplyJobRequest = (data, callback) => ({
  type: POST_CANDIDATE_APPLY_JOB_REQUEST,
  data,
  callback
})
export const postApplyJobSuccess = data => ({
  type: POST_CANDIDATE_APPLY_JOB_SUCCESS,
  data
})
export const postApplyJobFailure = data => ({
  type: POST_CANDIDATE_APPLY_JOB_FAILURE,
  data
})

// archived jobs
export const getArchivedJobsRequest = data => ({
  type: GET_CANDIDATE_ARCHIVED_JOBS_REQUEST,
  data
})
export const getArchivedJobsSuccess = data => ({
  type: GET_CANDIDATE_ARCHIVED_JOBS_SUCCESS,
  data
})
export const getArchivedJobsFailure = data => ({
  type: GET_CANDIDATE_ARCHIVED_JOBS_FAILURE,
  data
})

// interviewing status
export const getInterviewingJobsRequest = data => ({
  type: GET_CANDIDATE_INTERVIEWING_JOBS_REQUEST,
  data
})
export const getInterviewingJobsSuccess = data => ({
  type: GET_CANDIDATE_INTERVIEWING_JOBS_SUCCESS,
  data
})
export const getInterviewingJobsFailure = data => ({
  type: GET_CANDIDATE_INTERVIEWING_JOBS_FAILURE,
  data
})

// offered status
export const getOfferedJobsRequest = data => ({
  type: GET_CANDIDATE_OFFERED_JOBS_REQUEST,
  data
})
export const getOfferedJobsSuccess = data => ({
  type: GET_CANDIDATE_OFFERED_JOBS_SUCCESS,
  data
})
export const getOfferedJobsFailure = data => ({
  type: GET_CANDIDATE_OFFERED_JOBS_FAILURE,
  data
})

// hired status
export const getHiredJobsRequest = data => ({
  type: GET_CANDIDATE_HIRED_JOBS_REQUEST,
  data
})
export const getHiredJobsSuccess = data => ({
  type: GET_CANDIDATE_HIRED_JOBS_SUCCESS,
  data
})
export const getHiredJobsFailure = data => ({
  type: GET_CANDIDATE_HIRED_JOBS_FAILURE,
  data
})

//rejected status
export const getRejectedJobsRequest = data => ({
  type: GET_CANDIDATE_REJECTED_JOBS_REQUEST,
  data
})
export const getRejectedJobsSuccess = data => ({
  type: GET_CANDIDATE_REJECTED_JOBS_SUCCESS,
  data
})
export const getRejectedJobsFailure = data => ({
  type: GET_CANDIDATE_REJECTED_JOBS_FAILURE,
  data
})

// job data by id
export const getJobDataByIdRequest = data => ({
  type: GET_JOB_DATA_BY_ID_REQUEST,
  data
})
export const getJobDataByIdRequestSuccess = data => ({
  type: GET_JOB_DATA_BY_ID_REQUEST_SUCCESS,
  data
})
export const getJobDataByIdRequestFailure = data => ({
  type: GET_JOB_DATA_BY_ID_REQUEST_FAILURE,
  data
})

export const displayJobsModal = data => ({
  type: DISPLAY_JOBS_MODAL,
  data
})

export const generateFingerprintRequest = data => ({
  type: GENERATE_FINGERPRINT_REQUEST,
  data
})
