import { connect } from "react-redux"
import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"

import { postConfirmPassword, postConfirmPasswordFailure } from "./redux/action"
import Button from "../../../components/Button"
import PasswordInput from "../../../components/PasswordInput"

import StrengthMeter from "../../../components/StrengthMeter"
import { useForm } from "../../../hooks"
import { MIN_PASSWORD_LENGTH, MAX_PASSWORD_LENGTH } from '../../../utils/constants'

const ConfirmPassword = props => {
  const {
    confirmPasswordRequest,
    requesting,
    error,
    otpInfo,
    postConfirmPasswordFailure
  } = props
  const history = useHistory()
  const [passMatched, setPassMatched] = useState(false)

  // Strenght Checker Progress Bar
  const [pwdInput, initValue] = useState({
    password: ""
  })
  const [confirmPwdInput, setConfirmPwdInput] = useState("")
  const [isError, setError] = useState(false)

  useEffect(() => {
    postConfirmPasswordFailure(false)
  }, [])

  const stateSchema = {
    password: {
      value: "",
      error: ""
    },
    confirm_password: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    password: {
      required: true
    },
    confirm_password: {
      required: true
    }
  }

  const { state, handleOnChange } = useForm(stateSchema, validationStateSchema)

  const onSubmit = async () => {
    const data = {
      new_password1: state.password.value,
      new_password2: state.confirm_password.value,
      token: otpInfo?.token,
      uid: otpInfo?.uid
    }
    // await confirmPasswordRequest(data, history)
    if (data.new_password1 === state.confirm_password.value) {
      await confirmPasswordRequest(data, history)
    } else if (!data.password) {
      setPassMatched("Please enter password")
    } else if (state.confirm_password.value) {
      setPassMatched("The password and confirmation password do not match.")
    }
  }

  const matchPassword = value => {
    setConfirmPwdInput(value)
    if (!state.password.value) {
      setPassMatched(false)
    } else if (value === state.password.value) {
      setPassMatched(false)
    } else {
      setPassMatched("The password and confirmation password do not match.")
    }
  }

  const validatePassword = value => {
    let caps, small, num, specialSymbol
    initValue({
      ...pwdInput,
      password: value
    })
    if (value?.length < 8) {
      setError(
        "Password should contain minimum 8 characters, with one UPPERCASE, lowercase, number and special character: @$! % * ? &"
      )
      return
    } else {
      caps = (value.match(/[A-Z]/g) || []).length
      small = (value.match(/[a-z]/g) || []).length
      num = (value.match(/[0-9]/g) || []).length
      specialSymbol = (value.match(/\W/g) || []).length
      if (caps < 1) {
        setError("Must add one UPPERCASE letter")
        return
      } else if (small < 1) {
        setError("Must add one lowercase letter")
        return
      } else if (num < 1) {
        setError("Must add one number")
        return
      } else if (specialSymbol < 1) {
        setError("Must add one special symbol: @$! % * ? &")
        return
      } else {
        setError(false)
      }
    }
  }

  const handleInputChange = field => e => {
    handleOnChange(field, e.target.value)
    if (field === "confirm_password") {
      matchPassword(e.target.value)
    }
    if (field === "password") {
      validatePassword(e.target.value)
    }
  }

  const clearErrors = () => postConfirmPasswordFailure(false)

  const goToSignIn = () => history.push("/auth/login")

  const [, initRobustPassword] = useState(null)
  const initPwdInput = async childData => {
    initRobustPassword(childData)
  }

  const shouldDisableConfirmButton = () => {
    return !!(!pwdInput.password || isError || !confirmPwdInput || passMatched)
  }

  return (
    <div className="auth-form-wrapper column-flex right-inside">
      <div className="first-wrapper">
        <div className="auth-welcome-text">
          <h1>Enter New Password</h1>
        </div>
      </div>
      <div className="second-wrapper">
        <PasswordInput
          focusedLabel="Password"
          placeholder="Password"
          value={state.password.value}
          onChange={handleInputChange("password")}
          onFocus={clearErrors}
          className={
            error && error?.new_password1?.[0] ? "error-field-wrapper" : ""
          }
          minLength={MIN_PASSWORD_LENGTH}
          maxLength={MAX_PASSWORD_LENGTH}
        />
        <StrengthMeter password={pwdInput.password} actions={initPwdInput} />
        {error && error?.new_password1?.[0] && (
          <div
            className={`error-message ${
              error && error?.new_password1?.[0] ? "opacity-1" : ""
            }`}
          >
            {error && error?.new_password1?.[0]}
          </div>
        )}
        {isError && (
          <div className={`error-message ${isError ? "opacity-1" : ""}`}>
            {isError}
          </div>
        )}
        <PasswordInput
          focusedLabel="Confirm Password"
          placeholder="Confirm Password"
          value={state.confirm_password.value}
          onChange={handleInputChange("confirm_password")}
          onFocus={clearErrors}
          className={
            error && error?.new_password2?.[0] ? "error-field-wrapper" : ""
          }
          minLength={MIN_PASSWORD_LENGTH}
          maxLength={MAX_PASSWORD_LENGTH}
        />
        {passMatched && (
          <div
            className={`error-message ${
              passMatched && passMatched ? "opacity-1" : ""
            }`}
          >
            {passMatched && passMatched}
          </div>
        )}
        <div
          className={`error-message ${
            error && error?.new_password2?.[0] ? "opacity-1" : ""
          }`}
        >
          {error && error?.new_password2?.[0]}
        </div>
        <Button
          title="Reset Password"
          onClick={onSubmit}
          showSpinner={requesting}
          className={`full-width ${shouldDisableConfirmButton() ? "disabled-btn" : "save-btn"}`}
        />
        <div className="sign-in" onClick={goToSignIn}>
          Sign In
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  requesting: state.confirmPassword.requesting,
  error: state.confirmPassword.error,
  otpInfo: state.confirmPassword.otpInfo
})

const mapDispatchToProps = dispatch => ({
  confirmPasswordRequest: (data, history) =>
    dispatch(postConfirmPassword(data, history)),
  postConfirmPasswordFailure: data => dispatch(postConfirmPasswordFailure(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPassword)
