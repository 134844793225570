import React from "react"
import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from "chart.js"
import { Line } from "react-chartjs-2"
import "chartjs-adapter-moment"

ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)
ChartJS.defaults.font.family = "Inter, sans-serif"

const LineChart = ({ labels, datasets }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index"
    },
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        type: "time",
        time: {
          unit: "month",
          displayFormats: {
            month: "MMM YY"
          },
          tooltipFormat: "MM-DD-YYYY",
          bounds: "data"
        },
        ticks: {
          source: "data"
        }
      },
      y: {
        grid: {
          color: "#F2F2F2"
        }
      }
    }
  }

  const data = {
    labels,
    datasets
  }

  return <Line options={options} data={data} />
}

export default LineChart
