import { all, call, put, takeLatest } from "redux-saga/effects"

import { BASE_URL } from "../../../config/app"
import { GET_CONCEPT_LIST_REQUEST } from "./type"
import { getConceptListSuccess } from "./action"
import XHR from "../../../utils/XHR"

const DEFAULT_SUGGESTION_COLLECTION = 'skills'

const getConceptListDataAPI = async data => {
  const URL = `${BASE_URL}api/v1/uiba-api/concept-autocomplete/?input=${data?.input}&collection=${data?.collection?? DEFAULT_SUGGESTION_COLLECTION}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getConceptListData({ data }) {
  try {
    const response = yield call(getConceptListDataAPI, data)
    yield put(getConceptListSuccess(response.data?.search || []))
  } catch (e) {
    yield put(getConceptListSuccess([]))
  }
}

export default all([
  takeLatest(GET_CONCEPT_LIST_REQUEST, getConceptListData)
])
