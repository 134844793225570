import { Route, Switch } from "react-router-dom"
import { routes } from "../../Router"
import HomeLayout from "../HomeLayout"

const Recruiter = () => {
  const getRoutes = routes => {
    const renderedRoutes = {}
    return routes.map((prop, key) => {
      const routePath = `${prop.layout}${
        prop.urlParam ? prop.urlParam : prop.path
      }`
      if (prop.layout === "/recruiter" && !renderedRoutes[routePath]) {
        renderedRoutes[routePath] = true

        return <Route path={routePath} component={prop.component} key={key} />
      } else {
        return null
      }
    })
  }

  return (
    <HomeLayout>
      <Switch>{getRoutes(routes)}</Switch>
    </HomeLayout>
  )
}

export default Recruiter
