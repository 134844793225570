export const GET_ALL_TEAM_MEMBERS_REQUEST = "GET_ALL_TEAM_MEMBERS_REQUEST"
export const GET_ALL_TEAM_MEMBERS_REQUEST_SUCCESS = "GET_ALL_TEAM_MEMBERS_REQUEST_SUCCESS"
export const GET_ALL_TEAM_MEMBERS_REQUEST_FAILURE = "GET_ALL_TEAM_MEMBERS_REQUEST_FAILURE"

export const DELETE_TEAM_MEMBER_REQUEST = "DELETE_TEAM_MEMBER_REQUEST"
export const DELETE_TEAM_MEMBER_REQUEST_SUCCESS = "DELETE_TEAM_MEMBER_REQUEST_SUCCESS"
export const DELETE_TEAM_MEMBER_REQUEST_FAILURE = "DELETE_TEAM_MEMBER_REQUEST_FAILURE"

export const ADD_TEAM_MEMBER_REQUEST = "ADD_TEAM_MEMBER_REQUEST"
export const ADD_TEAM_MEMBER_REQUEST_SUCCESS = "ADD_TEAM_MEMBER_REQUEST_SUCCESS"
export const ADD_TEAM_MEMBER_REQUEST_FAILURE = "ADD_TEAM_MEMBER_REQUEST_FAILURE"

export const DISPLAY_ADD_MEMBER_MODAL = "DISPLAY_ADD_MEMBER_MODAL"