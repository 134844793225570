import React, { useState } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import PrivacyTermsModal from "../../components/PrivacyTermsModal"
import { validateOTP, signupRegenerateOTP } from "./SignUp/redux/action"
import {
  postForgotRequest,
  forgotOTPVerification
} from "./ForgotPassword/redux/action"
import OTPScreen from "./OTPScreen"
import SignUp from "./SignUp"
import SignUpRedirect from "./SignUp/SignUpRedirect"
import Login from "./Login"
import ConfirmPassword from "./ConfirmPassword"
import ForgotPassword from "./ForgotPassword"

import "./style.scss"
import Logo from "../../components/Logo"

const AuthScreens = ({
  displayOTPModal,
  requesting,
  resendOTPRequest,
  verifyOTPDispatch,
  ...props
}) => {
  const [show, setShow] = useState(false)
  const [currentData, setCurrentData] = useState("")
  const history = useHistory()
  const { location } = history
  const [email, setEmail] = useState("")
  const [, setFirstName] = useState('')
  const [, setLastName] = useState('')

  const renderComponent = () => {
    const pathname = location?.pathname?.replace(/\/$/, "")

    switch (pathname) {
      case "/auth/signup":
      case "/auth/recruiter-signup":
        return {
          className: "signup-page-section",
          content: (
            <SignUp
              setEmail={setEmail}
              setFirstName={setFirstName}
              setLastName={setLastName}
              showTermsAndConditionsModal={showTermsAndConditionsModal}
            />
          )
        }

      case "/auth/login":
        return {
          className: "",
          content: <Login setEmail={setEmail} />
        }

      case "/auth/forgot_password":
        return {
          className: "forgot-password-page-section",
          content: <ForgotPassword setEmail={setEmail} />
        }

      case "/auth/confirm_password":
        return {
          className: "confirm-password-section",
          content: <ConfirmPassword setEmail={setEmail} />
        }

      case "/microsoft-oauth/redirect":
        return {
          className: "",
          content: <SignUpRedirect setEmail={setEmail} />
        }

      case "/auth/microsoft":
        return {
          className: "",
          content: <SignUpRedirect setEmail={setEmail} />
        }

      default:
        return {
          className: "",
          content: <Login setEmail={setEmail} />
        }
    }
  }

  const showPrivacyModal = () => {
    setShow(true)
    setCurrentData("privacy")
  }

  const showTermsAndConditionsModal = () => {
    setShow(true)
    setCurrentData("terms")
  }

  const { className, content } = renderComponent()

  return (
    <section
      className={`auth-page-section ${displayOTPModal ? "" : className}`}
    >
      <div className="container-fluid">
        <div className="row parent">
          <div className="col-md-5 col-lg-5 auth-left-side">
            <div className="main-logo">
              {
                //<span>Experis Manpower Group</span>
              }
              <Logo />
            </div>
            <div className="main-banner">
              <div className="main-banner-image" />
              <span>Experis</span>
            </div>
            {location?.pathname === "/auth/signup" ? (
              <div className="welcome-text">
                <h1>Welcome to the Experis X-Border Global Community</h1>
                <p>
                  At Experis, we connect millions of people each year with
                  meaningful and sustainable work. Now, in a world driven by
                  remote and agile working practices, we use our global reach
                  across 80 countries to connect you to the best opportunities
                  the world has to offer.
                </p>
              </div>
            ) : (
              <div className="welcome-text">
                <h1>The world of work is at your fingertips</h1>
                <p>
                  Search and apply for exciting global roles that match your
                  unique skills fingerprint and experience.
                </p>
              </div>
            )}
          </div>
          <div className="col-md-7 col-lg-5 auth-right-side">
            {!displayOTPModal && content}
            {displayOTPModal && (
              <OTPScreen
                showOTPModal={displayOTPModal}
                email={email}
                history={history}
                handleResendOTP={resendOTPRequest}
                handleVerifyOTP={verifyOTPDispatch}
                requesting={requesting}
              />
            )}
          </div>
          <div className="bottom-wrapper">
            <div className="privacy-text" onClick={showPrivacyModal}>
              View our <span>GDPR & Privacy Policies</span>
            </div>
            <div className="copyright">
              Copyright {new Date()?.getFullYear()}
            </div>
          </div>
        </div>
      </div>
      <PrivacyTermsModal
        show={show}
        setShow={setShow}
        currentData={currentData}
        setCurrentData={setCurrentData}
      />
    </section>
  )
}

const mapStateToProps = state => ({
  requesting:
    state.signup.requesting ||
    state.forgotPassword.requesting ||
    state.confirmPassword.requesting ||
    state.login.requesting,
  displayOTPModal: state.signup.displayOTPModal
})

const mapDispatchToProps = dispatch => ({
  resendOTPRequest: (data, history) =>
    dispatch(
      history?.location?.pathname === "/auth/forgot_password"
        ? postForgotRequest(data, history)
        : signupRegenerateOTP(data, history)
    ),
  verifyOTPDispatch: (data, history) =>
    dispatch(
      history?.location?.pathname === "/auth/forgot_password"
        ? forgotOTPVerification(data, history)
        : validateOTP(data, history)
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthScreens)
