import {
  POST_CONFIRM_PASSWORD,
  POST_CONFIRM_PASSWORD_SUCCESS,
  POST_CONFIRM_PASSWORD_FAILURE,
  FORGOT_PASSWORD_OTP_STATUS
} from "./type"

const initialState = {
  confirmInfo: false,
  requesting: false,
  error: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_CONFIRM_PASSWORD:
      return {
        ...state,
        requesting: true,
        error: false
      }
    case POST_CONFIRM_PASSWORD_SUCCESS:
      return {
        ...state,
        requesting: false,
        confirmInfo: action.data
      }
    case POST_CONFIRM_PASSWORD_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
    case FORGOT_PASSWORD_OTP_STATUS:
      return {
        ...state,
        requesting: false,
        otpInfo: action.data
      }
    default:
      return state
  }
}
