import {
  POST_RECRUITER_PROFILE_REQUEST,
  POST_RECRUITER_PROFILE_SUCCESS,
  POST_RECRUITER_PROFILE_FAILURE
} from "./type"

// POST_PAYMENT_INFORMATION
export const postRecruiterProfileRequest = (data, history) => ({
  type: POST_RECRUITER_PROFILE_REQUEST,
  data,
  history
})

export const postRecruiterProfileSuccess = data => ({
  type: POST_RECRUITER_PROFILE_SUCCESS,
  data
})

export const postRecruiterProfileFailure = data => ({
  type: POST_RECRUITER_PROFILE_FAILURE,
  data
})
