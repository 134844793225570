import React, { useState, useEffect, useCallback, useMemo } from "react"
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
} from "chart.js"
import { Radar } from "react-chartjs-2"

// Style
import "./style.scss"
import { sortByKey } from "../../utils/commonFunction"
import { getSkillProficiencyLevel } from "../../utils/helper"
import { MAX_RADAR_CHART_LABEL_LENGTH } from "../../utils/constants"
import { cropString } from "../../utils/b_string"

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
)
ChartJS.defaults.font.family = "Inter, sans-serif"

const RadarChart = ({ skillsInfo, compareSkillsInfo, legends }) => {
  const [graphData, setGraphData] = useState({ labels: [], data: [] })

  const chartDataConfig = [
    {
      backgroundColor: "rgba(144, 164, 174, 0.24)",
      borderColor: "#90A4AE",
      borderWidth: 1,
      pointBackgroundColor: "#607D8B",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgb(255, 99, 132)",
      fill: true
    },
    {
      backgroundColor: "rgba(255, 173, 107, 0.24)",
      borderColor: "#FFAD6B",
      borderWidth: 1,
      pointBackgroundColor: "#FFAD6B",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#855CF8",
      fill: true
    }
  ]

  // temporary hack to show some score. Remove once API is updated
  const getScore = ({ proficiency, year_of_experience: years, skillScore }) => {
    if (skillScore >= 0) {
      return skillScore * 100
    }

    if (typeof proficiency === "string") {
      proficiency = getSkillProficiencyLevel(proficiency)
    }

    const max = 100
    const score = 20 * proficiency - 10 + 3 * years
    return 0.5 * (max + score - Math.abs(max - score))
  }

  const getDateLabelAndDataSet = useCallback(() => {
    let labels
    let compareSkillsWithScore
    let data
    let compareData

    let skillsWithScore = skillsInfo?.map(item => ({
      ...item,
      score: getScore(item)
    }))
    skillsWithScore = sortByKey("score", -1, skillsWithScore)

    if (compareSkillsInfo?.length > 0) {
      compareSkillsWithScore = compareSkillsInfo.map(item => ({
        ...item,
        score: getScore(item)
      }))
      compareSkillsWithScore = sortByKey("score", -1, compareSkillsWithScore)
      let matchedSkills = compareSkillsWithScore?.filter(item =>
        skillsWithScore?.some(
          skillInfo =>
            skillInfo?.label?.toLowerCase() === item?.label?.toLowerCase()
        )
      )
      if (matchedSkills?.length < 10) {
        const notMatched = [...compareSkillsWithScore].filter(
          item =>
            !matchedSkills?.some(
              skillInfo =>
                skillInfo?.label?.toLowerCase() === item?.label?.toLowerCase()
            )
        )
        if (notMatched?.length) {
          matchedSkills = [...matchedSkills, ...notMatched]
        }
      }
      labels = matchedSkills?.map(item => item?.label).slice(0, 10)

      compareData = labels.reduce((acc, label) => {
        const skillInfo = compareSkillsWithScore?.find(
          item => item.label?.toLowerCase() === label?.toLowerCase()
        )
        return skillInfo ? [...acc, skillInfo.score] : [...acc, 1]
      }, [])

      data = labels.reduce((acc, label) => {
        const skillInfo = skillsWithScore?.find(
          item => item.label?.toLowerCase() === label?.toLowerCase()
        )
        return skillInfo ? [...acc, skillInfo?.score] : [...acc, 1]
      }, [])
    } else {
      labels = skillsWithScore?.map(item => cropString(item?.label, MAX_RADAR_CHART_LABEL_LENGTH)).slice(0, 10)
      data = skillsWithScore?.map(item => item.score).slice(0, 10)
    }

    return {
      labels,
      data: compareData ? [data, compareData] : [data],
      maxValue: Math.max(...(compareData ? [...data, ...compareData] : data))
    }
  }, [skillsInfo, compareSkillsInfo])

  useEffect(() => {
    setGraphData(getDateLabelAndDataSet())
  }, [getDateLabelAndDataSet])

  const options = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          display: false
        },
        title: {
          display: false
        }
      },
      scales: {
        r: {
          beginAtZero: true,
          grid: {
            drawTicks: false,
            color: function (context) {
              if (context.tick.value >= graphData?.maxValue) {
                return "#FFF"
              }
              return "#CFD8DC"
            }
          },
          ticks: {
            stepSize: 15,
            align: "inner",
            callback: function (val) {
              return val <= graphData?.maxValue
                ? this.getLabelForValue(val)
                : ""
            }
          }
        }
      }
    }),
    [graphData]
  )

  const data = {
    labels: graphData.labels || [],
    datasets: graphData.data?.map((data, i) => ({
      ...chartDataConfig[i],
      data
    }))
  }

  return (
    <div className="radar-chart-container">
      <Radar data={data} options={options} className="radar-chart" />
      {legends?.length && (
        <div className="radar-chart-legends">
          {legends?.map((legend, index) => (
            <label
              className={`radar-chart-legends-title legend-${index + 1}`}
              key={index}
            >
              {legend}
            </label>
          ))}
        </div>
      )}
    </div>
  )
}

export default RadarChart
