import { all, call, put, takeLatest } from "redux-saga/effects"
// import AsyncStorage from '@react-native-community/async-storage';
// config
import { BASE_URL } from "../../../../config/app"
// utils
import XHR from "../../../../utils/XHR"
// types
import {
  LOGIN_REQUEST_GOOGLE,
  LOGIN_REQUEST_MICROSOFT,
  LOGOUT,
  POST_REQUEST_LOGIN,
  POST_DELETE_ACCOUNT_REQUEST,
  GET_USER_TYPE
} from "./type"
// actions
import {
  loginRequestSuccess,
  loginRequestFailure,
  setAccessToken,
  googleLoginRequestSuccess,
  microsoftLoginRequestSuccess,
  googleLoginRequestFailure,
  microsoftLoginRequestFailure,
  deleteAccountSuccess,
  deleteAccountFailure,
  setUserType
} from "./action"
import { displayOTPModal, signupRegenerateOTP } from "../../SignUp/redux/action"
import toast from "react-hot-toast"
import dayjs from "dayjs"
import { firstLetterCapital } from "../../../../utils/commonFunction"

async function postLoginAPI(data) {
  const URL = `${BASE_URL}api/v1/login/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

async function logout() {
  const URL = `${BASE_URL}api/v1/logout/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* postLogout() {
  try {
    yield call(logout)
    toast.success("You have been successfully logged out.")
  } catch (e) {
    const { response } = e
    response.data?.detail !== "Invalid token." &&
      toast.error(firstLetterCapital(response.data?.detail))
  }
}

function* postLoginInfo({ data }) {
  let expiryTime
  if (localStorage.getItem("rememberMe")) {
    expiryTime = dayjs(new Date()).add(7, "d")
  } else {
    expiryTime = dayjs(new Date()).add(2, "h")
  }
  try {
    const response = yield call(postLoginAPI, data)
    yield put(
      loginRequestSuccess({
        ...response.data,
        expiryTime
      })
    )
    localStorage.setItem("token", response.data.token)
    yield put(setAccessToken(response.data.token))
    yield put(loginRequestFailure(false))
  } catch (e) {
    const { response } = e
    if (response?.data?.is_email_verified === false) {
      yield put(displayOTPModal(true))
      yield put(signupRegenerateOTP({ email: data?.username }))
    }
    response?.data?.error_message &&
      toast.error(firstLetterCapital(response.data.error_message))
    yield put(loginRequestFailure(response?.data?.error_message))
  }
}
async function googleLoginRequestAPI(data) {
  const URL = `${BASE_URL}api/v1/google/connect/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

async function microsoftLoginRequestAPI(data) {
  const URL = `${BASE_URL}api/v1/microsoft/connect`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* googleRequestLogin({ data }) {
  try {
    const response = yield call(googleLoginRequestAPI, data)
    let expiryTime
    if (localStorage.getItem("rememberMe")) {
      expiryTime = dayjs(new Date()).add(7, "d")
    } else {
      expiryTime = dayjs(new Date()).add(2, "h")
    }
    yield put(loginRequestSuccess({ ...response?.data, expiryTime }))
    yield put(googleLoginRequestSuccess(response?.data))
    localStorage.setItem("token", response?.data?.token)
    yield put(setAccessToken(response?.data?.token))
  } catch (e) {
    const { response } = e
    response?.data?.non_field_errors &&
      toast.error(firstLetterCapital(response.data.non_field_errors[0]))
    yield put(googleLoginRequestFailure(response?.data))
  }
}

function* microsoftRequestLogin({ data }) {
  try {
    const response = yield call(microsoftLoginRequestAPI, data)
    let expiryTime
    if (localStorage.getItem("rememberMe")) {
      expiryTime = dayjs(new Date()).add(7, "d")
    } else {
      expiryTime = dayjs(new Date()).add(2, "h")
    }
    yield put(loginRequestSuccess({ ...response?.data, expiryTime }))
    yield put(microsoftLoginRequestSuccess(response?.data))
    localStorage.setItem("token", response?.data?.token)
    yield put(setAccessToken(response?.data?.token))
  } catch (e) {
    const { response } = e
    response?.data?.non_field_errors &&
      toast.error(firstLetterCapital(response.data.non_field_errors[0]))
    yield put(microsoftLoginRequestFailure(response?.data))
  }
}

async function accountDelete(data) {
  const URL = `${BASE_URL}api/v1/cancel-account/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* deleteUser({ data, history }) {
  try {
    const response = yield call(accountDelete, data)
    if (response) {
      localStorage.clear()
      yield put(setAccessToken(false))
      toast.success(firstLetterCapital(response?.data?.msg))
      yield put(deleteAccountSuccess(response?.data))
    }
  } catch (e) {
    const { response } = e
    yield put(deleteAccountFailure(firstLetterCapital(response.data)))
    toast.error(firstLetterCapital(response?.data?.error))
  }
}

async function getUserTypeAPI() {
  const URL = `${BASE_URL}api/v1/userType/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getUserTypeData() {
  try {
    const response = yield call(getUserTypeAPI)
    yield put(setUserType(response?.data?.userType))
  } catch (e) {
    localStorage.clear()
  }
}

export default all([
  takeLatest(POST_REQUEST_LOGIN, postLoginInfo),
  takeLatest(LOGIN_REQUEST_GOOGLE, googleRequestLogin),
  takeLatest(LOGIN_REQUEST_MICROSOFT, microsoftRequestLogin),
  takeLatest(LOGOUT, postLogout),
  takeLatest(POST_DELETE_ACCOUNT_REQUEST, deleteUser),
  takeLatest(GET_USER_TYPE, getUserTypeData)
])
