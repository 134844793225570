// Candidate Profile
export const GET_CANDIDATE_PROFILE_REQUEST = "GET_CANDIDATE_PROFILE_REQUEST"
export const GET_CANDIDATE_PROFILE_REQUEST_SUCCESS =
  "GET_CANDIDATE_PROFILE_REQUEST_SUCCESS"
export const GET_CANDIDATE_PROFILE_REQUEST_FAILURE =
  "GET_CANDIDATE_PROFILE_REQUEST_FAILURE"

// types 1
export const GET_PROFILE_CERTIFICATION_REQUEST =
  "GET_PROFILE_CERTIFICATION_REQUEST"
export const POST_PROFILE_CERTIFICATION_REQUEST =
  "POST_PROFILE_CERTIFICATION_REQUEST"
export const POST_PROFILE_CERTIFICATION_SUCCESS =
  "POST_PROFILE_CERTIFICATION_SUCCESS"
export const POST_PROFILE_CERTIFICATION_FAILURE =
  "POST_PROFILE_CERTIFICATION_FAILURE"

// types 2
export const GET_PROFILE_EDUCATION_REQUEST = "GET_PROFILE_EDUCATION_REQUEST"
export const POST_PROFILE_EDUCATION_REQUEST = "POST_PROFILE_EDUCATION_REQUEST"
export const POST_PROFILE_EDUCATION_SUCCESS = "POST_PROFILE_EDUCATION_SUCCESS"
export const POST_PROFILE_EDUCATION_FAILURE = "POST_PROFILE_EDUCATION_FAILURE"
export const DELETE_PROFILE_EDUCATION_REQUEST =
  "DELETE_PROFILE_EDUCATION_REQUEST"

// types 3
export const GET_PROFILE_EXPERIENCE_REQUEST = "GET_PROFILE_EXPERIENCE_REQUEST"
export const POST_PROFILE_EXPERIENCE_REQUEST = "POST_PROFILE_EXPERIENCE_REQUEST"
export const POST_PROFILE_EXPERIENCE_SUCCESS = "POST_PROFILE_EXPERIENCE_SUCCESS"
export const POST_PROFILE_EXPERIENCE_FAILURE = "POST_PROFILE_EXPERIENCE_FAILURE"
export const DELETE_PROFILE_EXPERIENCE_REQUEST =
  "DELETE_PROFILE_EXPERIENCE_REQUEST"

// types 4
export const GET_PROFILE_HOBBIES_REQUEST = "GET_PROFILE_HOBBIES_REQUEST"
export const POST_PROFILE_HOBBIES_REQUEST = "POST_PROFILE_HOBBIES_REQUEST"
export const POST_PROFILE_HOBBIES_SUCCESS = "POST_PROFILE_HOBBIES_SUCCESS"
export const POST_PROFILE_HOBBIES_FAILURE = "POST_PROFILE_HOBBIES_FAILURE"

// types 5
export const POST_PROFILE_INFO_REQUEST = "POST_PROFILE_INFO_REQUEST"
export const POST_PROFILE_INFO_SUCCESS = "POST_PROFILE_INFO_SUCCESS"
export const POST_PROFILE_INFO_FAILURE = "POST_PROFILE_INFO_FAILURE"

// types 6
export const POST_PROFILE_LANGUAGE_REQUEST = "POST_PROFILE_LANGUAGE_REQUEST"
export const POST_PROFILE_LANGUAGE_SUCCESS = "POST_PROFILE_LANGUAGE_SUCCESS"
export const POST_PROFILE_LANGUAGE_FAILURE = "POST_PROFILE_LANGUAGE_FAILURE"
export const GET_PROFILE_LANGUAGE_REQUEST = "GET_PROFILE_LANGUAGE_REQUEST"
export const DELETE_PROFILE_LANGUAGE_REQUEST = "DELETE_PROFILE_LANGUAGE_REQUEST"

// types 7
export const GET_PROFILE_PROJECT_REQUEST = "GET_PROFILE_PROJECT_REQUEST"
export const POST_PROFILE_PROJECT_REQUEST = "POST_PROFILE_PROJECT_REQUEST"
export const POST_PROFILE_PROJECT_SUCCESS = "POST_PROFILE_PROJECT_SUCCESS"
export const POST_PROFILE_PROJECT_FAILURE = "POST_PROFILE_PROJECT_FAILURE"
export const DELETE_PROFILE_PROJECT_REQUEST = "DELETE_PROFILE_PROJECT_REQUEST"

// types 8
export const GET_PROFILE_SALARY_REQUEST = "GET_PROFILE_SALARY_REQUEST"
export const POST_PROFILE_SALARY_REQUEST = "POST_PROFILE_SALARY_REQUEST"
export const POST_PROFILE_SALARY_SUCCESS = "POST_PROFILE_SALARY_SUCCESS"
export const POST_PROFILE_SALARY_FAILURE = "POST_PROFILE_SALARY_FAILURE"

// types 9
export const GET_PROFILE_SKILL_REQUEST = "GET_PROFILE_SKILL_REQUEST"
export const POST_PROFILE_SKILL_REQUEST = "POST_PROFILE_SKILL_REQUEST"
export const GET_PROFILE_SKILL_SUCCESS = "GET_PROFILE_SKILL_SUCCESS"
export const GET_PROFILE_SKILL_FAILURE = "GET_PROFILE_SKILL_FAILURE"
export const DELETE_PROFILE_SKILL_REQUEST = "DELETE_PROFILE_SKILL_REQUEST"

export const GET_PROFILE_SOCIAL_MEDIA_REQUEST =
  "GET_PROFILE_SOCIAL_MEDIA_REQUEST"
export const POST_PROFILE_SOCIAL_MEDIA_REQUEST =
  "POST_PROFILE_SOCIAL_MEDIA_REQUEST"
export const GET_PROFILE_SOCIAL_MEDIA_SUCCESS =
  "GET_PROFILE_SOCIAL_MEDIA_SUCCESS"
export const GET_PROFILE_SOCIAL_MEDIA_FAILURE =
  "GET_PROFILE_SOCIAL_MEDIA_FAILURE"

export const POST_UPLOAD_RESUME_REQUEST = "POST_UPLOAD_RESUME_REQUEST"
export const POST_UPLOAD_RESUME_SUCCESS = "POST_UPLOAD_RESUME_SUCCESS"
export const POST_UPLOAD_RESUME_FAILURE = "POST_UPLOAD_RESUME_FAILURE"

export const POST_JOB_CONSENT_REQUEST = "POST_JOB_CONSENT_REQUEST"
export const POST_JOB_CONSENT_SUCCESS = "POST_JOB_CONSENT_SUCCESS"
export const POST_JOB_CONSENT_FAILURE = "POST_JOB_CONSENT_FAILURE"

export const GET_PROFILE_REQUIRED_INFO_REQUEST = "GET_PROFILE_REQUIRED_INFO_REQUEST"
export const GET_PROFILE_REQUIRED_INFO_SUCCESS = "GET_PROFILE_REQUIRED_INFO_SUCCESS"
export const GET_PROFILE_REQUIRED_INFO_FAILURE = "GET_PROFILE_REQUIRED_INFO_FAILURE"
