import dayjs from "dayjs"
import React from "react"

import { getFormattedLocation, getEmploymentTypeLabel } from "../../utils/helper"
import { Images } from "../../theme/Images"
import Image from "../Image"
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder"
import FavoriteIcon from "@mui/icons-material/Favorite"

import "./style.scss"
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

const DESCRIPTION_SNIPPET_LENGTH = 240

const getTextSnippet = (text, max_length=DESCRIPTION_SNIPPET_LENGTH) => {
  return text.length > 5 ? `${text.substring(0, max_length)}...` : text;
}

const JobCard = ({
  handleCardClick,
  jobData,
  isSelected,
  isSavedJob
}) => {
  return (
    <div
      className={`candidate-job-card ${isSelected ? "active" : ""}`}
      key={jobData?.id}
      onClick={handleCardClick}
    >
      <div className="job-title">
        <div className="job-name">{jobData?.title}</div>
        <div className="action-btns">
          <div className="like-job">
            {isSavedJob ? <FavoriteIcon /> : <FavoriteBorderIcon />}
          </div>
        </div>
      </div>
      <div className="job-info">
        {`${getEmploymentTypeLabel(jobData?.employment_type)}`}
      </div>
      <div
        className="job-description"
        dangerouslySetInnerHTML={{ __html: getTextSnippet(jobData?.description) }}
      ></div>
      <div className="skills">
        {jobData?.skills?.length > 0 &&
          jobData.skills?.slice(0, 4).map(({ label }, index) => {
            return (
              <div className="job-skill" key={`${index}-${label}`}>
                {label}
              </div>
            )
          })}
      </div>
      <div className="job-footer">
        <div className="job-location">
          <Image src={Images.location} />
          <div className="location">
            {getFormattedLocation(jobData?.locations)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default JobCard
