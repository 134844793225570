import {
  GET_PROFILE_EDUCATION_REQUEST,
  POST_PROFILE_EDUCATION_REQUEST,
  POST_PROFILE_EDUCATION_SUCCESS,
  POST_PROFILE_EDUCATION_FAILURE,
  DELETE_PROFILE_EDUCATION_REQUEST,
  GET_PROFILE_CERTIFICATION_REQUEST,
  POST_PROFILE_CERTIFICATION_REQUEST,
  POST_PROFILE_CERTIFICATION_SUCCESS,
  POST_PROFILE_CERTIFICATION_FAILURE,
  POST_PROFILE_EXPERIENCE_REQUEST,
  GET_PROFILE_EXPERIENCE_REQUEST,
  POST_PROFILE_EXPERIENCE_SUCCESS,
  POST_PROFILE_EXPERIENCE_FAILURE,
  DELETE_PROFILE_EXPERIENCE_REQUEST,
  POST_PROFILE_HOBBIES_REQUEST,
  POST_PROFILE_HOBBIES_SUCCESS,
  POST_PROFILE_HOBBIES_FAILURE,
  POST_PROFILE_INFO_REQUEST,
  POST_PROFILE_INFO_SUCCESS,
  POST_PROFILE_INFO_FAILURE,
  POST_PROFILE_LANGUAGE_REQUEST,
  POST_PROFILE_LANGUAGE_SUCCESS,
  POST_PROFILE_LANGUAGE_FAILURE,
  DELETE_PROFILE_LANGUAGE_REQUEST,
  GET_PROFILE_LANGUAGE_REQUEST,
  GET_PROFILE_PROJECT_REQUEST,
  POST_PROFILE_PROJECT_REQUEST,
  DELETE_PROFILE_PROJECT_REQUEST,
  POST_PROFILE_PROJECT_SUCCESS,
  POST_PROFILE_PROJECT_FAILURE,
  GET_PROFILE_SALARY_REQUEST,
  POST_PROFILE_SALARY_REQUEST,
  POST_PROFILE_SALARY_SUCCESS,
  POST_PROFILE_SALARY_FAILURE,
  GET_PROFILE_SKILL_REQUEST,
  GET_PROFILE_SKILL_SUCCESS,
  GET_PROFILE_SKILL_FAILURE,
  POST_PROFILE_SKILL_REQUEST,
  DELETE_PROFILE_SKILL_REQUEST,
  GET_CANDIDATE_PROFILE_REQUEST,
  GET_CANDIDATE_PROFILE_REQUEST_SUCCESS,
  GET_CANDIDATE_PROFILE_REQUEST_FAILURE,
  GET_PROFILE_HOBBIES_REQUEST,
  POST_UPLOAD_RESUME_SUCCESS,
  POST_UPLOAD_RESUME_FAILURE,
  GET_PROFILE_SOCIAL_MEDIA_FAILURE,
  GET_PROFILE_SOCIAL_MEDIA_SUCCESS,
  GET_PROFILE_SOCIAL_MEDIA_REQUEST,
  POST_PROFILE_SOCIAL_MEDIA_REQUEST,
  POST_JOB_CONSENT_REQUEST,
  POST_JOB_CONSENT_SUCCESS,
  POST_JOB_CONSENT_FAILURE,
  GET_PROFILE_REQUIRED_INFO_REQUEST,
  GET_PROFILE_REQUIRED_INFO_FAILURE,
  GET_PROFILE_REQUIRED_INFO_SUCCESS
} from "./type"

const initialState = {
  candidate_profile: [],
  skills: [],
  hobbies: [],
  education: [],
  experience: [],
  languages: [],
  certificate: [],
  projects: [],
  salary: {},
  certificateProviders: [],
  hobbiesList: [],
  requiredCandidateInfo: {},
  skillsList: [],
  socialMediaInfo: {},
  error: false,
  requesting: false,
  profileRequesting: false,
  skillsRequesting: false,
  hobbiesRequesting: false,
  educationRequesting: false,
  experienceRequesting: false,
  languagesRequesting: false,
  certificateRequesting: false,
  projectsRequesting: false,
  salaryRequesting: false,
  socialMediaInfoRequesting: false,
  requiredCandidateInfoRequesting: false,
  uploadResumeFailure: false,
  uploadResumeSuccess: false,
  deleteRequesting: false,
  jobConsentRequesting: false
}

const candidateProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CANDIDATE_PROFILE_REQUEST:
      return {
        ...state,
        profileRequesting: true
      }

    case GET_CANDIDATE_PROFILE_REQUEST_SUCCESS:
      return {
        ...state,
        profileRequesting: false,
        requesting: false,
        candidate_profile: action.data || []
      }

    case GET_CANDIDATE_PROFILE_REQUEST_FAILURE:
      return {
        ...state,
        profileRequesting: false,
        requesting: false,
        error: action.data
      }

    case GET_PROFILE_EDUCATION_REQUEST:
      return {
        ...state,
        educationRequesting: true
      }

    case POST_PROFILE_EDUCATION_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case POST_PROFILE_EDUCATION_SUCCESS:
      return {
        ...state,
        educationRequesting: false,
        requesting: false,
        education: action.data,
        deleteRequesting: false
      }

    case DELETE_PROFILE_EDUCATION_REQUEST:
      return {
        ...state,
        deleteRequesting: true
      }

    case POST_PROFILE_EDUCATION_FAILURE:
      return {
        ...state,
        requesting: false,
        educationRequesting: false,
        error: action.data,
        deleteRequesting: false
      }

    case GET_PROFILE_CERTIFICATION_REQUEST:
      return {
        ...state,
        certificateRequesting: true
      }

    case POST_PROFILE_CERTIFICATION_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case POST_PROFILE_CERTIFICATION_SUCCESS:
      return {
        ...state,
        requesting: false,
        certificateRequesting: false,
        certificate: action.data
      }
    case POST_PROFILE_CERTIFICATION_FAILURE:
      return {
        ...state,
        requesting: false,
        certificateRequesting: false,
        error: action.data
      }

    case GET_PROFILE_EXPERIENCE_REQUEST:
      return {
        ...state,
        experienceRequesting: true
      }

    case POST_PROFILE_EXPERIENCE_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case POST_PROFILE_EXPERIENCE_SUCCESS:
      return {
        ...state,
        requesting: false,
        experienceRequesting: false,
        deleteRequesting: false,
        experience: action.data
      }
    case POST_PROFILE_EXPERIENCE_FAILURE:
      return {
        ...state,
        requesting: false,
        experienceRequesting: false,
        deleteRequesting: false,
        error: action.data
      }

    case DELETE_PROFILE_EXPERIENCE_REQUEST:
      return {
        ...state,
        deleteRequesting: true
      }

    case GET_PROFILE_HOBBIES_REQUEST:
      return {
        ...state,
        hobbiesRequesting: false
      }

    case POST_PROFILE_HOBBIES_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case POST_PROFILE_HOBBIES_SUCCESS:
      return {
        ...state,
        requesting: false,
        hobbiesRequesting: false,
        hobbies: action.data
      }
    case POST_PROFILE_HOBBIES_FAILURE:
      return {
        ...state,
        requesting: false,
        hobbiesRequesting: false,
        error: action.data
      }

    case POST_PROFILE_INFO_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case POST_PROFILE_INFO_SUCCESS:
      return {
        ...state,
        requesting: false,
        profileRequesting: false,
        profileInfoData: action.data
      }
    case POST_PROFILE_INFO_FAILURE:
      return {
        ...state,
        requesting: false,
        profileRequesting: false,
        error: action.data
      }

    case POST_PROFILE_LANGUAGE_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case POST_PROFILE_LANGUAGE_SUCCESS:
      return {
        ...state,
        requesting: false,
        languagesRequesting: false,
        deleteRequesting: false,
        languages: action.data
      }

    case DELETE_PROFILE_LANGUAGE_REQUEST:
      return {
        ...state,
        deleteRequesting: true
      }

    case POST_PROFILE_LANGUAGE_FAILURE:
      return {
        ...state,
        requesting: false,
        languagesRequesting: false,
        deleteRequesting: false,
        error: action.data
      }
    case GET_PROFILE_LANGUAGE_REQUEST:
      return {
        ...state,
        languagesRequesting: true
      }

    case GET_PROFILE_PROJECT_REQUEST: {
      return {
        ...state,
        projectsRequesting: true
      }
    }

    case POST_PROFILE_PROJECT_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case DELETE_PROFILE_PROJECT_REQUEST:
      return {
        ...state,
        deleteRequesting: true
      }

    case POST_PROFILE_PROJECT_SUCCESS:
      return {
        ...state,
        requesting: false,
        projectsRequesting: false,
        projects: action.data,
        deleteRequesting: false
      }

    case POST_PROFILE_PROJECT_FAILURE:
      return {
        ...state,
        requesting: false,
        projectsRequesting: false,
        error: action.data,
        deleteRequesting: false
      }

    case GET_PROFILE_SALARY_REQUEST:
      return {
        ...state,
        salaryRequesting: true
      }

    case POST_PROFILE_SALARY_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case POST_PROFILE_SALARY_SUCCESS:
      return {
        ...state,
        requesting: false,
        salaryRequesting: false,
        salary: action.data
      }
    case POST_PROFILE_SALARY_FAILURE:
      return {
        ...state,
        requesting: false,
        salaryRequesting: false,
        error: action.data
      }

    case GET_PROFILE_SKILL_REQUEST:
      return {
        ...state,
        skillsRequesting: true
      }
    case POST_PROFILE_SKILL_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case DELETE_PROFILE_SKILL_REQUEST:
      return {
        ...state,
        deleteRequesting: true
      }

    case GET_PROFILE_SKILL_SUCCESS:
      return {
        ...state,
        requesting: false,
        deleteRequesting: false,
        skillsRequesting: false,
        skills: action.data
      }

    case GET_PROFILE_SKILL_FAILURE:
      return {
        ...state,
        requesting: false,
        skillsRequesting: false,
        deleteRequesting: false,
        error: action.data
      }

    case GET_PROFILE_SOCIAL_MEDIA_REQUEST:
      return {
        ...state,
        socialMediaInfoRequesting: true
      }

    case GET_PROFILE_SOCIAL_MEDIA_SUCCESS:
      return {
        ...state,
        requesting: false,
        socialMediaInfoRequesting: false,
        socialMediaInfo: action.data
      }

    case GET_PROFILE_SOCIAL_MEDIA_FAILURE:
      return {
        ...state,
        requesting: false,
        socialMediaInfoRequesting: false,
        error: action.data
      }

    case POST_PROFILE_SOCIAL_MEDIA_REQUEST:
      return {
        ...state,
        socialMediaInfoRequesting: true
      }

      case GET_PROFILE_REQUIRED_INFO_REQUEST:
        return {
          ...state,
          requiredCandidateInfoRequesting: true
        }

      case GET_PROFILE_REQUIRED_INFO_SUCCESS:
        return {
          ...state,
          requesting: false,
          requiredCandidateInfoRequesting: false,
          requiredCandidateInfo: action.data
        }

      case GET_PROFILE_REQUIRED_INFO_FAILURE:
        return {
          ...state,
          requesting: false,
          requiredCandidateInfoRequesting: false,
          error: action.data
        }

    case POST_UPLOAD_RESUME_SUCCESS:
      return {
        ...state,
        uploadResumeSuccess: action.data
      }

    case POST_UPLOAD_RESUME_FAILURE:
      return {
        ...state,
        uploadResumeFailure: action.data
      }

    case POST_JOB_CONSENT_REQUEST:
      return {
        ...state,
        jobConsentRequesting: true
      }

    case POST_JOB_CONSENT_SUCCESS:
      return {
        ...state,
        jobConsentRequesting: false
      }

    case POST_JOB_CONSENT_FAILURE:
      return {
        ...state,
        jobConsentRequesting: false,
        error: action.data
      }

    default:
      return state
  }
}

export default candidateProfileReducer
