import dayjs from "dayjs"
import React, { useCallback, useEffect } from "react"
import { connect } from "react-redux"
import { Route, Redirect } from "react-router-dom"
import { getUserType, logOut } from "../pages/AuthScreens/Login/redux/action"
import { isAdmin, isCandidate, isRecruiter } from "../utils/helper"
import {
  userTypeAdmin,
  userTypeCandidate,
  userTypeRecruiter
} from "../utils/constants"
import ReactGA from 'react-ga';

const RouteGuard = ({
  isProtected = false,
  accessToken,
  loginInfo,
  userType,
  dispatchLogout,
  dispatchGetUserType,
  component: Component,
  location,
  ...rest
}) => {
  const referrer =
    userType && location?.state?.referrer?.includes(`/${userType}/`)
      ? location?.state?.referrer
      : ""

  ReactGA.pageview(window.location.pathname + window.location.search)

  // TODO: Fix page load with correct parameters when userType is checked and goes from '' to say admin

  const isTokenValid = useCallback(() => {
    if (dayjs(loginInfo?.expiryTime).diff(dayjs(new Date()), "h") <= 0) {
      return false
    } else {
      return true
    }
  }, [loginInfo])

  useEffect(() => {
    const isValidToken = accessToken && isTokenValid()
    if (accessToken && !isValidToken) {
      dispatchLogout()
    } else if (isValidToken) {
      dispatchGetUserType()
    }
  }, [isTokenValid, accessToken, dispatchLogout, dispatchGetUserType])

  return (
    <Route
      {...rest}
      render={props => {
        if (isProtected) {
          return accessToken && isTokenValid() ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: "/auth/login" }} />
          )
        } else {
          return accessToken && isTokenValid() ? (
            isCandidate(userType) ? (
              <Redirect
                to={{
                  pathname: referrer || `/${userTypeCandidate}/profile`
                }}
              />
            ) : isAdmin(userType) ? (
              <Redirect
                to={{
                  pathname: referrer || `/${userTypeAdmin}/dashboard`
                }}
              />
            ) : (
              isRecruiter(userType) && (
                <Redirect
                  to={{
                    pathname: referrer || `/${userTypeRecruiter}/profile`
                  }}
                />
              )
            )
          ) : (
            <Component {...props} />
          )
        }
      }}
    />
  )
}

const mapStateToProps = state => ({
  accessToken: state.login.accessToken,
  loginInfo: state.login.loginInfo,
  userType: state.login.userType
})

const mapDispatchToProps = dispatch => ({
  dispatchLogout: data => dispatch(logOut(data)),
  dispatchGetUserType: data => dispatch(getUserType(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(RouteGuard)
