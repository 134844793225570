import React, { useState } from 'react';

// Components
import Modal from 'react-bootstrap/Modal';
import { Images } from '../../../theme/Images';
import Button from '../../Button';
import Image from '../../Image';

// Redux
import { connect } from 'react-redux';
import { logOut } from '../../../pages/AuthScreens/Login/redux/action';


const LogoutModal = ({ showLogoutModal, setShowLogoutModal, ...props }) => {

    const [requesting, setRequesting] = useState(false);

    const logOut = () => {
        setRequesting(true)
        setTimeout(() => {
            props.logOut()
            localStorage.clear()
            setRequesting(false)
        }, [1000]);
    }

    const hideLogoutModal = () => {
        setShowLogoutModal(false)
    }

    return (
        <Modal
            show={showLogoutModal}
            setShow={setShowLogoutModal}
            centered
            className='edit-modal logout-modal'
        >
            <Modal.Header>
                <Modal.Title>
                    <div className="wrapper flex-justify-end w-100">
                        <div className="close-icon"
                            onClick={hideLogoutModal}
                        >
                            <Image
                                src={Images.closeIcon}
                            />
                        </div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="profile-body-input-wrapper logout-input-wrapper">
                    <div className="main-title">
                        Are you sure you want to
                        log out from this account?
                    </div>
                    <div className="button-wrapper">
                        <Button
                            title='Cancel'
                            className='transparent-btn'
                            onClick={hideLogoutModal}
                        />
                        <Button
                            title='Logout'
                            className=''
                            onClick={logOut}
                            showSpinner={requesting}
                        />

                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}


const mapDispatchToProps = dispatch => ({
    logOut: data => dispatch(logOut(data)),
})

export default connect(null, mapDispatchToProps)(LogoutModal)
