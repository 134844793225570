import { useEffect, useState, useCallback } from "react"

const useTimer = (min = 4, sec = 30) => {
  const [minutes, setMinutes] = useState(min)
  const [seconds, setSeconds] = useState(sec)

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval)
        } else {
          setSeconds(59)
          setMinutes(minutes - 1)
        }
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [minutes, seconds])

  const setTimer = useCallback((m, s) => {
    m >= 0 && setMinutes(m)
    s >= 0 && setSeconds(s)
  }, [])

  return [minutes, seconds, setTimer]
}

export default useTimer
