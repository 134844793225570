import React, { useState, useEffect } from "react"

// Components
import Modal from "react-bootstrap/Modal"
import Button from "../../Button"
import PrivacyTermsModal from "../../PrivacyTermsModal"
import { getCookie, deleteCookie, setCookie } from "../../../utils/cookies"
import { convertToMilliSeconds } from "../../../utils/commonFunction"

import { Images } from "../../../theme/Images"
import Image from "../../Image"

// Style
import "../style.scss"

const CookiesModal = () => {
  const [showCookiesModal, setShowCookiesModal] = useState(false)
  const [show, setShow] = useState(false)
  const [currentData, setCurrentData] = useState("")

  useEffect(() => {
    const cookieConsent = getCookie("user_cookie_consent")
    setShowCookiesModal(cookieConsent !== "" ? false : true)
  }, [])

  const acceptCookieConsent = () => {
    deleteCookie("user_cookie_consent")
    setCookie("user_cookie_consent", 1, convertToMilliSeconds(30, "days"))
    setShowCookiesModal(false)
  }

  const declineCookieConsent = () => {
    setShowCookiesModal(false)
  }

  const showPrivacyModal = () => {
    setShow(true)
    setCurrentData("privacy")
  }

  return (
    <>
      <Modal
        show={showCookiesModal}
        setShow={setShowCookiesModal}
        className="auth-modal cookies-modal"
      >
        <Modal.Body>
          <div className="auth-modal-body-wrapper cookies-modal-body-wrapper">
            <div className="cookies-modal-body-wrapper__image-container">
              <Image
                src={Images.cookieIcon}
                className="cookies-modal-body-wrapper__image-container__icon"
              />
            </div>
            <div className="main-title cookies-modal-body-wrapper__title">
              Can we store cookies?
            </div>
            <div className="cookies-modal-body-wrapper__text">
              We use cookies to provide you with the best possible experience.
              They also allow us to analyze user behavior in order to constantly
              improve our app for you.
            </div>
            <div className="cookies-modal-body-wrapper__privacy-link">
              <span onClick={showPrivacyModal}>Privacy Policy</span>
            </div>
            <div className="button-wrapper flex-justify-center">
              <Button
                title="Decline"
                className="transparent-btn button-wrapper__decline-btn"
                onClick={declineCookieConsent}
              />
              <Button
                title="Accept"
                className="uiba-btn button-wrapper__accept-btn"
                onClick={acceptCookieConsent}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <PrivacyTermsModal
        show={show}
        setShow={setShow}
        currentData={currentData}
        setCurrentData={setCurrentData}
      />
    </>
  )
}

export default CookiesModal
