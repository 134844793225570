import { connect } from "react-redux"
import React, { useState, useEffect } from "react"

import Button from "../../../Button"
import UDatePicker from "../../../UDatePicker"
import UInput from "../../../UInput"
import USelect from "../../../USelect"

import {
  profileEducationFailure,
  profileEducationRequest,
  deleteProfileEducationRequest
} from "../../../../pages/CandidateScreens/Profile/redux/action"
import dayjs from "dayjs"
import UCheckbox from "../../../UCheckbox"
import { useMetaData, useForm } from "../../../../hooks"
import validator from "../../../../utils/validation"
import { validateStartEndDate } from "../../../../utils/helper"

const ProfileEducation = props => {
  const {
    profileEducationFailure,
    profileEducationRequest,
    BeError,
    selectedId,
    editProfileEducation,
    requesting,
    deleteProfileEducation,
    deleteRequesting
  } = props
  const { degreesList } = useMetaData()

  const selectedEducation = editProfileEducation?.find(
    item => item?.id === selectedId
  )
  const [stillStudying, setStillStudying] = useState(
    selectedEducation ? Boolean(selectedEducation?.still_studying) : undefined
  )
  const selectedDegree = selectedEducation?.degree
    ? { key: selectedEducation?.degree, label: selectedEducation?.degree }
    : null

  const stateSchema = {
    degree: {
      value: selectedDegree,
      error: ""
    },
    major: {
      value: selectedEducation?.major,
      error: ""
    },
    school: {
      value: selectedEducation?.school,
      error: ""
    },
    minor: {
      value: selectedEducation?.minor || "",
      error: ""
    },
    startDate: {
      value: selectedEducation?.start_date,
      error: ""
    },
    endDate: {
      value: selectedEducation?.end_date,
      error: ""
    }
  }

  const validationStateSchema = {
    degree: {
      required: true
    },
    major: {
      required: true
    },
    school: {
      required: true
    },
    minor: {
      required: false
    },
    startDate: {
      required: true
    },
    endDate: {
      required: false,
      validator: validator.requiredIf(() => {
        return stillStudying === false
      })
    }
  }
  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  useEffect(() => {
    if (
      !stillStudying &&
      !validateStartEndDate(state.startDate?.value, state.endDate.value)
    ) {
      setState(prevState => ({
        ...prevState,
        startDate: {
          value: state?.startDate.value,
          error: "Start date must be earlier than end date"
        }
      }))
    } else {
      setState(prevState => ({
        ...prevState,
        startDate: {
          value: state?.startDate.value,
          error: ""
        }
      }))
    }
  }, [state?.startDate.value, state.endDate.value, setState, stillStudying])

  useEffect(() => {
    handleDateChange("endDate")(state?.endDate?.value)
  }, [stillStudying])

  const Submit = () => {
    const data = {
      id: selectedId,
      degree: state.degree.value?.data,
      major: state.major.value,
      school: state.school.value,
      still_studying: Boolean(stillStudying),
      start_date: `${dayjs(state?.startDate?.value).format("YYYY-MM-DD")}`,
      end_date: stillStudying
        ? null
        : `${dayjs(state?.endDate?.value).format("YYYY-MM-DD")}`,
      minor: state.minor.value || null
    }

    profileEducationRequest(data)
  }

  const deleteEducation = () => {
    deleteProfileEducation({ id: selectedId })
  }

  const handleDateChange = field => newValue => {
    handleOnChange(field, newValue)
  }

  useEffect(() => {
    profileEducationFailure(false)
  }, [profileEducationFailure])

  const handleCheckboxChange = () => {
    setStillStudying(!stillStudying)
  }

  const handleInputOnChange = field => e =>
    handleOnChange(field, e.target.value)

  const handleDropDownOnChange = field => (e, value) =>
    handleOnChange(field, value)

  const clearError = () => profileEducationFailure(false)

  return (
    <div className="profile-body-input-wrapper profile-education-input-wrapper">
      <div className="input-wrapper">
        <div className="info-text">
          Please include your education history with the dates of attendance and
          degrees earned. Minimum of 1 requested.
        </div>
        <UInput
          placeholder="School"
          onChange={handleInputOnChange("school")}
          onFocus={clearError}
          errorText={
            (BeError?.school && BeError.school[0]) || state.school.error
          }
          errorClass={BeError?.school || state.school.error ? "opacity-1" : ""}
          inputError={BeError?.school || state.school.error}
          value={state.school.value}
        />
        <USelect
          placeholderLabel={"Select a Degree"}
          options={degreesList}
          onChange={handleDropDownOnChange("degree")}
          value={state.degree.value}
          inputError={BeError?.degree || state.degree.error}
          className={
            BeError?.degree || state.degree.error ? "error-field-wrapper" : ""
          }
        />
        <div
          className={`error-message
              ${BeError?.degree || state.degree.error ? "opacity-1" : ""}`}
        >
          {BeError?.degree || state.degree.error}
        </div>
        <UDatePicker
          placeholderValue={"Start Date"}
          value={state?.startDate?.value || null}
          onChange={handleDateChange("startDate")}
          views={["year", "month"]}
          inputFormat="MMM, YYYY"
          maxDate={dayjs()}
        />
        <div
          className={`error-message
                                ${
                                  BeError?.start_date || state?.startDate?.error
                                    ? "opacity-1"
                                    : stillStudying && "display-hidden"
                                }`}
        >
          {BeError?.start_date || state?.startDate?.error}
        </div>
        {!stillStudying && (
          <>
            <UDatePicker
              placeholderValue={"End Date"}
              value={state?.endDate?.value || null}
              onChange={handleDateChange("endDate")}
              views={["year", "month"]}
              inputFormat="MMM, YYYY"
              maxDate={dayjs()}
            />
            <div
              className={`error-message
                                ${
                                  BeError?.end_date || state?.endDate?.error
                                    ? "opacity-1"
                                    : "display-hidden"
                                }`}
            >
              {BeError?.end_date || state?.endDate?.error}
            </div>
          </>
        )}
        <UCheckbox
          label="Use Current"
          onChange={handleCheckboxChange}
          checked={stillStudying}
          value={true}
        />
        <UInput
          placeholder="Major"
          onChange={handleInputOnChange("major")}
          onFocus={clearError}
          errorText={BeError?.major || state?.major?.error}
          errorClass={BeError?.major || state?.major?.error ? "opacity-1" : ""}
          inputError={BeError?.major || state?.major?.error}
          value={state.major.value}
        />
        <UInput
          placeholder="Minor"
          onChange={handleInputOnChange("minor")}
          onFocus={clearError}
          errorText={BeError?.minor || state.minor.error}
          errorClass={BeError?.minor || state.minor.error ? "opacity-1" : ""}
          inputError={BeError?.minor || state.minor.error}
          value={state.minor.value}
        />
      </div>
      <div className="button-wrapper">
        {selectedId && (
          <Button
            title="Delete"
            className="delete-btn"
            onClick={deleteEducation}
            showSpinner={deleteRequesting}
          />
        )}
        <Button
          title="Save"
          className={`${disable ? "disabled-btn" : ""}`}
          onClick={Submit}
          showSpinner={requesting}
          disabled={disable ? true : false}
        />
      </div>
    </div>
  )
}
const mapStateToProps = state => ({
  profileEducationData: state.profile.profileEducationData,
  requesting: state.profile.requesting,
  BeError: state.profile.error,
  deleteRequesting: state.profile.deleteRequesting
})
const mapDispatchToProps = dispatch => ({
  profileEducationRequest: data => dispatch(profileEducationRequest(data)),
  profileEducationFailure: data => dispatch(profileEducationFailure(data)),
  deleteProfileEducation: data => dispatch(deleteProfileEducationRequest(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEducation)
