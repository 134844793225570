import { all, call, put, takeLatest } from "redux-saga/effects"

// config
import { BASE_URL } from "../../../../config/app"
// utils
import XHR from "../../../../utils/XHR"
import toast from "react-hot-toast"

import {
  GET_ALL_TEAM_MEMBERS_REQUEST,
  DELETE_TEAM_MEMBER_REQUEST,
  ADD_TEAM_MEMBER_REQUEST
} from "./type"

import {
  getAllTeamMembers,
  getAllTeamMembersSuccess,
  getAllTeamMembersFailure,
  deleteTeamMemberSuccess,
  deleteTeamMemberFailure,
  addTeamMemberSuccess,
  addTeamMemberFailure,
  displayAddMemberModal
} from "./action"

const getAdminTeamMembersData = async data => {
  const URL = `${BASE_URL}api/v1/admin/all-team-members/?team_id=${data?.team_id}&page=1&limit=100`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getTeamMembers({ data }) {
  try {
    const response = yield call(getAdminTeamMembersData, data)
    yield put(getAllTeamMembersSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(getAllTeamMembersFailure(response?.data))
  }
}

async function addNewTeamMember(data) {
  const URL = `${BASE_URL}api/v1/admin/team-member/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* adminAddTeamMember({ data }) {
  try {
    const response = yield call(addNewTeamMember, data)
    toast.success(response.data?.message)
    yield put(addTeamMemberSuccess(response?.data))
    yield put(displayAddMemberModal(false))
    yield put(getAllTeamMembers(data))
  } catch (e) {
    const { response } = e
    yield put(addTeamMemberFailure(response?.data))
    yield put(displayAddMemberModal(false))
    toast.error(
      response?.data?.error || "Some error occurred while adding team member"
    )
  }
}

const deleteTeamsRecord = async data => {
  const URL = `${BASE_URL}api/v1/admin/team-member/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "DELETE",
    data
  }
  return XHR(URL, options)
}

function* adminDeleteTeamMember({ data }) {
  try {
    const response = yield call(deleteTeamsRecord, data)
    yield put(deleteTeamMemberSuccess(response?.data))
    yield put(getAllTeamMembers(data))
    toast.success(response?.data?.message)
  } catch (e) {
    const { response } = e
    yield put(deleteTeamMemberFailure(response?.data))
    toast.error(
      response?.data?.error || "Some error occurred while deleting team member"
    )
  }
}

export default all([
  takeLatest(GET_ALL_TEAM_MEMBERS_REQUEST, getTeamMembers),
  takeLatest(DELETE_TEAM_MEMBER_REQUEST, adminDeleteTeamMember),
  takeLatest(ADD_TEAM_MEMBER_REQUEST, adminAddTeamMember)
])
