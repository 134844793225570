import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import React, { useState, useEffect, useCallback } from "react"

import Button from "../../../components/Button"
import ConsentModal from "../../../components/AuthModals/ConsentModal"
import PasswordInput from "../../../components/PasswordInput"
import PrivacyTermsModal from "../../../components/PrivacyTermsModal"
import SocialLogin from "../../../components/SocialLogin"
import StrengthMeter from "../../../components/StrengthMeter"
import UInput from "../../../components/UInput"
import { useForm } from "../../../hooks"
import { MIN_PASSWORD_LENGTH, MAX_PASSWORD_LENGTH } from "../../../utils/constants"
import {
  signupRequest,
  signupRequestFailure,
  signupRequestSuccess,
  googleSignupRequest,
  googleSignupRequestSuccess,
  googleSignupRequestFailure,
  microsoftSignupRequest,
  microsoftSignupRequestSuccess,
  microsoftSignupRequestFailure
} from "./redux/action"

const SignUp = props => {
  const {
    signupRequest,
    signupRequestFailure,
    error,
    requesting,
    googleSignupRequest,
    microsoftSignupRequest,
    microsoftSignupRequestFailure,
    signupRequestSuccess,
    setEmail,
    setFirstName,
    setLastName
  } = props
  const history = useHistory()

  const [show, setShow] = useState(false)
  const [currentData, setCurrentData] = useState("")
  const [passMatched, setPassMatched] = useState(false)
  const [showConsentModal, setShowConsentModal] = useState(false)
  const [checkValue1, setCheckValue1] = useState(false)
  const [checkValue2, setCheckValue2] = useState(false)
  const [providerState, setProviderState] = useState({
    provider: "",
    access_token: ""
  })

  useEffect(() => {
    if (signupRequestSuccess) {
      signupRequestFailure(false)
    }
  }, [signupRequestSuccess])

  useEffect(() => {
    signupRequestFailure(false)
  }, [])

  const stateSchema = {
    email: {
      value: "",
      error: ""
    },
    first_name: {
      value: "",
      error: ""
    },
    last_name: {
      value: "",
      error: ""
    },
    password: {
      value: "",
      error: ""
    },
    confirm_password: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    email: {
      required: true
    },
    first_name: {
      required: true
    },
    last_name: {
      required: true
    },
    password: {
      required: true
    },
    confirm_password: {
      required: true
    }
  }

  const { state, handleOnChange } = useForm(stateSchema, validationStateSchema)

  const checkUserType = () => {
    let userType = ""
    if (history) {
      if (history?.location?.pathname) {
        userType = history?.location?.pathname
          .split("/")
          .includes("recruiter-signup")
          ? "recruiter"
          : "candidate"
      }
    }
    return userType
  }

  const isRecruiter = () => {
    return checkUserType() === "recruiter"
  }
  // Submit Function

  const onSignup = useCallback(async () => {
    setShowConsentModal(false)

    let data = {
      user_type: checkUserType(),
      communication_consent: isRecruiter() ? false : checkValue1,
      profile_share_consent: isRecruiter() ? false : checkValue2
    }
    if (providerState.provider === "google") {
      data = { ...data, access_token: providerState.access_token }
      googleSignupRequest(data, history)
    } else if (providerState.provider === "microsoft") {
      data = { ...data, access_token: providerState.access_token }
      microsoftSignupRequest(data, history)
    } else {
      data = {
        ...data,
        email: state.email.value,
        first_name: state.first_name.value,
        last_name: state.last_name.value,
        password: state.password.value
      }
      if (data.password === state.confirm_password.value) {
        signupRequest(data, history)
      } else {
        if (!data.password) {
          setPassMatched("Please enter password")
        } else if (data.password !== state.confirm_password.value) {
          setPassMatched("The password and confirmation password do not match.")
        }
      }
    }
    setCheckValue1(false)
    setCheckValue2(false)
  }, [
    providerState,
    checkValue1,
    checkValue2,
    googleSignupRequest,
    microsoftSignupRequest,
    signupRequest,
    history,
    state
  ])

  // Confirm Match Password
  const matchPassword = value => {
    if (!state.password.value) {
      setPassMatched(false)
    } else if (value === state.password.value) {
      setPassMatched(false)
    } else {
      setPassMatched("The password and confirmation password do not match.")
    }
  }

  // Strenght Checker Progress Bar
  const [pwdInput, initValue] = useState({
    password: ""
  })
  const [isError, setError] = useState(false)
  const [validEmail, setValidEmail] = useState(false)
  const [validFirstName, setValidFirstName] = useState(false)
  const [validLastName, setValidLastName] = useState(false)

  const validatePassword = value => {
    let caps, small, num, specialSymbol
    initValue({
      ...pwdInput,
      password: value
    })
    if (value?.length < 8) {
      setError(
        "Password should contain minimum 8 characters, with one UPPERCASE, lowercase, number and special character: @$! % * ? &"
      )
      return
    } else {
      caps = (value.match(/[A-Z]/g) || []).length
      small = (value.match(/[a-z]/g) || []).length
      num = (value.match(/[0-9]/g) || []).length
      specialSymbol = (value.match(/\W/g) || []).length
      if (caps < 1) {
        setError("Must add one UPPERCASE letter")
        return
      } else if (small < 1) {
        setError("Must add one lowercase letter")
        return
      } else if (num < 1) {
        setError("Must add one number")
        return
      } else if (specialSymbol < 1) {
        setError("Must add one special symbol: @$! % * ? &")
        return
      } else {
        setError(false)
      }
    }
  }

  const validateEmail = value => {
    if (value.length) {
      if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        setValidEmail(false)
      } else {
        setValidEmail("Invalid Email Format")
      }
    } else {
      setValidEmail(false)
    }
  }

  const validateFirstName = value => {
    if (value && value.length > 1) {
      setValidFirstName(value)
      return
    }
    setValidFirstName(false)
  }

  const validateLastName = value => {
    if (value && value.length > 1) {
      setValidLastName(value)
      return
    }
    setValidLastName(false)
  }

  const [, initRobustPassword] = useState(null)
  const initPwdInput = async childData => {
    initRobustPassword(childData)
  }

  const onSignupError = useCallback(
    (provider, error) => {
      console.log({ provider, error })
    },
    [googleSignupRequestFailure, microsoftSignupRequestFailure]
  )

  const onSignupSuccess = useCallback(
    (provider, userData) => {
      if (userData.access_token) {
        setShowConsentModal(true)
        setProviderState({ access_token: userData.access_token, provider })
      }
    },
    [localStorage, googleSignupRequest, microsoftSignupRequest]
  )

  const onSignupClick = () => {
    if (!state.email.value) {
    } else if (!state.first_name.value) {
    } else if (!state.last_name.value) {
    } else if (!state.password.value) {
    } else if (!state.confirm_password.value) {
    } else {
      if (isRecruiter()) {
        onSignup()
      } else {
        setShowConsentModal(true)
        signupRequestFailure(false)
      }
    }
  }

  const handleInputChange = field => val => {
    handleOnChange(field, val.target.value)
    if (field === "password") {
      validatePassword(val.target.value)
    }
    if (field === "confirm_password") {
      matchPassword(val.target.value)
    }
    if (field === "email") {
      setEmail(val.target.value)
      validateEmail(val.target.value)
    }
    if (field === 'last_name') {
      validateFirstName(val.target.value)
      setLastName(val.target.value)
    }
    if (field === 'last_name') {
      validateLastName(val.target.value)
      setFirstName(val.target.value)
    }
  }

  const goToSignIn = () => {
    history.push("/auth/login")
  }

  return (
    <>
      <div className="auth-form-wrapper column-flex right-inside">
        <div className="first-wrapper">
          <div className="auth-welcome-text">
            <h1>Sign up for an account</h1>
            <p>Get started today by entering just a few details</p>
          </div>
        </div>
        <div className="">
          <div className="other-sign-in-options">
              { isRecruiter() &&
                <>
                  <SocialLogin
                      className="sign-in-options"
                      onLoginSuccess={onSignupSuccess}
                      onLoginError={onSignupError}
                      pretext="Sign Up"
                      userType={checkUserType()}
                    />
                    <div>
                      <hr></hr>
                    </div>
                </>
              }
          </div>
        </div>
        <div className="second-wrapper">
          <UInput
            placeholder="Email Address"
            inputProps={{className: "email-signup-input"}}
            value={state.email.value}
            onChange={handleInputChange("email")}
            id={error?.email ? "outlined-error-helper-text" : ""}
            inputError={error?.email}
            errorText={error?.email || state.email.error}
            errorClass={error || state.email.error ? "opacity-1" : ""}
          />
          <UInput
            placeholder="First Name"
            value={state.first_name.value}
            onChange={handleInputChange("first_name")}
            id={error?.first_name ? "outlined-error-helper-text" : ""}
            inputError={error?.first_name}
            errorText={error?.first_name || state.first_name.error}
            errorClass={error || state.first_name.error ? "opacity-1" : ""}
          />
          <UInput
            placeholder="Last Name"
            value={state.last_name.value}
            onChange={handleInputChange("last_name")}
            id={error?.last_name ? "outlined-error-helper-text" : ""}
            inputError={error?.last_name}
            errorText={error?.last_name || state.last_name.error}
            errorClass={error || state.last_name.error ? "opacity-1" : ""}
          />
          <PasswordInput
            focusedLabel="Password"
            placeholder="Password"
            value={state.password.value}
            onChange={handleInputChange("password")}
            className={
              error?.password &&
              error?.password[0] === "This field may not be blank."
                ? "error-field-wrapper"
                : ""
            }
            onFocus={() => {
              // signupRequestFailure(false)
              // setPassMatched(false)
            }}
            minLength={MIN_PASSWORD_LENGTH}
            maxLength={MAX_PASSWORD_LENGTH}
          />
          <StrengthMeter password={pwdInput.password} actions={initPwdInput} />
          {(state.password.error || isError) && (
            <div
              className={`error-message ${
                state.password.error || isError ? "opacity-1" : ""
              }`}
            >
              {error?.password || state.password.error || isError}
            </div>
          )}
          <PasswordInput
            focusedLabel="Confirm Password"
            placeholder="Confirm Password"
            value={state.confirm_password.value}
            onChange={handleInputChange("confirm_password")}
            // onFocus={() => {
            //   postConfirmPasswordFailure(false)
            //   setPassMatched(false)
            // }}
            minLength={MIN_PASSWORD_LENGTH}
            maxLength={MAX_PASSWORD_LENGTH}
          />
          {passMatched && passMatched.length && (
            <div
              className={`error-message ${
                passMatched && passMatched ? "opacity-1" : ""
              }`}
            >
              {passMatched && passMatched}
            </div>
          )}
          {validEmail && validEmail.length && (
            <div
              className={`error-message ${
                validEmail && validEmail ? "opacity-1" : ""
              }`}
            >
              {validEmail && validEmail}
            </div>
          )}
          <Button
            title="Sign Up"
            onClick={onSignupClick}
            className={
              validEmail?.length || isError?.length || passMatched?.length || !validLastName || !validFirstName
                ? "disabled-btn full-width"
                : " full-width"
            }
            showSpinner={requesting}
            tagClassId="signup-button"
          />
          <div className="account-exist">
            Already have an account? &nbsp;
            <span onClick={goToSignIn}>Sign In</span>
          </div>
        </div>
      </div>
      <PrivacyTermsModal
        show={show}
        setShow={setShow}
        currentData={currentData}
        setCurrentData={setCurrentData}
      />
      <ConsentModal
        showConsentModal={showConsentModal}
        setShowConsentModal={setShowConsentModal}
        onClick={onSignup}
        checkValue1={checkValue1}
        checkValue2={checkValue2}
        setCheckValue1={setCheckValue1}
        setCheckValue2={setCheckValue2}
      />
    </>
  )
}

const mapStateToProps = state => ({
  signupInfo: state.signup.signupInfo,
  error: state.signup.error,
  requesting: state.signup.requesting
})

const mapDispatchToProps = dispatch => ({
  signupRequest: (data, history) => dispatch(signupRequest(data, history)),
  signupRequestSuccess: (data, history) =>
    dispatch(signupRequestSuccess(data, history)),
  signupRequestFailure: (data, history) =>
    dispatch(signupRequestFailure(data, history)),
  googleSignupRequest: (data, history) =>
    dispatch(googleSignupRequest(data, history)),
  microsoftSignupRequest: (data, history) =>
    dispatch(microsoftSignupRequest(data, history)),
  googleSignupRequestFailure: (data, history) =>
    dispatch(googleSignupRequestFailure(data, history)),
  googleSignupRequestSuccess: (data, history) =>
    dispatch(googleSignupRequestSuccess(data, history)),
  microsoftSignupRequestSuccess: (data, history) =>
    dispatch(microsoftSignupRequestSuccess(data, history)),
  microsoftSignupRequestFailure: (data, history) =>
    dispatch(microsoftSignupRequestFailure(data, history))
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
