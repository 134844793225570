import AddIcon from "@mui/icons-material/Add"
import React from "react"

import { getSkillProficiencyLevel } from "../../../utils/helper"
import { Images } from "../../../theme/Images"
import IconButton from "../../IconButton"
import Image from "../../Image"
import LevelBar from "../../LevelBar"
import RadarChart from "../../RadarChart"

import "../style.scss"

const SkillCard = ({
  isEdited,
  isCandidate,
  getSkillInfo,
  onClick,
  compareSkillsInfo,
  legends,
  hideChart
}) => {

  const addSkill = () => {
    onClick()
  }

  return (
    <>
      {isEdited ? (
        <div className="profile-details-card__body">
          {(Boolean(getSkillInfo?.length) && !hideChart) && (
            <div className="profile-details-card__body__skills-graph">
              <RadarChart
                skillsInfo={getSkillInfo}
                compareSkillsInfo={compareSkillsInfo}
                legends={legends}
              />
            </div>
          )}
          {Boolean(getSkillInfo?.length) && (
            <ul className="profile-details-card__body__skills flex-align-center">
              {getSkillInfo?.map(item => {
                const proficiencyLevel = getSkillProficiencyLevel(
                  item?.proficiency
                )
                return (
                  <li
                    className="profile-details-card__body__skills__tag flex-justify-center"
                    key={`${item.id}-${item.label}`}
                  >
                    <SkillTag
                      item={item}
                      isCandidate={isCandidate}
                      proficiencyLevel={proficiencyLevel}
                      onClick={onClick}
                    ></SkillTag>
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      ) : (
        <div className="profile-details-card__info-text">
          Important: Please include all knowledge, skills, and abilities
          developed during your work and non-work experience (a minimum of 8).
          The more information Cross Border has to work with, the more accurate
          the assessment of your ability to excel in roles will be.
        </div>
      )}
      {isCandidate && (
        <div className="profile-details-card__action">
          <IconButton
            icon={<AddIcon />}
            label="Add Skills"
            onClick={addSkill}
            isCandidate={isCandidate}
          />
        </div>
      )}
    </>
  )
}

const SkillTag = props => {
  const {isCandidate, item, proficiencyLevel, onClick } = props
  const handleOnClick = () => {
    onClick(item.id)
  }

  return (
    <>
      <div className="profile-details-card__body__skills__tag__title">
        {item?.label}
      </div>
      <div className="profile-details-card__body__skills__tag__rating flex-align-center">
        <LevelBar
          level={proficiencyLevel > 0 ? proficiencyLevel : 1}
        />
        <span>{parseInt(item?.year_of_experience, 10)}y</span>
      </div>
      {isCandidate && (
        <div className="profile-details-card__body__skills__tag__edit-img flex-justify-center">
          <Image
            src={Images.bgEditIcon}
            onClick={handleOnClick}
          />
        </div>
      )}
    </>
  )
}

export default SkillCard
