import { connect } from "react-redux"
import React, { useEffect, useMemo, useState } from "react"

import Button from "../../../Button"
import UInput from "../../../UInput"
import USelect from "../../../USelect"

import {
  profileInfoRequest,
  profileInfoFailure
} from "../../../../pages/CandidateScreens/Profile/redux/action"
import validator from "../../../../utils/validation"
import { useMetaData, useForm, useLocation } from "../../../../hooks"

const ProfileInfo = props => {
  const { phoneCodeList } = useMetaData()
  const {
    fetchLocationList,
    fetchLocationInfo,
    locationOptions,
    locationDetails,
    locationListRequesting,
    setLocationOptions
  } = useLocation()
  const [locationInput, setLocationInput] = useState("")

  const {
    profileInfoRequest,
    requesting,
    error,
    profileInfoFailure,
    editedPersonalInfoData
  } = props

  const stateSchema = useMemo(() => {
    const phoneNumberSplits = editedPersonalInfoData?.phone?.split(" ") || []
    const phoneCode = phoneNumberSplits[0]?.replace("+", "")
    const selectedPhoneCode = phoneCode
      ? phoneCodeList?.find(item => {
          return item.key === phoneCode
        }) || null
      : null
    return {
      firstName: {
        value: editedPersonalInfoData?.firstName,
        error: ""
      },
      lastName: {
        value: editedPersonalInfoData?.lastName,
        error: ""
      },
      email: {
        value: editedPersonalInfoData?.email,
        error: ""
      },
      phoneCode: {
        value: selectedPhoneCode,
        error: ""
      },
      phone: {
        value: phoneNumberSplits[1],
        error: ""
      },
      location: {
        value: editedPersonalInfoData?.locations?.length
        ? editedPersonalInfoData?.locations[0]?.formatted_address?? editedPersonalInfoData?.locations[0]?.description
        : "",
        error: ""
      },
      placeId: {
        value: editedPersonalInfoData?.place_id,
        error: ""
      }
    }
  }, [phoneCodeList, editedPersonalInfoData])

  const validationStateSchema = {
    firstName: {
      required: true
    },
    lastName: {
      required: true
    },
    email: {
      required: true,
      validator: validator.email
    },
    phoneCode: {
      required: true
    },
    phone: {
      required: true,
      validator: validator.phone
    },
    location: {
      required: false
    },
    placeId: {
      required: true
    }
  }

  const { state, handleOnChange, disable } = useForm(
    stateSchema,
    validationStateSchema
  )

  const handleInputChange = field => e => handleOnChange(field, e.target.value)

  useEffect(() => {
    setLocationOptions([])
  }, [setLocationOptions])

  useEffect(() => {
    if (
      locationDetails?.length > 0 &&
      locationDetails[0]?.place_id !== state.placeId?.value
    ) {
      handleInputChange("placeId")({
        target: { value: locationDetails[0]?.place_id }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationDetails])

  useEffect(() => {
    profileInfoFailure(false)
  }, [profileInfoFailure])

  useEffect(() => {
    if (
      editedPersonalInfoData?.place_id &&
      (!locationDetails?.length ||
        editedPersonalInfoData?.place_id !== locationDetails[0]?.place_id)
    ) {
      setLocationOptions([])
      fetchLocationInfo(editedPersonalInfoData?.place_id)
    }
  }, [fetchLocationInfo, editedPersonalInfoData?.place_id, locationDetails, setLocationOptions])

  const handleLocationInputChange = (e, value) => {
    setLocationInput(value)
    typeof value === "string" && value?.length >= 3
      ? fetchLocationList(value)
      : setLocationOptions([])
  }

  const handleSelectChange = field => (e, value) => {
    handleOnChange(field, value)
    field === "location" && fetchLocationInfo(value?.place_id)
  }

  const clearError = () => profileInfoFailure(false)

  const submit = () => {
    const data = {
      id: editedPersonalInfoData?.id,
      firstName: state.firstName.value,
      lastName: state.lastName.value,
      email: state.email.value,
      phone: `+${state.phoneCode.value?.key} ${state.phone.value}`,
      locations: locationDetails
    }
    profileInfoRequest(data)
  }

  return (
    <div className="profile-body-input-wrapper">
      <div className="input-wrapper">
        <UInput
          placeholder="First Name"
          value={state.firstName.value || ''}
          onChange={handleInputChange("firstName")}
          errorText={error?.firstName || state.firstName?.error}
          errorClass={
            error?.firstName || state.firstName?.error ? "opacity-1" : ""
          }
          onFocus={clearError}
          inputError={error?.firstName || state.firstName?.error}
        />
        <UInput
          placeholder="Last Name"
          value={state.lastName.value || ''}
          onChange={handleInputChange("lastName")}
          errorText={error?.lastName || state.lastName?.error}
          errorClass={
            error?.lastName || state.lastName?.error ? "opacity-1" : ""
          }
          onFocus={clearError}
          inputError={error?.lastName || state.lastName?.error}
        />
        <UInput
          placeholder="Email"
          value={state.email.value || ''}
          onChange={handleInputChange("email")}
          errorText={error?.email || state.email?.error}
          errorClass={error?.email || state.email?.error ? "opacity-1" : ""}
          onFocus={clearError}
          inputError={error?.email || state.email?.error}
        />
        <div className="phone-code-number">
          <div className="phone-code">
            <USelect
              placeholderLabel="Country Code"
              options={phoneCodeList}
              value={state.phoneCode.value || ''}
              onChange={handleSelectChange("phoneCode")}
              onFocus={clearError}
              className={
                error?.phoneCode || state.phoneCode.error
                  ? "error-field-wrapper"
                  : ""
              }
              inputError={error?.phoneCode || state.phoneCode.error}
              disableClearable
            />
            <div
              className={`error-message ${
                error?.phoneCode || state.phoneCode.error ? "opacity-1" : ""
              }`}
              style={{ marginLeft: "24px" }}
            >
              {error?.phoneCode || state.phoneCode.error}
            </div>
          </div>
          <div className="phone-number">
            <UInput
              id={error?.phone ? "outlined-error-helper-text" : ""}
              placeholder="Phone Number"
              value={state.phone.value || ''}
              onChange={handleInputChange("phone")}
              errorText={error?.phone || state.phone?.error}
              errorClass={error.phone || state.phone?.error ? "opacity-1" : ""}
              onFocus={clearError}
              inputError={error?.phone || state.phone?.error}
            />
          </div>
        </div>
        <USelect
          placeholderLabel="Start typing your address"
          options={locationOptions}
          value={state.location.value || ""}
          onChange={handleSelectChange("location")}
          onInputChange={handleLocationInputChange}
          onFocus={clearError}
          className={
            error?.location || state.placeId.error ? "error-field-wrapper" : ""
          }
          inputError={error?.location || state.placeId.error}
          noOptionsText={
            locationInput ? "No locations found" : "Type to search location"
          }
          locationData={true}
          loading={locationListRequesting}
        />
        <div
          className={`error-message
              ${error?.location || state.placeId.error ? "opacity-1" : ""}`}
        >
          {error?.location || state.placeId.error}
        </div>
      </div>
      <div className="button-wrapper">
        <Button
          title="Save"
          showSpinner={requesting}
          className={`${disable ? "disabled-btn" : ""}`}
          onClick={submit}
          disabled={disable}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  profileInfoData: state.profile.profileInfoData,
  requesting: state.profile.requesting,
  error: state.profile.error
})
const mapDispatchToProps = dispatch => ({
  profileInfoRequest: data => dispatch(profileInfoRequest(data)),
  profileInfoFailure: data => dispatch(profileInfoFailure(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileInfo)
