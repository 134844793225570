import { all, call, put, takeLatest } from "redux-saga/effects"

import { BASE_URL } from "../../../../config/app"
import {
  GET_ASSIGNEES_REQUEST,
  GET_SKILLS_SCORE_REQUEST,
  POST_CREATE_NEW_ROLE
} from "./type"
import {
  postCreateNewRoleFailure,
  postCreateNewRoleSuccess,
  getAssigneesSuccess,
  getSkillsScoreSuccess
} from "./action"
import XHR from "../../../../utils/XHR"
import toast from "react-hot-toast"

const postCreateNewRoleAPI = async data => {
  const jobId = data?.id ? `${data?.id}/` : ""
  const URL = `${BASE_URL}api/v1/recruiter/jobs/${jobId}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: data?.id ? "PUT" : "POST",
    data
  }
  return XHR(URL, options)
}

function* postCreateNewRole({ data, history }) {
  try {
    const response = yield call(postCreateNewRoleAPI, data)
    yield put(postCreateNewRoleSuccess(response.data))
    yield put(postCreateNewRoleFailure(false))
    const action = data?.status === "active" ? "posted" : "saved"
    toast.success(
      data?.id
        ? "Successfully updated role."
        : `Successfully ${action} new role.`
    )
    history.push(`/${data?.userType}/jobs/my_jobs`)
  } catch (e) {
    const { response } = e
    yield put(postCreateNewRoleFailure(response?.data))
    if (response?.data?.error) {
      toast.error(
        data?.id
          ? "Some error occurred while updating the role."
          : "Some error occurred while creating new role."
      )
    }
  }
}

const getAssigneesAPI = async data => {
  const URL = `${BASE_URL}api/v1/recruiter/assignees/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET",
    data
  }
  return XHR(URL, options)
}

function* getAssignees({ data }) {
  try {
    const response = yield call(getAssigneesAPI, data)
    yield put(getAssigneesSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getAssigneesSuccess(response.data))
  }
}

const getSkillsScoreAPI = async data => {
  const URL = `${BASE_URL}api/v1/recruiter/getskillscore/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* getSkillsScoreData({ data }) {
  try {
    const response = yield call(getSkillsScoreAPI, data)
    yield put(getSkillsScoreSuccess(response.data))
  } catch (e) {
    yield put(getSkillsScoreSuccess([]))
  }
}

export default all([
  takeLatest(POST_CREATE_NEW_ROLE, postCreateNewRole),
  takeLatest(GET_ASSIGNEES_REQUEST, getAssignees),
  takeLatest(GET_SKILLS_SCORE_REQUEST, getSkillsScoreData)
])
