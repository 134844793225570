import {
  chartColors,
  colorOrder,
  languageProficiencyLevels,
  skillProficiency,
  userTypeAdmin,
  userTypeCandidate,
  userTypeRecruiter,
  DEFAULT_CURRENCY_CODE
} from "./constants"
import countryData from "country-js"
import { sortByKey, titleCase, isArray } from "./commonFunction"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import dayjs from "dayjs"
import { convertToRaw, EditorState, ContentState } from "draft-js"
import { EMPLOYMENT_TYPES } from "./constants/metadata"
import React from "react";
import { useLocation } from "react-router-dom";

export const getSkillProficiencyLevel = proficiency => {
  if (Number.isInteger(proficiency) && proficiency < 5) {
    return proficiency + 1
  }

  return skillProficiency?.reduce((level, item, index) => {
    return item?.code === proficiency ? index + 2 : level
  }, 1)
}

export const getLanguageProficiencyLevel = proficiency => {
  if (Number.isInteger(proficiency) && proficiency < 5) {
    return proficiency + 1
  }

  return languageProficiencyLevels?.reduce((level, item, index) => {
    return item?.key === proficiency ? index + 2 : level
  }, 1)
}

export const getCurrencySymbol = currencyCode => {
  const countryInfo = countryData.search(currencyCode)
  if (countryInfo?.length) {
    return countryInfo[0].currency?.currencyCode
  }

  return DEFAULT_CURRENCY_CODE
}

export const dropdownFormat = (key, label, data) => {
  return { key, label: label || key, data }
}

export const sortByLabel = list => sortByKey("label", 1, list)

export const prepareDropdownList = (list, keyField, labelField) => {
  if (Array.isArray(list) && list?.length) {
    if (keyField && labelField) {
      const uniqueList = list?.reduce((acc, item) => {
        return { ...acc, [item[labelField]]: item }
      }, {})
      return Object.values(uniqueList).map(item => {
        const label =
          labelField === "degree"
            ? titleCase(item[labelField])
            : item[labelField]
        return dropdownFormat(item[keyField], label, item)
      })
    } else {
      return sortByLabel(
        Array.from(new Set(list))?.map(item => dropdownFormat(item, item))
      )
    }
  }

  return []
}

export const prepareCurrencyList = currencies => {
  if (currencies && typeof currencies === "object") {
    return prepareDropdownList(Object.values(currencies), "code", "code")
  }

  return []
}

export const prepareCountryAndPhoneCodeList = cities => {
  if (cities?.length) {
    const countries = {}
    const phoneCodes = []
    cities?.forEach(({ country, iso3, iso2, cities, ph_code: phoneCode }) => {
      countries[country] = { country, iso3, iso2, cities, phoneCode }
    })

    const countryList = Object.values(countries).map(
      ({ country, iso3, iso2, cities, phoneCode }) => {
        phoneCode &&
          phoneCodes.push({
            label: `${iso2}(+${phoneCode.replace("+", "")})`,
            key: phoneCode,
            countryCode: iso2
          })

        return {
          label: country,
          key: country,
          code: iso3,
          cities
        }
      }
    )
    return {
      countryList: sortByLabel(countryList),
      phoneCodeList: sortByLabel(phoneCodes)
    }
  }

  return {
    countryList: [],
    phoneCodeList: []
  }
}

export const prepareMetaData = data => {
  const { countryList, phoneCodeList } = prepareCountryAndPhoneCodeList(
    data?.cities
  )
  const metaData = {
    currencyList: prepareCurrencyList(data?.COMMON_CURRENCIES_EN),
    compensationPeriodList: prepareDropdownList(
      data?.COMPENSATION_PERIOD,
      "label",
      "label"
    ),
    skillsList: prepareDropdownList(data?.skills),
    degreesList: prepareDropdownList(data?.US_DEGREES, "acronym", "degree"),
    hobbiesList: prepareDropdownList(data?.hobbies),
    certificatesList: prepareDropdownList(
      data?.certificate,
      "acronym",
      "title"
    ),
    jobCategoryList: prepareDropdownList(
      data?.JOB_CATEGORIES_EN,
      "code",
      "label"
    ),
    employmentTypeList: prepareDropdownList(
      data?.EMPLOYMENT_TYPES,
      "code",
      "label"
    ),
    experienceLevelList: prepareDropdownList(
      data?.EXPERIENCE_LEVELS,
      "code",
      "label"
    ),
    fillabilityLevelList: prepareDropdownList(
      data?.FILLABILITY_LEVELS,
      "code",
      "label"
    ),
    countryList,
    phoneCodeList,
    rawData: data
  }

  return metaData
}

// metadataItems formatted as [{key, label, ...}]
export const getKeyToLabelData = (metadataItems) => {
  const dict = metadataItems?.reduce((dict, item, index) => (dict[item.key] = item.label, dict), {})
  return dict
}

export const convertDraftToHtml = editorState => {
  return (
    editorState && draftToHtml(convertToRaw(editorState.getCurrentContent()))
  )
}

export const convertHtmlToDraft = html => {
  const contentBlock = html && htmlToDraft(html)
  let editorState

  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks,
      contentBlock.entityMap
    )
    editorState = EditorState.createWithContent(contentState)
  }

  return editorState
}

export const validateStartEndDate = (start, end) => {
  if (start && end && dayjs(end).diff(start, "d") < 0) {
    return false
  }

  return true
}

export const appendId = response => {
  if (isArray(response)) {
    return response.map(item => appendId(item))
  } else if (response && typeof response === "object") {
    if (response?._id) {
      response.id = response._id
    }
    Object.keys(response).map(field => {
      return appendId(response[field])
    })
    return response
  } else {
    return response
  }
}

export const isURL = str => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)" + // protocol
      "(([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}" + // domain
      "(\\/[-a-z\\d%@_.~+&:]*)*" + // path
      "(\\?[;&a-z\\d%@_.,~+&:=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$", // fragments
    "i"
  )

  return pattern.test(str)
}

export const getAccountCreationChartData = data => {
  let formattedData = [
    {
      label: "Accounts created",
      borderColor: chartColors.orange,
      borderWidth: 1,
      backgroundColor: "#FFFFFF",
      cubicInterpolationMode: "monotone",
      tension: 0.4,
      data: []
    },
    {
      label: "Profiles created",
      borderColor: chartColors.blue,
      borderWidth: 1,
      backgroundColor: "#FFFFFF",
      cubicInterpolationMode: "monotone",
      tension: 0.4,
      data: []
    }
  ]

  if (data && typeof data === "object") {
    const sortedData = sortByKey(
      "dateStr",
      1,
      Object.keys(data).map(date => ({
        ...data[date],
        date: dayjs(date),
        dateStr: date
      })),
      "date"
    )

    formattedData = sortedData.reduce((acc, dataRow) => {
      acc[0].data.push({
        y: dataRow.account_creation,
        x: dataRow.dateStr
      })
      acc[1].data.push({
        y: dataRow.profile_creation,
        x: dataRow.dateStr
      })

      return acc
    }, formattedData)
  }

  return formattedData
}

export const getLoginActivityChartData = data => {
  let formattedData = [
    {
      type: "line",
      label: "Logged in Users",
      borderColor: chartColors.blue,
      borderWidth: 3,
      backgroundColor: "#FFFFFF",
      cubicInterpolationMode: "monotone",
      tension: 0.4,
      data: []
    },
    {
      type: "bar",
      label: "Active Users",
      borderColor: "#FFFFFF",
      borderWidth: 1,
      backgroundColor: chartColors.orange,
      data: []
    }
  ]

  if (data && typeof data === "object") {
    const sortedData = sortByKey(
      "dateStr",
      1,
      Object.keys(data).map(date => ({
        ...data[date],
        date: dayjs(date),
        dateStr: date
      })),
      "date"
    )

    formattedData = sortedData.reduce((acc, dataRow) => {
      acc[0].data.push({
        y: dataRow.login_users,
        x: dataRow.dateStr
      })
      acc[1].data.push({
        y: dataRow.active_accounts,
        x: dataRow.dateStr
      })

      return acc
    }, formattedData)
  }

  return formattedData
}

export const getReviewActivityChartData = data => {
  let formattedData = [
    {
      label: "in %",
      data: [],
      borderWidth: 0,
      backgroundColor: [chartColors.orange, chartColors.blue]
    }
  ]

  if (data && typeof data === "object") {
    formattedData[0].data = [data?.unsubscribed, data?.inactive]
  }

  return formattedData
}

export const getAggregateCohortChartData = (data, config) => {
  let formattedData = {
    datasets: [
      {
        label: "User # in %",
        data: [],
        borderWidth: 0,
        backgroundColor: []
      }
    ],
    labels: []
  }

  const chartConfig = sortByKey(
    "value",
    -1,
    config.reduce((acc, item) => {
      return [...acc, { value: data[item.key] || 0, ...item }]
    }, [])
  )

  formattedData = chartConfig?.reduce((acc, item, index) => {
    item.color = colorOrder[index]
    acc.datasets[0].data.push(data[item.key] || 0)
    acc.datasets[0].backgroundColor.push(chartColors[colorOrder[index]])
    acc.labels.push(item.label)

    return acc
  }, formattedData)

  return [formattedData, chartConfig]
}

export const isRecruiter = userType => userType === userTypeRecruiter

export const isCandidate = userType => userType === userTypeCandidate

export const isAdmin = userType => userType === userTypeAdmin

export const chartJsNoDataPlugin = {
  id: "noData-message",
  afterDraw: function (chart) {
    if (chart.data.datasets[0].data.every(item => item === 0)) {
      let ctx = chart.ctx
      let width = chart.width
      let height = chart.height

      chart.clear()
      ctx.save()
      ctx.textAlign = "center"
      ctx.textBaseline = "middle"
      ctx.fillStyle = "#77787D"
      ctx.font = "12px Inter"
      ctx.fillText("No data to display", width / 2, height / 2)
      ctx.restore()
    }
  }
}

export const getAddressFields = locationDetails => {
  // Get the place details from the autocomplete object.
  let address1 = ""
  let postcode = ""
  let city = ""
  let state = ""
  let country = ""

  if (typeof locationDetails !== "object") {
    return {}
  }

  // Get each component of the address from the place details,
  // and then fill-in the corresponding field on the form.
  // place.address_components are google.maps.GeocoderAddressComponent objects
  // which are documented at http://goo.gle/3l5i5Mr
  for (const component of locationDetails?.address_components) {
    // @ts-ignore remove once typings fixed
    const componentType = component.types[0]

    switch (componentType) {
      case "street_number": {
        address1 = `${component.long_name} ${address1}`
        break
      }

      case "route": {
        address1 += component.short_name
        break
      }

      case "postal_code": {
        postcode = `${component.long_name}${postcode}`
        break
      }

      case "postal_code_suffix": {
        postcode = `${postcode}-${component.long_name}`
        break
      }
      case "locality":
        city = component.long_name
        break

      case "administrative_area_level_1": {
        state = component.long_name
        break
      }
      case "country":
        country = component.long_name
        break

      default:
        address1 += ""
    }
  }

  city ||= state

  return { address1, city, state, country, postcode }
}

export const getFormattedLocation = locationDetails => {
  const { city, state, country } = getAddressFields(
    isArray(locationDetails) ? locationDetails[0] : locationDetails
  )

  return [city, state, country].filter(name => name)?.join(", ")
}

export const formatSkills = skills => {
  return (skills || [])?.map((item, index) => ({
    label: item?.label,
    proficiency: item?.proficiency,
    year_of_experience: item?.lengthOfUse,
    skillScore: item?.skillScore,
    id: item?.id || index,
    uri: item.uri,
    wikidata_id: item?.wikidata_id
  }))
}

export const formatSkillsForJobs = skills => {
  return (skills || [])?.map((item, index) => ({
    label: item?.label,
    proficiency: item?.proficiency,
    lengthOfUse: item?.year_of_experience,
    type: item?.label,
    skillScore: item?.skillScore,
    lang: "en",
    id: item?.id || index,
    uri: item.uri,
    wikidata_id: item?.wikidata_id
  }))
}

const employmentTypesCodeToLabbel = {}
EMPLOYMENT_TYPES.forEach((x) => {
  employmentTypesCodeToLabbel[x.code] = x.label
})

export const getEmploymentTypeLabel = (employment_type_code) => {
  return employmentTypesCodeToLabbel[employment_type_code]?? ''
}

export const currentUser = () => {
  const storageData = JSON.parse(localStorage.getItem('persist:login'))
  return JSON.parse(storageData?.loginInfo?? "{}")
}

// A custom hook that builds on useLocation to parse
// the query string for you.
export function useQueryParams() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
