
import {
    GET_TEAMS_DATA_REQUEST,
    GET_TEAMS_DATA_REQUEST_SUCCESS,
    GET_TEAMS_DATA_REQUEST_FAILURE,
    CLOSE_MODAL,
    ADD_NEW_TEAM,
    ADD_NEW_TEAM_SUCCESS,
    ADD_NEW_TEAM_FAILURE,
    DELETE_TEAM,
    DELETE_TEAM_FAILURE,
    DELETE_TEAM_SUCCESS,
    DELETE_TEAM_RECORD,
    POST_EDIT_TEAM_REQUEST,
    POST_EDIT_TEAM_REQUEST_SUCCESS,
    POST_EDIT_TEAM_REQUEST_FAILURE,
    EDIT_TEAM_RECORD
} from "./type";


const initialState = {
    teamsInfo: [],
    error: false,
    requesting: false
}

const adminTeamReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TEAMS_DATA_REQUEST:
            return {
                ...state,
                requesting: true,
            }

        case GET_TEAMS_DATA_REQUEST_SUCCESS:
            return {
                ...state,
                teamsInfo: action.data,
                requesting: false
            }
        case GET_TEAMS_DATA_REQUEST_FAILURE:
            return {
                ...state,
                error: action.data,
                requesting: false
            }
        case CLOSE_MODAL:
            return {
                ...state,
                displayModal: action.data
            }
        case ADD_NEW_TEAM:
            return {
                ...state,
                newTeamInfo: action.data,
                requesting: true
            }
        case ADD_NEW_TEAM_SUCCESS:
            return {
                ...state,
                newTeamInfo: action.data,
                requesting: false
            }
        case ADD_NEW_TEAM_FAILURE:
            return {
                ...state,
                error: action.error,
                requesting: false
            }
        case DELETE_TEAM:
            return {
                ...state,
                requesting: true,
            }
        case DELETE_TEAM_SUCCESS:
            return {
                ...state,
                deletedRecordSuccess: action.data
            }
        case DELETE_TEAM_FAILURE:
            return {
                ...state,
                deletedRecordError: action.error
            }
        case DELETE_TEAM_RECORD: {
            return {
                ...state,
                deleteTeam: action.info
            }
        }

        case POST_EDIT_TEAM_REQUEST: {
            return {
                ...state,
                requesting: true
            }
        }

        case POST_EDIT_TEAM_REQUEST_SUCCESS: {
            return {
                ...state,
                requesting: false,
                editTeamInfo: action.data
            }
        }

        case POST_EDIT_TEAM_REQUEST_FAILURE: {
            return {
                ...state,
                requesting: false,
                editTeamError: action.error
            }
        }

        case EDIT_TEAM_RECORD: {
            return {
                ...state,
                editTeamRecord: action.data
            }
        }
        
        default:
            return state;
    }
}

export default adminTeamReducer;