import React, { useState } from "react"

// Components
import UInput from "../../../UInput"
import Button from "../../../Button"
import USelect from "../../../USelect"
import { connect } from "react-redux"
import { useForm } from "../../../../hooks"
import { getAllJobsRequest } from "../../../../pages/CommonScreens/JobsList/redux/action"

const ApplyFilter = props => {
  const {
    BeError,
    profileLanguageFailure,
    languageProficiencyLevels,
    requesting,
    getAllJobs
  } = props
  const stateSchema = {
    AdressCityZipcode: {
      value: "",
      error: ""
    },
    ConpanyName: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    AdressCityZipcode: {
      required: true
    },
    ConpanyName: {
      value: "",
      error: ""
    }
  }
  const { state, handleOnChange } = useForm(
    stateSchema,
    validationStateSchema
  )

  const [selectEmploymentType, setselectEmploymentType] = useState({
    key: "",
    lable: ""
  })
  const [selectJobCategory, setSelectJobCategory] = useState({
    key: "",
    lable: ""
  })
  const [LevelofExperience, setLevelofExperience] = useState({
    key: "",
    lable: ""
  })
  const [fillabity, setFillabity] = useState({ key: "", lable: "" })

  const handleSelectEmploymentType = (event, option) => {
    setselectEmploymentType(option)
  }
  const handleSelectJobCategory = (event, option) => {
    setSelectJobCategory(option)
  }
  const handleFillabity = (event, option) => {
    setLevelofExperience(option)
  }
  const handleLevelofExperience = (event, option) => {
    setFillabity(option)
  }
  const Submit = () => {
    const data = {
      location: state.AdressCityZipcode.value
        ? state.AdressCityZipcode.value
        : "",
      client_company: state.ConpanyName.value ? state.ConpanyName.value : "",
      employment_type: selectEmploymentType.lable
        ? selectEmploymentType.lable
        : "",
      job_category: selectJobCategory.lable,
      experience_level: LevelofExperience.lable ? LevelofExperience.lable : "",
      fillability: fillabity.lable ? fillabity.lable : ""
    }

    getAllJobs(
      `?location=${data.location}&client_company=${data.client_company}&employment_type=${data.employment_type}&job_category=${data.job_category}&experience_level=${data.experience_level}&fillability=${data.fillability}`
    )
  }
  return (
    <div className="profile-body-input-wrapper profile-info-input-wrapper">
      <div className="input-wrapper">
        <UInput
          placeholder="Adress,city,zipcode"
          onChange={val =>
            handleOnChange("AdressCityZipcode", val.target.value)
          }
          onFocus={() => profileLanguageFailure(false)}
          errorText={BeError?.language && BeError.language[0]}
          errorClass={BeError && BeError.language ? "opacity-1" : ""}
          inputError={BeError?.language}
        />
        <UInput
          placeholder="Conpany Name"
          onChange={val => handleOnChange("ConpanyName", val.target.value)}
          onFocus={() => profileLanguageFailure(false)}
          errorText={BeError?.language && BeError.language[0]}
          errorClass={BeError && BeError.language ? "opacity-1" : ""}
          inputError={BeError?.language}
        />

        <USelect
          placeholderLabel={"Employment Type"}
          options={languageProficiencyLevels}
          onChange={handleSelectEmploymentType}
          getOptionLabel={option => option.lable}
        />

        <div
          className={`error-message
            ${BeError?.proficiency && BeError?.proficiency ? "opacity-1" : ""}`}
        >
          {BeError?.proficiency}
        </div>
        <USelect
          placeholderLabel={"Employment Type"}
          options={languageProficiencyLevels}
          onChange={handleSelectJobCategory}
          getOptionLabel={option => option.lable}
        />

        <div
          className={`error-message
              ${
                BeError?.proficiency && BeError?.proficiency ? "opacity-1" : ""
              }`}
        >
          {BeError?.proficiency}
        </div>
        <USelect
          placeholderLabel={"Level of Experience"}
          options={languageProficiencyLevels}
          onChange={handleLevelofExperience}
          getOptionLabel={option => option.lable}
        />

        <div
          className={`error-message
              ${
                BeError?.proficiency && BeError?.proficiency ? "opacity-1" : ""
              }`}
        >
          {BeError?.proficiency}
        </div>
        <USelect
          placeholderLabel={"Fillabity"}
          options={languageProficiencyLevels}
          onChange={handleFillabity}
          getOptionLabel={option => option.lable}
        />

        <div
          className={`error-message
              ${
                BeError?.proficiency && BeError?.proficiency ? "opacity-1" : ""
              }`}
        >
          {BeError?.proficiency}
        </div>
      </div>
      <div className="button-wrapper">
        <Button
          title="Save"
          onClick={Submit}
          className="purple-btn"
          showSpinner={requesting}
        />
      </div>
    </div>
  )
}
const mapStateToProps = state => ({
  languageData: state.profile.languageData,
  requesting: state.profile.requesting,
  BeError: state.profile.error
})
const mapDispatchToProps = dispatch => ({
  getAllJobs: data => dispatch(getAllJobsRequest(data))

  // signUpRequestSuccess: data => dispatch(signUpRequestSuccess(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ApplyFilter)
