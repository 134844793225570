import React from "react"
// Components
import ProgressBar from "react-bootstrap/ProgressBar"

// Style
import "./style.scss"

const UProgressbar = ({ progressValue, className = "" }) => {
  return (
    <div className={`progress-bar-wrapper flex-align-center ${className}`}>
      <ProgressBar now={progressValue} className="w-100" />
      <span className="label">{`${progressValue}%`}</span>
    </div>
  )
}

export default UProgressbar
