import React from "react"
import "./style.scss"

const Landing = () => {
  return (
    <>
      Login Successful
    </>
  )
}

export default Landing
