import { GET_META_DATA_SUCCESS } from "./type"

const initialState = {
  metaDataItems: {
    countryList: [],
    phoneCodeList: [],
    hobbiesList: [],
    skillsList: [],
    degreesList: [],
    certificatesList: [],
    fillabilityLevelList: [],
    jobCategoryList: [],
    employmentTypeList: [],
    experienceLevelList: [],
    currencyList: [],
    compensationPeriodList: [],
    OnboardingTypes: [],
    workTimezones: [],
    skillProficiency: [],
    languageProficiencyLevels: []
  }
}
const metaDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_META_DATA_SUCCESS:
      return {
        ...state,
        metaDataItems: action.data
      }
    default:
      return state
  }
}

export default metaDataReducer
