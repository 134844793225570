import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import React, { useEffect, useState, useCallback, useRef } from "react"
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined"

import IconButton from "../../../components/IconButton"
import AccordionCard from "../../../components/AccordionCard"
import CertificateCard from "../../../components/ProfileEditedCards/CertificateCard"
import EditModal from "../../../components/CandidateProfileEditModal"
import EducationCard from "../../../components/ProfileEditedCards/EducationCard"
import ExperienceCard from "../../../components/ProfileEditedCards/ExperienceCard"
import HobbiesCard from "../../../components/ProfileEditedCards/HobbiesCard"
import LanguageCard from "../../../components/ProfileEditedCards/LanguageCard"
import ProjectsCard from "../../../components/ProfileEditedCards/ProjectsCard"
import SkillCard from "../../../components/ProfileEditedCards/SkillCard"
import UploadFile from "../../../components/UploadFile"
import SalaryCard from "../../../components/ProfileEditedCards/SalaryCard"
import JobRecommendationConsentModal from "../../../components/AuthModals/JobRecommendationConsentModal"

import {
  getCandidateProfileRequest,
  getProfileSkillRequest,
  getProfileSalaryRequest,
  getProfileExperienceRequest,
  getProfileCertificationRequest,
  getProfileProjectRequest,
  getProfileLanguageRequest,
  getProfileHobbiesRequest,
  getProfileEducationRequest,
  getProfileSocialMediaRequest,
  profileSocialMediaRequest,
  postJobConsentRequest
} from "./redux/action"

import "./style.scss"
import AvatarBadge from "../../../components/AvatarBadge"
import ProfileDetailCard from "../../../components/ProfileDetailCard"
import SocialMediaCard from "../../../components/ProfileEditedCards/SocialMediaCard"

const Profile = props => {
  const {
    loginInfo,
    candidate_profile,
    skills,
    hobbies,
    education,
    experience,
    languages,
    certificate,
    projects,
    salary,
    socialMediaInfo,
    getCandidateProfileRequest,
    getCandidateSkills,
    getProfileSalary,
    getProfileExperience,
    getProfileCertification,
    getProfileProjects,
    getProfileLanguages,
    getProfileHobbies,
    getProfileEducation,
    getSocialMediaInfo,
    profileRequesting,
    skillsRequesting,
    hobbiesRequesting,
    educationRequesting,
    experienceRequesting,
    languagesRequesting,
    certificateRequesting,
    projectsRequesting,
    salaryRequesting,
    socialMediaInfoRequesting,
    postSocialMediaInfo,
    jobConsentRequesting,
    postJobConsentDispatch
  } = props

  const [show, setShow] = useState(false)
  const [currentModal, setCurrentModal] = useState({})
  const [uploadResumeOpen, setUploadResumeOpen] = useState(false)
  const [showConsentModal, setShowConsentModal] = useState(false)
  const personalInfoRef = useRef()
  const history = useHistory()
  const { location } = history

  const loadProfileData = useCallback(() => {
    getCandidateProfileRequest()
    getCandidateSkills()
    getProfileSalary()
    getProfileExperience()
    getProfileCertification()
    getProfileProjects()
    getProfileLanguages()
    getProfileHobbies()
    getProfileEducation()
    getSocialMediaInfo()
  }, [
    getCandidateProfileRequest,
    getCandidateSkills,
    getProfileSalary,
    getProfileExperience,
    getProfileCertification,
    getProfileProjects,
    getProfileLanguages,
    getProfileHobbies,
    getProfileEducation,
    getSocialMediaInfo
  ])

  useEffect(() => loadProfileData(), [loadProfileData])

  useEffect(() => {
    setShow(false)
    setCurrentModal({})
  }, [
    candidate_profile,
    skills,
    hobbies,
    education,
    experience,
    languages,
    certificate,
    projects,
    salary
  ])

  useEffect(() => {
    const activeMenuItem = location?.state?.activeSection
    if (
      activeMenuItem?.activeElement === location?.pathname &&
      !activeMenuItem?.section
    ) {
      personalInfoRef.current?.scrollIntoView({ behavior: "smooth" })
    }
  }, [location])

  useEffect(() => {
    if (
      candidate_profile?.id &&
      (typeof candidate_profile?.jobs_recommendation_consent !== "number" ||
        !candidate_profile?.jobs_recommendation_consent > 0)
    ) {
      setShowConsentModal(true)
    } else {
      setShowConsentModal(false)
    }
  }, [candidate_profile])

  const openUploadResumeModal = () => setUploadResumeOpen(true)
  const closeUploadResumeModal = () => setUploadResumeOpen(false)

  const setJobConsent = jobConsent => {
    postJobConsentDispatch({
      job_recommendation_consent: jobConsent
    })
  }

  const handleOnClick = (section, editData) => id => {
    setShow(true)
    setCurrentModal({ section, editData, id })
  }

  const handleSocialMediaUrlSave = urls => {
    postSocialMediaInfo(urls)
  }

  return (
    <section className="profile-section-wrapper">
      <div className="profile-section">
        <div
          className="personal-profile-section row-flex"
          ref={personalInfoRef}
        >
          <AvatarBadge
            name={
              candidate_profile?.name ||
              loginInfo.user?.name ||
              loginInfo.user?.username
            }
          />
          <div className="details-left">
            <div className="details-top row-flex">
              <ProfileDetailCard
                isEdited={Boolean(candidate_profile?.id)}
                profileInfo={candidate_profile}
                loginInfo={loginInfo}
                isCandidate={true}
                onClick={handleOnClick("profile-info", candidate_profile)}
                loading={profileRequesting}
              />
            </div>
            <div className="details-bottom">
              <SalaryCard
                isEdited={Boolean(salary?.id)}
                salaryInfo={salary}
                isCandidate={true}
                onClick={handleOnClick("payment-terms", salary)}
                loading={salaryRequesting}
              />
            </div>
          </div>
          <div className="details-right">
            <div className="button-wrapper">
              <IconButton
                icon={<CloudUploadOutlinedIcon />}
                label="Import Resume"
                onClick={openUploadResumeModal}
                className="border-button"
              />
              <UploadFile
                open={uploadResumeOpen}
                handleClose={closeUploadResumeModal}
                loadProfileData={loadProfileData}
              />
            </div>
          </div>
        </div>
        <div className="profile-detail-section">
          <div className="profile-card-wrapper">
            <AccordionCard
              panelId="candidate-skills-section"
              className="profile-details-card"
              title="Skills"
              loading={skillsRequesting}
              details={
                <SkillCard
                  isEdited={skills?.length > 0}
                  getSkillInfo={skills}
                  isCandidate={true}
                  onClick={handleOnClick("profile-skills", skills)}
                />
              }
            />
            <AccordionCard
              panelId="candidate-experience-section"
              className="profile-details-card"
              title="Experience"
              loading={experienceRequesting}
              details={
                <ExperienceCard
                  isEdited={experience?.length > 0}
                  getExperienceInfo={experience}
                  isCandidate={true}
                  onClick={handleOnClick("profile-experience", experience)}
                />
              }
            />
            <AccordionCard
              panelId="candidate-certificate-section"
              className="profile-details-card"
              title="Certifications"
              loading={certificateRequesting}
              details={
                <CertificateCard
                  isEdited={certificate?.length > 0}
                  getCertificateInfo={certificate}
                  isCandidate={true}
                  onClick={handleOnClick("profile-certificate", certificate)}
                />
              }
            />
            <AccordionCard
              panelId="candidate-social-media-section"
              className="profile-details-card"
              title="Social Media"
              loading={false}
              details={
                <SocialMediaCard
                  isEdited={true}
                  socialMediaInfo={socialMediaInfo}
                  isCandidate={true}
                  onClick={handleSocialMediaUrlSave}
                  requesting={socialMediaInfoRequesting}
                />
              }
            />
            <AccordionCard
              panelId="candidate-education-section"
              className="profile-details-card"
              title="Education"
              loading={educationRequesting}
              details={
                <EducationCard
                  isEdited={education?.length > 0}
                  getEducationInfo={education}
                  isCandidate={true}
                  onClick={handleOnClick("profile-education", education)}
                />
              }
            />
            <AccordionCard
              panelId="candidate-language-section"
              className="profile-details-card"
              title="Languages"
              loading={languagesRequesting}
              details={
                <LanguageCard
                  isEdited={languages?.length > 0}
                  getLanguageInfo={languages}
                  isCandidate={true}
                  onClick={handleOnClick("profile-language", languages)}
                />
              }
            />
            <AccordionCard
              panelId="candidate-projects-section"
              className="profile-details-card"
              title="Projects"
              loading={projectsRequesting}
              details={
                <ProjectsCard
                  isEdited={projects?.length > 0}
                  getProjectInfo={projects}
                  isCandidate={true}
                  onClick={handleOnClick("profile-projects", projects)}
                />
              }
            />
            <AccordionCard
              panelId="candidate-hobbies-section"
              className="profile-details-card"
              title="Hobbies & Interests"
              loading={hobbiesRequesting}
              details={
                <HobbiesCard
                  isEdited={hobbies?.length > 0}
                  getHobbiesInfo={hobbies}
                  isCandidate={true}
                  onClick={handleOnClick("profile-hobbies", hobbies)}
                />
              }
            />
          </div>
        </div>
      </div>
      <EditModal
        showModal={show}
        setShowModal={setShow}
        currentModal={currentModal?.section}
        setCurrentData={setCurrentModal}
        editData={currentModal?.editData}
        selectedId={currentModal?.id}
      />
      <JobRecommendationConsentModal
        showConsentModal={showConsentModal}
        setShowConsentModal={setShowConsentModal}
        showSpinner={jobConsentRequesting}
        onClick={setJobConsent}
        consentValue={candidate_profile?.job_recommendation_consent}
      />
    </section>
  )
}

const mapStateToProps = state => ({
  loginInfo: state.login.loginInfo,
  candidate_profile: state.profile.candidate_profile,
  skills: state.profile.skills,
  salary: state.profile.salary,
  hobbies: state.profile.hobbies,
  education: state.profile.education,
  experience: state.profile.experience,
  languages: state.profile.languages,
  certificate: state.profile.certificate,
  projects: state.profile.projects,
  socialMediaInfo: state.profile.socialMediaInfo,
  profileRequesting: state.profile.profileRequesting,
  skillsRequesting: state.profile.skillsRequesting,
  hobbiesRequesting: state.profile.hobbiesRequesting,
  educationRequesting: state.profile.educationRequesting,
  experienceRequesting: state.profile.experienceRequesting,
  languagesRequesting: state.profile.languagesRequesting,
  certificateRequesting: state.profile.certificateRequesting,
  projectsRequesting: state.profile.projectsRequesting,
  salaryRequesting: state.profile.salaryRequesting,
  socialMediaInfoRequesting: state.profile.socialMediaInfoRequesting,
  jobConsentRequesting: state.profile.jobConsentRequesting,
  error: state.profile.error
})

const mapDispatchToProps = dispatch => ({
  getCandidateProfileRequest: data =>
    dispatch(getCandidateProfileRequest(data)),
  getCandidateSkills: data => dispatch(getProfileSkillRequest(data)),
  getProfileSalary: data => dispatch(getProfileSalaryRequest(data)),
  getProfileExperience: data => dispatch(getProfileExperienceRequest(data)),
  getProfileCertification: data =>
    dispatch(getProfileCertificationRequest(data)),
  getProfileProjects: data => dispatch(getProfileProjectRequest(data)),
  getProfileLanguages: data => dispatch(getProfileLanguageRequest(data)),
  getProfileHobbies: data => dispatch(getProfileHobbiesRequest(data)),
  getProfileEducation: data => dispatch(getProfileEducationRequest(data)),
  getSocialMediaInfo: data => dispatch(getProfileSocialMediaRequest(data)),
  postSocialMediaInfo: data => dispatch(profileSocialMediaRequest(data)),
  postJobConsentDispatch: data => dispatch(postJobConsentRequest(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
