import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"
import React, { useRef, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import PlaceholderCard from "../PlaceholderCard"

const AccordionCard = ({
  className,
  panelId,
  title,
  details,
  expanded,
  loading
}) => {
  const history = useHistory()
  const { location } = history
  const accordionRef = useRef()
  const [expand, setExpand] = useState(expanded || true)
  className = className || "accordion-card"

  useEffect(() => {
    const activeMenuItem = location?.state?.activeSection
    if (
      activeMenuItem?.activeElement === location?.pathname &&
      activeMenuItem?.section === panelId
    ) {
      accordionRef.current?.scrollIntoView({ behavior: "smooth" })
    }
  }, [location, panelId])

  const handleOnChange = (e, expandState) => {
    !expanded && setExpand(expandState)
  }

  return (
    <Accordion
      disableGutters={true}
      defaultExpanded={true}
      className={className}
      ref={accordionRef}
      expanded={expand}
      onChange={handleOnChange}
    >
      <AccordionSummary
        expandIcon={
          expand ? (
            <RemoveIcon sx={{ color: "#1D8EE6" }} />
          ) : (
            <AddIcon sx={{ color: "#1D8EE6" }} />
          )
        }
        aria-controls={`${panelId}-content`}
        id={panelId}
        sx={{
          minHeight: "unset",
          height: "50px",
          marginTop: "10px"
        }}
      >
        <span className={`${className}__title`}>{title}</span>
      </AccordionSummary>
      <AccordionDetails id={`${panelId}-content`}>
        {loading ? <PlaceholderCard section={panelId} /> : details}
      </AccordionDetails>
    </Accordion>
  )
}

export default AccordionCard
