import React, { useState, useEffect } from "react"
import ProfileDetailCard from "../../../components/ProfileDetailCard"
import SkillCard from "../../../components/ProfileEditedCards/SkillCard"
import CertificateCard from "../../../components/ProfileEditedCards/CertificateCard"
import ExperienceCard from "../../../components/ProfileEditedCards/ExperienceCard"
import ProjectsCard from "../../../components/ProfileEditedCards/ProjectsCard"
import LanguageCard from "../../../components/ProfileEditedCards/LanguageCard"
import HobbiesCard from "../../../components/ProfileEditedCards/HobbiesCard"
import EducationCard from "../../../components/ProfileEditedCards/EducationCard"
import SalaryCard from "../../../components/ProfileEditedCards/SalaryCard"
import SocialMediaCard from "../../../components/ProfileEditedCards/SocialMediaCard"
import AccordionCard from "../../../components/AccordionCard"
import { isArray } from "../../../utils/commonFunction"
import { connect } from "react-redux"
import UCheckbox from "../../../components/UCheckbox"
import ConfirmModal from "../../../components/Modals/ConfirmModal"
import toast from "react-hot-toast"
import { postCandidateTypeRequest } from "./redux/action"
import { formatSkills } from "../../../utils/helper"

const CandidateProfile = ({
  requesting,
  error,
  candidateProfile,
  skills,
  hobbies,
  education,
  experience,
  languages,
  certificate,
  projects,
  salary,
  addressData,
  phoneData,
  postCandidateTypeDispatch,
  jobData,
  candidateTypeRequesting
}) => {
  const personalInfo = isArray(candidateProfile)
    ? candidateProfile[0]
    : candidateProfile
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (
      showModal?.show &&
      showModal?.candidateType === personalInfo?.candidate_type
    ) {
      setShowModal(false)
      toast.success("Successfully updated the candidate type")
    }
  }, [showModal, personalInfo])

  const handleInternalCandidateChange = e => {
    const type = e?.target?.checked ? "internal" : "general"
    setShowModal({
      show: true,
      action: updateCandidateProfile({
        candidate_type: type,
        id: personalInfo?.id,
        candidateId: personalInfo?.user_id,
        from: "recruiter"
      }),
      candidateType: type,
      confirmMessage: `Are you sure you want to update candidate type to "${
        type === "internal" ? "internal candidate" : "general candidate"
      }"?`
    })
  }

  const updateCandidateProfile = actionData => () => {
    postCandidateTypeDispatch(actionData)
  }

  const mergeProfileInfo = () => {
    const addressInfo = isArray(addressData) ? addressData[0] : addressData
    delete addressInfo?._id
    delete addressInfo?.id
    const phoneNumber = isArray(phoneData)
      ? phoneData[0]?.number
      : phoneData?.number
    let profileData =
      typeof personalInfo === "object"
        ? { ...personalInfo, phone: phoneNumber }
        : { phone: phoneNumber }
    profileData =
      typeof addressInfo === "object"
        ? { ...profileData, ...addressInfo }
        : profileData

    return profileData
  }

  const closeModal = () => setShowModal(false)

  return (
    <>
      <AccordionCard
        panelId="candidate-personal-info-section"
        className="profile-details-card"
        title="Bio"
        expanded
        details={
          <ProfileDetailCard
            isEdited={true}
            profileInfo={mergeProfileInfo()}
            isCandidate={false}
          />
        }
        loading={requesting}
      />
      <AccordionCard
        panelId="candidate-salary-section"
        className="profile-details-card"
        title="Payment Terms"
        expanded
        details={
          <SalaryCard
            isEdited={true}
            salaryInfo={isArray(salary) ? salary[0] : salary}
            isCandidate={false}
          />
        }
        loading={requesting}
      />
      {!requesting && (
        <div className="internal-candidate-section">
          <UCheckbox
            onChange={handleInternalCandidateChange}
            label="Mark as Internal Candidate"
            className="internal-candidate-checkbox"
            checked={personalInfo?.candidate_type?.toLowerCase() === "internal"}
          />
        </div>
      )}
      <AccordionCard
        panelId="candidate-skills-section"
        className="profile-details-card"
        title="Skills"
        expanded
        details={
          <SkillCard
            isEdited={true}
            getSkillInfo={skills}
            isCandidate={false}
            compareSkillsInfo={formatSkills(jobData?.skills)}
            legends={jobData?.skills?.length > 0 ? ["Candidate Skills", "Job Listing Skills ;l;"] : ""}
          />
        }
        loading={requesting}
      />
      <AccordionCard
        panelId="candidate-experience-section"
        className="profile-details-card"
        title="Experience"
        expanded
        details={
          <ExperienceCard
            isEdited={true}
            getExperienceInfo={experience}
            isCandidate={false}
          />
        }
        loading={requesting}
      />
      <AccordionCard
        panelId="candidate-certificate-section"
        className="profile-details-card"
        title="Certificates"
        expanded
        details={
          <CertificateCard
            isEdited={true}
            getCertificateInfo={certificate}
            isCandidate={false}
          />
        }
        loading={requesting}
      />
      <AccordionCard
        panelId="candidate-social-media-section"
        className="profile-details-card"
        title="Social Media"
        expanded
        details={
          <SocialMediaCard
            isEdited={true}
            socialMediaInfo={personalInfo}
            isCandidate={false}
          />
        }
        loading={requesting}
      />
      <AccordionCard
        panelId="candidate-education-section"
        className="profile-details-card"
        title="Education"
        expanded
        details={
          <EducationCard
            isEdited={true}
            getEducationInfo={education}
            isCandidate={false}
          />
        }
        loading={requesting}
      />
      <AccordionCard
        panelId="candidate-projects-section"
        className="profile-details-card"
        title="Projects"
        expanded
        details={
          <ProjectsCard
            isEdited={true}
            getProjectInfo={projects}
            isCandidate={false}
          />
        }
        loading={requesting}
      />
      <AccordionCard
        panelId="candidate-language-section"
        className="profile-details-card"
        title="Language"
        expanded
        details={
          <LanguageCard
            isEdited={true}
            getLanguageInfo={languages}
            isCandidate={false}
          />
        }
        loading={requesting}
      />
      <AccordionCard
        panelId="candidate-hobbies-section"
        className="profile-details-card"
        title="Hobbies & Interests"
        expanded
        details={
          <HobbiesCard
            isEdited={true}
            getHobbiesInfo={hobbies}
            isCandidate={false}
          />
        }
        loading={requesting}
      />
      <ConfirmModal
        showModal={showModal?.show}
        confirmText={showModal?.confirmMessage}
        loading={requesting || candidateTypeRequesting}
        onCancel={closeModal}
        onConfirm={showModal?.action}
        handleOnClose={closeModal}
      />
    </>
  )
}

const mapStateToProps = state => ({
  loginInfo: state.login.loginInfo,
  requesting: state.selectedProfile.requesting,
  error: state.selectedProfile.error,
  candidateProfile: state.selectedProfile.candidateInfo?.profile_data,
  skills: state.selectedProfile.candidateInfo?.skills,
  salary: state.selectedProfile.candidateInfo?.payment_terms,
  hobbies: state.selectedProfile.candidateInfo?.hobbies,
  education: state.selectedProfile.candidateInfo?.education,
  experience: state.selectedProfile.candidateInfo?.experience,
  languages: state.selectedProfile.candidateInfo?.languages,
  certificate: state.selectedProfile.candidateInfo?.certification,
  addressData: state.selectedProfile.candidateInfo?.address_data,
  phoneData: state.selectedProfile.candidateInfo?.phone_data,
  projects: state.selectedProfile.candidateInfo?.projects,
  candidateInfo: state.selectedProfile.candidateInfo,
  candidateTypeRequesting: state.selectedProfile.candidateTypeRequesting
})

const mapDispatchToProps = dispatch => ({
  postCandidateTypeDispatch: data => dispatch(postCandidateTypeRequest(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(CandidateProfile)
