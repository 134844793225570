import {
  POST_RECRUITER_PROFILE_REQUEST,
  POST_RECRUITER_PROFILE_SUCCESS,
  POST_RECRUITER_PROFILE_FAILURE
} from "./type"

const initialState = {
  recruiterProfileData: {},
  error: false,
  requesting: false
}

const editRecruiterProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_RECRUITER_PROFILE_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case POST_RECRUITER_PROFILE_SUCCESS:
      return {
        ...state,
        requesting: false,
        recruiterProfileData: action.data
      }
    case POST_RECRUITER_PROFILE_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    default:
      return state
  }
}

export default editRecruiterProfileReducer
