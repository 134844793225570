export const US_DEGREES = [
    {
        "acronym": "HS",
        "degreeValue": 0,
        "degree": "High School"
    },
    {
        "acronym": "AD",
        "degreeValue": 2,
        "altLabels": [
            "Associate",
            "Associate's"
        ],
        "degree": "Associate's Degree"
    },
    {
        "acronym": "B",
        "degreeValue": 4,
        "altLabels": [
            "Bachelor",
            "Bachelor's"
        ],
        "degree": "Bachelor's Degree"
    },
    {
        "acronym": "M",
        "degreeValue": 5.5,
        "altLabels": [
            "Master",
            "Master's"
        ],
        "degree": "Master's Degree"
    },
    {
        "acronym": "ED",
        "degreeValue": 5.5,
        "degree": "Engineer's Degree"
    },
    {
        "acronym": "CERT",
        "degreeValue": 1,
        "degree": "Certificate"
    },
    {
        "acronym": "CW",
        "degreeValue": 0,
        "degree": "Coursework"
    },
    {
        "acronym": "O",
        "degreeValue": 0,
        "degree": "Other"
    },
    {
        "acronym": "AA",
        "degreeValue": 2,
        "degree": "Associate of Arts"
    },
    {
        "acronym": "AAS",
        "degreeValue": 2,
        "degree": "Associate of Applied Science"
    },
    {
        "acronym": "AAT",
        "degreeValue": 2,
        "degree": "Associate in Applied Tech."
    },
    {
        "acronym": "AOT",
        "degreeValue": 2,
        "degree": "Associate in Occupational Technology"
    },
    {
        "acronym": "AS",
        "degreeValue": 2,
        "degree": "Associate of Science"
    },
    {
        "acronym": "AB",
        "degreeValue": 4,
        "degree": "Bachelor of Arts"
    },
    {
        "acronym": "BA",
        "degreeValue": 4,
        "degree": "Bachelor of Arts"
    },
    {
        "acronym": "BABA",
        "degreeValue": 4,
        "degree": "Bachelor of Arts of Business Administration"
    },
    {
        "acronym": "BACom",
        "degreeValue": 4,
        "degree": "Bachelor of Arts in Communication"
    },
    {
        "acronym": "BAE",
        "degreeValue": 4,
        "degree": "Bachelor of Arts in Education, Bachelor of Art Education"
    },
    {
        "acronym": "BAg",
        "degreeValue": 4,
        "degree": "Bachelor of Agriculture"
    },
    {
        "acronym": "BArch",
        "degreeValue": 4,
        "degree": "Bachelor of Architecture"
    },
    {
        "acronym": "BBA",
        "degreeValue": 4,
        "degree": "Bachelor of Business Administration"
    },
    {
        "acronym": "BCE",
        "degreeValue": 4,
        "degree": "Bachelor of Civil Engineering"
    },
    {
        "acronym": "BChE",
        "degreeValue": 4,
        "degree": "Bachelor of Chemical Engineering"
    },
    {
        "acronym": "BD",
        "degreeValue": 4,
        "degree": "Bachelor of Divinity"
    },
    {
        "acronym": "BE",
        "degreeValue": 4,
        "degree": "Bachelor of Education, Bachelor of Engineering"
    },
    {
        "acronym": "BEE",
        "degreeValue": 4,
        "degree": "Bachelor of Electrical Engineering"
    },
    {
        "acronym": "BFA",
        "degreeValue": 4,
        "degree": "Bachelor of Fine Arts"
    },
    {
        "acronym": "BInDsn",
        "degreeValue": 4,
        "degree": "Bachelor of Industrial Design"
    },
    {
        "acronym": "BJ",
        "degreeValue": 4,
        "degree": "Bachelor of Journalism"
    },
    {
        "acronym": "BLA",
        "degreeValue": 4,
        "degree": "Bachelor of Liberal Arts"
    },
    {
        "acronym": "BMEd",
        "degreeValue": 4,
        "degree": "Bachelor of Music Education"
    },
    {
        "acronym": "BPharm",
        "degreeValue": 4,
        "degree": "Bachelor of Pharmacy"
    },
    {
        "acronym": "BS",
        "degreeValue": 4,
        "degree": "Bachelor of Science",
        "altLabels": [
            "Bachelor of Science (B.S.)"
        ]
    },
    {
        "acronym": "SB",
        "degreeValue": 4,
        "degree": "Bachelor of Science"
    },
    {
        "acronym": "BSc",
        "degreeValue": 4,
        "degree": "Bachelor of Science"
    },
    {
        "acronym": "BSAE",
        "degreeValue": 4,
        "degree": "B.S. in Aerospace Engineering"
    },
    {
        "acronym": "BSBA",
        "degreeValue": 4,
        "degree": "B.S. in Business Administration"
    },
    {
        "acronym": "BSCS",
        "degreeValue": 4,
        "degree": "B.S. in Computer Science"
    },
    {
        "acronym": "BSChem",
        "degreeValue": 4,
        "degree": "B.S. in Chemistry"
    },
    {
        "acronym": "BSE",
        "degreeValue": 4,
        "degree": "B.S. in Engineering"
    },
    {
        "acronym": "BSEd",
        "degreeValue": 4,
        "degree": "B.S. in Education"
    },
    {
        "acronym": "BSME",
        "degreeValue": 4,
        "degree": "B.S. in Mechanical Engineering"
    },
    {
        "acronym": "BSMicr",
        "degreeValue": 4,
        "degree": "B.S. in Microbiology"
    },
    {
        "acronym": "BSSW",
        "degreeValue": 4,
        "degree": "B.S. in Social Work"
    },
    {
        "acronym": "PhB",
        "degreeValue": 4,
        "degree": "Bachelor of Philosophy"
    },
    {
        "acronym": "ThB",
        "degreeValue": 4,
        "degree": "Bachelor of Theology"
    },
    {
        "acronym": "AM",
        "degreeValue": 5.5,
        "degree": "Master of Arts"
    },
    {
        "acronym": "MA",
        "degreeValue": 5.5,
        "degree": "Master of Arts"
    },
    {
        "acronym": "MAcct",
        "degreeValue": 5.5,
        "degree": "Master of Accounting"
    },
    {
        "acronym": "MAqua",
        "degreeValue": 5.5,
        "degree": "Master of Aquaculture"
    },
    {
        "acronym": "MBA",
        "degreeValue": 5.5,
        "degree": "Master of Business Administration"
    },
    {
        "acronym": "MCD",
        "degreeValue": 5.5,
        "degree": "Master of Communication Disorders"
    },
    {
        "acronym": "MCS",
        "degreeValue": 5.5,
        "degree": "Master of Computer Science"
    },
    {
        "acronym": "MDiv",
        "degreeValue": 5.5,
        "degree": "Master of Divinity"
    },
    {
        "acronym": "ME",
        "degreeValue": 5.5,
        "degree": "Master of Engineering"
    },
    {
        "acronym": "MEd",
        "degreeValue": 5.5,
        "degree": "Master of Education"
    },
    {
        "acronym": "MFstry",
        "degreeValue": 5.5,
        "degree": "Master of Forestry"
    },
    {
        "acronym": "MLArch",
        "degreeValue": 5.5,
        "degree": "Master of Landscape Architecture"
    },
    {
        "acronym": "MLIS",
        "degreeValue": 5.5,
        "degree": "Master of Library & Information Studies"
    },
    {
        "acronym": "MM",
        "degreeValue": 5.5,
        "degree": "Master of Music"
    },
    {
        "acronym": "MMus",
        "degreeValue": 5.5,
        "degree": "Master of Music"
    },
    {
        "acronym": "MPS",
        "degreeValue": 5.5,
        "degree": "Master of Political Science"
    },
    {
        "acronym": "MS",
        "degreeValue": 5.5,
        "degree": "Master of Science",
        "altLabels": [
            "Master of Science (M.S.)"
        ]
    },
    {
        "acronym": "MSc",
        "degreeValue": 5.5,
        "degree": "Master of Science",
        "altLabels": [
            "Master of Science (MSc)"
        ]
    },
    {
        "acronym": "MSCJ",
        "degreeValue": 5.5,
        "degree": "M.S. in Criminal Justice"
    },
    {
        "acronym": "MSCS",
        "degreeValue": 5.5,
        "degree": "M.S. in Computer Science"
    },
    {
        "acronym": "MSChem",
        "degreeValue": 5.5,
        "degree": "M.S. in Chemistry"
    },
    {
        "acronym": "MSFS",
        "degreeValue": 5.5,
        "degree": "M.S. in Forensic Science"
    },
    {
        "acronym": "MSIT",
        "degreeValue": 5.5,
        "degree": "M.S. in Information Technology"
    },
    {
        "acronym": "MSMSci",
        "degreeValue": 5.5,
        "degree": "M.S. in Marine Science"
    },
    {
        "acronym": "MSMet",
        "degreeValue": 5.5,
        "degree": "M.S. in Metallurgical Engineering"
    },
    {
        "acronym": "MSwE",
        "degreeValue": 5.5,
        "degree": "Master of Software Engineering"
    },
    {
        "acronym": "MSW",
        "degreeValue": 5.5,
        "degree": "Master of Social Work"
    },
    {
        "acronym": "MTh",
        "degreeValue": 5.5,
        "degree": "Master of Theology"
    },
    {
        "acronym": "MTech",
        "degreeValue": 5.5,
        "degree": "Master of Technology"
    },
    {
        "acronym": "ThM",
        "degreeValue": 5.5,
        "degree": "Master of Theology"
    },
    {
        "acronym": "AuD",
        "degreeValue": 8,
        "degree": "Doctor of Audiology"
    },
    {
        "acronym": "ArtD",
        "degreeValue": 7.5,
        "degree": "Doctor of Arts"
    },
    {
        "acronym": "DA",
        "degreeValue": 7.5,
        "degree": "Doctor of Arts"
    },
    {
        "acronym": "DBA",
        "degreeValue": 7.5,
        "degree": "Doctor of Business Administration"
    },
    {
        "acronym": "DC",
        "degreeValue": 7.5,
        "degree": "Doctor of Chiropractic"
    },
    {
        "acronym": "DD",
        "degreeValue": 7.5,
        "degree": "Doctor of Divinity"
    },
    {
        "acronym": "DEd",
        "degreeValue": 7.5,
        "degree": "Doctor of Education"
    },
    {
        "acronym": "DLS",
        "degreeValue": 7.5,
        "degree": "Doctor of Library Science"
    },
    {
        "acronym": "DMA",
        "degreeValue": 7.5,
        "degree": "Doctor of Musical Arts"
    },
    {
        "acronym": "DPA",
        "degreeValue": 7.5,
        "degree": "Doctor of Public Administration"
    },
    {
        "acronym": "DPH",
        "degreeValue": 7.5,
        "degree": "Doctor of Public Health"
    },
    {
        "acronym": "MPH",
        "degreeValue": 5.5,
        "degree": "Master of Public Health"
    },
    {
        "acronym": "DSc",
        "degreeValue": 7.5,
        "degree": "Doctor of Science"
    },
    {
        "acronym": "DSW",
        "degreeValue": 7.5,
        "degree": "Doctor of Social Welfare, Doctor of Social Work"
    },
    {
        "acronym": "DVM",
        "degreeValue": 8,
        "degree": "Doctor of Veterinary Medicine"
    },
    {
        "acronym": "EdD",
        "degreeValue": 7.5,
        "degree": "Doctor of Education"
    },
    {
        "acronym": "JD",
        "degreeValue": 7,
        "degree": "Doctor of Law",
        "altLabels": [
            "Doctor of Jurisprudence",
            "Doctor of Laws",
            "Juris Doctor"
        ]
    },
    {
        "acronym": "LHD",
        "degreeValue": 7.5,
        "degree": "Doctor of Humane Letters"
    },
    {
        "acronym": "LLD",
        "degreeValue": 7,
        "degree": "Doctor of Laws"
    },
    {
        "acronym": "MusD",
        "degreeValue": 7.5,
        "degree": "Doctor of Music"
    },
    {
        "acronym": "DM",
        "degreeValue": 7.5,
        "degree": "Doctor of Music"
    },
    {
        "acronym": "OD",
        "degreeValue": 7.5,
        "degree": "Doctor of Optimetry"
    },
    {
        "acronym": "PhD",
        "degreeValue": 7.5,
        "degree": "Doctor of Philosophy",
        "altLabels": [
            "Doctor of Philosophy (PhD)",
            "Doctor of Philosophy (Ph.D.)"
        ]
    },
    {
        "acronym": "SD",
        "degreeValue": 7.5,
        "degree": "Doctor of Science"
    },
    {
        "acronym": "ScD",
        "degreeValue": 7.5,
        "degree": "Doctor of Science"
    },
    {
        "acronym": "SScD",
        "degreeValue": 7.5,
        "degree": "Doctor of Social Science"
    },
    {
        "acronym": "ThD",
        "degreeValue": 7.5,
        "degree": "Doctor of Theology"
    },
    {
        "acronym": "BN",
        "degreeValue": 4,
        "degree": "Bachelor of Nursing"
    },
    {
        "acronym": "BSN",
        "degreeValue": 4,
        "degree": "B.S. in Nursing"
    },
    {
        "acronym": "MN",
        "degreeValue": 5.5,
        "degree": "Master of Nursing"
    },
    {
        "acronym": "MNA",
        "degreeValue": 5.5,
        "degree": "Master of Nurse Anesthesia"
    },
    {
        "acronym": "DDS",
        "degreeValue": 8,
        "degree": "Doctor of Dental Surgery"
    },
    {
        "acronym": "DMD",
        "degreeValue": 8,
        "degree": "Doctor of Dental Medicine, Doctor of Medical Dentistry"
    },
    {
        "acronym": "DO",
        "degreeValue": 8,
        "degree": "Doctor of Osteopathic Medicine"
    },
    {
        "acronym": "DPT",
        "degreeValue": 8,
        "degree": "Doctor of Physical Therapy"
    },
    {
        "acronym": "DSN",
        "degreeValue": 8,
        "degree": "Doctor of Science in Nursing"
    },
    {
        "acronym": "DScPT",
        "degreeValue": 8,
        "degree": "Doctor of Science in Physical Therapy"
    },
    {
        "acronym": "MD",
        "degreeValue": 8,
        "degree": "Doctor of Medicine"
    },
    {
        "acronym": "OD",
        "degreeValue": 8,
        "degree": "Doctor of Optometry"
    },
    {
        "acronym": "PharmD",
        "degreeValue": 8,
        "degree": "Doctor of Pharmacy"
    }
]
