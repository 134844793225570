import {
  POST_REQUEST_LOGIN,
  POST_REQUEST_LOGIN_SUCCESS,
  POST_REQUEST_LOGIN_FAILURE,
  SET_ACCESS_TOKEN,
  USER_STATE,
  LOGOUT,
  LOGIN_REQUEST_GOOGLE,
  LOGIN_REQUEST_MICROSOFT,
  LOGIN_REQUEST_GOOGLE_FAILURE,
  LOGIN_REQUEST_MICROSOFT_FAILURE,
  LOGIN_REQUEST_MICROSOFT_SUCCESS,
  LOGIN_REQUEST_GOOGLE_SUCCESS,
  POST_DELETE_ACCOUNT_REQUEST,
  POST_DELETE_ACCOUNT_REQUEST_SUCCESS,
  POST_DELETE_ACCOUNT_REQUEST_FAILURE,
  SET_USER_TYPE
} from "./type"

const initialState = {
  loginInfo: false,
  error: false,
  userType: "",
  requesting: false,
  accessToken: false,
  currentUserInfo: "recruiter",
  googleLoginInfo: false,
  microsoftLoginInfo: false
}

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_REQUEST_LOGIN:
      return {
        ...state,
        requesting: true
      }

    case POST_REQUEST_LOGIN_SUCCESS:
      return {
        ...state,
        requesting: false,
        loginInfo: action.data,
        userType: action.data?.user?.user_type
      }
    case POST_REQUEST_LOGIN_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
    case USER_STATE:
      return {
        ...state,
        currentUserInfo: action.data
      }

    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.accessToken
      }
    case LOGOUT:
      return {
        ...state,
        requesting: false,
        accessToken: false
      }
    case LOGIN_REQUEST_GOOGLE:
      return {
        ...state,
        requesting: true
      }
    case LOGIN_REQUEST_MICROSOFT:
      return {
        ...state,
        requesting: true
      }
    case LOGIN_REQUEST_GOOGLE_SUCCESS:
      return {
        ...state,
        requesting: false,
        googleLoginInfo: action.data
      }
    case LOGIN_REQUEST_MICROSOFT_SUCCESS:
      return {
        ...state,
        requesting: false,
        microsoftLoginInfo: action.data
      }
    case LOGIN_REQUEST_GOOGLE_FAILURE:
      return {
        ...state,
        requesting: false
      }
    case LOGIN_REQUEST_MICROSOFT_FAILURE:
      return {
        ...state,
        requesting: false
      }
    case POST_DELETE_ACCOUNT_REQUEST:
      return {
        ...state,
        requesting: true
      }
    case POST_DELETE_ACCOUNT_REQUEST_SUCCESS:
      return {
        ...state,
        accountDeleteInfo: action.data,
        requesting: false
      }
    case POST_DELETE_ACCOUNT_REQUEST_FAILURE:
      return {
        ...state,
        accountDeleteError: action.error,
        requesting: false
      }
    case SET_USER_TYPE:
      return {
        ...state,
        userType: action.data
      }
    default:
      return state
  }
}

export default loginReducer
