import {
    GET_CONCEPT_LIST_REQUEST,
    GET_CONCEPT_LIST_SUCCESS
  } from "./type"

  export const getConceptListRequest = data => ({
    type: GET_CONCEPT_LIST_REQUEST,
    data
  })

  export const getConceptListSuccess = data => ({
    type: GET_CONCEPT_LIST_SUCCESS,
    data
  })
