export const GET_TEAMS_DATA_REQUEST = "GET_TEAMS_DATA_REQUEST"
export const GET_TEAMS_DATA_REQUEST_SUCCESS = "GET_TEAMS_DATA_REQUEST_SUCCESS"
export const GET_TEAMS_DATA_REQUEST_FAILURE = "GET_TEAMS_DATA_REQUEST_FAILURE"
export const CLOSE_MODAL = "CLOSE_MODAL"

//Add Team

export const ADD_NEW_TEAM = "ADD_NEW_TEAM"
export const ADD_NEW_TEAM_SUCCESS = "ADD_NEW_TEAM_SUCCESS"
export const ADD_NEW_TEAM_FAILURE = "ADD_NEW_TEAM_FAILURE"

// Delete Team

export const DELETE_TEAM = "DELETE_TEAM"
export const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS"
export const DELETE_TEAM_FAILURE = "DELETE_TEAM_FAILURE"
export const DELETE_TEAM_RECORD = "DELETE_TEAM_RECORD"

// Edit Team
export const POST_EDIT_TEAM_REQUEST = "POST_EDIT_TEAM_REQUEST"
export const POST_EDIT_TEAM_REQUEST_SUCCESS = "POST_EDIT_TEAM_REQUEST_SUCCESS"
export const POST_EDIT_TEAM_REQUEST_FAILURE = "POST_EDIT_TEAM_REQUEST_FAILURE"
export const EDIT_TEAM_RECORD = "EDIT_TEAM_RECORD"
