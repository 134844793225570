import { GET_PROFILE_DATA_REQUEST, GET_PROFILE_DATA_SUCCESS } from "./type"

const initialState = {
  profileData: {},

  requesting: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE_DATA_REQUEST:
      return {
        ...state,
        requesting: true
      }
    case GET_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        requesting: false,
        profileData: action.data
      }

    default:
      return state
  }
}
