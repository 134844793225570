import React from "react"

import Modal from "react-bootstrap/Modal"
import { Images } from "../../../theme/Images"
import Button from "../../Button"
import Image from "../../Image"
import USelect from "../../USelect"

import "../style.scss"

const ExportModal = ({ showExportModal, setShowExportModal }) => {
  return (
    <>
      <Modal
        show={showExportModal}
        setShow={setShowExportModal}
        centered
        className="recruiter-modal export-modal"
      >
        <Modal.Header>
          <Modal.Title>
            <div className="wrapper flex-justify-end w-100">
              <div
                className="close-icon"
                onClick={() => setShowExportModal(false)}
              >
                <Image src={Images.closeIcon} />
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="recruiter-modal-body-wrapper export-modal-body-wrapper">
            <div className="main-title">Export Candidates Data</div>
            <div className="input-wrapper">
              <USelect
                placeholderLabel={"Duration"}
                options={[{ key: "last_week", label: "Last Week" }]}
              />
              <div className="error-message"></div>
            </div>
            <div className="button-wrapper flex-justify-center">
              <Button
                title="Export"
                className="export-data"
                onClick={() => setShowExportModal(false)}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ExportModal
