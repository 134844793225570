import React from "react"
import { Editor } from "react-draft-wysiwyg"
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import "./style.scss"
import { Images } from "../../theme/Images"

const RichTextEditor = ({
  onChange,
  placeholder,
  defaultEditorState,
  error
}) => {
  const onEditorStateChange = editor => {
    onChange(editor)
  }

  return (
    <Editor
      placeholder={placeholder}
      wrapperClassName={`rich-text-wrapper ${
        error ? "rich-text-wrapper--error" : ""
      }`}
      editorClassName="rich-text-editor"
      toolbarClassName="rich-text-editor-toolbar"
      onEditorStateChange={onEditorStateChange}
      defaultEditorState={defaultEditorState}
      toolbar={{
        options: [
          "inline",
          "blockType",
          "list",
          "textAlign",
          "link",
          "history"
        ],
        inline: {
          options: [
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "monospace"
          ],
          bold: {
            icon: Images.boldIcon,
            className: "rich-text-editor-toolbar-option"
          },
          italic: {
            icon: Images.italicIcon,
            className: "rich-text-editor-toolbar-option"
          },
          underline: {
            icon: Images.underlineIcon,
            className: "rich-text-editor-toolbar-option"
          },
          strikethrough: {
            className: "rich-text-editor-toolbar-option"
          },
          monospace: { className: "rich-text-editor-toolbar-option" }
        },
        blockType: {
          className: "rich-text-editor-toolbar-option-wide",
          dropdownClassName: "rich-text-editor-toolbar-option-dropdown"
        },
        list: {
          unordered: {
            className: "rich-text-editor-toolbar-option"
          },
          ordered: {
            className: "rich-text-editor-toolbar-option"
          },
          indent: {
            className: "rich-text-editor-toolbar-option"
          },
          outdent: {
            className: "rich-text-editor-toolbar-option"
          }
        },
        textAlign: {
          options: ["left", "center", "right"],
          left: {
            className: "rich-text-editor-toolbar-option"
          },
          center: {
            className: "rich-text-editor-toolbar-option"
          },
          right: {
            className: "rich-text-editor-toolbar-option"
          }
        },
        link: {
          popupClassName: "demo-popup-custom",
          link: {
            className: "rich-text-editor-toolbar-option"
          },
          unlink: {
            className: "rich-text-editor-toolbar-option"
          }
        },
        history: {
          undo: {
            className: "rich-text-editor-toolbar-option"
          },
          redo: {
            className: "rich-text-editor-toolbar-option"
          }
        }
      }}
    />
  )
}

export default RichTextEditor
