import React from "react"

// Components
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker"
import { createTheme, ThemeProvider } from "@mui/material"
import DayJsDateAdapter from "./DayJsDateAdapter"
// Style
import "./style.scss"

const UDatePicker = ({
  placeholderValue,
  value,
  onChange,
  disabled,
  views,
  inputFormat = "YYYY/MM/DD",
  error = "",
  ...rest
}) => {
  const mytheme = createTheme({
    typography: {
      fontFamily: ["Inter"].join(",")
    }
  })

  return (
    <div className="input-field-wrapper date-field-wrapper">
      <ThemeProvider theme={mytheme}>
        <LocalizationProvider dateAdapter={DayJsDateAdapter}>
          <Stack spacing={3}>
            <MobileDatePicker
              label={placeholderValue}
              inputFormat={inputFormat}
              value={value}
              onChange={onChange}
              renderInput={params => (
                <TextField {...params} error={Boolean(error)} />
              )}
              disabled={disabled}
              views={views}
              showToolbar={false}
              {...rest}
            />
          </Stack>
        </LocalizationProvider>
      </ThemeProvider>
    </div>
  )
}

export default UDatePicker
