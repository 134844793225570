import {
    GET_ALL_TEAM_MEMBERS_REQUEST,
    GET_ALL_TEAM_MEMBERS_REQUEST_SUCCESS,
    GET_ALL_TEAM_MEMBERS_REQUEST_FAILURE,
    DELETE_TEAM_MEMBER_REQUEST,
    DELETE_TEAM_MEMBER_REQUEST_SUCCESS,
    DELETE_TEAM_MEMBER_REQUEST_FAILURE,
    ADD_TEAM_MEMBER_REQUEST,
    ADD_TEAM_MEMBER_REQUEST_SUCCESS,
    ADD_TEAM_MEMBER_REQUEST_FAILURE,
    DISPLAY_ADD_MEMBER_MODAL
} from './type'

export const getAllTeamMembers = (data) => ({
    type: GET_ALL_TEAM_MEMBERS_REQUEST,
    data
})

export const getAllTeamMembersSuccess = (data) => ({
    type: GET_ALL_TEAM_MEMBERS_REQUEST_SUCCESS,
    data
})

export const getAllTeamMembersFailure = (data) => ({
    type: GET_ALL_TEAM_MEMBERS_REQUEST_FAILURE,
    data
})

export const deleteTeamMember = (data) => ({
    type: DELETE_TEAM_MEMBER_REQUEST,
    data
})

export const deleteTeamMemberSuccess = (data) => ({
    type: DELETE_TEAM_MEMBER_REQUEST_SUCCESS,
    data
})

export const deleteTeamMemberFailure = (data) => ({
    type: DELETE_TEAM_MEMBER_REQUEST_FAILURE,
    data
})

export const addTeamMember = (data) => ({
    type: ADD_TEAM_MEMBER_REQUEST,
    data
})

export const addTeamMemberSuccess = (data) => ({
    type: ADD_TEAM_MEMBER_REQUEST_SUCCESS,
    data
})

export const addTeamMemberFailure = (data) => ({
    type: ADD_TEAM_MEMBER_REQUEST_FAILURE,
    data
})

export const displayAddMemberModal = (data) => ({
    type: DISPLAY_ADD_MEMBER_MODAL,
    data
    
})