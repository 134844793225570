import { useHistory } from "react-router-dom"
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined"
import { connect } from "react-redux"
import React, { useCallback, useEffect, useRef, useState } from "react"
import USearch from "../../../components/USearch"
import DataTable from "../../../components/DataTable"
import DeleteConfirmModal from "../../../components/Modals/DeleteConfirmModal"
import { getAllUsers, deleteUserRequest } from "./redux/action"
import AvatarBadge from "../../../components/AvatarBadge"

import "./style.scss"
import { isCandidate } from "../../../utils/helper"

const AdminAllUsers = props => {
  const {
    getAllUsers,
    closeUserDispatch,
    usersData,
    requesting,
    deleteRequesting
  } = props
  const history = useHistory()
  const { allUsers } = usersData || {}
  const [showDeleteModal, setDeleteModal] = useState(false)
  const [userToDelete, setUserToDelete] = useState({})
  const [searchParam, setSearchParam] = useState("")
  const { location } = history
  const pageInfo = useRef(window.history.state?.pageInfo?? {})

  const setPageInfoFromQueryParams = (pageInfo) => {
    const parsedQueryParams = new URLSearchParams(location?.search)
    pageInfo.current = {
      page: parseInt(parsedQueryParams.get('page')?? 1),
      limit: parseInt(parsedQueryParams.get('limit')?? 10),
      sortBy: parsedQueryParams.get('sortBy'),
      sortDir: parsedQueryParams.get('sortDir')?? 'asc',
      searchTerm: parsedQueryParams.get('searchTerm')
    }
  }

  setPageInfoFromQueryParams(pageInfo)

  useEffect(() => {
    setPageInfoFromQueryParams(pageInfo)
  }, [location?.search])

  const updateQueryParamsInUrl = (pageInfo) => {
    const queryParams = new URLSearchParams(window.location.search)
    pageInfo.current.page ? queryParams.set('page', pageInfo.current.page) : queryParams.delete('page')
    pageInfo.current.limit ? queryParams.set('limit', pageInfo.current.limit) : queryParams.delete('limit')
    pageInfo.current.sortBy ? queryParams.set('sortBy', pageInfo.current.sortBy) : queryParams.delete('sortBy')
    pageInfo.current.sortDir ? queryParams.set('sortDir', pageInfo.current.sortDir) : queryParams.delete('sortDir')
    pageInfo.current.searchTerm ? queryParams.set('searchTerm', pageInfo.current.searchTerm) : queryParams.delete('searchTerm')
    window.history.replaceState(null, null, "?"+queryParams.toString())
  }

  const goToUserProfile = rowData => () => {
    if (isCandidate(rowData?.user_type)) {
      history.push(`/admin/candidate-user-profile/${rowData.user_id}`, {
        candidateData: {
          ...rowData,
          candidate_id: rowData.user_id,
          candidate_name: rowData.name1 || rowData.name2
        }
      })
    }
  }

  const columns = [
    {
      id: "name1",
      label: "User",
      width: "25%",
      sortable: true,
      renderColumn: rowData => {
        const name = rowData.name1 || rowData?.name2 || rowData.email
        return (
          <span
            onClick={goToUserProfile(rowData)}
            className={`user-title-label`}
          >
            <AvatarBadge name={name} />
            <span
              className={isCandidate(rowData.user_type) ? "" : "disabled-row"}
            >
              {name}
            </span>
          </span>
        )
      }
    },
    {
      id: "email",
      label: "Email",
      width: "20%",
      sortable: true
    },
    {
      id: "phone",
      label: "Phone",
      width: "20%",
      sortable: true
    },
    {
      id: "location",
      label: "Location",
      width: "20%",
      sortable: false,
      renderColumn: rowData => {
        return (
          <span className={`user-location`}>
            {rowData.locations?.[0]?.formatted_address || "Not Available"}
          </span>
        )
      }
    },
    {
      id: "user_type",
      label: "Type",
      width: "15%",
      sortable: true
    },
    {
      id: "action",
      label: "Action",
      width: "10%",
      renderColumn: rowData => {
        return (
          <div className="job-actions">
            <DeleteOutlinedIcon
              onClick={handleUserDelete(rowData)}
              className="action-delete"
            />
          </div>
        )
      }
    }
  ]

  const handleUserDelete = userData => () => {
    setDeleteModal(true)
    setUserToDelete(userData)
  }

  const getAllUsersData = useCallback(
    ({ page, limit, sortBy, sortDir, searchTerm }) => {
      pageInfo.current = { page, limit, searchTerm, sortBy, sortDir }
      getAllUsers({
        page,
        limit,
        sortBy,
        sortDir,
        searchParam: searchTerm
      })
      updateQueryParamsInUrl(pageInfo)
    },
    [getAllUsers]
  )

  useEffect(() => {
    if (!deleteRequesting && userToDelete?.user_id) {
      setDeleteModal(false)
      let { page, limit, sortBy, sortDir, searchTerm } = pageInfo.current
      let reloadMethod = getAllUsersData
      let userData = allUsers
      if (userData?.data.length <= 1 && !userData?.page?.has_next) {
        page = page > 0 ? page - 1 : 1
      }
      reloadMethod({ page, limit, sortBy, sortDir, searchParam: searchTerm })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteRequesting])

  const closeDeleteModal = () => {
    setDeleteModal(false)
    setUserToDelete({})
  }

  const deleteUser = () => {
    closeUserDispatch(userToDelete)
  }

  const handleSearch = searchTerm => {
    setSearchParam(searchTerm)
  }

  return (
    <>
      <div className="admin-users-container">
        <div className="header">
          <div className="heading-div">
            <h3>Users</h3>
          </div>
          <USearch
            placeholder="Search for a user"
            onClick={handleSearch}
            searchRequesting={searchParam && requesting}
          />
        </div>
        <div className="admin-users-section">
          <DataTable
            columns={columns}
            data={requesting ? [] : allUsers?.data}
            totalPages={allUsers?.page?.total_page}
            getTableData={getAllUsersData}
            loading={requesting}
            searchTerm={searchParam}
            pageInfoInitialValue={pageInfo.current}
          />
        </div>
        <DeleteConfirmModal
          showModal={showDeleteModal}
          handleOnClose={closeDeleteModal}
          onCancel={closeDeleteModal}
          onConfirm={deleteUser}
          confirmText="Are you sure you want to delete this user?"
          itemName={`"${userToDelete?.email}"`}
          loading={deleteRequesting}
        />
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  usersData: state.adminUsers.usersData,
  requesting: state.adminUsers.requesting,
  deleteRequesting: state.adminUsers.deleteRequesting
})
const mapDispatchToProps = dispatch => ({
  getAllUsers: data => dispatch(getAllUsers(data)),
  closeUserDispatch: data => dispatch(deleteUserRequest(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminAllUsers)
