import React, { useCallback, useEffect, useState, useRef } from "react"
import Button from "../../../components/Button"
import USearch from "../../../components/USearch"
import Image from "../../../components/Image"
import { Images } from "../../../theme/Images"
import RadarChart from "../../../components/RadarChart"
import JobCard from "../../../components/JobCard"
import EditModal from "../../../components/CandidateProfileEditModal"
import { getProfileSkillRequest, getCandidateProfileRequest } from "../Profile/redux/action"
import { connect } from "react-redux"
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"
import dayjs from "dayjs"

import "./style.scss"
import {
  postSavedJobRequest,
  getSavedJobsRequest,
  getApplyJobsRequest,
  getAllCandidateJobs
} from "./redux/action"
import { getSkillsScoreRequest } from "../../CommonScreens/CreateRole/redux/action"
import { formatSkills, getFormattedLocation } from "../../../utils/helper"
import { isArray } from "../../../utils/commonFunction"
import { TablePagination } from "@mui/material"
import TablePaginationActions from "../../../components/DataTable/components/TablePaginationActions"
import { getEmploymentTypeLabel } from "../../../utils/helper"
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

const Home = props => {
  const {
    getAllJobs,
    requesting,
    allJobsList,
    getCandidateSkills,
    candidateSkills,
    applyJobRequesting,
    savedJobRequesting,
    postSavedJobDispatch,
    getSavedJobsDispatch,
    getApplyJobDispatch,
    savedJobsData,
    applyJobsData,
    getSkillsScore,
    skillsScore,
    getCandidateProfileRequest,
    candidate_profile
  } = props
  const detailsSectionRef = useRef()
  const matchAnalytics = useRef()

  const [jobType, setJobType] = useState("All Jobs")
  const jobTypes = ["All Jobs", "Recently Added"]
  const [jobCardIndex, setJobCardIndex] = useState(0)
  const [show, setShow] = useState(false)
  const [currentModal, setCurrentModal] = useState({})
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [searchParam, setSearchParm] = useState("")
  const [jobSkills, setJobSkills] = useState([])

  const [showProfileInfoModal, setShowProfileInfoModal] = useState(false)
  const [, setProfileInfoModal] = useState({})
  const [uploadedResume, setUploadedResume] = useState()

  const scrollSidePanelBackToTop = () => {
    const sidePanel = document.getElementsByClassName('job-data-first-column')?.[0]
    sidePanel?.scrollTo({top: 0})
  }

  useEffect(() => {
    if (
      allJobsList?.data?.length
    ) {
      setJobSkills(formatSkills(allJobsList.data[jobCardIndex]?.skills))
      getSkillsScore({
        skills: allJobsList.data[jobCardIndex]?.skills
      })
    }
  }, [jobCardIndex, getSkillsScore, allJobsList])

  useEffect(() => {
    skillsScore?.length && setJobSkills(formatSkills([...skillsScore]))
  }, [skillsScore])

  const loadProfileData = useCallback(() => {
    getCandidateProfileRequest()
  }, [getCandidateProfileRequest])

  useEffect(() => loadProfileData(), [loadProfileData])

  useEffect(() => {
    setProfileInfoModal({
      section: 'profile-mandatory-info',
      editData: { ...candidate_profile}
    })
  }, [candidate_profile])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  let allJobs = useCallback(() => {
    getAllJobs({
      page: jobType === "Recently Added" ? 1 : page + 1,
      limit: rowsPerPage,
      sortBy: "job_created_date",
      sortDir: "desc",
      section: "recruiter-posted-jobs",
      searchParam: searchParam
    })

    jobType === "Recently Added" && setPage(0)
  }, [getAllJobs, page, rowsPerPage, jobType, searchParam])

  useEffect(() => {
    allJobs()
  }, [allJobs])

  useEffect(() => {
    getCandidateSkills()
  }, [getCandidateSkills])

  useEffect(() => {
    getApplyJobDispatch()
  }, [getApplyJobDispatch])

  useEffect(() => {
    getSavedJobsDispatch()
  }, [getSavedJobsDispatch])

  const handleJobClick = index => () => {
    setJobCardIndex(index)
    detailsSectionRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  const isAppliedJob = job => {
    return (
      isArray(applyJobsData) &&
      applyJobsData?.find(item => item?.job_id === job?.id)
    )
  }

  const isSavedJob = job => {
    return (
      isArray(savedJobsData) &&
      savedJobsData?.find(item => item?.job_id === job?.id)
    )
  }

  const isCandidateJobExist = job => {
    return isSavedJob(job) || isAppliedJob(job)
  }

  const changeTab = tab => () => setJobType(tab)

  const validateProfileInfo = (profileInfo) => {
    const hasLocation = !!profileInfo.locations && profileInfo.locations.length > 0
    const hasPhone = !!profileInfo.phone
    const hasResume = !!profileInfo.resume
    return hasLocation && hasPhone && hasResume
  }

  const applyJob = () => {
    if (!validateProfileInfo(candidate_profile)) {
      setShowProfileInfoModal(true)
    } else {
      const jobData = allJobsList?.data[jobCardIndex]
      setShow(true)
      setCurrentModal({
        section: "apply-job",
        jobData: { ...jobData, exists: isCandidateJobExist(jobData) }
      })
    }
  }

  const saveJob = () => {
    const jobData = allJobsList?.data[jobCardIndex]
    postSavedJobDispatch({ ...jobData, exists: isCandidateJobExist(jobData) })
  }

  const handleSearch = searchTerm => {
    setPage(0)
    setSearchParm(searchTerm)
  }

  useEffect(() => {
    scrollSidePanelBackToTop()
  }, [page, searchParam, rowsPerPage, jobType])

  return (
    <section className="jobs-main-section">
      <div className="header">
        <USearch
          placeholder="Search job title, keywords, or company name..."
          onClick={handleSearch}
          title="Find Jobs"
        />
        <div className="heading-div">
          <h3>All Jobs Matching Your Skills</h3>
        </div>
        <div className="jobs-tab-section">
          {jobTypes.map(item => (
            <div className="job-type-tab" key={item} onClick={changeTab(item)}>
              <span className={`text ${jobType === item ? "active" : ""}`}>
                {item}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="job-data">
        <Backdrop
          sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
          open={requesting}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {allJobsList?.data?.length > 0 ? (
          <>
            <div className="job-data-first-column">
              {allJobsList?.data?.map((item, index) => {
                return (
                  <JobCard
                    key={`${jobType}-${index}-${item.id}`}
                    isSelected={jobCardIndex === index}
                    jobData={item}
                    handleCardClick={handleJobClick(index)}
                    isAppliedJob={isAppliedJob(item)}
                    isSavedJob={isSavedJob(item)}
                  />
                )
              })}
              {jobType === "All Jobs" && (
                <div className="pagination">
                  <TablePagination
                    rowsPerPageOptions={[5]}
                    component="div"
                    count={allJobsList?.page?.total_page * rowsPerPage || 1}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    labelDisplayedRows={({ page }) => {
                      return `Page ${page + 1} of ${
                        allJobsList?.page?.total_page || 1
                      }`
                    }}
                  />
                </div>
              )}
            </div>
            <div className="job-data-second-column" ref={detailsSectionRef}>
              {allJobsList?.data?.length > 0 && (
                <div className="job-detail-description">
                  <div className="job-title">
                    <div className="job-name">
                      {allJobsList.data[jobCardIndex]?.title}
                    </div>
                  </div>
                  <div className="published-by">
                    <div className="location">
                      <Image src={Images.location_dark} />
                      <div className="location-name">
                        {getFormattedLocation(
                          allJobsList.data[jobCardIndex]?.locations
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="job-info">
                    {`${
                      getEmploymentTypeLabel(allJobsList.data[jobCardIndex]?.employment_type)
                    }`}
                  </div>
                  <div className="action-btn-container">
                    <Button
                      title="Apply"
                      showSpinner={!savedJobRequesting && applyJobRequesting}
                      onClick={applyJob}
                      disabled={
                        isAppliedJob(allJobsList.data[jobCardIndex])
                          ? true
                          : false
                      }
                      className={`apply-job ${
                        isAppliedJob(allJobsList.data[jobCardIndex])
                          ? "disabled-btn"
                          : ""
                      }`}
                    />
                    <Button
                      title="Save for later"
                      showIcon={true}
                      src={Images.like}
                      className={`transparent-outline-btn save-job ${
                        isSavedJob(allJobsList.data[jobCardIndex])
                          ? "disabled-btn"
                          : ""
                      }`}
                      onClick={saveJob}
                      showSpinner={!applyJobRequesting && savedJobRequesting}
                      disabled={
                        isSavedJob(allJobsList.data[jobCardIndex])
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div className="description">
                    <div className="head">Job Details</div>
                    <div
                      className="job-description"
                      dangerouslySetInnerHTML={{
                        __html: allJobsList.data[jobCardIndex]?.description
                      }}
                    ></div>
                  </div>
                  <div className="skills">
                    <div className="head">Skills and Expertise</div>
                    <div className="expertise">
                      {jobSkills?.length > 0 &&
                        jobSkills?.map((skill, index) => {
                          return (
                            <div
                              className="job-skill"
                              key={`${skill?.label}-${index}`}
                            >
                              {skill?.label}
                            </div>
                          )
                        })}
                    </div>
                  </div>
                  <div className="match-analysis">
                    <div className="head" ref={matchAnalytics}>
                      Match Analysis
                    </div>
                    <div className="cart-container">
                      <RadarChart
                        skillsInfo={candidateSkills}
                        compareSkillsInfo={jobSkills}
                        legends={jobSkills?.length > 0 ? ["Candidate Skills", "Job Listing Skills"] : ""}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          !requesting && <div className="no-jobs-message">No jobs found</div>
        )}
      </div>
      <EditModal
        showModal={show}
        setShowModal={setShow}
        currentModal={currentModal?.section}
        editData={currentModal?.jobData}
      />
      <EditModal
        showModal={showProfileInfoModal}
        setShowModal={setShowProfileInfoModal}
        currentModal={'profile-mandatory-info'}
        editData={candidate_profile}
        uploadedResume={uploadedResume}
        setUploadedResume={setUploadedResume}
      />
    </section>
  )
}

const mapStateToProps = state => ({
  allJobsList: state.candidateJobs.allJobsData,
  requesting: state.candidateJobs.requesting,
  applyJobRequesting: state.candidateJobs.applyJobRequesting,
  savedJobRequesting: state.candidateJobs.savedJobRequesting,
  candidateSkills: state.profile.skills,
  applyJobsData: state.candidateJobs.applyJobsData,
  savedJobsData: state.candidateJobs.savedJobsData,
  skillsScore: state.createRole.skillsScore,
  skillsScoreRequesting: state.createRole.skillsScoreRequesting,
  profileRequesting: state.profile.profileRequesting,
  candidate_profile: state.profile.candidate_profile
})
const mapDispatchToProps = dispatch => ({
  getAllJobs: data => dispatch(getAllCandidateJobs(data)),
  getCandidateSkills: data => dispatch(getProfileSkillRequest(data)),
  getApplyJobDispatch: data => dispatch(getApplyJobsRequest(data)),
  getSavedJobsDispatch: data => dispatch(getSavedJobsRequest(data)),
  postSavedJobDispatch: data => dispatch(postSavedJobRequest(data)),
  getSkillsScore: data => dispatch(getSkillsScoreRequest(data)),
  getCandidateProfileRequest: data => dispatch(getCandidateProfileRequest(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)
