const CISCO_CERTIFICATIONS = [
    {
        "title": "Cisco Certified Entry Networking Technician",
        "description": "The CCENT certification validates the ability to install, operate and troubleshoot a small enterprise branch network, including basic network security.\n\nThe CCENT certification validates the skills required for entry-level network support positions, the starting point for many successful careers in networking. CCENT certified professionals have the knowledge and skill to install, operate, and troubleshoot a small enterprise branch network, including basic network security.\n\nIt opens doors to a career in networking. Having your CCENT means you have what it takes to manage a small, enterprise branch network. A CCENT is your first step toward CCNA certification and will help you stand out from the crowd in entry-level positions.",
        "acronym": "CCENT",
        "company": "Cisco"
    },
    {
        "title": "Cisco Certified Technician (CCT)",
        "description": "Cisco Certified Technicians have the skills to diagnose, restore, repair, and replace critical Cisco networking and system devices at customer sites.\n\nCisco Certified Technicians have the skills to diagnose, restore, repair, and replace critical Cisco networking and system devices at customer sites. Technicians work closely with the Cisco Technical Assistance Center (TAC) to quickly and efficiently resolve support incidents. Cisco authorized training is available online and can be completed in multiple short sessions, enabling technicians to stay productive in the field. Cisco Certified Technician (CCT) certification is available in multiple technology tracks, providing an opportunity for Cisco support technicians to expand their area of expertise.",
        "acronym": "CCT",
        "company": "Cisco"
    },
    {
        "title": "CCT Data Center",
        "description": "The CCT Data Center certification focuses on the skills required for onsite support and maintenance of Cisco Unified Computing Systems and servers.\n\nCisco Certified Technician Data Center (CCT Data Center) certification focuses on the skills required for onsite support and maintenance of Cisco Unified Computing Systems and servers. Technicians in this area must be able to identify Cisco Unified Computing System components and servers, accessories, cabling and interfaces; understand the Cisco UCS and NX-OS operating modes and identify commonly-found software; and be able to use the Cisco Graphical User Interface to connect and service product components.",
        "acronym": "CCT Data Center",
        "company": "Cisco"
    },
    {
        "title": "CCT Routing & Switching",
        "description": "CCT Routing and Switching certification focuses on the skills required for onsite support and maintenance of Cisco routers, switches, and operating environments.\n\nCisco Certified Technican Routing and Switching (CCT Routing and Switching) certification focuses on the skills required for onsite support and maintenance of Cisco routers, switches, and operating environments. Technicians in this area must be able to identify Cisco router and switch models, accessories, cabling, and interfaces; understand the Cisco IOS Software operating modes and identify commonly found software; and be able to use the Cisco Command Line Interface (CLI) to connect and service products. Achieving CCT Routing and Switching certification is considered the best foundation for supporting other Cisco devices and systems.",
        "acronym": "CCT Routing & Switching",
        "company": "Cisco"
    },
    {
        "title": "CCDA",
        "company": "Cisco",
        "prerequisites": [
            "CCENT",
            "CCNA Routing and Switching",
            "CCIE"
        ],
        "exams": [
            "200-310 DESGN"
        ],
        "description": "Enterprise environments require networks designed for performance, availability, and scalability with the flexibility to meet rapidly evolving demands. To meet these challenges head on, skilled IT professionals are needed with up-to-date, fundamental network design skills. For network design engineers, system engineers, and sales engineers and individuals looking to build and validate Cisco network design fundamental knowledge the Cisco CCDA certification program focuses on design methodologies and objectives, addressing and routing protocols, and network expansion considerations within basic campus, data center, security, voice, and wireless networks.",
        "acronym": "CCDA"
    },
    {
        "title": "CCNA Cloud",
        "exams": [
            "210-451 CLDFND",
            "210-455 CLDADM"
        ],
        "description": "\nMany companies are embracing the Cloud to help them to be more agile, flexible, and effective at delivering better business outcomes. Today, the majority of companies are already using XaaS offerings, and by 2018, it's estimated that 78% of workloads will be processed through the cloud.\n\nThe CCNA Cloud certification is a job role focused certification and training program that helps Cloud engineers, Cloud Administrators, and Network Engineers to develop, advance, and validate their cloud skill set, and enables them to help their IT organization meet changing business demands from technology transitions.\n\nWith a CCNA Cloud certification, you will obtain the skills to perform entry-level provisioning and support of Cisco cloud solutions. Learn from the only company that has an end-to-end Cloud and Intercloud story.",
        "acronym": "CCNA Cloud",
        "company": "Cisco"
    },
    {
        "title": "CCNA Collaboration",
        "exams": [
            "210-060 CICD",
            "210-065 CIVND"
        ],
        "description": "CCNA Collaboration certification is for network video engineers, IP telephony and IP network engineers who want to develop and advance their collaboration and video skills in line with the convergence of voice, video, data and mobile applications.\n\nFor network video engineers, collaboration engineers, IP telephony and IP network engineers who want to develop and advance their collaboration and video skills in line with the convergence of voice, video, data and mobile applications, the Cisco CCNA Collaboration certification is a job-role focused training and certification program. It will allow you to maximize your investment in your education, and increase your professional value by giving you the skills to help your IT organization meet increased business demands resulting from these technology transitions.",
        "acronym": "CCNA Collaboration",
        "company": "Cisco"
    },
    {
        "title": "CCNA Cyber Ops",
        "exams": [
            "210-250 SECFND",
            "210-255 SECOPS"
        ],
        "description": "Today's organizations are challenged with rapidly detecting cybersecurity breaches and effectively responding to security incidents. Teams of people in Security Operations Centers (SOC’s) keep a vigilant eye on security systems, protecting their organizations by detecting and responding to cybersecurity threats.\n\nThe CCNA Cyber Ops certification prepares candidates to begin a career working with associate-level cybersecurity analysts within security operations centers.",
        "acronym": "CCNA Cyber Ops",
        "company": "Cisco"
    },
    {
        "title": "CCNA Data Center",
        "exams": [
            "200-150 DCICN",
            "200-155 DCICT"
        ],
        "description": "The data center has come a long way from its hardware- and database-centric roots. Data center professionals today need to understand how their field intersects with automation, cloud, software-defined technologies, and virtualization. Are you ready?\n\nAgility is the hallmark of today's successful data center. Built for rapid application deployment and supported by a highly elastic infrastructure, the data center has become core to businesses competing in our digital era. CCNA Data Center certification provides the confidence and nimbleness you need to install, configure, and maintain data center technology. Gain grounding in data center infrastructure, data center networking concepts and technologies, storage networking, unified computing, network virtualization, data center automation and orchestration, and Cisco Application Centric Infrastructure (ACI).\n\n",
        "acronym": "CCNA Data Center",
        "company": "Cisco"
    },
    {
        "title": "CCNA Industrial",
        "company": "Cisco",
        "prerequisites": [
            "Industrial Networking Specialist",
            "CCENT",
            "CCNA Routing and Switching",
            "CCIE"
        ],
        "exams": [
            "200-601 IMINS2"
        ],
        "description": "The Cisco Certified Network Associate Industrial (CCNA Industrial) certification is for plant administrators, control system engineers and traditional network engineers in the manufacturing, process control, and oil and gas industries, who will be involved with the convergence of IT and Industrial networks. This certification provides candidates the necessary skills to successfully implement and troubleshoot the most common industry standard protocols while leveraging best practices needed for todays connected networks.\n\nCombining theoretic knowledge with practical lab exercises, this curriculum provides the real-world skills that allow information technology (IT) and operational technology (OT) professionals to ensure that their current infrastructures are maximized while developing a converged platform for flexibility to support future business outcomes.\n\n",
        "acronym": "CCNA Industrial"
    },
    {
        "title": "CCNA Routing and Switching",
        "company": "Cisco",
        "prerequisites": [],
        "exams": [
            "200-125 CCNA",
            "100-105 ICND1",
            "200-105 ICND2"
        ],
        "description": "As Enterprises migrate toward controller based architectures, the role and skills required of a core network engineer are evolving and more vital than ever. To prepare for this network transition, the CCNA Routing and Switching certification will not only prepare you with the knowledge of foundational technologies, but ensure you stay relevant with skill sets needed for the adoption of next generation technologies.\n\n\n",
        "acronym": "CCNA Routing and Switching"
    },
    {
        "title": "CCNA Security",
        "company": "Cisco",
        "prerequisites": [
            "CCENT",
            "CCNA Routing and Switching",
            "CCIE"
        ],
        "exams": [
            "210-260 IINS"
        ],
        "description": "Cisco Certified Network Associate Security (CCNA Security) validates associate-level knowledge and skills required to secure Cisco networks. With a CCNA Security certification, a network professional demonstrates the skills required to develop a security infrastructure, recognize threats and vulnerabilities to networks, and mitigate security threats. The CCNA Security curriculum emphasizes core security technologies, the installation, troubleshooting and monitoring of network devices to maintain integrity, confidentiality and availability of data and devices, and competency in the technologies that Cisco uses in its security structure.",
        "acronym": "CCNA Security"
    },
    {
        "title": "CCNA Service Provider",
        "company": "Cisco",
        "prerequisites": [],
        "exams": [
            "640-875 SPNGN1",
            "640-878 SPNGN2"
        ],
        "description": "The CCNA Service Provider certification is for service provider network engineers, technicians, and support personnel who want to configure and implement robust baseline Cisco Service Provider IP next-generation networks.\n\nCisco Certified Network Associate Service Provider (CCNA SP) certification is for service provider network engineers, technicians and designers who focus on the latest in Service Provider industry core networking technologies and trends. With the ability to configure, implement, and troubleshoot baseline Cisco Service Provider Next-Generation networks, CCNA SP certified individuals deploy, maintain and improve carrier-grade network infrastructures.",
        "acronym": "CCNA Service Provider"
    },
    {
        "title": "CCNA Wireless",
        "company": "Cisco",
        "prerequisites": [
            "CCENT",
            "CCNA Routing and Switching",
            "CCIE"
        ],
        "exams": [
            "200-355 WIFUND"
        ],
        "description": "Cisco Wireless technology growth places increased demands on networks and the professionals that support them. Ensuring this technology is optimally configured, monitored, and supported is paramount to achieving business outcomes and requires a workforce of skilled wireless professionals. Earn the CCNA Wireless certification and amplify your basic Cisco Wireless LAN's configuration, monitoring, troubleshooting and support skills for optimal performance of Cisco Wireless networks.",
        "acronym": "CCNA Wireless"
    },
    {
        "title": "Cisco Certified Design Professional (CCDP)",
        "company": "Cisco",
        "prerequisites": [
            "CCIE",
            "CCDE",
            "CCDA",
            "CCNA Routing and Switching",
            "CCNP Routing and Switching"
        ],
        "exams": [
            "300-101 ROUTE",
            "300-115 SWITCH",
            "300-320 ARCH"
        ],
        "description": "The CCDP certification builds advanced knowledge of the network design concepts and principles required to develop multi-layer enterprise architectures and network components.\n\nEnterprise environments require networks designed for performance, availability and scalability to achieve outcomes. Seasoned IT professionals with progressive end-to-end network design expertise are crucial to ensure networks deliver to today's requirements while future proofing investments. For Senior Network Design Engineers, Principle System Engineer, Network/Solution Architects and CCDA professionals looking to build upon your fundamental Cisco network design expertise the Cisco CCDP certification program focuses on advanced addressing and routing protocols, WANs, services virtualization, and integration strategies for multi-layered Enterprise architectures.",
        "acronym": "CCDP"
    },
    {
        "title": "CCNP Cloud",
        "company": "Cisco",
        "prerequisites": [
            "CCNA Cloud",
            "CCIE"
        ],
        "exams": [
            "300-460 CLDINF",
            "300-465 CLDDES",
            "300-470 CLDAUT",
            "300-475 CLDACI"
        ],
        "description": "Cloud adoption is driving new roles and responsibilities. Cloud engineers need the skills to work with private, public and hybrid cloud models, and leverage Intercloud solutions. The CCNP Cloud certification is a lab based training and certification program that is targeted at Cloud engineers, Cloud Administrators, Cloud Designers, and Architects working in Data Centers. This program delivers the knowledge and skills necessary to design, provision, automate and manage Cloud and Infrastructure-as-a-Service deployments. Learn from the only company that has an end-to-end Cloud and Intercloud story.",
        "acronym": "CCNP Cloud"
    },
    {
        "title": "CCNP Collaboration",
        "company": "Cisco",
        "prerequisites": [
            "CCNA Collaboration",
            "CCIE",
            "CCDE",
            "CCIE Collaboration"
        ],
        "exams": [
            "300-070 CIPTV1",
            "300-075 CIPTV2",
            "300-080 CTCOLLAB",
            "300-085 CAPPS"
        ],
        "description": "For collaboration and unified communications network engineers who want develop advanced collaboration skills designing, deploying, configuring, and troubleshooting Cisco Collaboration and Unified communications applications, devices and networks, the Cisco CCNP Collaboration certification is a job-role focused training and certification program that will expand your skills and ability to deliver business value.\n\nCollaboration is becoming a critical necessity for business success and innovation. You can use your knowledge to lead the transformation and increase the effectiveness of your organizations collaboration experience.",
        "acronym": "CCNP Collaboration"
    },
    {
        "title": "CCNP Data Center",
        "company": "Cisco",
        "prerequisites": [
            "CCIE",
            "CCNA Data Center"
        ],
        "exams": [
            "300-175 DCUCI",
            "300-165 DCII",
            "300-170 DCVAI",
            "300-160 DCID",
            "300-180 DCIT"
        ],
        "description": "The CCNP Data Center certification and training program offers comprehensive certification and Professional-level skills focused on the data center solutions, technologies and best practices to design, implement, and manage a modern data center infrastructure.\n\nIT practitioners who are Cisco trained and certified are uniquely qualified for key roles in complex data center environments, with expertise utilizing technologies including policy-driven infrastructure, virtualization, automation and orchestration, unified computing, data center security, and integration of cloud initiatives. CCNP Data Center certified professionals are highly qualified for senior roles chartered with enabling digital business transformation initiatives.",
        "acronym": "CCNP Data Center"
    },
    {
        "title": "CCNP Routing and Switching",
        "company": "Cisco",
        "prerequisites": [
            "CCNA Routing and Switching",
            "CCIE"
        ],
        "exams": [
            "300-101 ROUTE",
            "300-115 SWITCH",
            "300-135 TSHOOT"
        ],
        "description": "Cisco Certified Network Professional (CCNP) Routing and Switching certification validates the ability to plan, implement, verify and troubleshoot local and wide-area enterprise networks and work collaboratively with specialists on advanced security, voice, wireless and video solutions. The CCNP Routing and Switching certification is appropriate for those with at least one year of networking experience who are ready to advance their skills and work independently on complex network solutions. Those who achieve CCNP Routing and Switching have demonstrated the skills required in enterprise roles such as network engineer, support engineer, systems engineer or network technician. The routing and switching protocol knowledge from this certification will provide a lasting foundation as these skills are equally relevant in the physical networks of today and the virtualized network functions of tomorrow.\n\n",
        "acronym": "CCNP Routing & Switching"
    },
    {
        "title": "CCNP Security",
        "company": "Cisco",
        "prerequisites": [
            "CCNA Security",
            "CCIE"
        ],
        "exams": [
            "300-208 SISAS",
            "300-206 SENSS",
            "300-209 SIMOS",
            "300-210 SITCS"
        ],
        "description": "Cisco Certified Network Professional Security (CCNP Security) certification program is aligned specifically to the job role of the Cisco Network Security Engineer responsible for Security in Routers, Switches, Networking devices and appliances, as well as choosing, deploying, supporting and troubleshooting Firewalls, VPNS, and IDS/IPS solutions for their networking environments.\n\n",
        "acronym": "CCNP Security"
    },
    {
        "title": "CCNP Service Provider",
        "company": "Cisco",
        "prerequisites": [
            "CCNA Service Provider",
            "CCIP",
            "CCIE"
        ],
        "exams": [
            "642-883 SPROUTE",
            "642-885 SPADVROUTE",
            "642-887 SPCORE",
            "642-889 SPEDGE"
        ],
        "description": "CCNP Service Provider is a certification for engineers required to deliver carrier-grade infrastructure and new managed services.\n\nThe Cisco Certified Network Professional Service Provider (CCNP Service Provider) certification is for service provider network engineers, systems engineers, and network specialists who are responsible for delivering a scalable carrier-grade infrastructure capable of rapid expansion to support ongoing introduction of new managed services and other customer requirements.\n",
        "acronym": "CCNP Service Provider"
    },
    {
        "title": "CCNP Wireless",
        "company": "Cisco",
        "prerequisites": [
            "CCNA Wireless",
            "CCIE"
        ],
        "exams": [
            "300-360 WIDESIGN",
            "300-365 WIDEPLOY",
            "300-370 WITSHOOT",
            "300-375 WISECURE"
        ],
        "description": "CCNP Wireless certification addresses the need for designing, implementing, and securing Cisco Wireless networks for optimal performance. CCNP Wireless certification emphasizes wireless networking principles and theory. It also recognizes the expertise and technical acumen of wireless professionals who can assess and translate network business requirements into technical specifications that in turn, result in successful business outcomes.\n\n",
        "acronym": "CCNP Wireless"
    },
    {
        "title": "Cisco Certified Design Expert (CCDE)",
        "exams": [
            "CCDE Written Exam",
            "CCDE Practical Exam"
        ],
        "description": "The CCDE certification identifies networking professionals with expert-level knowledge and skills in network design.\n\nThe Cisco Certified Design Expert (CCDE) is for expert-level network design engineers, expert-level network leads of IT infrastructure teams, and expert-level network leads of architecture teams working in job roles that require them to translate business needs, budget, and operational constraints into the design of a converged solution. The CCDE curriculum prepares designers to develop design solutions at the infrastructure level for large customer networks. Network engineers holding an active CCDE certification are recognized for their expert-level knowledge and skills in network infrastructure design. The deep technical networking knowledge that a CCDE brings ensures that they are well qualified to address the most technically challenging network infrastructure design assignments.\n\n",
        "acronym": "CCDE",
        "company": "Cisco"
    },
    {
        "title": "CCIE Collaboration Training",
        "exams": [
            "CCIE Collaboration Written Exam",
            "CCIE Collaboration Lab Exam"
        ],
        "description": "The CCIE Collaboration certification is for Collaboration Architects, Unified Communications Architects, or Voice and Video Network Managers who are responsible for the design, implementation, and troubleshooting of complex collaboration solutions. The certification is an expert-level certification that ensures that you gain knowledge and experience in collaboration solutions integration and interoperation, configuration, and troubleshooting in complex networks and the challenges of video, mobility, and presence as the foundation for workplace collaboration solutions.\n\nOur expertise in providing voice, video, IM, presence, and call center solutions and training will prepare you to deploy collaboration systems and services that increase user productivity improve the experience for you customers and are adaptable and provide a seamless user experience.",
        "acronym": "CCIE Collaboration Training",
        "company": "Cisco"
    },
    {
        "title": "CCIE Data Center",
        "exams": [
            "CCIE Data Center Written Exam",
            "CCIE Data Center Lab Exam"
        ],
        "description": "Cisco CCIE Data Center certification validates the expert-level skills required for the planning, design, implementation and management of complex modern IT data center infrastructure.\n\nAdvanced skills are focused on Layer 2 and Layer 3 connectivity, fabric infrastructure, storage networking and compute, network services, and automation and orchestration. Knowledge of evolving technologies, and their impact on the architectural framework, deployment models, implementation, and operations–are included in CCIE certification.\n\nThe CCIE Data Center practitioner is uniquely qualified to play a leadership role in harnessing evolving technologies and deep domain expertise to meet business requirements that depend on an agile IT infrastructure. Professionals who achieve CCIE Data Center certification have demonstrated their technical skills at the highest level, using industry best practices.",
        "acronym": "CCIE Data Center",
        "company": "Cisco"
    },
    {
        "title": "CCIE Routing and Switching",
        "exams": [
            "CCIE Routing and Switching Written Exam",
            "CCIE Routing and Switching Lab Exam"
        ],
        "description": "Cisco Certified Internetwork Expert Routing and Switching (CCIE Routing and Switching) certifies the skills required of expert-level network engineers to plan, operate and troubleshoot complex, converged network infrastructure.",
        "acronym": "CCIE Routing and Switching",
        "company": "Cisco"
    },
    {
        "title": "CCIE Security",
        "exams": [
            "CCIE Security Written Exam",
            "CCIE Security Lab Exam"
        ],
        "description": "The Cisco Certified Internetwork Expert Security (CCIE Security) program recognizes security experts who have the knowledge and skills to architect, engineer, implement, troubleshoot, and support the full suite of Cisco security technologies and solutions using the latest industry best practices to secure systems and environments against modern security risks, threats, vulnerabilities, and requirements.\n\n",
        "acronym": "CCIE Security",
        "company": "Cisco"
    },
    {
        "title": "CCIE Service Provider",
        "exams": [
            "CCIE Service Provider Written Exam",
            "CCIE Service Provider Lab Exam"
        ],
        "description": "CCIE Service Provider certification is for expert-level network engineers who have the skills to build an extensible Service Provider infrastructure.\n",
        "acronym": "CCIE Service Provider",
        "company": "Cisco"
    },
    {
        "title": "CCIE Wireless",
        "exams": [
            "CCIE Wireless Written Exam",
            "CCIE Wireless Lab Exam"
        ],
        "description": "The Cisco Certified Internetwork Expert Wireless (CCIE Wireless) certification assesses and validates wireless expertise. Candidates who pass the CCIE Wireless certification exams demonstrate broad theoretical knowledge of wireless networking and a solid understanding of wireless local area networking (WLAN) technologies from Cisco, the market leader in WLAN technology.",
        "acronym": "CCIE Wireless",
        "company": "Cisco"
    },
    {
        "title": "The Cisco Certified Architect (CCAr)",
        "company": "Cisco",
        "prerequisites": [
            "CCDE"
        ],
        "exams": [
            "CCAr Board Exam"
        ],
        "description": "CCAr is the highest level of accreditation achievable within the Cisco certification program.\n\nThe Cisco Certified Architect (CCAr) certification is for senior network infrastructure architects who produce technical specifications for the network to support business objectives. The curriculum focuses on understanding the business strategy and translating it into technical infrastructure requirements.",
        "acronym": "CCAr"
    },
    {
        "title": "Cisco Unified Contact Center Enterprise Specialist",
        "company": "Cisco",
        "prerequisites": [],
        "exams": [
            "600-455 UCCED",
            "600-460 UCCEIS"
        ],
        "description": "Enterprise-level contact centers utilizing the Cisco Unified Contact Center Enterprise (CCE) solution require highly skilled support engineers to design, deploy, configure, and troubleshoot for maximum performance and minimal downtime. The Cisco Unified Contact Center Enterprise Specialist certification ensures that you have the expertise needed to enable optimal solution performance.\n\n",
        "acronym": "Cisco Unified Contact Center Enterprise Specialist"
    },
    {
        "title": "Cisco TelePresence Solutions Specialist",
        "company": "Cisco",
        "prerequisites": [
            "CCNA"
        ],
        "exams": [
            "700-070 IX5K"
        ],
        "description": "The Cisco TelePresence Solutions Specialist certification focuses on the job-performance needs of a midcareer voice specialist or networking engineer who seeks to specialize in the planning, design, implementation (PDI) and maintenance of Cisco TelePresence deployments. Tasks include assessing network paths for rich media, evaluating call-control design options and configuring interoperability functions.\n\n\n",
        "acronym": "Cisco TelePresence Solutions Specialist"
    },
    {
        "title": "Cisco Video Network Specialist",
        "company": "Cisco",
        "prerequisites": [],
        "exams": [],
        "description": "The Cisco Video Network Specialist program enables traditional analog audio-visual professionals to extend their careers into the growing Video-over-IP network job role.\n\nProfessionals, who need Video-over-IP knowledge and skills, can benefit from Cisco's expertise in the field.\n\nCandidates will learn to deploy video end-points, set-up new users, and operate video networks. This certification validates an individual's knowledge of installing, operating and supporting networked video solutions and how to deploy and optimize the product features and benefits.\n\n",
        "acronym": "Cisco Video Network Specialist"
    },
    {
        "title": "Cisco Industrial Networking Specialist",
        "description": "The Cisco Industrial Networking Specialist certification is for information technology (IT) and operational technology (OT) professionals in the manufacturing, process control, and oil and gas industries, who will be involved with the implementation, operation, and support of networked industrial products and solutions. This certification ensures candidates have the foundational skills to manage and administer networked industrial control systems. It provides plant administrators, control system engineers and traditional network engineers with an understanding of the networking technologies needed in today's connected plants and enterprises.\n\nUnlike other industrial networking programs, this certification tests the hands-on skills through simulations as well as testing theoretical knowledge. This approach ensures that candidates are ready for real-world implementations.",
        "acronym": "Cisco Industrial Networking Specialist",
        "company": "Cisco"
    },
    {
        "title": "Cisco Network Programmability Developer Specialist",
        "exams": [
            "300-560 NPDEV"
        ],
        "description": "The Cisco Network Programmability Developer Specialist certification is for software programmers who focus on the development of the network applications layer, which can live in any of the Cisco provided programmable components, and will enable service provider, campus, and data center use cases. This certification and course develop the foundation skills needed to develop network applications in programmable environments such as Cisco's One Platform Kit (onePK), Open Daylight Controller (ODL), and Application Policy Infrastructure Controllers (APICs). Unlike SDN courses that focus on network theory, this certification adds to your practical networking understanding and utilizes Cisco's experience in WAN, data center, and campus environments to provide you with the skills you will need to become a network developer.\n\nCandidates should be able to program in Python, C, or other languages in an open networking environment prior to this certification.",
        "acronym": "Cisco Network Programmability Developer Specialist",
        "company": "Cisco"
    },
    {
        "title": "Cisco Network Programmability Design and Implementation Specialist",
        "company": "Cisco",
        "prerequisites": [
            "CCNP",
            "CCDP",
            "CCIE",
            "CCDE"
        ],
        "exams": [
            "300-550 NPDESI"
        ],
        "description": "The Cisco Network Programmability Design and Implementation Specialist addresses the evolving role of network engineers towards more programmability, automation and orchestration, enabling them to leverage the powerful level of abstraction provided by controller based architectures to create real added value.",
        "acronym": "Cisco Network Programmability Design and Implementation Specialist"
    },
    {
        "title": "Cisco IOS XR Specialist",
        "company": "Cisco",
        "prerequisites": [],
        "exams": [
            "644-906 IMTXR"
        ],
        "description": "The Cisco IOS XR Specialist certification is a certification for network engineers, who wish to obtain prescriptive information and experience using Cisco IOS XR. This certification validates the skills to implement, verify, and maintain core and edge technologies on Cisco carrier-class platforms running Cisco IOS XR.\n\nUnlike competitive offerings, the Cisco IOS XR Specialist certification provides service providers, enterprise companies, and partners a way to differentiate themselves and to show their prospective customer base the value in possessing staff with knowledge of Cisco IOS XR, e.g. the ability to utilize more advanced features, more successful and higher performing IT deployments, lower cost for network maintenance.",
        "acronym": "Cisco IOS XR Specialist"
    },
    {
        "title": "Cisco and NetApp FlexPod Design Specialist",
        "company": "Cisco",
        "prerequisites": [],
        "exams": [
            "500-170 FPDESIGN"
        ],
        "description": "The Cisco and NetApp FlexPod Design Specialist is for storage and data management professionals including systems engineers, field engineers, professional services consultants, and channel partners who are focused on FlexPod solution enablement.\n\nThis FlexPod certification is the first jointly-developed, global, multi-vendor technical certification that delivers in-depth knowledge of the tools and standards to assess performance characteristics and requirements of the FlexPod integrated solution.",
        "acronym": "Cisco and NetApp FlexPod Design Specialist"
    },
    {
        "title": "Cisco and NetApp FlexPod Implementation and Administration Specialist",
        "company": "Cisco",
        "prerequisites": [],
        "exams": [
            "500-171 FPIMPADM"
        ],
        "description": "The Cisco and NetApp FlexPod Implementation and Administration Specialist is for storage and data management professionals including systems engineers, field engineers, professional services consultants, and channel partners who are focused on FlexPod solution enablement.\n\nThis FlexPod certification is the first jointly-developed, global, multi-vendor technical certification that delivers in-depth knowledge of the tools and standards to assess performance characteristics and requirements of the FlexPod integrated solution.",
        "acronym": "Cisco and NetApp FlexPod Implementation and Administration Specialist"
    },
    {
        "title": "Cisco Cybersecurity Specialist",
        "company": "Cisco",
        "prerequisites": [],
        "exams": [
            "600-199 SCYBER"
        ],
        "description": "The Cisco Cybersecurity Specialist certification recognizes security professionals who have attained specialized in-depth expertise and proven knowledge in the essential areas of proactive cyber threat detection and mitigation.\n\nDesigned for professional security analysts and leveraging the features of Cisco and other network security products used today, the Cisco Cybersecurity Specialist certification focuses on the topics of event monitoring, security event/alarm/traffic analysis, and incident response.",
        "acronym": "Cisco Cybersecurity Specialist"
    },
    {
        "title": "Cisco Service Provider Mobility CDMA to LTE Specialist",
        "company": "Cisco",
        "prerequisites": [],
        "exams": [
            "600-211",
            "600-212"
        ],
        "description": "The Cisco Service Provider Mobility CDMA to LTE Specialist certification validates a Service Provider Network engineer's knowledge and skills required to understand technologies, components, architectures, and products that are found in Code Division Multiple Access (CDMA) packet core networks, specifically in the migration to Long-Term Evolution (LTE) Evolved Packet Systems (EPS), including Evolved Packet Core (EPC) networks and radio access networks (RANs).",
        "acronym": "Cisco Service Provider Mobility CDMA to LTE Specialist"
    },
    {
        "title": "Cisco Service Provider Mobility UMTS to LTE Specialist",
        "company": "Cisco",
        "prerequisites": [],
        "exams": [
            "600-210",
            "600-212"
        ],
        "description": "The Cisco Service Provider Mobility UMTS to LTE Specialist certification validates a Service Provider Network engineer's knowledge and skills required to understand technologies, components, architectures, and products that are found in Universal Mobile Telecommunications System (UMTS) packet core networks, specifically in the migration to Long-Term Evolution (LTE) Evolved Packet Systems (EPS), including Evolved Packet Core (EPC) networks and radio access networks (RANs).",
        "acronym": "Cisco Service Provider Mobility UMTS to LTE Specialist"
    },
    {
        "title": "Cisco Business Architecture Analyst",
        "company": "Cisco",
        "prerequisites": [],
        "exams": [
            "810-440 DTBAA"
        ],
        "description": "\nThe Cisco Business Architecture Analyst is a certification program that prepares IT and line-of-business professionals to build and strengthen the bridge between technology solutions and critical business needs. As the digital transformation of business accelerates, the discipline of Business Architecture becomes an important enabler and much-needed skill set of the IT professional. This certification is the first step in preparing candidates to serve as trusted advisors who can engage customers and lead them through their digital transformation.\n\nThis job role based certification will help candidates to:\n\nUnderstand the concepts of the four pillars of Business Architecture\nIdentify the roles of the transformative team, and how they work to a solution\nDefine and understand the business, priority, requirements, and capability\nFamiliarity with the concept of Enterprise Architecture, and the use of frameworks in context",
        "acronym": "Cisco Business Architecture Analyst"
    },
    {
        "title": "Cisco Business Architecture Specialist",
        "company": "Cisco",
        "prerequisites": [
            "Cisco Business Architecture Analyst"
        ],
        "exams": [
            "820-445 DTBAS"
        ],
        "description": "The Cisco Business Architecture Specialist is a certification program that picks up from the Business Architecture Analyst certification to continue your journey to becoming a full-fledged Business Architect. Beyond reinforcing foundational knowledge, this program builds the explicit skills and tools to model the business, empower stakeholders, and achieve the desired outcomes to further the digital transformation of the customer. Practice building rapport and credibility, fostering entrepreneurship, and implementing consultative approaches that help develop critical customer skills. This certification continues the candidate's journey toward Business Architect.\n\nThis job role based certification will help candidates to:\n\nAdopt a consultative and need-driven approach\nUnderstand and employ the Cisco Business Architecture Methodology\nDefine and determine the maturity level for the business\nBuild a business model that accurately describes a business\nIdentify internal and external business drivers",
        "acronym": "Cisco Business Architecture Specialist"
    },
    {
        "title": "Cisco Business Architecture Practitioner",
        "company": "Cisco",
        "prerequisites": [
            "Cisco Business Architecture Specialist"
        ],
        "exams": [
            "840-450 DTBAD"
        ],
        "description": "The Cisco Business Architecture Practitioner is a certification program that builds and extends the skills inherent in the Cisco Business Architect. Those who achieve this level of expertise will be able to fully lead a business engagement, developing a comprehensive roadmap, managing engagement artifacts, and effortlessly participating at all levels within an organization to build rapport and credibility. Business Architecture Practitioners will demonstrate mastery of the tools and techniques that are essential to successful transformative business engagements. This certification is the ultimate destination of the Cisco Business Architect.\n\nThis job role based certification will help candidates to:\n\nIdentify, define, and fulfill strategies to transform businesses\nProvide full architectures, solutions, and deliver on the vision and outcomes that are promised\nCreate, refine, and document business cases, roadmaps, and other plan artifacts that deliver on the desired outcomes\nBuild and lead a team to both develop and deliver on the promise of digital transformation of business outcomes",
        "acronym": "Cisco Business Architecture Practitioner"
    },
    {
        "title": "Cisco Business IT Roadmap Specialist",
        "company": "Cisco",
        "prerequisites": [],
        "exams": [
            "820-432 BTROAD"
        ],
        "description": "\nThe Cisco Business IT Roadmap Specialist is a certification program for sales professionals that engage in customer conversations designed to pinpoint a strategic view of customer needs, lead stakeholders to consensus decisions, and produce cross-architectural roadmaps. This certification will help candidates to become and succeed as a trusted advisor, to engage in and lead customers through comprehensive, relevant business discussions.\n\nThis job role based certification will help candidates to:\n\nUnderstand IT/business strategy and priorities\nProvide architectures, solutions, software and services\nDeliver cross-architecture initiative roadmap",
        "acronym": "Cisco Business IT Roadmap Specialist"
    },
    {
        "title": "Cisco Customer Success Manager Specialist",
        "company": "Cisco",
        "prerequisites": [],
        "exams": [
            "820-602 DTCSM"
        ],
        "description": "The Cisco Customer Success Manager Specialist certification provides a solid framework, and a core set of proven fundamental skills and knowledge to deliver immediate value to the vendor/customer relationship.\n\nTo help customers achieve the value and utility that they expect, and to realize the outcomes, it is increasingly crucial for a customer advocate to take responsibility and ensure that the deployment, adoption, and follow on use of the product/service deliver on the up front, agreed upon and purchased business outcome.",
        "acronym": "Cisco Customer Success Manager Specialist"
    }
]

const ORACLE_CERTIFICATIONS = [
    {
        "exam": "Oracle CRM On Demand Essentials 1Z0-527",
        "title": "Oracle CRM On Demand",
        "description": "",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:10"
    },
    {
        "exam": "Oracle Value Chain Planning: Demantra Demand Management 7 Essentials 1Z0-558",
        "title": "Demantra",
        "description": "",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:11"
    },
    {
        "exam": "Oracle E-Business Suite R12.1 General Ledger Essentials 1Z0-516",
        "title": "E-Business Suite Financial Management",
        "description": "\n\n\n\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:13"
    },
    {
        "exam": "Oracle E-Business Suite R12.1 Payables Essentials 1Z0-517",
        "title": "E-Business Suite Financial Management",
        "description": "",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:14"
    },
    {
        "exam": "Oracle E-Business Suite R12.1 Receivables Essentials 1Z0-518",
        "title": "E-Business Suite Financial Management",
        "description": "\n\t ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:15"
    },
    {
        "exam": "Oracle E-Business Suite (EBS) R12 Human Capital Management Essentials 1Z0-548",
        "title": "E-Business Suite Human Capital Management",
        "description": "",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:16"
    },
    {
        "exam": "Oracle E-Business Suite R12.1 Inventory Essentials 1Z0-519",
        "title": "E-Business Suite Manufacturing",
        "description": "\n\n\t\tThis certification is available to all candidates but is geared toward members of the Oracle PartnerNetwork who are focused on selling and implementing Oracle E-Business Suite Supply Chain Management modules.  This exam targets the intermediate-level implementation team member. Up-to-date training and field experience are recommended.\n\n\tThe Oracle E-Business Suite 12 Supply Chain Certified Implementation Specialist: Oracle Inventory certification validates that you possess a functional foundation in E-Business Essentials. You'll develop the skills to access and navigate within the R12 E-Business Suite, enter data, retrieve information in the form of a query and access online help. Through preparation for the exam, you will also gain a basic understanding of the features, functions and benefits of Oracle Inventory.\n\tYou will be able to add items, enable lot control, enable serial control, enable dual unit of measure control, perform inventory transfers, perform inventory replenishment, perform a cycle, view on-hand quantities, create reservations and setup material statuses.\n\tOPN Members earning this certification will be recognized as OPN Certified Specialists, which helps their companies qualify for the Oracle E-Business Suite Supply Chain Management Specialization. Gain an edge in the job market by certifying your Oracle Inventory expertise.\n\t ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:23"
    },
    {
        "exam": "Oracle E-Business Suite R12.1 Order Management Essentials 1Z0-521",
        "title": "E-Business Suite Order Fulfillment",
        "description": "\n\t \n\tThe Oracle E-Business Suite 12 Supply Chain Certified Implementation Specialist: Oracle Order Management certification indicates a functional foundation in E-Business Essentials including skills such as how to access and navigate within the R12 E-Business Suite, how to enter data, retrieve information in the form of a query and access online help. Through exam preparation, you will gain the skills necessary to set up and manage the Oracle Order Management process – from sales order creation to autoinvoice process in Receivables. This exam targets the intermediate-level implementation team member. Up-to-date training and field experience are recommended.\n\tThis certification is available to all candidates but is geared toward members of the Oracle PartnerNetwork who are focused on selling and implementing Oracle E-Business Suite Supply Chain Management modules. OPN Members earning this certification will be recognized as OPN Certified Specialists, which helps their companies qualify for the Oracle E-Business Suite Supply Chain Management Specialization.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:24"
    },
    {
        "exam": "Oracle E-Business Suite R12.1 Purchasing Essentials 1Z0-520",
        "title": "E-Business Suite Procurement",
        "description": "",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:17"
    },
    {
        "exam": "Oracle E-Business Suite (EBS) R12 Project Essentials 1Z0-511",
        "title": "E-Business Suite Project Management",
        "description": "\n\n\tThe Oracle E-Business Suite Project Certified Implementation Specialist is designed for individuals who possess a strong foundation and expertise in implementing Oracle E-Business Suite Project solutions. This certification is available to all candidates but is geared toward members of the Oracle PartnerNetwork. OPN members earning this certification will be recognized as OPN Certified Specialists\n\n\tThis certification covers topics such as the fundamentals and implementation options of Project Foundation, Project Costing and Project Billing. You'll become certified to use a solution that supports the full lifecycle of project and portfolio management with a single, accurate view of all project-related activities. Industry leading functionality allows users to select the best portfolio of initiatives, execute projects in adherence with methodologies, assign the right global resources, proactively streamline project delivery and track profitability via accurate budgeting, forecasting and billings/chargebacks.\n\n\tThe Oracle E-Business Suite Project Specialization recognizes partner organizations that are proficient in selling, implementing and/or developing the Oracle EBS Projects modules. This certification differentiates candidates in the marketplace by providing a competitive edge through proven expertise.\n\tUp-to-date training and field experience are recommended. This certification qualifies as competency criteria for the Oracle EBS R12 Project Specialization. \n\t ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:18"
    },
    {
        "exam": "Oracle EBS R12: Advanced Supply Chain Planning 1Z0-244",
        "title": "E-Business Suite Supply Chain Management",
        "description": "\n\t \n\n\n\n\tOracle EBS R12 Advanced Supply Chain Planning Certified Expert Consultants can help an organization evaluate, plan, configure and introduce the new planning environment into the organization. The ability to execute a plan is the difference between success and failure. Moving to an Advanced Planning System such as ASCP often enables a change in the planning process and allows a company to drive greater disciplines in other departments to get the most out of their planning process. \n\tOracle EBS R12 Advanced Supply Chain Planning Certified Expert Consultants can present an integrated set of services that can assist the customer in driving real value from the medium term decision making and make the best use of the supply chain resources. By adding a well-respected Oracle certification to your resume, you'll help yourself stand out in a competitive job market.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:19"
    },
    {
        "exam": "Oracle EBS R12: Install, Patch and Maintain Applications 1Z0-238",
        "title": "E-Business Suite Tools and Technology",
        "description": "\n\n\tThe Oracle E-Business Suite R12 Applications Database Administrator Certified Professional Certification is designed for individuals who possess a strong foundation and expertise in implementing Oracle E-Business Suite solutions.\n\n\n",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:21",
        "prerequisites": [
            "\n"
        ],
        "level": "Professional Level Certification",
        "company": "Oracle"
    },
    {
        "exam": "Oracle EBS R12: Install, Patch and Maintain Applications 1Z0-238",
        "title": "E-Business Suite Tools and Technology",
        "description": "\n\n\tThe Oracle E-Business Suite R12 Applications Database Administrator Certified Professional Certification is designed for individuals who possess a strong foundation and expertise in implementing Oracle E-Business Suite solutions.\n\n\n",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:21#tabs-2",
        "prerequisites": [
            "\n"
        ],
        "level": "Professional Level Certification",
        "company": "Oracle"
    },
    {
        "exam": "Oracle Advanced Controls Applications 2014 Essentials 1Z0-487",
        "title": "Oracle Fusion Governance, Risk and Compliance",
        "description": "\n\tThe Oracle Advanced Controls Applications 2014 Certified Implementation Specialist certification recognizes candidates that are proficient in selling, implementing and developing Oracle Advanced Controls solutions. As organizations strive to deal with increased regulatory and oversight pressures, and attempt to minimize the cost impact on daily business activities, they must align their approach and tools to address compliance and risk management in the most efficient and repeatable way.\n\tTopics covered in this exam include: Fusion GRC Set Up and Administration, Application Access Controls Governor, Transaction Controls Governor, Configuration Controls Governor and Preventive Controls Governor. Candidates who achieve this certification are differentiated in the marketplace through proven expertise.\n\tThis certification is available to all candidates but is geared toward members of the Oracle PartnerNetwork. OPN members earning this certification will be recognized as OPN Certified Specialists. This certification qualifies as competency criteria for the Oracle Fusion Governance, Risk, and Compliance 11g Applications Specialization.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:247"
    },
    {
        "exam": "Oracle Hyperion Data Relationship Management Essentials 1Z0-588",
        "title": "Data Relationship Management",
        "description": "\n\t \n\tThe Oracle Hyperion Data Relationship Management Specialization is for individuals focused on selling and implementing a Master Data Management software solution to build consistency within master data assets despite endless changes within the underlying transactional and analytical systems. The certification will cover such topics as: creating applications, working with hierarchies and nodes, defining properties, querying and comparing data, blending versions, formulas for derived properties and managing security.\n\tThis certification is available to all candidates but is geared toward members of the Oracle PartnerNetwork. OPN members earning this certification will be recognized as OPN Certified Specialists. This certification helps the OPN member's company qualify for the Oracle Master Data Management Specialization.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:35"
    },
    {
        "exam": "Oracle Hyperion Financial Management 11 Essentials 1Z0-532",
        "title": "Hyperion Financial Management",
        "description": "\n\t \n\tThe Oracle Hyperion Financial Management 11 Specialist Certification identifies professionals that are skilled in implementing solutions based on Oracle Hyperion Financial Management. The certification covers skills such as: creating applications using and EPMA and via the classic method, loading data, defining rules, working with shared services and defining reports. The exam targets the intermediate-level implementation team member. Up-to-date training and field experience are recommended.\n\tThis certification is available to all candidates but is geared toward members of the Oracle Partner Network who are focused on selling and implementing Oracle Business Intelligence technology. OPN Members earning this certification will be recognized as OPN Certified Specialists, which helps their companies qualify for the Oracle Business Intelligence Foundation Specialization.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:37"
    },
    {
        "exam": "Oracle Hyperion Planning 11 Essentials 1Z0-533",
        "title": "Planning",
        "description": "\n\t \n\tThe Oracle Hyperion Planning 11 Specialist Certification identifies professionals that are skilled in implementing solutions based on Oracle Hyperion Financial Management. The certification covers skills such as: creating Planning applications, setting up dimensions and account hierarchies, loading data, working with EPMA, defining forms, defining business rules and defining security. The exam targets the intermediate-level implementation team member. Up-to-date training and field experience are recommended.\n\tThis certification is available to all candidates but is geared toward members of the Oracle Partner Network who are focused on selling and implementing Oracle Business Intelligence technology. OPN Members earning this certification will be recognized as OPN Certified Specialists, which helps their companies qualify for the Oracle Business Intelligence Foundation Specialization.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:38"
    },
    {
        "exam": "JD Edwards EnterpriseOne Financial Management 9.2 Implementation Essentials 1Z0-342",
        "title": "JD Edwards Financial Management",
        "description": "",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:478"
    },
    {
        "exam": "JD Edwards EnterpriseOne Distribution 9.2 Implementation Essentials 1Z0-343",
        "title": "JD Edwards Supply Chain Execution (Logistics)",
        "description": "",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:475"
    },
    {
        "exam": "JD Edwards EnterpriseOne Configurable Network Computing 9.2 Implementation Essentials 1Z0-344",
        "title": "JD Edwards Tools and Technology",
        "description": "",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:506"
    },
    {
        "exam": "Oracle Commerce 11 Guided Search Implementation Essentials 1Z0-446",
        "title": "Oracle Commerce Developer",
        "description": "\n\tOracle Commerce 11 Guided Search Certified Implementation Specialist certification is designed for individuals who possess a strong foundation and expertise in selling and implementing Oracle Commerce 11 Guided Search solutions. This certification covers topics such as: Architecture and Components, Guided Search Application Configuration, Record Design in Oracle Commerce 11.1 Guided Search, Pipeline Development, Search Features, Oracle Commerce 11 Experience Manager Concepts or Oracle Commerce 11 Experience Manager Implementation.\n\tThis certification differentiates candidates in the marketplace by providing a competitive edge through proven expertise. Up-to-date training and field experience are recommended.\n\tThis certification is available to all candidates but is geared toward members of the Oracle PartnerNetwork. OPN members earning this certification will be recognized as OPN Certified Specialists. This certification qualifies as competency criteria for the Oracle Commerce 11 Specialization.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:353"
    },
    {
        "exam": "Oracle Commerce 11 Platform Development Implementation Essentials 1Z0-437",
        "title": "Oracle Commerce Developer",
        "description": "",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:340"
    },
    {
        "exam": "Oracle Policy Automation 10 Rule Developer Essentials 1Z0-534",
        "title": "Oracle Policy Modeling",
        "description": "\n\t \n\tThe Oracle Policy Automation 10 Implementation Specialist exam is primarily intended for implementation consultants with at least one year experience, who will be authoring rules in Oracle Policy Modeling, and who have completed the Oracle Policy Automation 10 Implementation Specialist Guided Learning Path or equivalent knowledge acquired through alternate training or on-the-job experience.\n\tThis certification is available to all candidates but is geared toward members of the Oracle PartnerNetwork. OPN Members earning this certification will be recognized as OPN Certified Specialists, which helps their companies qualify for the Oracle Policy Automation Specialization.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:67"
    },
    {
        "exam": "Oracle Unified Method 5 Essentials 1Z0-568",
        "title": "Oracle Unified Method",
        "description": "\n\t \n\tThe Oracle Unified Method Certified Implementation Specialist Certification identifies professionals who are skilled in Oracle’s all inclusive methodology. The certification covers the core features the Oracle Unified Method suite, including but not limited to, Focus Areas, Uses Cases, and Requirements Gathering. The certification proves a baseline of the consultant’s knowledge and allows the implementation team to work as a cohesive team from day 1. Up-to-date training and field experience are highly recommended.\n\tThis certification is available to all candidates but is geared toward members of the Oracle Partner Network who are focused on selling and implementing this technology. OPN Members earning this certification will be recognized as OPN Certified Specialists, which helps their companies qualify for the Oracle Unified Method Specialization.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:69"
    },
    {
        "exam": "Oracle Unified Method with Cloud Application Services 2017 Implementation Essentials 1Z0-633",
        "title": "Oracle Unified Method",
        "description": "",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:488"
    },
    {
        "exam": "PeopleSoft Campus Solutions 9 Student Enrollment Implementation Essentials 1Z0-494",
        "title": "PeopleSoft Campus Solutions",
        "description": "",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:317"
    },
    {
        "exam": "PeopleSoft Payables 9.1 Essentials 1Z0-573",
        "title": "PeopleSoft Financial Management",
        "description": "\n\t \n\tThe PeopleSoft Payables 9.1 certification is designed for individuals with expertise implementing Oracle’s PeopleSoft Accounts Payable solutions. This certification covers topics such as: Defining Core Tables, Setting Up Payables Business Units, Processing Vouchers, and Creating and Managing Payments.\n\tSuccessfully completing the requirements of this certification track earns the designation of PeopleSoft 9.1 Financial Management: Payables Certified Implementation Specialist. This certification differentiates candidates in the marketplace by providing a competitive edge through proven expertise. Up-to-date training and field experience are recommended.\n\tThis certification is available to all candidates but is geared toward members of the Oracle PartnerNetwork. OPN members earning this certification will be recognized as OPN Certified Specialists. This certification qualifies as competency criteria for the PeopleSoft Enterprise Financial Management Specialization.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:75"
    },
    {
        "exam": "PeopleSoft Receivables 9.1 Essentials 1Z0-575",
        "title": "PeopleSoft Financial Management",
        "description": "\n\t \n\tThe PeopleSoft Receivables 9.1 certification is designed for individuals with expertise in implementing Oracle’s PeopleSoft Accounts Receivable solutions. This certification covers topics such as: Defining Receivables Tables, Defining Payment Processing, Entering Payments, and Processing Drafts.\n\tSuccessfully completing the requirements of this certification track earns the designation of PeopleSoft Enterprise 9.1 Financial Management: Receivables Certified Implementation Specialist.\n\tThis certification differentiates candidates in the marketplace by providing a competitive edge through proven expertise. Up-to-date training and field experience are recommended. This certification is available to all candidates but is geared toward members of the Oracle PartnerNetwork. OPN members earning this certification will be recognized as OPN Certified Specialists. This certification qualifies as competency criteria for the PeopleSoft Enterprise Financial Management Specialization.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:73"
    },
    {
        "exam": "PeopleSoft 9.2 Financials Implementation Essentials 1Z0-631",
        "title": "PeopleSoft Financial Management",
        "description": "\n\tA PeopleSoft 9.2 Financials Certified Implementation Specialist has demonstrated the knowledge required to reduce period end close processing time, to maximize cash from financial operations, and to lower the cost of compliance and controls.  Individuals who earn this certification are able to assist in the implementation of modules such as General Ledger, Payables, Receivables, Billing, and eSettlements.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:505"
    },
    {
        "exam": "PeopleSoft General Ledger 9 1Z0-228",
        "title": "PeopleSoft Financial Management",
        "description": "\n\t \n\tUsing next-generation technologies and numerous functional enhancements, Oracle’s PeopleSoft helps you get more value from your existing applications, streamline and improve key business processes, and increase workforce efficiency while lowering costs.\n\tMaximizing your return on investment requires you to fully utilize the capabilities of all your existing applications. The PeopleSoft Enterprise 9 Financial Management, General Ledger Certified Consultant has proficiency and knowledge in key areas of implementation such as Oracle general ledger financial controls, journal entry creation and setup, period close and financial reporting.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:74"
    },
    {
        "exam": "PeopleSoft 9.2 Human Resources Essentials 1Z0-416",
        "title": "PeopleSoft Human Capital Management",
        "description": "\n\tPeopleSoft 9.2 Human Resources Certified Implementation Specialist\n\tThe PeopleSoft 9.2 Human Resources Certified Implementation Specialist certification is designed for individuals who possess a strong foundation and expertise in selling and implementing PeopleSoft Human Resources solutions.  This certification covers topics such as: Core Foundation Tables, Administering Data Permission Security, Adding People, Managing Worker Data, Managing Person and Job Data, and Managing Positions. This certification differentiates candidates in the marketplace by providing a competitive edge through proven expertise.  Up-to-date training and field experience are recommended.\n\tThis certification is available to all candidates but is geared toward members of the Oracle PartnerNetwork.  OPN members earning this certification will be recognized as OPN Certified Specialists.  This certification qualifies as competency criteria for the PeopleSoft 9.2 Human Capital Management Specialization.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:354,p_new_design:Y"
    },
    {
        "exam": "PeopleSoft Human Resources 9 Fundamentals 1Z0-218",
        "title": "PeopleSoft Human Capital Management",
        "description": "\n\t \n\tOracle's PeopleSoft Human Capital Management (HCM) is a complete solution that enables you to maximize the potential of your organization's workforce. Streamline and enhance performance in Human Resources processes, including recruitment, payroll and compensation, performance appraisal and talent management.\n\tThe PeopleSoft Human Resources 9 Consultant Certified Expert is recognized for proficiency and knowledge in implementing, configuring and customizing the PeopleSoft Enterprise Human Resources Management System.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:70"
    },
    {
        "exam": "PeopleSoft PeopleTools 8.5x Implementation Essentials 1Z0-632",
        "title": "PeopleTools",
        "description": "",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:493"
    },
    {
        "exam": "PeopleSoft Application Developer II: Application Engine & Integration 1Z0-242",
        "title": "PeopleTools",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:72"
    },
    {
        "exam": "PeopleSoft Application Developer I: PeopleTools & PeopleCode 1Z0-241",
        "title": "PeopleTools",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:71"
    },
    {
        "exam": "Project Lifecycle Management (PLM) Essentials 1Z0-466",
        "title": "Lifecycle Management",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:130"
    },
    {
        "title": "CRM Applications",
        "exam": "Siebel 8 Consultant Exam 1Z0-202",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:118",
        "prerequisites": [
            "\n",
            "Show Courses",
            "\n\tCourses can be instructor-led in-class (ILT), a live virtual class (LVC), training on demand (TOD), or Unlimited Learning Subscription. Self-study CD and online courses are great preparation tools, but do not fulfill the training requirement."
        ],
        "description": "\n"
    },
    {
        "title": "CRM Applications",
        "exam": "Siebel 8 Consultant Exam 1Z0-202",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:118#tabs-2",
        "prerequisites": [
            "\n",
            "Show Courses",
            "\n\tCourses can be instructor-led in-class (ILT), a live virtual class (LVC), training on demand (TOD), or Unlimited Learning Subscription. Self-study CD and online courses are great preparation tools, but do not fulfill the training requirement."
        ],
        "description": "\n"
    },
    {
        "exam": "Oracle Customer Hub and Oracle Data Quality Essentials 1Z0-587",
        "title": "Oracle Customer Hub",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:56"
    },
    {
        "exam": "Oracle iStore 12 Essentials 1Z0-555",
        "title": "Oracle iStore",
        "description": "\n\n\n\t ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:55"
    },
    {
        "exam": "Oracle Database SQL 1Z0-071",
        "title": "SQL and PL/SQL",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:228#tabs-2-2",
        "level": "Associate Level Certification"
    },
    {
        "exam": "Oracle Database 12c: Advanced PL/SQL 1Z0-148",
        "title": "SQL and PL/SQL",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:228#tabs-2-2",
        "level": "Professional Level Certification"
    },
    {
        "exam": "Oracle Database SQL 1Z0-071",
        "title": "SQL and PL/SQL",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:472#tabs-2-1",
        "level": "Associate Level Certification"
    },
    {
        "exam": "Oracle Database 12c: Advanced PL/SQL 1Z0-148",
        "title": "SQL and PL/SQL",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:472#tabs-2-1",
        "level": "Professional Level Certification"
    },
    {
        "exam": "Oracle Database SQL 1Z0-071",
        "title": "SQL and PL/SQL",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:457#tabs-1-1",
        "level": "Associate Level Certification"
    },
    {
        "exam": "Oracle Database 12c: Advanced PL/SQL 1Z0-148",
        "title": "SQL and PL/SQL",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:457#tabs-1-1",
        "level": "Professional Level Certification"
    },
    {
        "exam": "Oracle Database SQL 1Z0-071",
        "title": "SQL and PL/SQL",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:162#tabs-1-2",
        "level": "Associate Level Certification"
    },
    {
        "exam": "Oracle Database 12c: Advanced PL/SQL 1Z0-148",
        "title": "SQL and PL/SQL",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:162#tabs-1-2",
        "level": "Professional Level Certification"
    },
    {
        "exam": "MySQL 5.6 Database Administrator 1Z0-883",
        "title": "MySQL Database Administration",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:260",
        "level": "Professional Level Certification"
    },
    {
        "exam": "MySQL 5.6 Database Administrator 1Z0-883",
        "title": "MySQL Database Administration",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:260#tabs-2",
        "level": "Professional Level Certification"
    },
    {
        "exam": "MySQL 5.6 Developer 1Z0-882",
        "title": "MySQL Developer",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:259#tabs-1",
        "level": "Professional Level Certification"
    },
    {
        "exam": "MySQL 5.6 Developer 1Z0-882",
        "title": "MySQL Developer",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:259#tabs-2",
        "level": "Professional Level Certification"
    },
    {
        "exam": "Oracle Real Application Clusters (RAC) 11g Release 2 and Grid Infrastructure Administration (Retiring August 31, 2018) 1Z0-058",
        "title": "Oracle Database 11g",
        "description": "\n",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:207#tabs-2",
        "prerequisites": [
            "\n"
        ],
        "company": "Oracle"
    },
    {
        "exam": "Oracle Real Application Clusters (RAC) 11g Release 2 and Grid Infrastructure Administration (Retiring August 31, 2018) 1Z0-058",
        "title": "Oracle Database 11g",
        "description": "\n",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:207",
        "prerequisites": [
            "\n"
        ],
        "company": "Oracle"
    },
    {
        "title": "Oracle Database 11g",
        "exam": "Oracle Database 11g: Administration I 1Z0-052",
        "description": "\n\t ",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:50#tabs-1",
        "prerequisites": [
            "Pass one SQL Exam:"
        ],
        "level": "Associate Level Certification",
        "company": "Oracle"
    },
    {
        "exam": "Oracle Database 11g: Administration II 1Z0-053",
        "title": "Oracle Database 11g",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:50#tabs-1",
        "level": "Professional Level Certification"
    },
    {
        "exam": "Oracle Database 11g Certified Master Exam 11GOCM",
        "title": "Oracle Database 11g",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:50#tabs-1",
        "level": "Master Level Certification"
    },
    {
        "title": "Oracle Database 11g",
        "exam": "Oracle Database 11g: Administration I 1Z0-052",
        "description": "\n\t ",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:52#tabs-3",
        "prerequisites": [
            "Pass one SQL Exam:"
        ],
        "level": "Associate Level Certification",
        "company": "Oracle"
    },
    {
        "exam": "Oracle Database 11g: Administration II 1Z0-053",
        "title": "Oracle Database 11g",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:52#tabs-3",
        "level": "Professional Level Certification"
    },
    {
        "exam": "Oracle Database 11g Certified Master Exam 11GOCM",
        "title": "Oracle Database 11g",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:52#tabs-3",
        "level": "Master Level Certification"
    },
    {
        "title": "Oracle Database 11g",
        "exam": "Oracle Database 11g: Administration I 1Z0-052",
        "description": "\n\t ",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:52#tabs-4",
        "prerequisites": [
            "Pass one SQL Exam:"
        ],
        "level": "Associate Level Certification",
        "company": "Oracle"
    },
    {
        "exam": "Oracle Database 11g: Administration II 1Z0-053",
        "title": "Oracle Database 11g",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:52#tabs-4",
        "level": "Professional Level Certification"
    },
    {
        "exam": "Oracle Database 11g Certified Master Exam 11GOCM",
        "title": "Oracle Database 11g",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:52#tabs-4",
        "level": "Master Level Certification"
    },
    {
        "title": "Oracle Database 11g",
        "exam": "Oracle Database 11g: Administration I 1Z0-052",
        "description": "\n\t ",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:51#tabs-2",
        "prerequisites": [
            "Pass one SQL Exam:"
        ],
        "level": "Associate Level Certification",
        "company": "Oracle"
    },
    {
        "exam": "Oracle Database 11g: Administration II 1Z0-053",
        "title": "Oracle Database 11g",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:51#tabs-2",
        "level": "Professional Level Certification"
    },
    {
        "exam": "Oracle Database 11g Certified Master Exam 11GOCM",
        "title": "Oracle Database 11g",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:51#tabs-2",
        "level": "Master Level Certification"
    },
    {
        "exam": "Oracle Enterprise Manager 11g Essentials (Retiring May 31st, 2018) 1Z0-530",
        "title": "Enterprise Manager",
        "description": "\n\t\tThis certification retires May 31st, 2018. \n\n\n\t ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:108"
    },
    {
        "exam": "Oracle Enterprise Manager 12c Essentials 1Z0-457",
        "title": "Enterprise Manager",
        "description": "\n\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:110"
    },
    {
        "exam": "Primavera Contract Management Essentials 1Z0-582",
        "title": "Primavera Contract Management",
        "description": "\n\t \n\tThe Primavera Contract Management certification is designed for individuals who possess a strong foundation and expertise implementing Project Controls solutions. This certification covers topics on core elements such as: System Set-up and Project Information, Project Configuration, Control and Communication, Contract Information, Logs, Change Management, Administrative Functions, in the context of typical use case implementations. Up-to-date training and field experience are recommended. ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:76"
    },
    {
        "exam": "Primavera P6 Enterprise Project Portfolio Management 8 Essentials 1Z0-567",
        "title": "Primavera P6 Enterprise Project Portfolio Management",
        "description": "\n\t \n\tThe Primavera P6 Enterprise Project Portfolio Management 8 certification is designed for individuals who possess a strong foundation and expertise implementing Project Portfolio Management solutions. This certification covers topics on core elements such as: Enterprise Data Structures, Administrative Functions, Portfolio and Project Configuration, in the context of typical use caseimplementations. Up-to-date training and field experience are recommended\n\tSuccessfully completing the requirements of this certification track earns the designation of Primavera P6 Enterprise Project Portfolio Management Certified Implementation Specialist. This certification differentiates candidates in the marketplace by providing a competitive edge through proven expertise. Up-to-date training and field experience are recommended\n\tThis certification is available to all candidates but is geared toward members of the Oracle PartnerNetwork.OPN members earning this certification will be recognized as OPN Certified Specialists. This certification helps the OPN member’s partner organization qualify for the Primavera P6 Enterprise Project Portfolio Management Specialization.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:77"
    },
    {
        "exam": "Primavera Portfolio Management Essentials 1Z0-544",
        "title": "Primavera Portfolio Management",
        "description": "\n\t \n\tThe Primavera Portfolio Management 9 certification is designed for individuals who possess a strong foundation and expertise implementing Portfolio Management solutions. This certification exam covers topics on core elements such as: System Set-up, Portfolio Analytics, Administrative Capabilities, and Advanced Calculative Functions, in the context of typical use case implementations. Up-to-date training and field experience are recommended.\n\tSuccessfully completing the requirements of this certification track earns the designation of Primavera Portfolio Management Certified Implementation Specialist. This certification is available to all candidates but is geared toward members of the Oracle PartnerNetwork. OPN members earning this certification will be recognized as OPN Certified Specialists. This certification helps the OPN member’s partner organization qualify for the Primavera Portfolio Management Specialization.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:79"
    },
    {
        "exam": "Primavera Unifier Cloud Service 2016 Implementation Essentials 1Z0-439",
        "title": "Primavera Unifier (Skire)",
        "description": "\n\tPrimavera Unifier 10 Implementation Specialist\n\tThe Primavera Unifier 10 Implementation Specialist certification is designed for individuals who possess a strong foundation and expertise in selling and implementing Primavera Unifier 10 solutions.  This certification covers topics such as: Solution Product Overview and Positioning, Product Features and Functions - Case Processing and Workflow, Architechture, Product Installations, Configuration and integration.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:358"
    },
    {
        "exam": "Oracle Communications BRM Elastic Charging Engine 2017 Implementation Essentials 1Z0-321",
        "title": "Billing and Revenue Management",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:466"
    },
    {
        "exam": "Oracle Communications Billing and Revenue Management 7 Advanced Implementation Essentials 1Z0-509",
        "title": "Billing and Revenue Management",
        "description": "\n\n\n\n\t ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:111"
    },
    {
        "exam": "Oracle Argus Enterprise Edition 8 Implementation Essentials 1Z0-328",
        "title": "Oracle Argus",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:415"
    },
    {
        "exam": "Oracle Utilities Customer Care and Billing 2 Essentials 1Z0-562",
        "title": "Customer Care and Billing",
        "description": "\n\n\n\t ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:99"
    },
    {
        "exam": "Oracle Utilities Meter Data Management 2 Essentials 1Z0-598",
        "title": "Meter Data Management",
        "description": "\n\n\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:101"
    },
    {
        "exam": "Oracle Utilities Mobile Workforce Management 2 Implementation Essentials 1Z0-458",
        "title": "Mobile Workforce Management",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:256"
    },
    {
        "exam": "Oracle Utilities Smart Grid Gateway 2 for Advanced Metering Infrastructure Implementation Essentials 1Z0-492",
        "title": "Smart Grid Gateway",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:257"
    },
    {
        "exam": "Oracle Business Intelligence (OBI) Applications 7 for CRM Essentials (retiring May 31st, 2018) 1Z0-524",
        "title": "BI Applications",
        "description": "\n\t\tPlease be advised that this exam will be retired on May 31st, 2018. \n\n\n\t ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:185"
    },
    {
        "exam": "Oracle Business Intelligence (OBI) Applications 7 for ERP Essentials (retiring May 31st, 2018) 1Z0-525",
        "title": "BI Applications",
        "description": "\n\t\tPlease be advised that this exam will be retired on May 31st, 2018. \n\n\n\t ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:184"
    },
    {
        "exam": "Oracle Business Intelligence (OBI) Foundation 10 Essentials (retiring May 31st, 2018) 1Z0-526",
        "title": "BI Enterprise Edition (BI EE)",
        "description": "\n\t\tPlease be advised that this exam will be retired on May 31st, 2018. \n\n\n\t ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:165"
    },
    {
        "exam": "Oracle Business Intelligence (OBI) Foundation Suite 11g Essentials 1Z0-591",
        "title": "BI Enterprise Edition (BI EE)",
        "description": "\n\n\n\t ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:166"
    },
    {
        "exam": "Oracle Endeca Information Discovery  2.3 Essentials (retiring May 31st, 2018) 1Z0-461",
        "title": "Endeca Information Discovery",
        "description": "\n\t\tPlease be advised that this exam will be retired on May 31st, 2018. ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:183#tabs-2"
    },
    {
        "exam": "Oracle Endeca Information Discovery 3.1 Essentials 1Z0-428",
        "title": "Endeca Information Discovery",
        "description": "\n\tThe Oracle Endeca Information Discovery 3.1 Certified Implementation Specialist certification is intended for implementation consultants specializing in Oracle Endeca Information Discovery 3.1. The recommended online and/or in class training sessions provide consultants with technical product information as they work with customers to implement solutions based on Oracle products. Passing the required exam enables you to become an Oracle Endeca Information Discovery 3.1 Certified Implementation Specialist.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:332#tabs-1-2"
    },
    {
        "exam": "Oracle Essbase 11 Essentials 1Z0-531",
        "title": "Essbase",
        "description": "\n\n\n\t ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:182"
    },
    {
        "exam": "Oracle Application Grid 11g Essentials 1Z0-523",
        "title": "Coherence",
        "description": "\n\n\n\t ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:137"
    },
    {
        "exam": "Oracle WebLogic Server 12c: Administration I 1Z0-133",
        "title": "WebLogic Server",
        "description": "\n\t ",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:195#tabs-1-2",
        "level": "Associate Level Certification",
        "company": "Oracle"
    },
    {
        "exam": "Oracle WebLogic Server 12c: Advanced  Administrator II 1Z0-134",
        "title": "WebLogic Server",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:195#tabs-1-2",
        "level": "Professional Level Certification"
    },
    {
        "exam": "Oracle WebLogic Server 12c: Administration I 1Z0-133",
        "title": "WebLogic Server",
        "description": "\n\t ",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:195#tabs-1-1",
        "level": "Associate Level Certification",
        "company": "Oracle"
    },
    {
        "exam": "Oracle WebLogic Server 12c: Advanced  Administrator II 1Z0-134",
        "title": "WebLogic Server",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:195#tabs-1-1",
        "level": "Professional Level Certification"
    },
    {
        "exam": "Oracle WebLogic Server 12c: Administration I 1Z0-133",
        "title": "WebLogic Server",
        "description": "\n\t ",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:347#tabs-2",
        "level": "Associate Level Certification",
        "company": "Oracle"
    },
    {
        "exam": "Oracle WebLogic Server 12c: Advanced  Administrator II 1Z0-134",
        "title": "WebLogic Server",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:347#tabs-2",
        "level": "Professional Level Certification"
    },
    {
        "exam": "Oracle Cloud Application Foundation Essentials 1Z0-468",
        "title": "WebLogic Server",
        "description": "\n\n\n\t ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:86"
    },
    {
        "exam": "Oracle WebLogic Server 12c Essentials 1Z0-599",
        "title": "WebLogic Server",
        "description": "\n\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:136"
    },
    {
        "exam": "Oracle Data Integrator 11g Certified Implementation Essentials 1Z0-482",
        "title": "Data Integrator (ODI)",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:262"
    },
    {
        "exam": "Oracle Data Integrator 12c Essentials 1Z0-448",
        "title": "Data Integrator (ODI)",
        "description": "\n\tThe Oracle Data Integration 12c Essentials (1Z0-448) exam is designed for individuals who possess a strong foundation and expertise in selling or implementing oracle Data Integration 12c solutions.  This certification exam covers topics such as: Oracle Data Integration 12c Architecture; Oracle Data Integration 12c Project Development; Oracle Data Integration 12c Models and Datastores and more. Up-to-date training and field experience are recommended.\n\tThe Oracle Data Integration 12c Implementation Specialist certification recognizes OPN members as OPN Certified Specialists.  This certification differentiates OPN members in the marketplace by providing a competitive edge through proven expertise.  This certification helps the OPN member’s partner organization qualify for the Data Integration 12c.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:379#tabs-1-2"
    },
    {
        "exam": "Oracle GoldenGate 12c Implementation Essentials 1Z0-447",
        "title": "GoldenGate",
        "description": "\n\tThe Oracle GoldenGate 12c Essentials (1Zx-xxx) exam is designed for individuals who possess a strong foundation and expertise in selling or implementing oracle GoldenGate 12c solutions.  This certification exam covers topics such as: Oracle Goldengate 12c Architecture; Oracle GoldenGate 12c Parametres; Oracle Goldengate 12c Mapping and Transformation Overview and more. Up-to-date training and field experience are recommended.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:389"
    },
    {
        "exam": "Oracle Access Management Suite Plus 11g Essentials 1Z0-479",
        "title": "Access Manager",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:246"
    },
    {
        "exam": "Oracle Identity Governance Suite 11g PS3 Implementation Essentials 1Z0-339",
        "title": "Identity Manager",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:417"
    },
    {
        "exam": "Java EE 6 Java Persistence API Developer Certified Expert 1Z0-898",
        "title": "Java EE and Web Services",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:160"
    },
    {
        "exam": "Java EE 6 Enterprise JavaBeans Developer Certified Expert 1Z0-895",
        "title": "Java EE and Web Services",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:159"
    },
    {
        "exam": "Java EE 6 JavaServer Faces Developer Certified Expert 1Z0-896",
        "title": "Java EE and Web Services",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:281"
    },
    {
        "exam": "Java EE 6 Web Component Developer Certified Expert 1Z0-899",
        "title": "Java EE and Web Services",
        "description": "\n\n\n\t ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:168"
    },
    {
        "exam": "Java EE 6 Web Services Developer Certified Expert 1Z0-897",
        "title": "Java EE and Web Services",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:161"
    },
    {
        "exam": "Java EE 6 Enterprise Architect Certified Master 1Z0-807",
        "title": "Java EE and Web Services",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:212#tabs-1",
        "level": "Master Level Certification"
    },
    {
        "exam": "Java EE 6 Enterprise Architect Certified Master 1Z0-807",
        "title": "Java EE and Web Services",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:212#tabs-2",
        "level": "Master Level Certification"
    },
    {
        "exam": "Java Foundations (novice-level exam) 1Z0-811",
        "title": "Java SE",
        "description": "\n\tPosition yourself to enter the workforce, or get the internship of your dreams with marketable Java skills.\n\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:372"
    },
    {
        "exam": "Java SE 8 Programmer I 1Z0-808",
        "title": "Java SE",
        "description": "\n\n\n\n\n\n\n",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:158#tabs-1-3",
        "level": "Associate Level Certification",
        "company": "Oracle"
    },
    {
        "exam": "Java SE 8 Programmer II  1Z0-809",
        "title": "Java SE",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:158#tabs-1-3",
        "level": "Professional Level Certification"
    },
    {
        "exam": "Java SE 8 Programmer I 1Z0-808",
        "title": "Java SE",
        "description": "\n\n\n\n\n\n\n",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:154#tabs-1-2",
        "level": "Associate Level Certification",
        "company": "Oracle"
    },
    {
        "exam": "Java SE 8 Programmer II  1Z0-809",
        "title": "Java SE",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:154#tabs-1-2",
        "level": "Professional Level Certification"
    },
    {
        "exam": "Java SE 8 Programmer I 1Z0-808",
        "title": "Java SE",
        "description": "\n\n\n\n\n\n\n",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:357#tabs-1-1",
        "level": "Associate Level Certification",
        "company": "Oracle"
    },
    {
        "exam": "Java SE 8 Programmer II  1Z0-809",
        "title": "Java SE",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:357#tabs-1-1",
        "level": "Professional Level Certification"
    },
    {
        "exam": "Java SE 8 Programmer I 1Z0-808",
        "title": "Java SE",
        "description": "\n\n\n\n\n\n\n",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:157#tabs-2-3",
        "level": "Associate Level Certification",
        "company": "Oracle"
    },
    {
        "exam": "Java SE 8 Programmer II  1Z0-809",
        "title": "Java SE",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:157#tabs-2-3",
        "level": "Professional Level Certification"
    },
    {
        "exam": "Java SE 8 Programmer I 1Z0-808",
        "title": "Java SE",
        "description": "\n\n\n\n\n\n\n",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:157#tabs-4",
        "level": "Associate Level Certification",
        "company": "Oracle"
    },
    {
        "exam": "Java SE 8 Programmer II  1Z0-809",
        "title": "Java SE",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:157#tabs-4",
        "level": "Professional Level Certification"
    },
    {
        "exam": "Java SE 8 Programmer I 1Z0-808",
        "title": "Java SE",
        "description": "\n\n\n\n\n\n\n",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:155#tabs-2-2",
        "level": "Associate Level Certification",
        "company": "Oracle"
    },
    {
        "exam": "Java SE 8 Programmer II  1Z0-809",
        "title": "Java SE",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:155#tabs-2-2",
        "level": "Professional Level Certification"
    },
    {
        "exam": "Java SE 8 Programmer I 1Z0-808",
        "title": "Java SE",
        "description": "\n\n\n\n\n\n\n",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:154#tabs-4",
        "level": "Associate Level Certification",
        "company": "Oracle"
    },
    {
        "exam": "Java SE 8 Programmer II  1Z0-809",
        "title": "Java SE",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:154#tabs-4",
        "level": "Professional Level Certification"
    },
    {
        "exam": "Java SE 8 Programmer I 1Z0-808",
        "title": "Java SE",
        "description": "\n\n\n\n\n\n\n",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:357#tabs-2-1",
        "level": "Associate Level Certification",
        "company": "Oracle"
    },
    {
        "exam": "Java SE 8 Programmer II  1Z0-809",
        "title": "Java SE",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:357#tabs-2-1",
        "level": "Professional Level Certification"
    },
    {
        "exam": "Java SE 8 Programmer I 1Z0-808",
        "title": "Java SE",
        "description": "\n\n\n\n\n\n\n",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:357#tabs-4",
        "level": "Associate Level Certification",
        "company": "Oracle"
    },
    {
        "exam": "Java SE 8 Programmer II  1Z0-809",
        "title": "Java SE",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:357#tabs-4",
        "level": "Professional Level Certification"
    },
    {
        "exam": "Oracle Application Development Framework (ADF) 11g Essentials (retiring July 31, 2018) 1Z0-554",
        "title": "Application Development Framework",
        "description": "\n\tThis certification retires July 31, 2018. \n\tThe Oracle Application Development Framework 11\n\tThis certification is available to all candidates but is geared toward members of the Oracle Partner Network who are focused on implementing Oracle Fusion applications with ADF Business Components.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:84"
    },
    {
        "exam": "Oracle Application Development Framework 12c Essentials 1Z0-419",
        "title": "Application Development Framework",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:316"
    },
    {
        "exam": "Oracle Fusion Middleware 11g: Build Applications with Oracle Forms 1Z0-151",
        "title": "Forms and Reports",
        "description": "\n\n\n\t ",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:177#tabs-1-3",
        "level": "Professional Level Certification",
        "company": "Oracle"
    },
    {
        "exam": "Oracle Fusion Middleware 11g: Build Applications with Oracle Forms 1Z0-151",
        "title": "Forms and Reports",
        "description": "\n\n\n\t ",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:177#tabs-2",
        "level": "Professional Level Certification",
        "company": "Oracle"
    },
    {
        "exam": "Oracle Mobile Development 2015 Essentials 1Z0-441",
        "title": "Mobile Application Framework",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:360"
    },
    {
        "exam": "Oracle IT Architecture Release 3 Essentials 1Z0-574",
        "title": "Oracle Technical Architecture",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:131"
    },
    {
        "exam": "Oracle Application Integration Architecture 11g Essentials 1Z0-543",
        "title": "AIA",
        "description": "\n\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:140"
    },
    {
        "exam": "Oracle Business Process Management Suite 12c Essentials 1Z0-435",
        "title": "BPM",
        "description": "\n\tOracle Business Process Management Suite 12c Implementation Specialist\n\tThe Oracle Business Process Management Suite 12c Implementation Specialist certification is designed for individuals who possess a strong foundation and expertise in implementing Oracle Business Process Management Suite 12c solutions.  This certification covers topics such as: BPMN process modeling, adaptive case management, simulation, business rules, human workflow, human task forms, process analytics, BPM Workspace, Security and Administration.  This certification differentiates candidates in the marketplace by providing a competitive edge through proven expertise.  Up-to-date training and field experience are recommended.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:356"
    },
    {
        "exam": "Oracle IT Architecture BPM 2013 Essentials 1Z0-476",
        "title": "BPM",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:273"
    },
    {
        "exam": "Oracle Unified Business Process Management Suite 11g Essentials (retiring July 31, 2018) 1Z0-560",
        "title": "BPM",
        "description": "\n\t\tThis certification retires July 31, 2018. \n\n\n\t ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:139"
    },
    {
        "exam": "Oracle IT Architecture SOA 2013 Essentials 1Z0-475",
        "title": "SOA Suite",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:253"
    },
    {
        "exam": "Oracle SOA Suite 11g Essentials (retiring July 31st, 2018) 1Z0-478",
        "title": "SOA Suite",
        "description": "\n\t\tThis certification retires July 31st, 2018. \n\n\n\t ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:138"
    },
    {
        "exam": "Oracle SOA Suite 12c Essentials 1Z0-434",
        "title": "SOA Suite",
        "description": "\n\tOracle SOA Suite 12c Implementation Specialist\n\tThe Oracle SOA Suite 12c Implementation Specialist certification is designed for individuals who possess a strong foundation and expertise in implementing Oracle Soute Suite 12c solutions.  This certification covers topics such as: BPEL modeling, adapters, business rules, human task services, service mediation, event processing, business activity monitoring, securing services, deployment, troubleshooting, installation and configuration. This certification differentiates candidates in the marketplace by providing a competitive edge through proven expertise.  Up-to-date training and field experience are recommended.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:355"
    },
    {
        "exam": "Oracle WebCenter Content 11g Essentials 1Z0-542",
        "title": "WebCenter Content",
        "description": "\n\n\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:141"
    },
    {
        "exam": "Oracle WebCenter Portal 11.1.1.8 Essentials 1Z0-430",
        "title": "WebCenter Portal",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:337"
    },
    {
        "exam": "Oracle WebCenter Sites 11g Essentials 1Z0-462",
        "title": "WebCenter Sites",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:241"
    },
    {
        "exam": "Oracle Linux 5 and 6 System Administration 1Z0-100",
        "title": "Oracle Linux Administration",
        "description": "\n",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:251#tabs-1",
        "level": "Associate Level Certification",
        "company": "Oracle"
    },
    {
        "exam": "Oracle Linux 6 Advanced System Administration 1Z0-105",
        "title": "Oracle Linux Administration",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:251#tabs-1",
        "level": "Professional Level Certification"
    },
    {
        "exam": "Oracle Linux 5 and 6 System Administration 1Z0-100",
        "title": "Oracle Linux Administration",
        "description": "\n",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:251#tabs-2",
        "level": "Associate Level Certification",
        "company": "Oracle"
    },
    {
        "exam": "Oracle Linux 6 Advanced System Administration 1Z0-105",
        "title": "Oracle Linux Administration",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:251#tabs-2",
        "level": "Professional Level Certification"
    },
    {
        "exam": "Oracle Linux 6 Implementation Essentials 1Z0-460",
        "title": "Oracle Linux Administration",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:88"
    },
    {
        "exam": "Oracle Solaris 11 System Administration 1Z0-821",
        "title": "Solaris 11 Administration",
        "description": "\n\tThe OCA and OCP, Solaris 11 System Administrator certifications are designed to begin with individuals who possess a strong foundation in the administration of the Oracle Solaris 11 Operating System at the OCA level and who, at the OCP level have expanded their mastery of the administration of the Oracle Solaris 11 Operating System from the base-level of skills and have a fundamental understanding of the UNIX operating system, commands, and utilities.\n\tThe OCA certification covers essential system administration skills such as managing local disk devices, managing file systems, installing and removing Solaris packages and patches, performing system boot procedures and system processes. The OCP certification covers system administration skills such as configuring network interfaces, managing swap configurations, crash dumps, and core files. Certification provides a solid foundation on which to base continued learning. Up-to-date training and field experience are recommended.\n\tOracle Certifications differentiate candidates in the marketplace by providing a competitive edge through proven expertise.",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:202#tabs-1",
        "level": "Associate Level Certification",
        "company": "Oracle"
    },
    {
        "exam": "Oracle Solaris 11 Advanced System Administration 1Z0-822",
        "title": "Solaris 11 Administration",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:202#tabs-1",
        "level": "Professional Level Certification"
    },
    {
        "exam": "Oracle Solaris 11 System Administration 1Z0-821",
        "title": "Solaris 11 Administration",
        "description": "\n\tThe OCA and OCP, Solaris 11 System Administrator certifications are designed to begin with individuals who possess a strong foundation in the administration of the Oracle Solaris 11 Operating System at the OCA level and who, at the OCP level have expanded their mastery of the administration of the Oracle Solaris 11 Operating System from the base-level of skills and have a fundamental understanding of the UNIX operating system, commands, and utilities.\n\tThe OCA certification covers essential system administration skills such as managing local disk devices, managing file systems, installing and removing Solaris packages and patches, performing system boot procedures and system processes. The OCP certification covers system administration skills such as configuring network interfaces, managing swap configurations, crash dumps, and core files. Certification provides a solid foundation on which to base continued learning. Up-to-date training and field experience are recommended.\n\tOracle Certifications differentiate candidates in the marketplace by providing a competitive edge through proven expertise.",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:190#tabs-2",
        "level": "Associate Level Certification",
        "company": "Oracle"
    },
    {
        "exam": "Oracle Solaris 11 Advanced System Administration 1Z0-822",
        "title": "Solaris 11 Administration",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:190#tabs-2",
        "level": "Professional Level Certification"
    },
    {
        "exam": "Oracle Solaris 11 System Administration 1Z0-821",
        "title": "Solaris 11 Administration",
        "description": "\n\tThe OCA and OCP, Solaris 11 System Administrator certifications are designed to begin with individuals who possess a strong foundation in the administration of the Oracle Solaris 11 Operating System at the OCA level and who, at the OCP level have expanded their mastery of the administration of the Oracle Solaris 11 Operating System from the base-level of skills and have a fundamental understanding of the UNIX operating system, commands, and utilities.\n\tThe OCA certification covers essential system administration skills such as managing local disk devices, managing file systems, installing and removing Solaris packages and patches, performing system boot procedures and system processes. The OCP certification covers system administration skills such as configuring network interfaces, managing swap configurations, crash dumps, and core files. Certification provides a solid foundation on which to base continued learning. Up-to-date training and field experience are recommended.\n\tOracle Certifications differentiate candidates in the marketplace by providing a competitive edge through proven expertise.",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:190#tabs-3",
        "level": "Associate Level Certification",
        "company": "Oracle"
    },
    {
        "exam": "Oracle Solaris 11 Advanced System Administration 1Z0-822",
        "title": "Solaris 11 Administration",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:190#tabs-3",
        "level": "Professional Level Certification"
    },
    {
        "exam": "Oracle Solaris 11 Installation and Configuration Essentials 1Z0-580",
        "title": "Solaris 11 Administration",
        "description": "\n\t \n\tOracle's Solaris 11 Certified Implementation Specialist Certification identifies professionals that are skilled in implementing solutions based on the Oracle Solaris 11 operating system. The certification covers the installation and configuration of Oracle Solaris 11. The exam targets the intermediate-level implementation team member. Up-to-date training and field experience are highly recommended.\n\tThis certification is available to all candidates but is geared toward members of the Oracle Partner Network who are focused on selling and implementing Oracle Solaris 11 solutions. OPN Members earning this certification will be recognized as OPN Certified Implementation Specialists, which helps their companies qualify for the Oracle Solaris 11 Specialization.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:89"
    },
    {
        "exam": "Oracle Cloud Infrastructure 2018 Architect Associate 1Z0-932",
        "title": "Infrastructure as a Service (IaaS)",
        "description": "\n\tThe Oracle Cloud Infrastructure Architect Associate exam is designed for individuals who possess a strong foundation knowledge in architecting infrastructure using Oracle Cloud Infrastructure services. This certification covers topics such as: Cloud computing concepts (HA, DR, Security), regions, availability domains, OCI terminology and services, networking, databases, load balancing, IAM, DNS, FASTCONNECT, VPN, Compartments, tagging, Terraform, with focus on how to use it with OCI and Exadata. This certification validates deep understanding of OCI services to spin up infrastructure and provides a competitive edge in the industry. Up-to-date OCI training and hands-on experience are recommended. This certification is available to all candidates. ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:538"
    },
    {
        "exam": "Oracle Cloud Infrastructure Classic 2018 Associate Architect 1Z0-337",
        "title": "Infrastructure as a Service (IaaS)",
        "description": "\n\tAn Oracle Cloud Infrastructure Classic Associate Architect has demonstrated the knowledge required to  specify, architect, implement applicable Oracle Infrastructure as a Service services/solutions.  Individuals who earn this certification are able to explain, deploy, configure, and utilize Oracle Infrastructure as a Service.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:474"
    },
    {
        "exam": "Oracle Java Cloud Service Certified Associate 1Z0-161",
        "title": "Application Development",
        "description": "\n\tAdministrators and developers learn the key areas of functionality of the Java Cloud Service and how to use them as they develop, deploy and migrate Java EE applications. Also learn how to administer, secure, tune and troubleshoot Java Cloud Service.  ",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:548#tabs-1-2",
        "level": "Associate Level Certification",
        "company": "Oracle"
    },
    {
        "exam": "Oracle Java Cloud Service Certified Associate 1Z0-161",
        "title": "Application Development",
        "description": "\n\tAdministrators and developers learn the key areas of functionality of the Java Cloud Service and how to use them as they develop, deploy and migrate Java EE applications. Also learn how to administer, secure, tune and troubleshoot Java Cloud Service.  ",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:546#tabs-1-3",
        "level": "Associate Level Certification",
        "company": "Oracle"
    },
    {
        "exam": "Oracle Java Cloud Service Certified Associate 1Z0-161",
        "title": "Application Development",
        "description": "\n\tAdministrators and developers learn the key areas of functionality of the Java Cloud Service and how to use them as they develop, deploy and migrate Java EE applications. Also learn how to administer, secure, tune and troubleshoot Java Cloud Service.  ",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:411",
        "level": "Associate Level Certification",
        "company": "Oracle"
    },
    {
        "exam": "Oracle Cloud Platform Enterprise Analytics 2018 Associate 1Z0-936",
        "title": "Business Analytics",
        "description": "An Oracle Analytics Cloud Associate is responsible for implementing Oracle Analytics Cloud. They have the knowledge required to perform provisioning, build dimensional modelling and create data visualizations. They can use Advanced Analytics capabilities, create a machine learning model and configure Oracle Analytics Cloud Essbase.",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:543",
        "level": "Associate Level Certification",
        "company": "Oracle"
    },
    {
        "exam": "MySQL Cloud Service 2018 Implementation Essentials 1Z0-320",
        "title": "Data Management",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:489"
    },
    {
        "exam": "Oracle Cloud Platform Big Data Management 2018 Associate 1Z0-928",
        "title": "Data Management",
        "description": "The Oracle Big Data Cloud Service 2018 Associate exam is designed for individuals who possess a strong foundational knowledge in using and configuring Oracle Big Data services. This certification covers topics such as: Fundamentals of Big Data, Oracle Solutions and Services for Big Data Needs (BDC and (BDCS), Data Streaming Platform, Oracle Big Data Security, and Big Data Cloud Integration.  This certification validates understanding of Oracle Big Data to implement the service and provides a competitive edge in the industry. Up-to-date training and hands-on experience supporting or leading PaaS implementations, or equivalent practice is recommended. This certification is available to all candidates.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:539"
    },
    {
        "exam": "Oracle Cloud Platform Data Management 2018 Associate 1Z0-950",
        "title": "Data Management",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:537"
    },
    {
        "exam": " Oracle Database Cloud Service 1Z0-160",
        "title": "Data Management",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:468#tabs-1-1",
        "prerequisites": [],
        "level": "Associate Level Certification",
        "company": "Oracle"
    },
    {
        "exam": " Oracle Database Cloud Service 1Z0-160",
        "title": "Data Management",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:468#tabs-1-1",
        "level": "Professional Level Certification"
    },
    {
        "exam": " Oracle Database Cloud Service 1Z0-160",
        "title": "Data Management",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:412#tabs-2",
        "prerequisites": [],
        "level": "Associate Level Certification",
        "company": "Oracle"
    },
    {
        "exam": " Oracle Database Cloud Service 1Z0-160",
        "title": "Data Management",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:412#tabs-2",
        "level": "Professional Level Certification"
    },
    {
        "exam": " Oracle Database Cloud Service 1Z0-160",
        "title": "Data Management",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:463#tabs-1-2",
        "prerequisites": [],
        "level": "Associate Level Certification",
        "company": "Oracle"
    },
    {
        "exam": " Oracle Database Cloud Service 1Z0-160",
        "title": "Data Management",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=653&get_params=p_id:463#tabs-1-2",
        "level": "Professional Level Certification"
    },
    {
        "exam": "Oracle Cloud Platform Application Integration 2018 Associate 1Z0-934",
        "title": "Integration",
        "description": "The Oracle Cloud Platform Application Integration 2018 Associate exam is designed for individuals who possess a strong foundational knowledge in using and configuring Application Integration services. This certification covers topics such as: Oracle Cloud Application Integration basics, Application Integration: Oracle Integration Cloud (OIC), Service-Oriented Architecture Cloud Service (SOACS), Integration API Platform Cloud Service, Internet of Things - Cloud Service (IOTCS), and Oracle's Process Cloud Service. This certification validates understanding of Oracle Application Integration to implement the service. Up-to-date training and hands-on experience supporting or leading Application Integration implementations, or equivalent practice is recommended. This certification is available to all candidates.",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:549",
        "level": "Associate Level Certification",
        "company": "Oracle"
    },
    {
        "exam": "Oracle Cloud Platform Application Integration 2018 Associate 1Z0-934",
        "title": "Integration",
        "description": "The Oracle Cloud Platform Application Integration 2018 Associate exam is designed for individuals who possess a strong foundational knowledge in using and configuring Application Integration services. This certification covers topics such as: Oracle Cloud Application Integration basics, Application Integration: Oracle Integration Cloud (OIC), Service-Oriented Architecture Cloud Service (SOACS), Integration API Platform Cloud Service, Internet of Things - Cloud Service (IOTCS), and Oracle's Process Cloud Service. This certification validates understanding of Oracle Application Integration to implement the service. Up-to-date training and hands-on experience supporting or leading Application Integration implementations, or equivalent practice is recommended. This certification is available to all candidates.",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:545",
        "level": "Associate Level Certification",
        "company": "Oracle"
    },
    {
        "exam": "Oracle Mobile Cloud Service 2016 Developer Essentials 1Z0-346",
        "title": "Mobile Cloud Service",
        "description": "\n\tAn Oracle Certified Specialist, Mobile Cloud Service 2016 Devleoper has demonstrated the knowledge and skills required to begin using Mobile Cloud Service (MCS) in a project by navigating the design-time architecture, creating mobile backends, using platform APIs, configuring connectors and implementing custom APIs.  In addition, he/she is capable of using the analytics dashboard, controlling mobile user access and administering environments.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:469"
    },
    {
        "exam": "Oracle Cloud Platform Systems Management 2018 Associate 1Z0-930",
        "title": "Oracle Management Cloud",
        "description": "An Oracle Management Cloud 2018 Certified Associate has demonstrated the skills and knowledge to architect and implement Oracle Management Cloud. This individual can configure Application Performance Monitoring, Oracle Infrastructure Monitoring, Oracle Log Analytics, Oracle IT Analytics, Oracle Orchestration, Oracle Security Monitoring and Analytics and Oracle Configuration and Compliance.",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:547",
        "level": "Associate Level Certification",
        "company": "Oracle"
    },
    {
        "exam": "Oracle Management Cloud 2017 Implementation Essentials 1Z0-500",
        "title": "Oracle Management Cloud",
        "description": "\n\tAn Oracle Management Cloud 2017 Certified Implementation Specialist has demonstrated the knowledge required to specify, architect, implement applicable  Oracle Management Cloud solution/service(s).  Individuals who earn this certification are able to explain, deploy, configure, utilize Oracle Management Cloud services.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:473"
    },
    {
        "exam": " Oracle Cloud Platform Identity and Security Management 2018 Associate 1Z0-933",
        "title": "Security",
        "description": "The Oracle Cloud Security 2018 Associate exam is designed for security professionals who possess expertise implementing Cloud solutions. This certification covers topics such as: Identity Security Operations Center Framework, Identity Cloud Service, CASB Cloud Service, Security Monitoring and Analytics Cloud Service, Configuration and Compliance Service, and services Architecture and Deployment. This certification validates understanding of Oracle Cloud Security portfolio and capacity to configure the services. Up-to-date training and hands-on experience supporting or leading Cloud Security implementations in an administrator role, or equivalent practice is recommended. This certification is available to all candidates.",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:544",
        "level": "Associate Level Certification",
        "company": "Oracle"
    },
    {
        "exam": "Oracle CPQ Cloud Service 2016 Implementation Essentials (Retiring May 31st, 2018) 1Z0-436",
        "title": "Oracle CPQ Cloud",
        "description": "\n\tThis certification retires May 31st, 2018. \n\tThe Oracle CPQ Cloud Service 2016 Implementation Essentials recognizes partners that are proficient in implementing and/or developing BigMachines Configure Price Quote (CPQ) Cloud solution.\n\tThis certification is for individuals focused on implementing and designing  a solution for dramatically improving the way project-driven organizations and project professionals work using Oracle BigMachines CPQ Cloud Service. \n\tThis exam will cover topics such as designing product and pricing structure, implementing pricing functions, designing commerce pricing, designing workflow approval and notifications, integrating with CRM and ERP applications using webservices and planning environment and testing strategies.\n\tOracle partners who achieve this Certification are differentiated in the marketplace through proven expertise in Oracle BigMachines CPQ Cloud Service.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:362"
    },
    {
        "exam": "Oracle CPQ Cloud Service 2017 Implementation Essentials 1Z0-976",
        "title": "Oracle CPQ Cloud",
        "description": "\n\tAn Oracle CPQ Cloud Service 2017 Certified Implementation Specialist has demonstrated the knowledge required to Design and integrate CPQ Cloud with ERP and other backend systems, install and configure CPQ Cloud Managed Packages, and design commerce pricing, rules, and workflows.  Individuals who earn this certification are able to write and design BML scripts and configure subscription ordering and implement BOM mapping.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:516"
    },
    {
        "exam": "Oracle Data Management Platform Cloud 2017 Implementation Essentials 1Z0-952",
        "title": "Oracle Marketing Cloud",
        "description": "\n\tAn Oracle Marketing Cloud,Data Management Platform 2018 Certified Implementation Specialist has demonstrated the knowledge required to apply data scoping and strategies, implement BlueKai Core Tags for first-party data ingest, and configure ad vendors and campaigns for data delivery.  Individuals who earn this certification are able to facilitate media tagging and analytics, configure site side optimization, server data transfer and API methods for data delivery.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:515"
    },
    {
        "exam": "Oracle Eloqua Marketing Cloud Service 2017 Implementation Essentials 1Z0-349",
        "title": "Oracle Marketing Cloud",
        "description": "\n\tAn Oracle Eloqua Marketing Cloud Service Certified Implementation Specialist has demonstrated the knowledge required to complete a full implementation of Eloqua, provide consultation on project deliverables to the client and apply knowledge of client’s business, marketing and sales processes to make recommendations when integrating Eloqua with third-party systems. Individuals who earn this certification are able to describe the different components of the Eloqua application, Implement and configure each Branding and Deliverability package, integrate CRM custom objects with Eloqua and leverage the data for segmentation, personalization, and scoring and much more.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:471"
    },
    {
        "exam": "Oracle Responsys Marketing Platform Cloud Service 2017 Implementation Essentials 1Z0-477",
        "title": "Oracle Marketing Cloud",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:470"
    },
    {
        "exam": "Oracle Customer Data Management Cloud Service 2017 Implementation Essentials 1Z0-978",
        "title": "Oracle Sales Cloud",
        "description": "\n\tThe Oracle Customer Data Management Cloud 2017 Certified Implementation Specialist is designed for individuals who possess as strong foundation and expertise implementing Customer Data Management solutions. This certification covers topics such as: Setup and Customer Data Configuration, Data Modelling, Architecture, Addressing Cleansing and Verification, Duplicate Identification and Resolution, Data Enrichment, and Extensibility and Integrations. This certification differentiates candidates in the marketplace by providing a competitive edge through proven expertise.  Up-to-date Customer Data Management training and field experience are recommended. This certification is available to all candidates but is geared toward members of the Oracle PartnerNetwork.  OPN members earning this certification will be recognized as OPN Certified Specialists.  ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:527"
    },
    {
        "exam": "Oracle Incentive Compensation ​Cloud 2017 Implementation ​Essentials 1Z0-971",
        "title": "Oracle Sales Cloud",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:503"
    },
    {
        "exam": "Oracle Partner Relationship Management Cloud 2017 Implementation Essentials 1Z0-977",
        "title": "Oracle Sales Cloud",
        "description": "\n\tThe Oracle Partner Relationship Management Cloud 2017 Certified Implementation Specialist is designed for individuals who possess a strong foundation and expertise implementing Partner Relationship Management solutions. This certification covers topics such as: Configuration of Channel Sales, Deal Registration, Deal Management, Partner Program Management, Business Plan and Objectives, Marketing Development Funds, Partner Recruitment and On-Boarding, PRM Reporting and Extensibility. This certification differentiates candidates in the marketplace by providing a competitive edge through proven expertise. Up-to-date Partner Relationship Management training and field experience are recommended. This certification is available to all candidates but is geared toward members of the Oracle Partner Network.  OPN members earning this certification will be recognized as OPN Certified Specialists.  ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:536"
    },
    {
        "exam": "Oracle Sales Cloud 2017 Implementation Essentials 1Z0-970",
        "title": "Oracle Sales Cloud",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:499"
    },
    {
        "exam": "Oracle Sales Performance Management Cloud Service 2017 Implementation Essentials 1Z0-979",
        "title": "Oracle Sales Cloud",
        "description": "\n\tAn Oracle Sales Performance Management Cloud 2017 Certified Implementation Specialist has demonstrated the knowledge required to implement, configure and use Oracle Sales Performance Management Cloud. Individuals who earn this certification are able to identify key concepts and configuration components of sales performance management, Quota management, Sales Forecasting, Territory management, configure territory and resource assignment, configure compensation plans, design crediting and roll up processes and build advanced plan structures and calculations.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:519"
    },
    {
        "exam": "Oracle Cross-Channel Contact Center Cloud 2017 Implementation Essentials 1Z0-981",
        "title": "Oracle Service Cloud",
        "description": "\n\tAn Oracle Cross-Channel Contact Center Cloud 2017 Certified Implementation Specialist has demonstrated the knowledge required to create queues and assignments, implement automation and customization, set up process between Customers and Agents and manage Reports. Individuals who earn this certification  are able to integrate Cross-Channel Contact Center with Connect-SOAP and Connect-REST based web services and configure add-ins.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:521"
    },
    {
        "exam": "Oracle Field Service Cloud Service 2017 Implementation Essentials 1Z0-628",
        "title": "Oracle Service Cloud",
        "description": "\n\tAn Oracle Field Service Cloud Service 2017 Certified Implementation Specialist has demonstrated the skills and knowledge required  to implement and use Oracle Field Service Cloud. Individuals who posses this certification are able to configure users and activity types, configure business rules settings and work zones, manage routing and quota and configure service requests.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:513"
    },
    {
        "exam": "Oracle Knowledge Management Cloud 2017 Implementation Essentials 1Z0-980",
        "title": "Oracle Service Cloud",
        "description": "\n\tAn Oracle Knowledge Management Cloud 2017 Certified Implementation Specialist has demonstrated the knowledge required to use and modify answers, set up reports and configure queues. Individuals who earn this certification are able to integrate with Connect Web Services for SOAP API and use Knowledge Advanced.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:520"
    },
    {
        "exam": "Oracle Policy Automation Cloud Service 2017 Implementation Essentials 1Z0-345",
        "title": "Oracle Service Cloud",
        "description": "\n\tAn Oracle Policy Automation Cloud Service 2017 Certified Implementation Specialist has demonstrated the knowledge required to get started with rules / user Interface, use attributes and attribute text, write rules and negations, group operators and intermediate attributes. Individuals who earn this certification are able to use comparisons and calculations in rules, know when to use entities and reasoning with entities, test rules, create and enhance interviews, validate data and plan a Policy Automation project.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:481"
    },
    {
        "exam": "Oracle RightNow Cloud Service 2016 Implementation Essentials 1Z0-325",
        "title": "Oracle Service Cloud",
        "description": "\n\tThe Oracle RightNow Cloud Service 2016 Implementation Essentials (1Z1-325) exam is designed for individuals who possess a strong foundation and expertise in selling or implementing Oracle RightNow CX Cloud Service solutions.  This certification exam covers topics such as: Application Appearance, Staff Management, Answer Management Admin, Site Configuration Admin, Analytics, Surveys, Customer Portal, Chat & Co-browse. Up-to-date training and field experience are recommended.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:404"
    },
    {
        "exam": "Oracle RightNow Cloud Service 2016 Technical Implementation Essentials 1Z0-326",
        "title": "Oracle Service Cloud",
        "description": "\n\tThe Oracle RightNow Cloud Service 2016 Technical Implementation Essentials (1Z1-326) exam is designed for individuals who possess a strong foundation and expertise in selling or implementing Oracle RightNow CX Cloud Service solutions.  This certification exam covers topics such as: CP Tools, CP - Basic Customizations,  CP - Widgets and Scripting, CP - Advanced Customizations, Integration & Customization - Agent Desktop, Connect Web Services for PHP\\SOAP\\REST, Desktop Integration and General Integration & Customization. Up-to-date training and field experience are recommended.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:410"
    },
    {
        "exam": "Oracle Social Relationship Management Cloud Service 2014 Essentials 1Z0-426",
        "title": "Oracle Service Cloud",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:324"
    },
    {
        "exam": "Oracle Enterprise Performance Reporting Cloud Service 2017 Implementation Essentials 1Z0-985",
        "title": "Enterprise Performance Reporting Cloud",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:528"
    },
    {
        "exam": "Oracle Enterprise Planning and Budgeting Cloud Service 2017 Implementation Essentials 1Z0-982",
        "title": "Enterprise Planning and Budgeting Cloud",
        "description": "\n\tThe Oracle Enterprise Planning and Budgeting Cloud 2017 Certified Implementation Specialist has demonstrated the knowldege and expertise in implementing Enterprise Performance Management and Enterprise Planning and Budgeting Cloud solutions. Inviduals with this certification can set up and configure Cloud security, build EPM Cloud automation routines and integrations across systems and services, set up a Planning application, diagnose enabled business process features for impact on application element, and configure Financials/Workforce/Capital business process elements, and designing Reports and Documents.  ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:529"
    },
    {
        "exam": "Oracle Financial Consolidation and Close Cloud Service 2017 Implementation Essentials 1Z0-983",
        "title": "Financial Consolidation and Close Cloud",
        "description": "\n\tThe Oracle Financial Consolidation and Close 2017 Certified Implementation Specialist has demonstrated the knowledge and skills in implementing Enterprise Performance Management and Oracle Financial Consolidation and Close Cloud solutions. Inviduals holding this certification are able to set up and configure Cloud security, build EPM Cloud automation routines, creating a FCCS Application, vreate data sets and dimension attributes, set up a close process, and design reports using Financial Reporting Studio. ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:530"
    },
    {
        "exam": "Oracle Profitability and Cost Management Cloud Service 2017 Implementation Essentials 1Z0-984",
        "title": "Profitability and Cost Management Cloud",
        "description": "\n\tAn Oracle Profitability and Cost Management Cloud 2017 Certified Implementation Specialist has demonstrated the knowledge and skills of implementing Enterprise Performance Management and Oracle Profitability and Cost Management Cloud solutions.  This individual understands Profitability and Cost Management Cloud system architecture and requirements, can set up and configure Cloud security, build EPM Cloud automation routines, perform lifecycle management for different scenarios, create a PCMCS application, create and manage Allocations,and design reports using Financial Reporting Studio.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:534"
    },
    {
        "exam": "Oracle Accounting Hub Cloud 2017 Implementation Essentials 1Z0-975",
        "title": "Oracle Financials Cloud",
        "description": "\n\tAn Oracle Accounting Hub Cloud Service 2017 Certified Implementation Specialist demonstrated a strong foundation and expertise implementing  Enterprise Resource Planning (ERP) and Fincancials Cloud  solutions in Accounting Hub Cloud Services. Individuals who earn this certification can analyze transaction type life cycle, analyze accounting/reporting/audit/reconciliation requirements for accounting transactions, model considerations, configure Accounting Rules, Register Source System and PaaS (ICS) integration, update the Registered Source System, perform automation with web services,  adjust journal entries, and perform diagnostics.  This certification differentiates candidates in the marketplace by providing a competitive edge through proven expertise.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:523"
    },
    {
        "exam": "Oracle Financials Cloud: General Ledger 2017 Implementation Essentials 1Z0-960",
        "title": "Oracle Financials Cloud",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:501"
    },
    {
        "exam": "Oracle Financials Cloud: Payables 2017 Implementation Essentials 1Z0-961",
        "title": "Oracle Financials Cloud",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:498"
    },
    {
        "exam": "Oracle Financials Cloud: Receivables 2017 Implementation Essentials 1Z0-962",
        "title": "Oracle Financials Cloud",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:502"
    },
    {
        "exam": "Oracle Revenue Management Cloud Service 2017 Implementation Essentials 1Z0-974",
        "title": "Oracle Financials Cloud",
        "description": "\n\tAn Oracle Revenue Management Cloud 2017 Certified Implementation Specialist possesses a strong foundation and expertise implementing  Enterprise Resource Planning (ERP) and Financials Cloud  solutions in Revenue Management. Individuals earning this certification can configureRevenue Management Cloud services, implement Revenue Management Cloud, set up Revenue Management and configuring standalone pricing, and manage contracts and Revenue. ",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:535"
    },
    {
        "exam": "Oracle Procurement Cloud 2017 Implementation Essentials 1Z0-963",
        "title": "Oracle Procurement Cloud",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:497"
    },
    {
        "exam": "Oracle Project Portfolio Management Cloud 2017 Implementation Essentials 1Z0-964",
        "title": "Oracle Project Portfolio Management Cloud",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:500"
    },
    {
        "exam": "Oracle Financial Reporting Compliance Cloud 2017 Implementation Essentials 1Z0-951",
        "title": "Oracle Risk Management Cloud",
        "description": "\n\tAn Oracle Financial Reporting Compliance Cloud Service 2017 Certified Implementation Specialist has demonstrated the knowledge required to gather implementation requirements for Financial Reporting Compliance Implementation, perform initial configuration and data migration. Individuals who earn this certification are able to manage Assessments, Issues and Reports in Oracle Risk Management Cloud.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:514"
    },
    {
        "exam": "Oracle Absence Management Cloud 2017 Implementation Essentials 1Z0-967",
        "title": "Oracle Global Human Resources Cloud",
        "description": "\n\tAn Oracle Absence Management Cloud 2017 Certified Implementation Specialist has demonstrated the knowledge required to implement, configure and use Oracle Absence Management Cloud. Individuals who earn this certification are able to identify key concepts of absence management, identify the setup components of absence management and create accrual and qualification absence plans.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:511"
    },
    {
        "exam": "Oracle Global Human Resources Cloud 2017 Implementation Essentials 1Z0-965",
        "title": "Oracle Global Human Resources Cloud",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:494"
    },
    {
        "exam": "Oracle Time and Labor Cloud 2017 Implementation Essentials 1Z0-968",
        "title": "Oracle Global Human Resources Cloud",
        "description": "\n\tAn Oracle Time and Labor Cloud 2017 Certified Implementation Specialist has demonstrated the knowledge required to implement, configure and use Oracle Time and Labor Cloud. Individuals who earn this certification are able to use Oracle Functional Setup Manager to implement Time and Labor, perform common Time and Labor processes, and perform transactional tasks such as reporting, approving and tranferring time.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:512"
    },
    {
        "exam": "Oracle Workforce Compensation Cloud 2017 Implementation Essentials 1Z0-330",
        "title": "Oracle Global Human Resources Cloud",
        "description": "\n\tThe Oracle Workforce Rewards Cloud 2016 Implementation Essentials exam is designed for individuals who possess a strong foundation and expertise in selling and implementing Oracle HCM Cloud Service solutions. This certification exam covers topics such as: Base Pay, Individual Compensation, Workforce Compensation and Total Compensation Statements. Up-to-date training and field experience are recommended.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:406"
    },
    {
        "exam": "Oracle Talent Acquisition Cloud 2016 Implementation Essentials 1Z0-324",
        "title": "Oracle Talent Management Cloud",
        "description": "\n\tThe Oracle Talent Acquisition Cloud 2016 Certified Implementation Essentials exam is designed for individuals who possess a strong foundation and expertise in selling and implementing Oracle Talent Management Cloud Service solutions. This certification exam covers topics such as: SmartOrg Configuration, Career Site Configuration, ACE Prescreening and Workflow design. Up-to-date training and field experience are recommended.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:401"
    },
    {
        "exam": "Oracle Talent Management Cloud 2017 Implementation Essentials 1Z0-966",
        "title": "Oracle Talent Management Cloud",
        "description": "\n\tThe Oracle Talent Management Cloud 2017 Implementation Essentials (1Z0-966) exam is designed for individuals who possess a strong foundation and expertise in selling and implementing Oracle HCM Cloud Service solutions. This certification exam covers topics such as: Goals, Questionnaires, Performance, Fusion Succession Plans and Talent Pools, Talent Review. Up-to-date training and field experience are recommended.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:496"
    },
    {
        "exam": "Oracle Taleo Learn Cloud Service 2016 Implementation Essentials 1Z0-443",
        "title": "Oracle Talent Management Cloud",
        "description": "\n\tOracle Taleo Learn Cloud Service 2016 Certified Implementation Specialist",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:387"
    },
    {
        "exam": "Oracle Payroll Cloud 2017 Implementation Essentials 1Z0-969",
        "title": "Payroll",
        "description": "\n\tAn Oracle Payroll Cloud 2017 Certified Implementation Specialist has demonstrated the knowledge required to set up Oracle Payroll Cloud using Workforce Deployment tasks offered in the functional setup manager.  Individuals who earn this certification are able to identify the key concepts of Oracle Payroll Cloud that determine a successful implementation, test your setup by entering data and performing common payroll processes, use Oracle Payroll Cloud Functional Setup Manager (FSM) to implement payroll and much more.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:510"
    },
    {
        "exam": "Oracle Benefits Cloud 2017 Implementation Essentials 1Z0-973",
        "title": "Workforce Rewards Cloud",
        "description": "\n\tAn Oracle Benefits Cloud 2017 Certified Implementation Specialist has demonstrated the konwledge and skills required to configure and implement Oracle HCM Cloud Benefits Service solutions. Individuals who earn this certification are able to configure and administer Benefits, set up benefits extracts and extract and transmit data to plan carriers.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:524"
    },
    {
        "exam": "Oracle Transportation Management Cloud Service 2017 Implementation Essentials 1Z0-988",
        "title": "Logistics Cloud",
        "description": "\n\tAn Oracle Transportation Management Cloud Service 2017 Certified Implementation Specialist has demonstrated the knwledge required to explain Oracle Transportation Management core functionalities and configure the User Interface. Individuals who earn this certification are able to perform Freight Payment and Data Management, configure Automation Agents and the Shipment Planning process and create Order bases and Order Releases.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:522"
    },
    {
        "exam": "Oracle Warehouse Management Cloud 2018 Implementation Essentials 1Z0-956",
        "title": "Logistics Cloud",
        "description": "\n\tDigital badge description: An Oracle Warehouse Management Cloud 2018 Certified Implementation Specialist has demonstrated the knowledge required to Setup Parent-Child Company Hierarchy, Inbound process, execute Outbound orders and manage Inventory. Individuals who earn this certification are able to create Web reports, Perform Picking, Packing, Cycle Count, Check-in and receive Loads, Appointments.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:540"
    },
    {
        "exam": "Oracle Cost Management Cloud 2017 Implementation Essentials 1Z0-987",
        "title": "Oracle Inventory Management Cloud",
        "description": "\n\tAn Oracle Cost Management 2017 Certified Implementation Specialist has demonstrated the knowledge required to manage landed costs, manage subledger accounting, manage cost scenarios, and configure costing methods.  Individuals who earn this certification are able to perform receipt accounting processes and analyze and resolve standard cost variances.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:526"
    },
    {
        "exam": "Oracle Inventory Cloud 2017 Implementation Essentials 1Z0-986",
        "title": "Oracle Inventory Management Cloud",
        "description": "\n\tAn Oracle Inventory Cloud 2017 Certified Implementation Specialist has demonstrated the knowledge required to configure an enterprise structure, design an enterprise configuration, model a financial reporting structure, and manage advanced inventory transactions.  Individuals who earn this certification are able to analyze and manage the material request flow within Supply Chain Orchestration, and import on-hand balances, sub-inventories, and transfer orders via ADF Desktop Integration.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:517"
    },
    {
        "exam": "Oracle Manufacturing Cloud 2017 Implementation Essentials 1Z0-348",
        "title": "Oracle Manufacturing Cloud",
        "description": "\n\tAn Oracle Manufacturing Cloud 2017 Certified Implementation Specialist has demonstrated the knowledge required to use Manufacturing Cloud on a project, utilize manufacturing functionalities, create work order, manage work definitions, and execute production.  Individuals who earn this certification are able to perform manufacturing set-up, manage items and item structures, and understand  the complexities of manufacturing integrations.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:490"
    },
    {
        "exam": "Oracle Innovation Management Cloud 2017 Implementation Essentials 1Z0-991",
        "title": "Oracle Product Lifecycle Management Cloud",
        "description": "\n\tAn Oracle Innovation Management Cloud 2017 Certified Implementation Specialist has demonstrated the knowledge and skills required to implementing Oracle Innovation Management Cloud and customize it as per customer requirements. Individuals who earn this certification are able to create Proposals, Portfolios, custom Idea class and custom attributes for Requirements, as well as to configure integration with external systems.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:532"
    },
    {
        "exam": "Oracle Product Hub Cloud 2016 Implementation Essentials (Retiring May 31st, 2018) 1Z0-634",
        "title": "Oracle Product Master Data Management Cloud",
        "description": "\n\t This certification retires May 31st, 2018. \n\tOracle Product Hub Cloud enables organizations to take control of their product master data across their entire portfolio of applications. With Oracle Fusion Product Hub, product information is shared across systems and users, resulting in improved data accuracy, better decisions, and accelerated time to market.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:388"
    },
    {
        "exam": "Oracle Product Hub Cloud 2017 Implementation Essentials 1Z0-972",
        "title": "Oracle Product Master Data Management Cloud",
        "description": "\n\tAn Oracle Product Hub Cloud 2017 Certified Implementation Specialist has demonstrated the knowledge required to configure and invoke data validation, transformation, and map features and rules.  Individuals who earn this certification are able to configure and manage supplier data and manage security for transactional business intelligence.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:531"
    },
    {
        "exam": "Oracle Planning Central Cloud Service 2017 Implementation Essentials 1Z0-992",
        "title": "Oracle Supply Chain Planning Cloud",
        "description": "\n\tAn Oracle Planning Central Cloud Service 2017 Certified Implementation Specialist has demonstrated the knowledge required to configure and manage a demand plan, configure and manage a supply plan, and manage data collection and analytics.  Individuals who earn this certification are well-versed in managing exceptions, working with advanced fulfillment, and using simulation sets.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:533"
    },
    {
        "exam": "Oracle Configurator Cloud 2017 Implementation Essentials 1Z0-989",
        "title": "Order Management Cloud",
        "description": "\n\tAn Oracle Configurator Cloud 2017 Certified Implementation Specialist has demonstrated the knowledge required to work with Configurator Models, Structures, Snapshots, and Rules. Individuals who earn this certification are able to customize user interfaces, use and create extension rules, and manage configured item exceptions.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:525"
    },
    {
        "exam": "Oracle Order Management Cloud 2017 Implementation Essentials 1Z0-347",
        "title": "Order Management Cloud",
        "description": "\n\tAn Oracle Order Management Cloud 2017 Certified Implementation Specialist has demonstrated the knowledge required to use Order Management Cloud on a project, utilize Order Entry functionalities, create basic reports, pricing startegies and setup shipping.  Individuals who earn this certification are able to understand interfaces and implement Order to cash business process, use different pricing functionalities, perform shipping related inventory transactions.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:477"
    },
    {
        "exam": "Oracle Pricing Cloud 2017 Implementation Essentials 1Z0-990",
        "title": "Order Management Cloud",
        "description": "\n\tAn Oracle Pricing Cloud 2017 Certified Implementation Specialist has demonstrated the knowledge required to create and manage price lists, costs lists, tiers and matrices, pricing charge definitions and parameters.  Individuals who earn this certification are able to create and maintain pricing algorithms, service mappings, and matrix classes.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:518"
    },
    {
        "exam": "Oracle Big Data 2017 Implementation Essentials (Retiring October 30, 2019) 1Z0-449",
        "title": "Big Data Appliance",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:416"
    },
    {
        "exam": "Oracle Exadata X3 and X4 Administration 1Z0-027",
        "title": "Exadata",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:179"
    },
    {
        "exam": "Oracle Exadata X5 Administration 1Z0-070",
        "title": "Exadata",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:465"
    },
    {
        "exam": "Oracle Exadata Database Machine 2014 Implementation Essentials 1Z0-485",
        "title": "Exadata",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:250"
    },
    {
        "exam": "Oracle Exadata Database Machine and Cloud Service 2017 Implementation Essentials 1Z0-338",
        "title": "Exadata",
        "description": "\n\tAn Oracle Exadata Database Machine and Cloud Service 2017 Certified Implementation Specialist has demonstrated the knowledge required to administer and configure the Exadata system. They can architect Exadata backup and recovery as well as high availability.  They can load data, monitor the system, and provide software maintenance of the Exadata Database Machine. Individuals who earn this certification are able to describe the Exadata Architecture and it’s benefits, configure security policies, set up service requests, and configure Oracle Exadata Database machine in the cloud.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:482"
    },
    {
        "exam": "Exalogic Elastic Cloud X2-2 Essentials (Retiring May 31st, 2018) 1Z0-569",
        "title": "Exalogic Elastic Cloud",
        "description": "\n\tThis certification retires May 31st, 2018. \n\tExalogic Elastic Cloud X2-2 Essentials exam is intended for system administrators who have implemented and are managing an Exalogic Elastic Cloud environment in a data center. The exam targets a broad range of topics from fundamentals and initial machine setup to storage and network configuration. In addition to on-the-job training, preparation can include attending Oracle University's Oracle Exalogic Elastic Cloud Administration course.\n\tThis certification is available to all candidates, but it is geared toward members of the Oracle PartnerNetwork. OPN Members earning this certification will be recognized as OPN Certified Specialists. This certification helps their companies qualify for the Exalogic Elastic Cloud Specialization.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:91"
    },
    {
        "exam": "Oracle Exalogic Elastic Cloud 2014 Implementation Essentials 1Z0-491",
        "title": "Exalogic Elastic Cloud",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:271"
    },
    {
        "exam": "Exalytics In-Memory Machine X3-4 Implementation Essentials (Retiring July 31, 2018) 1Z0-484",
        "title": "Exalytics",
        "description": "\n\tThis certification retires July 31, 2018. \n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:261"
    },
    {
        "exam": "Fujitsu M10 Servers Installation Essentials 1Z0-488",
        "title": "Server Administration",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:255"
    },
    {
        "exam": "SPARC M6-32 and SPARC M5-32 Servers Installation Essentials 1Z0-489",
        "title": "Server Administration",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:263"
    },
    {
        "exam": "SPARC T4-Based Server Installation Essentials 1Z0-597",
        "title": "Server Administration",
        "description": "\n",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:92"
    },
    {
        "exam": "Oracle FS1 Series Systems Implementation Essentials (retiring May 31st, 2018) 1Z0-429",
        "title": "Oracle All Flash Storage System",
        "description": "\n\t'This certification retires May 31st, 2018. \n\tThe Oracle FS1 Series Systems consist of high-availability components, providing connectivity between storage resources and external application hosts. These components are tightly integrated and managed via the Oracle FS System Services software (formerly known as Axiom software release 6.0), enabling intuitive storage management, configuration, and monitoring.",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:349"
    },
    {
        "exam": "Oracle ZFS Storage Appliance 2017 Implementation Essentials 1Z0-499",
        "title": "Oracle ZFS Storage Appliance",
        "company": "Oracle",
        "url": "https://education.oracle.com/pls/web_prod-plq-dad/db_pages.getpage?page_id=654&get_params=p_id:485"
    }
]

const MICROSOFT_CERTIFICATIONS = [
    {
        "card_description": "Demonstrate your expertise in Microsoft cloud-related technologies to reduce IT costs and deliver more value for the modern business.",
        "badge": "/en-us/CMSImages/lrn_mcc-MCSA-Cloud_Platform2x.png?version=2336e94d-33cf-6dd7-f867-9ac416ea2b2b",
        "description": "This certification demonstrates your expertise in Microsoft cloud-related technologies to reduce IT costs and deliver more value for the modern business.",
        "url": "mcsa-cloud-platform-certification.aspx",
        "ID": "MCSA_Cloud_Platform",
        "handle": "cloudPlatform",
        "api_parameter": "cloudPlatform",
        "title": "MCSA: Cloud Platform",
        "card_title": "Cloud Platform",
        "keywords": [
            "cloud",
            "platform",
            "cloud platform"
        ],
        "company": "Microsoft",
        "exams": [
            "Developing Microsoft Azure Solutions ; Exam 70-532",
            "Implementing Microsoft Azure Infrastructure Solutions ; Exam 70-533",
            "Architecting Microsoft Azure Solutions ; Exam 70-535",
            "Configuring and Operating a Hybrid Cloud with Microsoft Azure Stack ; Exam 70-537"
        ]
    },
    {
        "card_description": "Demonstrate your ability to design, architect, implement, and maintain complex cloud-enabled Linux® solutions that leverage Microsoft Azure open source capabilities.",
        "badge": "/en-us/CMSImages/lrn_mcc-MCSA-Linux_on_Aure2x.png?version=7bfe3fc2-ea6b-9f6c-853c-2d210a940cfe",
        "description": "This certification demonstrates your ability to design, architect, implement, and maintain complex cloud-enabled Linux® solutions that leverage Microsoft Azure open source capabilities. It also validates your Linux system administration skills to show that you are fluent in today's cloud-native world.",
        "url": "mcsa-linux-azure-certification.aspx",
        "ID": "MCSA_Linux_on_Azure",
        "handle": "mcsaLinuxOnAzure",
        "api_parameter": "mcsaLinuxOnAzure",
        "title": "MCSA: Linux on Azure",
        "card_title": "Linux on Azure",
        "keywords": [
            "mcsa",
            "linux",
            "azure",
            " mcsa linux on azure"
        ],
        "company": "Microsoft",
        "exams": [
            "Implementing Microsoft Azure Infrastructure Solutions ; Exam 70-533",
            "Linux Foundation Certified System Administrator ; Exam LFCS"
        ]
    },
    {
        "card_description": "Demonstrate your skills in transitioning to and administering cloud-hosted business applications with Microsoft Office 365.",
        "badge": "/en-us/CMSImages/lrn_mcc-MCSA-Office_365_Management2x.png?version=8fd525a0-2298-6bb0-8b65-d8aea4b74f5d",
        "description": "This certification demonstrates your skills in transitioning to and administering cloud-hosted business applications with Microsoft Office 365.",
        "url": "mcsa-office365-certification.aspx",
        "ID": "MCSA_Office_365",
        "handle": "mcsaOffice365",
        "api_parameter": "mcsaOffice365",
        "title": "MCSA: Office 365",
        "card_title": "Office 365",
        "keywords": [
            "mcsa",
            "office",
            "365",
            "mcsa office 365"
        ],
        "company": "Microsoft",
        "exams": [
            "Managing Office 365 Identities and Requirements ; Exam 70-346",
            "Enabling Office 365 Services ; Exam 70-347"
        ]
    },
    {
        "card_description": "Demonstrate your essential skills as a database professional for both on-premises and cloud-based databases.",
        "badge": " /en-us/CMSImages/lrn_mcc-MCSA-SQL_2016_Database_Dev2x.png?version=d7101966-2ca4-3c8a-3923-f28ac90f9557",
        "description": "This certification demonstrates your skills as a database professional, for both on-premises and cloud-based databases.",
        "url": "mcsa-sql2016-database-development-certification.aspx",
        "ID": "MCSA_SQL_2016_Database_Development",
        "handle": "mcsaSql2016DatabaseDevelopment",
        "api_parameter": "mcsaSql2016DatabaseDevelopment",
        "title": "MCSA: SQL 2016 Database Development",
        "card_title": "SQL 2016 Database Development",
        "keywords": [
            "mcsa",
            "sql",
            "2016",
            "database",
            "development",
            "mcsa sql database development"
        ],
        "company": "Microsoft",
        "exams": [
            "Querying Data with Transact-SQL ; Exam 70-761",
            "Developing SQL Databases ; Exam 70-762"
        ]
    },
    {
        "card_description": "Demonstrate your essential skills as a database professional for both on-premises and cloud-based databases.",
        "badge": "/en-us/CMSImages/lrn_mcc-MCSA-SQL_2016_Database_Admin2x.png?version=1a8b3600-481d-39f1-b8b8-c442d5ce422f",
        "description": "This certification demonstrates your skills with database installation, maintenance, configuration, and provisioning tasks.",
        "url": "mcsa-sql2016-database-administration-certification.aspx",
        "ID": "MCSA_SQL_2016_Database_Administration",
        "handle": "mcsaSql2016DatabaseAdministration",
        "api_parameter": "mcsaSql2016DatabaseAdministration",
        "title": "MCSA: SQL 2016 Database Administration",
        "card_title": "SQL 2016 Database Administration",
        "keywords": [
            "mcsa",
            "sql",
            "2016",
            "database",
            "administration",
            "mcsa sql 2016 database administration"
        ],
        "company": "Microsoft",
        "exams": [
            "Administering a SQL Database Infrastructure ; Exam 70-764",
            "Provisioning SQL Databases ; Exam 70-765"
        ]
    },
    {
        "card_description": "Demonstrate your essential skills as a database professional for both on-premises and cloud-based databases.",
        "badge": "/en-us/CMSImages/lrn_mcc-MCSA-SQL_2016_BI_Development2x.png?version=085305aa-d0ab-10c7-f417-fc708af08045",
        "description": "This certification validates your extract, transform, and load (ETL) and data warehouse skills, along with those for implementing BI solutions using multidimensional and tabular data models and online analytical processing (OLAP) cubes.",
        "url": "mcsa-sql2016-business-intelligence-certification.aspx",
        "ID": "MCSA_SQL_2016_BI_Development",
        "handle": "mcsaSql2016BusinessIntelligence",
        "api_parameter": "mcsaSql2016BusinessIntelligence",
        "title": "MCSA: SQL 2016 BI Development",
        "card_title": "SQL 2016 BI Development",
        "keywords": [
            "mcsa",
            "sql",
            "2016",
            "business",
            "intelligence",
            "mcsa sql 2016 business intelligence"
        ],
        "company": "Microsoft",
        "exams": [
            "Implementing a SQL Data Warehouse ; Exam 70-767",
            "Developing SQL Data Models ; Exam 70-768"
        ]
    },
    {
        "card_description": "Demonstrate your essential skills and breakthrough insights in developing and maintaining the next wave of mission-critical environments.",
        "badge": "/en-us/CMSImages/lrn_mcc-MCSA-SQL_Server_2012-20142x.png?version=d3d45c08-0ff2-2b05-da9f-b437ada19690",
        "description": "This certification demonstrates your skills and breakthrough insights in developing and maintaining the next wave of mission-critical environments.",
        "url": "mcsa-sql-certification.aspx",
        "ID": "MCSA_SQL_Server_2012/2014",
        "handle": "mcsaSqlServer2012_2014",
        "api_parameter": "mcsaSqlServer2012_2014",
        "title": "MCSA: SQL Server 2012/2014",
        "card_title": "SQL Server 2012/2014",
        "keywords": [
            "mcsa",
            "sql",
            "server",
            "2012",
            "2014",
            "mcsa sql server 2012-2014"
        ],
        "company": "Microsoft",
        "exams": [
            "Querying Microsoft SQL Server 2012/2014 ; Exam 70-461",
            "Administering Microsoft SQL Server 2012/2014 Databases ; Exam 70-462",
            "Implementing a Data Warehouse with Microsoft SQL Server 2012/2014 ; Exam 70-463"
        ]
    },
    {
        "card_description": "Demonstrate your expertise at implementing Universal Windows Platform apps that offer a compelling user experience across a wide range of Windows devices.",
        "badge": "/en-us/CMSImages/lrn_mcc-MCSA-Universal_Windows_Platform2x.png?version=153eb49b-8bac-f5d6-0d23-0c0ab41ff740",
        "description": "This certification demonstrates your expertise at implementing Universal Windows Platform apps that offer a compelling user experience across a wide range of Windows devices.",
        "url": "/mcsa-universal-windows-platform.aspx",
        "ID": "MCSA_Universal_Windows_Platform",
        "handle": "universalWindowsPlatform",
        "api_parameter": "universalWindowsPlatform",
        "title": "MCSA: Universal Windows Platform",
        "card_title": "Universal Windows Platform",
        "keywords": [
            "universal",
            "windows",
            "platform",
            "universal windows platform"
        ],
        "company": "Microsoft",
        "exams": [
            "Programming in C# ; Exam 70-483",
            "Developing Mobile Apps ; Exam 70-357"
        ]
    },
    {
        "card_description": "Demonstrate your expertise at implementing modern web apps.",
        "badge": "/en-us/CMSImages/lrn_mcc-MCSA-Web_Applications2x.png?version=42a7f1a8-f259-a282-0112-bb22f1e81c8e",
        "description": "Demonstrate your expertise at implementing modern web apps.",
        "url": "/mcsa-web-applications-certification.aspx",
        "ID": "MCSA_Web_Applications",
        "handle": "mcsaWebApplications",
        "api_parameter": "mcsaWebApplications",
        "title": "MCSA: Web Applications",
        "card_title": "Web Applications",
        "keywords": [
            "mcsa",
            "web",
            "applications",
            "mcsa web applications"
        ],
        "company": "Microsoft",
        "exams": [
            "Programming in HTML5 with JavaScript and CSS3 ; Exam 70-480",
            "Programming in C# ; Exam 70-483",
            "Developing ASP.NET MVC Web Applications ; Exam 70-486"
        ]
    },
    {
        "card_description": "Prove you have the expertise to configure, manage, and maintain a Windows 10 enterprise system.",
        "badge": "/en-us/CMSImages/lrn_mcc-MCSA-Windows_102x.png?version=5bb3fff4-8d99-3e1c-786c-ae264ff2ce5f",
        "description": "Prove you have the expertise to configure, manage, and maintain a Windows 10 enterprise system.",
        "url": "/mcsa-web-applications-certification.aspx",
        "ID": "MCSA_Windows_10",
        "handle": "mcsaWindows10",
        "api_parameter": "mcsaWindows10",
        "title": "MCSA: Windows 10",
        "card_title": "Windows 10",
        "keywords": [
            "mcsa",
            "windows",
            "windows 10",
            "mcsa windows 10"
        ],
        "company": "Microsoft",
        "exams": [
            "Installing and Configuring Windows 10 ; Exam 70-698",
            "Configuring Windows Devices ; Exam 70-697"
        ]
    },
    {
        "card_description": "Prove your mastery of the primary set of Windows Server 2012 skills required to reduce IT costs and deliver more business value. ",
        "badge": "/en-us/CMSImages/lrn_mcc-MCSA-Windows-Server-2012.png?version=2a1a9034-3e2d-c91c-09cd-df660cbcdcdf",
        "description": "Prove your mastery of the primary set of Windows Server 2012 skills required to reduce IT costs and deliver more business value.",
        "url": "mcsa-windows-server-certification.aspx",
        "ID": "MCSA_Windows_Server_2012",
        "handle": "mcsaWindowsServer2012",
        "api_parameter": "mcsaWindowsServer2012",
        "title": "MCSA: Windows Server 2012",
        "card_title": "Windows Server 2012",
        "keywords": [
            "mcsa",
            "windows",
            "server",
            "2012",
            "windows server 2012"
        ],
        "company": "Microsoft",
        "exams": [
            "Installing and Configuring Windows Server 2012 ; Exam 70-410",
            "Administering Windows Server 2012 ; Exam 70-411",
            "Configuring Advanced Windows Server 2012 ; Exam 70-412"
        ]
    },
    {
        "card_description": "Prove your mastery of the primary set of Windows Server 2016 skills required to reduce IT costs and deliver more business value.",
        "badge": "/en-us/CMSImages/lrn_mcc-MCSA-Windows_Server_20162x.png?version=361d4ab6-df44-b62e-41b9-c02ed7c275ff",
        "description": "Prove your mastery of the primary set of Windows Server 2016 skills required to reduce IT costs and deliver more business value.",
        "url": "mcsa-windows-server-2016-certification.aspx",
        "ID": "MCSA_Windows_Server_2016",
        "handle": "mcsaWindowsServer2016",
        "api_parameter": "mcsaWindowsServer2016",
        "title": "MCSA: Windows Server 2016",
        "card_title": "Windows Server 2016",
        "keywords": [
            "mcsa",
            "windows",
            "server",
            "2016",
            "windows server 2016"
        ],
        "company": "Microsoft",
        "exams": [
            "Installation, Storage, and Compute with Windows Server 2016 ; Exam 70-740",
            "Networking with Windows Server 2016 ; Exam 70-741",
            "Identity with Windows Server 2016 ; Exam 70-742"
        ]
    },
    {
        "card_description": "This certification demonstrates your expertise in Microsoft Dynamics 365 technologies to upgrade, configure, and customize.",
        "badge": "/en-us/CMSImages/lrn_badge-mcsa-d365.png?version=c013d584-aa73-cdfd-ecc2-ab83cff6acac",
        "description": "This certification demonstrates your expertise in Microsoft Dynamics 365 technologies to upgrade, configure, and customize.",
        "url": "mcsa-microsoft-dynamics-365.aspx",
        "ID": "MCSA_Microsoft_Dynamics_365",
        "handle": "mcsaMicrosoftDynamics365",
        "api_parameter": "mcsaMicrosoftDynamics365",
        "title": "MCSA: Microsoft Dynamics 365",
        "card_title": "Microsoft Dynamics 365",
        "keywords": [
            "mcsa",
            "microsoft",
            "dynamics",
            "365",
            "mcsa microsoft dynamics 365"
        ],
        "company": "Microsoft",
        "exams": [
            "Microsoft Dynamics 365 customer engagement Online Deployment ; Exam MB2-715",
            "Microsoft Dynamics 365 Customization and Configuration ; Exam MB2-716"
        ]
    },
    {
        "card_description": "This certification demonstrates your expertise in Microsoft Dynamics 365 technologies to implement the basic technical and development tasks required to customize Microsoft Dynamics 365 for Operations.",
        "badge": "/en-us/CMSImages/lrn_badge-mcsa-d365o.png?version=204dfa7b-14cd-edec-d12c-53da14eb882a",
        "description": "This certification demonstrates your expertise in Microsoft Dynamics 365 technologies to implement the basic technical and development tasks required to customize Microsoft Dynamics 365 for Operations. This audience typically includes technical consultants, programmers, and IT personnel.",
        "url": "mcsa-microsoft-dynamics-365-for-operations.aspx",
        "ID": "MCSA_Microsoft_Dynamics_365_for_Operations",
        "handle": "mcsaMicrosoftDynamics365Operation",
        "api_parameter": "mcsaMicrosoftDynamics365Operation",
        "title": "MCSA: Microsoft Dynamics 365 for Operations",
        "card_title": "Microsoft Dynamics 365 for Operations",
        "keywords": [
            "mcsa",
            "microsoft",
            "dynamics",
            "365",
            "operation",
            "mcsa microsoft dynamics 365 operation"
        ],
        "company": "Microsoft",
        "exams": [
            "Administering a Microsoft SQL Database Infrastructure ; Exam 70-764",
            "Provisioning SQL Databases ; Exam 70-765",
            "Development, Extensions and Deployment for Microsoft Dynamics 365 for Finance and Operations ; Exam MB6-894"
        ]
    },
    {
        "card_description": "This certification demonstrates your expertise in operationalizing Microsoft Azure machine learning and Big Data with R Server and SQL R Services.",
        "badge": "/en-us/CMSImages/lrn_mcc-MCSA-Machine-Learning.png?version=327a2f91-d5e0-c15f-aa24-fbfe896c8218",
        "description": "This certification demonstrates your expertise in operationalizing Microsoft Azure machine learning and Big Data with R Server and SQL R Services. ",
        "url": "mcsa-machine-learning.aspx",
        "ID": "MCSA_Machine_Learning",
        "handle": "mcsaMachineLearning",
        "api_parameter": "mcsaMachineLearning",
        "title": "MCSA: Machine Learning",
        "card_title": "Machine Learning",
        "keywords": [
            "mcsa",
            "machine",
            "learning",
            "mcsa machine learning"
        ],
        "company": "Microsoft",
        "exams": [
            "Analyzing Big Data with Microsoft R ; Exam 70-773",
            "Perform Cloud Data Science with Azure Machine Learning ; Exam 70-774"
        ]
    },
    {
        "card_description": "This certification demonstrates your expertise in analyzing data with both Power BI and Excel.",
        "badge": "/en-us/CMSImages/lrn_mcc-BI-Reporting.png?version=b7137fa5-3c58-8d13-8f0a-9a2d4f39a64b",
        "description": "This certification demonstrates your expertise in analyzing data with both Power BI and Excel.",
        "url": "mcsa-bi-reporting.aspx",
        "ID": "MCSA_BI_Reporting",
        "handle": "mcsaBIReporting",
        "api_parameter": "mcsaBIReporting",
        "title": "MCSA: BI Reporting",
        "card_title": "BI Reporting",
        "keywords": [
            "mcsa",
            "bi",
            "reporting",
            "mcsa bi reporting"
        ],
        "company": "Microsoft",
        "exams": [
            "Analyzing and Visualizing Data with Power BI ; Exam 70-778",
            "Analyzing and Visualizing Data with Microsoft Excel ; Exam 70-779"
        ]
    },
    {
        "card_description": "This certification demonstrates your expertise in engineering data with HD Insight and Cloud Services.",
        "badge": "/en-us/CMSImages/lrn_mcc-Data-Engineering-with-Azure.png?version=778b215d-ad2c-5687-b26c-23124a8ef8da",
        "description": "This certification demonstrates your expertise in engineering data with HD Insight and Cloud Services.",
        "url": "mcsa-data-engineering-with-azure.aspx",
        "ID": "MCSA_Data_Engineering_With_Azure",
        "handle": "mcsaDataEngineeringWithAzure",
        "api_parameter": "mcsaDataEngineeringWithAzure",
        "title": "MCSA: Data Engineering with Azure",
        "card_title": "Data Engineering with Azure",
        "keywords": [
            "mcsa",
            "data engineering",
            "azure",
            "mcsa data engineering with azure"
        ],
        "company": "Microsoft",
        "exams": [
            "Perform Data Engineering on Microsoft HD Insight ; Exam 70-775",
            "Perform Big Data Engineering on Microsoft Cloud Services (beta) ; Exam 70-776"
        ]
    },
    {
        "card_description": "This certification demonstrates that you have the skills needed to get the most out of Office by earning a Microsoft Office Specialist (MOS) certification in a specific Office program. ",
        "badge": "/en-us/CMSImages/lrn-all-office-specialist-badge.png?version=b19379de-1bea-79a9-214e-480891794982",
        "description": "This certification demonstrates that you have the skills needed to get the most out of Office by earning a Microsoft Office Specialist (MOS) certification in a specific Office program.",
        "url": "/microsoft-office-specialist-certification-2016.aspx",
        "ID": "Microsoft_Office_Specialist_2016",
        "handle": "mos2016",
        "api_parameter": "mos2016",
        "title": "Microsoft Office Specialist 2016",
        "card_title": "Productivity",
        "keywords": [
            "mos",
            "2016",
            "mos 2016"
        ],
        "company": "Microsoft",
        "exams": [
            "Word 2016: Core Document Creation, Collaboration, and Communication ; Exam 77-725",
            "Excel 2016: Core Data Analysis, Manipulation, and Presentation ; Exam 77-727",
            "Microsoft Office PowerPoint 2016 ; Exam 77-729",
            "Microsoft Office Access 2016 ; Exam 77-730",
            "Microsoft Outlook 2016 ; Exam 77-731"
        ]
    },
    {
        "card_description": "This certification demonstrates that you have the skills needed to get the most out of Office by earning a Microsoft Office Specialist (MOS) certification in a specific Office program.",
        "badge": "/en-us/CMSImages/lrn-all-office-specialist-badge.png?version=b19379de-1bea-79a9-214e-480891794982",
        "description": "This certification demonstrates that you have the skills needed to get the most out of Office by earning a Microsoft Office Specialist (MOS) certification in a specific Office program. Office 2013 combines cloud and web technologies to extend the way you create and consume information and introduces a new cloud app model for you to build secure and flexible apps – all discoverable from the familiar Office interface.",
        "url": "/microsoft-office-specialist-certification-2013.aspx",
        "ID": "Microsoft_Office_Specialist_2013",
        "handle": "mos2013",
        "api_parameter": "mos2013",
        "title": "Microsoft Office Specialist 2013",
        "card_title": "Productivity",
        "keywords": [
            "mos",
            "2013",
            "mos 2013"
        ],
        "company": "Microsoft",
        "exams": [
            "Microsoft Office Word 2013 ; Exam 77-418",
            "Microsoft Office Excel 2013 ; Exam 77-420",
            "Microsoft Office PowerPoint 2013 ; Exam 77-422",
            "Microsoft Office Outlook 2013 ; Exam 77-423",
            "Microsoft Office Access 2013 ; Exam 77-424",
            "Microsoft SharePoint 2013 ; Exam 77-419",
            "Microsoft Office OneNote 2013 ; Exam 77-421"
        ]
    },
    {
        "card_description": "This certification demonstrates that you have advanced skills in key Office programs by earning a Microsoft Office Specialist Expert certification.",
        "badge": "/en-us/CMSImages/lrn-all-office-specialist-expert-badge.png?version=b086f0a0-6da5-a254-4582-65defbd3119f",
        "description": "This certification demonstrates that you have advanced skills in key Office programs by earning a Microsoft Office Specialist Expert certification.",
        "url": "/microsoft-office-specialist-expert-certification-2016.aspx",
        "ID": "MOS_Expert_2016",
        "handle": "mosExpert2016",
        "api_parameter": "mosExpert2016",
        "title": "MOS Expert 2016",
        "card_title": "Productivity",
        "keywords": [
            "mos",
            "expert",
            "2016",
            "mos expert 2016"
        ],
        "company": "Microsoft",
        "exams": [
            "Microsoft Word 2016 Expert: Creating Documents for Effective Communication ; Exam 77-726",
            "Microsoft Excel 2016 Expert: Interpreting Data for Insights ; Exam 77-728"
        ]
    },
    {
        "card_description": "This certification demonstrates that you have advanced skills in key Office programs by earning a Microsoft Office Specialist Expert certification.",
        "badge": "/en-us/CMSImages/lrn-all-office-specialist-expert-badge.png?version=b086f0a0-6da5-a254-4582-65defbd3119f",
        "description": "This certification demonstrates that you have advanced skills in key Office programs by earning a Microsoft Office Specialist Expert certification. Office 2013 combines cloud and web technologies to extend the way you create and consume information, and introduces a new cloud app model for you to build secure and flexible apps – all discoverable from the familiar Office interface.",
        "url": "/microsoft-office-specialist-expert-certification-2013.aspx",
        "ID": "MOS_Expert_2013",
        "handle": "mosExpert2013",
        "api_parameter": "mosExpert2013",
        "title": "MOS Expert 2013",
        "card_title": "Productivity",
        "keywords": [
            "mos",
            "expert",
            "2013",
            "mos expert 2013"
        ],
        "company": "Microsoft",
        "exams": [
            "Microsoft Word 2013 Expert Part One ; Exam 77-425",
            "Microsoft Word 2013 Expert Part Two ; Exam 77-426",
            "Microsoft Office Excel 2013 Expert Part One ; Exam 77-728",
            "Microsoft Office Excel 2013 Part Two ; Exam 77-428"
        ]
    },
    {
        "card_description": "This certification demonstrates that you have the deepest level of skills needed to proficiently use Office programs by earning a Microsoft Office Specialist Master certification.",
        "badge": "/en-us/CMSImages/lrn-mos-master-office-specialist.png?version=365f169c-c03d-e483-589f-a26b1d7ed841",
        "description": "This certification demonstrates that you have the deepest level of skills needed to proficiently use Office programs by earning a Microsoft Office Specialist Master certification.",
        "url": "/microsoft-office-specialist-master-certification-2016.aspx",
        "ID": "MOS_Master_2016",
        "handle": "mosMaster2016",
        "api_parameter": "mosMaster2016",
        "title": "MOS Master 2016",
        "card_title": "Productivity",
        "keywords": [
            "mos",
            "master",
            "2016",
            "mos master 2016"
        ],
        "company": "Microsoft",
        "exams": [
            "Access 2016: Core Database Management, Manipulation, and Query Skills  ; Exam 77-730",
            "Outlook 2016: Core Communication, Collaboration and Email Skills ; Exam 77-731"
        ]
    },
    {
        "card_description": "microsoft-office-specialist-master-certification-2013.aspx",
        "badge": "/en-us/CMSImages/lrn-mos-master-office-specialist.png?version=365f169c-c03d-e483-589f-a26b1d7ed841",
        "description": "This certification demonstrates that you have the deepest level of skills needed to proficiently use Office programs by earning a Microsoft Office Specialist Master certification.",
        "url": "/microsoft-office-specialist-master-certification-2013.aspx",
        "ID": "MOS_Master_2013",
        "handle": "mosMaster2013",
        "api_parameter": "mosMaster2013",
        "title": "MOS Master 2013  ",
        "card_title": "Productivity",
        "keywords": [
            "mos",
            "master",
            "2013",
            "mos master 2013"
        ],
        "company": "Microsoft",
        "exams": [
            "Microsoft PowerPoint 2013 ; Exam 77-422",
            "Microsoft Access 2013 ; Exam 77-424",
            "Microsoft Outlook 2013 ; Exam 77-423",
            "Microsoft SharePoint 2013 ; Exam 77-419",
            "Microsoft OneNote 2013 ; Exam 77-421"
        ]
    },
    {
        "card_description": "This certification demonstrates that you have the skills needed to get the most out of Office by earning a Microsoft Office Specialist (MOS) certification in a specific Office program.",
        "badge": "/en-us/CMSImages/lrn-all-office-specialist-badge.png?version=b19379de-1bea-79a9-214e-480891794982",
        "description": "This certification demonstrates that you have the skills needed to get the most out of Office by earning a Microsoft Office Specialist (MOS) certification in a specific Office program.",
        "url": "/microsoft-office-specialist-certification-2016.aspx",
        "ID": "MOS_Microsoft_Office_Specialist",
        "handle": "mos2016",
        "api_parameter": "mos2016",
        "title": "MOS: Microsoft Office Specialist",
        "card_title": "Productivity",
        "keywords": [
            "mos",
            "2016",
            "mos 2016"
        ],
        "company": "Microsoft",
        "exams": [
            "Word 2016: Core Document Creation, Collaboration, and Communication ; Exam 77-725",
            "Excel 2016: Core Data Analysis, Manipulation, and Presentation ; Exam 77-727",
            "Microsoft Office PowerPoint 2016 ; Exam 77-729",
            "Microsoft Office Access 2016 ; Exam 77-730",
            "Microsoft Outlook 2016 ; Exam 77-731"
        ]
    },
    {
        "card_description": "This certification demonstrates that you have advanced skills in key Office programs by earning a Microsoft Office Specialist Expert certification.",
        "badge": "/en-us/CMSImages/lrn-all-office-specialist-expert-badge.png?version=b086f0a0-6da5-a254-4582-65defbd3119f",
        "description": "This certification demonstrates that you have advanced skills in key Office programs by earning a Microsoft Office Specialist Expert certification.",
        "url": "/microsoft-office-specialist-expert-certification-2016.aspx",
        "ID": "MOS_Expert_Microsoft_Office_Specialist_Expert",
        "handle": "mosExpert2016",
        "api_parameter": "mosExpert2016",
        "title": "MOS Expert: Microsoft Office Specialist Expert",
        "card_title": "Productivity",
        "keywords": [
            "mos",
            "expert",
            "2016",
            "mos expert 2016"
        ],
        "company": "Microsoft",
        "exams": [
            "Microsoft Word 2016 Expert: Creating Documents for Effective Communication ; Exam 77-726",
            "Microsoft Excel 2016 Expert: Interpreting Data for Insights ; Exam 77-728"
        ]
    },
    {
        "card_description": "This certification demonstrates that you have the deepest level of skills needed to proficiently use Office programs by earning a Microsoft Office Specialist Master certification.",
        "badge": "/en-us/CMSImages/lrn-mos-master-office-specialist.png?version=365f169c-c03d-e483-589f-a26b1d7ed841",
        "description": "This certification demonstrates that you have the deepest level of skills needed to proficiently use Office programs by earning a Microsoft Office Specialist Master certification.",
        "url": "/microsoft-office-specialist-master-certification-2016.aspx",
        "ID": "MOS_Master_Microsoft_Office_Specialist_Master",
        "handle": "mosMaster2016",
        "api_parameter": "mosMaster2016",
        "title": "MOS Master: Microsoft Office Specialist Master",
        "card_title": "Productivity",
        "keywords": [
            "mos",
            "master",
            "2016",
            "mos master 2016"
        ],
        "company": "Microsoft",
        "exams": [
            "Access 2016: Core Database Management, Manipulation, and Query Skills  ; Exam 77-730",
            "Outlook 2016: Core Communication, Collaboration and Email Skills ; Exam 77-731"
        ]
    },
    {
        "card_description": "Microsoft Technology Associate is an entry-level certification that validates fundamental technology knowledge.",
        "badge": "/en-us/CMSImages/lrn_mcc-MTA-Certification2x.png?version=6303ba51-6df0-6c10-fedd-3d21cd6672f4",
        "description": "MTA certifications are a great place to start if you would like to get into the technology field. MTA certifications address a wide spectrum of fundamental technical concepts, assess and validate core technical knowledge, and enhance technical credibility. Note: MTA exams do not qualify for MCP certification, nor are they a prerequisite for MCSA or MCSD certification.",
        "url": "mta-it-infrastructure-certification.aspx",
        "ID": "MTA_Database",
        "handle": "mtaDatabase",
        "api_parameter": "mtaDatabase",
        "title": "MTA: Certification",
        "card_title": "Certification",
        "keywords": [
            "mta",
            "database",
            "mta database"
        ],
        "company": "Microsoft",
        "exams": [
            "Windows Operating System Fundamentals ; Exam 98-349",
            "Software Development Fundamentals  ; Exam 98-361",
            "Database Fundamentals ; Exam 98-364",
            "Windows Server Administration Fundamentals  ; Exam 98-365",
            "Networking Fundamentals ; Exam 98-366",
            "Security Fundamentals ; Exam 98-367",
            "Mobility and Devices Fundamentals ; Exam 98-368",
            "Cloud Fundamentals ; Exam 98-369",
            "HTML5 Application Development Fundamentals ; Exam 98-375",
            "Introduction to Programming Using Block-Based Languages (Touch Develop) ; Exam 98-380",
            "Introduction to Programming Using Python ; Exam 98-381",
            "Introduction to Programming Using JavaScript ; Exam 98-382",
            "Introduction to Programming Using HTML and CSS ; Exam 98-383",
            "Introduction to Programming Using Java ; Exam 98-388"
        ]
    },
    {
        "card_description": "Validates skills needed to run a highly efficient and modern data center, with expertise in cloud technologies, identity management, systems management, virtualization, storage, and networking.",
        "badge": "/en-us/CMSImages/lrn_mcc-MCSE-Cloud_Platform_and_Infrastructure2x.png?version=5f276499-7ab4-9016-4ecc-96d18bd00a8d",
        "description": "This certification validates that you have the skills needed to run a highly efficient and modern data center, with expertise in cloud technologies, identity management, systems management, virtualization, storage, and networking.",
        "url": "mcse-cloud-platform-infrastructure.aspx",
        "ID": "MCSE_Cloud_Platform_and_Infrastructure",
        "handle": "mcseCloudPlatform",
        "api_parameter": "mcseCloudPlatform",
        "title": "MCSE: Cloud Platform and Infrastructure",
        "card_title": "Cloud platform and infrastructure",
        "keywords": [
            "mcse",
            "cloud",
            "platform",
            "mcse cloud platform"
        ],
        "company": "Microsoft",
        "exams": [
            "Developing Microsoft Azure Solutions ; Exam 70-532",
            "Implementing Microsoft Azure Infrastructure Solutions ; Exam 70-533",
            "Architecting Microsoft Azure Solutions ; Exam 70-535",
            "Designing and Implementing Cloud Data Platform Solutions ; Exam 70-473",
            "Designing and Implementing Big Data Analytics Solutions ; Exam 70-475",
            "Securing Windows Server 2016 ; Exam 70-744",
            "Implementing a Software-Defined Datacenter ; Exam 70-745",
            "Designing and Implementing a Server Infrastructure ; Exam 70-413",
            "Implementing an Advanced Server Infrastructure ; Exam 70-414",
            "Configuring and Operating a Hybrid Cloud with Microsoft Azure Stack ; Exam 70-537"
        ]
    },
    {
        "card_description": "Validates skills needed to manage devices in today’s bring-your-own-device (BYOD) enterprise. It qualifies you for a career path which can range from traditional desktop support technician to enterprise",
        "badge": "/en-us/CMSImages/lrn_mcc-MCSE-Mobility2x.png?version=3fe1dc28-c27d-b396-504f-dbaef1099584",
        "description": "This certification validates that you have the skills needed to manage devices in today's bring-your-own-device (BYOD) enterprise. ",
        "url": "mcsa-windows-10-certifications.aspx",
        "ID": "MCSE_Mobility",
        "handle": "mcseMobility",
        "api_parameter": "mcseMobility",
        "title": "MCSE: Mobility",
        "card_title": "Mobility",
        "keywords": [
            "mcse",
            "mobility",
            "mcse mobility"
        ],
        "company": "Microsoft",
        "exams": [
            "Deploying Windows Desktops and Enterprise Applications ; Exam 70-695",
            "Administering Microsoft System Center Configuration Manager and Cloud Services Integration ; Exam 70-703",
            "Planning for and Managing Devices in the Enterprise ; Exam 70-398"
        ]
    },
    {
        "card_description": "Validates skills needed to move your company to the cloud, increase user productivity and flexibility, reduce data loss, and improve data security for your organization.",
        "badge": "/en-us/CMSImages/lrn_mcc-MCSE-Productivity2x.png?version=fd4ab33c-ef09-9fdb-99fd-ab9f943e155e",
        "description": "This certification validates that you have the skills needed to move your company to the cloud, increase user productivity and flexibility, reduce data loss, and improve data security for your organization.",
        "url": "/mcse-productivity-certification.aspx",
        "ID": "MCSE_Productivity",
        "handle": "mcseProductivity",
        "api_parameter": "mcseProductivity",
        "title": "MCSE: Productivity",
        "card_title": "Productivity",
        "keywords": [
            "mcse",
            "productivity",
            "mcse productivity"
        ],
        "company": "Microsoft",
        "exams": [
            "Designing and Deploying Microsoft Exchange Server 2016 ; Exam 70-345",
            "Managing Microsoft SharePoint Server 2016 ; Exam 70-339",
            "Deploying Enterprise Voice with Skype for Business 2015 ; Exam 70-333",
            "Core Solutions of Microsoft Skype for Business 2015 ; Exam 70-334",
            "Core Solutions of Microsoft SharePoint Server 2013 ; Exam 70-331",
            "Advanced Solutions of Microsoft SharePoint Server 2013 ; Exam 70-332",
            "Core Solutions of Microsoft Exchange Server 2013 ; Exam 70-341",
            "Advanced Solutions of Microsoft Exchange Server 2013 ; Exam 70-342"
        ]
    },
    {
        "card_description": "Demonstrate your broad skill sets in SQL administration, building enterprise-scale data solutions, and leveraging business intelligence data – both on-premises and in cloud environments.",
        "badge": "/en-us/CMSImages/lrn_mcc-MCSE-Data_Management_and_Analytics2x.png?version=35241f5a-a7e0-916a-cb2b-e6f5713c7289",
        "description": "Demonstrate your broad skill sets in SQL administration, building enterprise-scale data solutions, and leveraging business intelligence data – both on-premises and in cloud environments.",
        "url": "mcse-data-management-analytics.aspx",
        "ID": "MCSE_Data_Management_and_Analytics",
        "handle": "mcseDataManagementAnalytics",
        "api_parameter": "mcseDataManagementAnalytics",
        "title": "MCSE: Data Management and Analytics",
        "card_title": "Data Management and Analytics",
        "keywords": [
            "mcse",
            "data",
            "management",
            "analytics",
            "mcse data management analytics"
        ],
        "company": "Microsoft",
        "exams": [
            "Designing and Implementing Cloud Data Platform Solutions ; Exam 70-473",
            "Designing and Implementing Big Data Analytics Solutions ; Exam 70-475",
            "Developing Microsoft SQL Server Databases ; Exam 70-464",
            "Designing Database Solutions for Microsoft SQL Server ; Exam 70-465",
            "Implementing Data Models and Reports with Microsoft SQL Server ; Exam 70-466",
            "Designing Business Intelligence Solutions with Microsoft SQL Server ; Exam 70-467",
            "Developing SQL Databases ; Exam 70-762",
            "Implementing a Data Warehouse using SQL ; Exam 70-767",
            "Developing SQL Data Models ; Exam 70-768",
            "Analyzing Big Data with Microsoft R ; Exam 70-773",
            "Perform Cloud Data Science with Azure Machine Learning ; Exam 70-774",
            "Perform Data Engineering on Microsoft Azure HDInsight ; Exam 70-775"
        ]
    },
    {
        "card_description": "Validates your expertise in working with and managing Microsoft Dynamics 365 technologies. With a foundation in Microsoft Dynamics 365, demonstrate additional skills in one or more business areas.",
        "badge": "/en-us/CMSImages/lrn_badge-ba.png?version=8af1bdca-956e-613a-5411-89f8b8c566e1",
        "description": "This certification validates that you have the expertise to work with and manage Microsoft Dynamics 365 technologies. You’ll start with a foundation in  Microsoft Dynamics 365 and demonstrate additional expertise in one or more business specific areas.",
        "url": "mcse-business-applications.aspx",
        "ID": "MCSE_Business_Applications",
        "handle": "mcseBusinessApplications",
        "api_parameter": "mcseBusinessApplications",
        "title": "MCSE: Business Applications",
        "card_title": "Business Applications",
        "keywords": [
            "mcse",
            "business",
            "applications",
            "mcse business applications"
        ],
        "company": "Microsoft",
        "exams": [
            "Microsoft Dynamics 365 for Sales ; Exam MB2-717",
            "Microsoft Dynamics 365 for Customer Service ; Exam MB2-718",
            "Financial Management in Microsoft Dynamics 365 for Finance and Operations ; Exam MB6-895",
            "Distribution and Trade in Microsoft Dynamics 365 for Finance and Operations ; Exam MB6-896",
            "Microsoft Dynamics 365 for Retail ; Exam MB6-897",
            "Microsoft Dynamics 365 for Field Service ; Exam MB2-877"
        ]
    },
    {
        "card_description": "Validate that you have the skills needed to build modern mobile and/or web applications and services.",
        "badge": "/en-us/CMSImages/lrn_mcc-MCSD-App_Builder2x.png?version=cfefc898-ba50-c591-18c7-6b756ccede88",
        "description": "This certification validates that you have the skills needed to build modern mobile and/or web applications and services.",
        "url": "mcsd-app-builder-certification.aspx",
        "ID": "MCSD_App_Builder",
        "handle": "mcsdAppBuilder",
        "api_parameter": "mcsdAppBuilder",
        "title": "MCSD: App Builder",
        "card_title": "App Builder",
        "keywords": [
            "mcsd",
            "app",
            "builder",
            "mcsd app builder"
        ],
        "company": "Microsoft",
        "exams": [
            "Developing Microsoft Azure Solutions ; Exam 70-532",
            "Architecting Microsoft Azure Solutions ; Exam 70-535",
            "Developing Microsoft Azure and Web Services ; Exam 70-487",
            "Developing Microsoft SharePoint Server 2013 Core Solutions ; Exam 70-488",
            "Developing Microsoft SharePoint Server 2013 Advanced Solutions ; Exam 70-489",
            "Administering Microsoft Visual Studio Team Foundation Server ; Exam 70-496",
            "Software Testing with Visual Studio ; Exam 70-497",
            "Delivering Continuous Value with Visual Studio Application Lifestyle Management ; Exam 70-498"
        ]
    }
]

const DELL_CERTIFICATIONS = [
    {
        "title": "DECA-ISM",
        "function": "Technology / Associate",
        "exam_name": "Information Storage and Management v3 Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-001-2015Q3&apass=INFORMATION",
        "exams": [
            "Information Storage and Management v3 Exam ; E05-001"
        ],
        "level": "Associate",
        "training": "Information Storage and Management",
        "company": "Dell",
        "exam_id": "E05-001"
    },
    {
        "title": "DECA-DS",
        "function": "Technology / Associate",
        "exam_name": "Data Science and Big Data Analytics Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-007-2012Q1&apass=DATASCIENCE",
        "exams": [
            "Data Science and Big Data Analytics Exam ; E20-007"
        ],
        "level": "Associate",
        "training": "Data Science",
        "company": "Dell",
        "exam_id": "E20-007"
    },
    {
        "title": "DECS-DS",
        "function": "Technology / Associate",
        "exam_name": "Advanced Analytics Specialist Exam for Data Scientists",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-065-2015Q2&apass=ADVANCED",
        "exams": [
            "Advanced Analytics Specialist Exam for Data Scientists ; E20-065"
        ],
        "level": "Specialist",
        "training": "Advanced Analytics",
        "company": "Dell",
        "exam_id": "E20-065"
    },
    {
        "title": "DECA-DPM",
        "function": "Technology / Associate",
        "exam_name": "Data Protection and Management Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-005-2016Q2&apass=DATAPROTECTIONANDMANAGEME",
        "exams": [
            "Data Protection and Management Exam ; E10-005"
        ],
        "level": "Associate",
        "training": "Data Protection and Management",
        "company": "Dell",
        "exam_id": "E10-005"
    },
    {
        "title": "DECS-CA",
        "function": "Design",
        "exam_name": "Cloud Infrastructure Specialist Exam for Cloud Architects",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-020-2015Q4&apass=CLOUDINFRASTRUCTURE",
        "exams": [
            "Cloud Infrastructure Specialist Exam for Cloud Architects ; E20-020"
        ],
        "level": "Specialist",
        "training": "Cloud Infrastructure",
        "company": "Dell",
        "exam_id": "E20-020"
    },
    {
        "title": "DECE-CA",
        "function": "Design",
        "exam_name": "Cloud Services Expert Exam for Cloud Architects",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-920-2016Q1&apass=CLOUDSERVICES",
        "exams": [
            "Cloud Services Expert Exam for Cloud Architects ; E20-920"
        ],
        "level": "Expert",
        "training": "Cloud Services",
        "company": "Dell",
        "exam_id": "E20-920"
    },
    {
        "title": "DECS-SA",
        "function": "Manage",
        "exam_name": "Specialist Systems Administrator | VMAX All Flash and VMAX3 Solutions Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-507-2016Q4&apass=VAF3SSSA",
        "exams": [
            "Specialist Systems Administrator | VMAX All Flash and VMAX3 Solutions Exam ; E20-507"
        ],
        "level": "Specialist",
        "training": "VMAX All Flash and VMAX3 Solutions",
        "company": "Dell",
        "exam_id": "E20-507"
    },
    {
        "title": "DECS-SA",
        "function": "Manage",
        "exam_name": "Specialist Systems Administrator | Isilon Solutions Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-559-2016Q3&apass=ISILONSOLUTIONS",
        "exams": [
            "Specialist Systems Administrator | Isilon Solutions Exam ; E20-559"
        ],
        "level": "Specialist",
        "training": "Isilon Solutions",
        "company": "Dell",
        "exam_id": "E20-559"
    },
    {
        "title": "DECS-SA",
        "function": "Manage",
        "exam_name": "Specialist Systems Administrator | VPLEX Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-562-2016Q3&apass=VPLEXSASPECIALIST",
        "exams": [
            "Specialist Systems Administrator | VPLEX Exam ; E20-562"
        ],
        "level": "Specialist",
        "training": "VPLEX",
        "company": "Dell",
        "exam_id": "E20-562"
    },
    {
        "title": "DECS-SA",
        "function": "Manage",
        "exam_name": "Specialist Systems Administrator | XtremIO Solutions Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-568-2017Q4&apass=SPESAXTRIOSO",
        "exams": [
            "Specialist Systems Administrator | XtremIO Solutions Exam ; E20-568"
        ],
        "level": "Specialist",
        "training": "XtremIO Solutions",
        "company": "Dell",
        "exam_id": "E20-568"
    },
    {
        "title": "DECS-SA",
        "function": "Manage",
        "exam_name": "Specialist Systems Administrator | RecoverPoint Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-575-2017Q1&apass=RECOVPOINTSPESAS",
        "exams": [
            "Specialist Systems Administrator | RecoverPoint Exam ; E20-575"
        ],
        "level": "Specialist",
        "training": "RecoverPoint",
        "company": "Dell",
        "exam_id": "E20-575"
    },
    {
        "title": "DECS-SA",
        "function": "Manage",
        "exam_name": "Specialist - Systems Administrator Data Domain Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-585-2017Q3&apass=SPSYSDADADO",
        "exams": [
            "Specialist - Systems Administrator Data Domain Exam ; E20-585"
        ],
        "level": "Specialist",
        "training": "Data Domain",
        "company": "Dell",
        "exam_id": "E20-585"
    },
    {
        "title": "DECS-SA",
        "function": "Manage",
        "exam_name": "Specialist Systems Administrator | NetWorker Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-597-2017Q1&apass=NETWORKERSPSA",
        "exams": [
            "Specialist Systems Administrator | NetWorker Exam ; E20-597"
        ],
        "level": "Specialist",
        "training": "NetWorker",
        "company": "Dell",
        "exam_id": "E20-597"
    },
    {
        "title": "DECS-SA",
        "function": "Manage",
        "exam_name": "Specialist Systems Administrator | Avamar Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-598-2017Q2&apass=AVASPEXSAS",
        "exams": [
            "Specialist Systems Administrator | Avamar Exam ; E20-598"
        ],
        "level": "Specialist",
        "training": "Avamar",
        "company": "Dell",
        "exam_id": "E20-598"
    },
    {
        "title": "DECE",
        "function": "Manage",
        "exam_name": "Expert VMAX All Flash and VMAX3 Solutions Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-807-2018Q1&apass=EXPVMAXAFVMAX3",
        "exams": [
            "Expert VMAX All Flash and VMAX3 Solutions Exam ; E20-807"
        ],
        "level": "Expert",
        "training": "VMAX All Flash and VMAX3 Solutions",
        "company": "Dell",
        "exam_id": "E20-807"
    },
    {
        "title": "DEC",
        "function": "Deploy ",
        "exam_name": "ScaleIO 2.x Server-Based SAN Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-214-2016Q3&apass=SCALEIOV2.0",
        "exams": [
            "ScaleIO 2.x Server-Based SAN Exam ; E22-214"
        ],
        "level": "Product Technology Specific",
        "training": "ScaleIO 2.x Server-Based SAN",
        "company": "Dell",
        "exam_id": "E22-214"
    },
    {
        "title": "DEC",
        "function": " Manage",
        "exam_name": "ScaleIO 2.x Server-Based SAN Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-214-2016Q3&apass=SCALEIOV2.0",
        "exams": [
            "ScaleIO 2.x Server-Based SAN Exam ; E22-214"
        ],
        "level": "Product Technology Specific",
        "training": "ScaleIO 2.x Server-Based SAN",
        "company": "Dell",
        "exam_id": "E22-214"
    },
    {
        "title": "DEC",
        "function": "Deploy ",
        "exam_name": "ViPR SRM 4.x Storage Resource Management Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-265-2017Q3&apass=VIPSRM4STREMA",
        "exams": [
            "ViPR SRM 4.x Storage Resource Management Exam ; E22-265"
        ],
        "level": "Product Technology Specific",
        "training": "ViPR SRM 4.x Storage Resource Management",
        "company": "Dell",
        "exam_id": "E22-265"
    },
    {
        "title": "DEC",
        "function": " Manage",
        "exam_name": "ViPR SRM 4.x Storage Resource Management Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-265-2017Q3&apass=VIPSRM4STREMA",
        "exams": [
            "ViPR SRM 4.x Storage Resource Management Exam ; E22-265"
        ],
        "level": "Product Technology Specific",
        "training": "ViPR SRM 4.x Storage Resource Management",
        "company": "Dell",
        "exam_id": "E22-265"
    },
    {
        "title": "DECS-IE",
        "function": "Deploy",
        "exam_name": "Specialist Implementation Engineer Dell EMC Unity Solutions Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-393-2018Q3&apass=IEUNITYSOL",
        "exams": [
            "Specialist Implementation Engineer Dell EMC Unity Solutions Exam ; E20-393"
        ],
        "level": "Specialist",
        "training": "Dell EMC Unity",
        "company": "Dell",
        "exam_id": "E20-393"
    },
    {
        "title": "DECS-IE",
        "function": "Deploy",
        "exam_name": "VPLEX Specialist Exam for Implementation Engineers",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-260-2016Q3&apass=VPLEXIESPECIALIST",
        "exams": [
            "VPLEX Specialist Exam for Implementation Engineers ; E20-260"
        ],
        "level": "Specialist",
        "training": "VPLEX",
        "company": "Dell",
        "exam_id": "E20-260"
    },
    {
        "title": "DECS-IE",
        "function": "Deploy",
        "exam_name": "VMAX All Flash and VMAX3 Solutions Specialist Exam for Implementation Engineers",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-307-2016Q4&apass=VMAXAF3SSIE",
        "exams": [
            "VMAX All Flash and VMAX3 Solutions Specialist Exam for Implementation Engineers ; E20-307"
        ],
        "level": "Specialist",
        "training": "VMAX All Flash and VMAX3 Solutions",
        "company": "Dell",
        "exam_id": "E20-307"
    },
    {
        "title": "DECE",
        "function": "Deploy",
        "exam_name": "Expert VMAX All Flash and VMAX3 Solutions Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-807-2018Q1&apass=EXPVMAXAFVMAX3",
        "exams": [
            "Expert VMAX All Flash and VMAX3 Solutions Exam ; E20-807"
        ],
        "level": "Expert",
        "training": "VMAX All Flash and VMAX3 Solutions",
        "company": "Dell",
        "exam_id": "E20-807"
    },
    {
        "title": "DECS-IE",
        "function": "Deploy",
        "exam_name": "Isilon Solutions Specialist Exam for Implementation Engineers",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-357-2017Q2&apass=ISILONSOSPIES",
        "exams": [
            "Isilon Solutions Specialist Exam for Implementation Engineers ; E20-357"
        ],
        "level": "Specialist",
        "training": "Isilon Solutions",
        "company": "Dell",
        "exam_id": "E20-357"
    },
    {
        "title": "DECS-IE",
        "function": "Deploy",
        "exam_name": "XtremIO Solutions Specialist Exam for Implementation Engineers",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-368-2017Q4&apass=SPEIEXTRIOSO",
        "exams": [
            "XtremIO Solutions Specialist Exam for Implementation Engineers ; E20-368"
        ],
        "level": "Specialist",
        "training": "XtremIO Solutions",
        "company": "Dell",
        "exam_id": "E20-368"
    },
    {
        "title": "DECS-IE",
        "function": "Deploy",
        "exam_name": "RecoverPoint Specialist Exam for Implementation Engineers",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-375-2016Q2&apass=RECOVERPOINT",
        "exams": [
            "RecoverPoint Specialist Exam for Implementation Engineers ; E20-375"
        ],
        "level": "Specialist",
        "training": "RecoverPoint",
        "company": "Dell",
        "exam_id": "E20-375"
    },
    {
        "title": "DECS-IE",
        "function": "Deploy",
        "exam_name": "Specialist -  Implementation Engineer Data Domain Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-385-2017Q3&apass=SPIMPDADO",
        "exams": [
            "Specialist -  Implementation Engineer Data Domain Exam ; E20-385"
        ],
        "level": "Specialist",
        "training": "Data Domain",
        "company": "Dell",
        "exam_id": "E20-385"
    },
    {
        "title": "DECS-IE",
        "function": "Deploy",
        "exam_name": "NetWorker Specialist Exam for Implementation Engineers",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-593-2017Q1&apass=NETWORKERSPIE",
        "exams": [
            "NetWorker Specialist Exam for Implementation Engineers ; E20-593"
        ],
        "level": "Specialist",
        "training": "NetWorker",
        "company": "Dell",
        "exam_id": "E20-593"
    },
    {
        "title": "DECS-IE",
        "function": "Deploy",
        "exam_name": "Avamar Specialist Exam for Implementation Engineers",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-594-2017Q2&apass=AVASPEXIES",
        "exams": [
            "Avamar Specialist Exam for Implementation Engineers ; E20-594"
        ],
        "level": "Specialist",
        "training": "Avamar",
        "company": "Dell",
        "exam_id": "E20-594"
    },
    {
        "title": "DECE-IE",
        "function": "Deploy",
        "exam_name": "NetWorker Expert Exam for Implementation Engineers",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-893-2016Q3&apass=NETWORKEREXPERT",
        "exams": [
            "NetWorker Expert Exam for Implementation Engineers ; E20-893"
        ],
        "level": "Expert",
        "training": "NetWorker",
        "company": "Dell",
        "exam_id": "E20-893"
    },
    {
        "title": "DECE-IE",
        "function": "Deploy",
        "exam_name": "Avamar Expert Exam for Implementation Engineers",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-895-2017Q2&apass=AVAEXPIES",
        "exams": [
            "Avamar Expert Exam for Implementation Engineers ; E20-895"
        ],
        "level": "Expert",
        "training": "Avamar",
        "company": "Dell",
        "exam_id": "E20-895"
    },
    {
        "title": "DECS-PE",
        "function": "Support",
        "exam_name": "VMAX Family Specialist Exam for Platform Engineers",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-624-2015Q2&apass=VMAXFAMILY",
        "exams": [
            "VMAX Family Specialist Exam for Platform Engineers ; E20-624"
        ],
        "level": "Specialist",
        "training": "VMAX Family",
        "company": "Dell",
        "exam_id": "E20-624"
    },
    {
        "title": "DECS-PE",
        "function": "Support",
        "exam_name": "Isilon Specialist Exam for Platform Engineers",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-655-2017Q2&apass=ISILONSPEPES",
        "exams": [
            "Isilon Specialist Exam for Platform Engineers ; E20-655"
        ],
        "level": "Specialist",
        "training": "Isilon",
        "company": "Dell",
        "exam_id": "E20-655"
    },
    {
        "title": "DECS-PE",
        "function": "Support",
        "exam_name": "XtremIO Specialist Exam for PlatformEngineers",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-668-2017Q4&apass=SPEPLATXTREMIO",
        "exams": [
            "XtremIO Specialist Exam for PlatformEngineers ; E20-668"
        ],
        "level": "Specialist",
        "training": "XtremIO",
        "company": "Dell",
        "exam_id": "E20-668"
    },
    {
        "title": "TSE",
        "function": "Support",
        "exam_name": "Isilon Specialist Exam for Technical Support Engineers",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-657-2016Q1&apass=ISILONSPECIALIST",
        "exams": [
            "Isilon Specialist Exam for Technical Support Engineers ; E20-657"
        ],
        "level": "Specialist",
        "training": "Isilon",
        "company": "Dell",
        "exam_id": "E20-657"
    },
    {
        "title": "DECS-TA",
        "function": "Design",
        "exam_name": "Backup Recovery Solutions Design Exam for Technology Architects",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-329-2016Q4&apass=BARESOLUDESIGTA",
        "exams": [
            "Backup Recovery Solutions Design Exam for Technology Architects ; E20-329"
        ],
        "level": "Specialist",
        "training": "Backup Recovery Solutions",
        "company": "Dell",
        "exam_id": "E20-329"
    },
    {
        "title": "DECS-TA",
        "function": "Design",
        "exam_name": "XtremIO Solutions and Design Specialist Exam for Technology Architects",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-526-2015Q4&apass=XTREMIOSOLUTIONS",
        "exams": [
            "XtremIO Solutions and Design Specialist Exam for Technology Architects ; E20-526"
        ],
        "level": "Specialist",
        "training": "XtremIO Solutions",
        "company": "Dell",
        "exam_id": "E20-526"
    },
    {
        "title": "DECS-TA",
        "function": "Design",
        "exam_name": "VMAX3 Solutions and Design Specialist Exam for Technology Architects",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-542-2015Q2&apass=VMAX3SOLUTIONS",
        "exams": [
            "VMAX3 Solutions and Design Specialist Exam for Technology Architects ; E20-542"
        ],
        "level": "Specialist",
        "training": "VMAX3 Solutions",
        "company": "Dell",
        "exam_id": "E20-542"
    },
    {
        "title": "DECS-TA",
        "function": "Design",
        "exam_name": "Isilon Solutions and Design Specialist Exam for Technology Architects",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-555-2017Q2&apass=ISISOLDESSPETAS",
        "exams": [
            "Isilon Solutions and Design Specialist Exam for Technology Architects ; E20-555"
        ],
        "level": "Specialist",
        "training": "Isilon Solutions",
        "company": "Dell",
        "exam_id": "E20-555"
    },
    {
        "title": "DECS-TA",
        "function": "Design",
        "exam_name": "Backup Recovery Solutions Specialist Exam for Technology Architects",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-591-2016Q2&apass=BACKUPRECOVERY",
        "exams": [
            "Backup Recovery Solutions Specialist Exam for Technology Architects ; E20-591"
        ],
        "level": "Specialist",
        "training": "Backup Recovery Solutions",
        "company": "Dell",
        "exam_id": "E20-591"
    },
    {
        "title": "DECE-TA",
        "function": "Design",
        "exam_name": "Backup Recovery Solutions Expert Exam for Technology Architects",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-891-2015Q3&apass=BACKUPRECOVERY",
        "exams": [
            "Backup Recovery Solutions Expert Exam for Technology Architects ; E20-891"
        ],
        "level": "Expert",
        "training": "Backup Recovery Solutions",
        "company": "Dell",
        "exam_id": "E20-891"
    },
    {
        "title": "DECE",
        "function": "Design",
        "exam_name": "Expert VMAX All Flash and VMAX3 Solutions Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-807-2018Q1&apass=EXPVMAXAFVMAX3",
        "exams": [
            "Expert VMAX All Flash and VMAX3 Solutions Exam ; E20-807"
        ],
        "level": "Expert",
        "training": "VMAX All Flash and VMAX3 Solutions",
        "company": "Dell",
        "exam_id": "E20-807"
    },
    {
        "title": "DEC",
        "function": "Deploy",
        "exam_name": "VxRail Appliance 4.x Deployment and Implementation Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-285-2017Q1&apass=VXRAILAPP4XDI",
        "exams": [
            "VxRail Appliance 4.x Deployment and Implementation Exam ; E22-285"
        ],
        "level": "Product Technology Specific",
        "training": "VxRail Appliance 4.x",
        "company": "Dell",
        "exam_id": "E22-285"
    },
    {
        "title": "Dell Certified",
        "function": "Deploy ",
        "exam_name": "Dell  PowerEdge Professional Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DCPPE200-2017Q1&apass=DELLPEPRO",
        "exams": [
            "Dell  PowerEdge Professional Exam ; DCPPE-200"
        ],
        "level": "Professional",
        "training": "PowerEdge",
        "company": "Dell",
        "exam_id": "DCPPE-200"
    },
    {
        "title": "Dell Certified",
        "function": " Manage ",
        "exam_name": "Dell  PowerEdge Professional Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DCPPE200-2017Q1&apass=DELLPEPRO",
        "exams": [
            "Dell  PowerEdge Professional Exam ; DCPPE-200"
        ],
        "level": "Professional",
        "training": "PowerEdge",
        "company": "Dell",
        "exam_id": "DCPPE-200"
    },
    {
        "title": "Dell Certified",
        "function": " Support",
        "exam_name": "Dell  PowerEdge Professional Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DCPPE200-2017Q1&apass=DELLPEPRO",
        "exams": [
            "Dell  PowerEdge Professional Exam ; DCPPE-200"
        ],
        "level": "Professional",
        "training": "PowerEdge",
        "company": "Dell",
        "exam_id": "DCPPE-200"
    },
    {
        "title": "Dell  Certified",
        "function": "Technology / Associate",
        "exam_name": "Dell  Networking Associate Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DCAN100-2017Q1&apass=DEASNETW",
        "exams": [
            "Dell  Networking Associate Exam ; DCAN-100"
        ],
        "level": "Associate",
        "training": "Networking",
        "company": "Dell",
        "exam_id": "DCAN-100"
    },
    {
        "title": "Dell Certified",
        "function": "Deploy ",
        "exam_name": "Dell  Networking Professional Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DNDNS200-2017Q1&apass=DELLNETPRO",
        "exams": [
            "Dell  Networking Professional Exam ; DNSDNS-200"
        ],
        "level": "Professional",
        "training": "Networking",
        "company": "Dell",
        "exam_id": "DNSDNS-200"
    },
    {
        "title": "Dell Certified",
        "function": " Manage ",
        "exam_name": "Dell  Networking Professional Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DNDNS200-2017Q1&apass=DELLNETPRO",
        "exams": [
            "Dell  Networking Professional Exam ; DNSDNS-200"
        ],
        "level": "Professional",
        "training": "Networking",
        "company": "Dell",
        "exam_id": "DNSDNS-200"
    },
    {
        "title": "Dell Certified",
        "function": " Support",
        "exam_name": "Dell  Networking Professional Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DNDNS200-2017Q1&apass=DELLNETPRO",
        "exams": [
            "Dell  Networking Professional Exam ; DNSDNS-200"
        ],
        "level": "Professional",
        "training": "Networking",
        "company": "Dell",
        "exam_id": "DNSDNS-200"
    },
    {
        "title": "DECS-PE",
        "function": "Support",
        "exam_name": "Specialist Platform Engineer | VMAX All Flash and VMAX3 Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-1141-2017Q3&apass=VMAXAFVMAX3SPEPES",
        "exams": [
            "Specialist Platform Engineer | VMAX All Flash and VMAX3 Exam ; DES-1141"
        ],
        "level": "Specialist",
        "training": "VMAX All Flash and VMAX3",
        "company": "Dell",
        "exam_id": "DES-1141"
    },
    {
        "title": "DECS-TA",
        "function": "Design",
        "exam_name": "Specialist Technology Architect Midrange Storage Solutions Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-1D11-2017Q3&apass=SPPEVMAXALL",
        "exams": [
            "Specialist Technology Architect Midrange Storage Solutions Exam ; DES-1D11"
        ],
        "level": "Specialist",
        "training": "Midrange Storage Solutions",
        "company": "Dell",
        "exam_id": "DES-1D11"
    },
    {
        "title": "DECS-SA",
        "function": "Manage",
        "exam_name": "Specialist Systems Administrator Elastic Cloud Storage (ECS) Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-1B31-2017Q3&apass=SPSYSAECSSOL",
        "exams": [
            "Specialist Systems Administrator Elastic Cloud Storage (ECS) Exam ; DES-1B31"
        ],
        "level": "Specialist",
        "training": "Elastic Cloud Storage ECS",
        "company": "Dell",
        "exam_id": "DES-1B31"
    },
    {
        "title": "DECS-SA",
        "function": "Manage",
        "exam_name": "Specialist Systems Administrator Converged Infrastructure Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-6131-2017Q4&apass=SPSYSACONVRINFRAS",
        "exams": [
            "Specialist Systems Administrator Converged Infrastructure Exam ; DES-6131"
        ],
        "level": "Specialist",
        "training": "Converged Infrastructure",
        "company": "Dell",
        "exam_id": "DES-6131"
    },
    {
        "title": "DECS-SA",
        "function": "Manage",
        "exam_name": "Specialist Systems Administrator Hyper-converged Infrastructure Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-6331-2017Q4&apass=SPSAHYCONINF",
        "exams": [
            "Specialist Systems Administrator Hyper-converged Infrastructure Exam ; DES-6331"
        ],
        "level": "Specialist",
        "training": "Hyper-converged Infrastructure",
        "company": "Dell",
        "exam_id": "DES-6331"
    },
    {
        "title": "DECA-CSHC",
        "function": "Technology / Associate",
        "exam_name": "Associate – Converged Systems and Hybrid Cloud Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-64T-2017Q4&apass=ASSCONSYSHYBCLO",
        "exams": [
            "Associate – Converged Systems and Hybrid Cloud Exam ; DEA-64T1"
        ],
        "level": "Associate",
        "training": "Converged Systems and Hybrid Cloud",
        "company": "Dell",
        "exam_id": "DEA-64T1"
    },
    {
        "title": "DECS-IE",
        "function": "Deploy ",
        "exam_name": "Specialist Implementation Engineer SC Series Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DES1721-2018Q1&apass=SPEIESCSERIES",
        "exams": [
            "Specialist Implementation Engineer SC Series Exam ; DES-1721"
        ],
        "level": "Specialist",
        "training": "SC Series",
        "company": "Dell",
        "exam_id": "DES-1721"
    },
    {
        "title": "DECS-IE",
        "function": " Manage ",
        "exam_name": "Specialist Implementation Engineer SC Series Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DES1721-2018Q1&apass=SPEIESCSERIES",
        "exams": [
            "Specialist Implementation Engineer SC Series Exam ; DES-1721"
        ],
        "level": "Specialist",
        "training": "SC Series",
        "company": "Dell",
        "exam_id": "DES-1721"
    },
    {
        "title": "DECS-IE",
        "function": " Support",
        "exam_name": "Specialist Implementation Engineer SC Series Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DES1721-2018Q1&apass=SPEIESCSERIES",
        "exams": [
            "Specialist Implementation Engineer SC Series Exam ; DES-1721"
        ],
        "level": "Specialist",
        "training": "SC Series",
        "company": "Dell",
        "exam_id": "DES-1721"
    },
    {
        "title": "DECS-IE",
        "function": "Deploy ",
        "exam_name": "Specialist Implementation Engineer PowerEdge Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DES4121-2018Q1&apass=SPEIEPOWEDG",
        "exams": [
            "Specialist Implementation Engineer PowerEdge Exam ; DES-4121"
        ],
        "level": "Specialist",
        "training": "PowerEdge",
        "company": "Dell",
        "exam_id": "DES-4121"
    },
    {
        "title": "DECS-IE",
        "function": " Manage ",
        "exam_name": "Specialist Implementation Engineer PowerEdge Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DES4121-2018Q1&apass=SPEIEPOWEDG",
        "exams": [
            "Specialist Implementation Engineer PowerEdge Exam ; DES-4121"
        ],
        "level": "Specialist",
        "training": "PowerEdge",
        "company": "Dell",
        "exam_id": "DES-4121"
    },
    {
        "title": "DECS-IE",
        "function": " Support",
        "exam_name": "Specialist Implementation Engineer PowerEdge Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DES4121-2018Q1&apass=SPEIEPOWEDG",
        "exams": [
            "Specialist Implementation Engineer PowerEdge Exam ; DES-4121"
        ],
        "level": "Specialist",
        "training": "PowerEdge",
        "company": "Dell",
        "exam_id": "DES-4121"
    },
    {
        "title": "DECE",
        "function": "Deploy",
        "exam_name": "Expert SC Series Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DES1721-2018Q1&apass=SPEIESCSERIES",
        "exams": [
            "Expert SC Series Exam ; DEE-1721"
        ],
        "level": "Expert",
        "training": "SC Series",
        "company": "Dell",
        "exam_id": "DEE-1721"
    },
    {
        "title": "DECM-EA",
        "function": "",
        "exam_name": "Master Enterprise Architect Certification Description",
        "exams": [
            "Master Enterprise Architect Certification Description ; "
        ],
        "level": "Master",
        "training": "Cloud",
        "company": "Dell",
        "exam_id": ""
    },
    {
        "title": "DECA-CIS",
        "function": "Technology / Associate",
        "exam_name": "Cloud Infrastructure and Services v3 Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DEA2TT3-2018Q1&apass=CLOUINFRSERVER3",
        "exams": [
            "Cloud Infrastructure and Services v3 Exam ; DEA-2TT3"
        ],
        "level": "Associate",
        "training": "Cloud Infrastructure and Services",
        "company": "Dell",
        "exam_id": "DEA-2TT3"
    },
    {
        "title": "DECE",
        "function": "Manage",
        "exam_name": "Expert SC Series Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DES1721-2018Q1&apass=SPEIESCSERIES",
        "exams": [
            "Expert SC Series Exam ; DEE-1721"
        ],
        "level": "Expert",
        "training": "SC Series",
        "company": "Dell",
        "exam_id": "DEE-1721"
    },
    {
        "title": "DECE",
        "function": "Support",
        "exam_name": "Expert SC Series Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DES1721-2018Q1&apass=SPEIESCSERIES",
        "exams": [
            "Expert SC Series Exam ; DEE-1721"
        ],
        "level": "Expert",
        "training": "SC Series",
        "company": "Dell",
        "exam_id": "DEE-1721"
    },
    {
        "title": "DECE-SA",
        "function": "Manage",
        "exam_name": "Multi-Cloud",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DEE2T31-2018Q1&apass=EXPSAMULTCLUD",
        "exams": [
            "Multi-Cloud ; DEE-2T31"
        ],
        "level": "Expert",
        "training": "Multi-Cloud",
        "company": "Dell",
        "exam_id": "DEE-2T31"
    },
    {
        "title": "DECA-DS",
        "function": "Technology / Associate",
        "exam_name": "Associate Data Science and Big Data Analytics v2 Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DEA7TT2-2018Q1&apass=ASODASCBGDAANYV2",
        "exams": [
            "Associate Data Science and Big Data Analytics v2 Exam ; DEA-7TT2"
        ],
        "level": "Associate",
        "training": "Data Science",
        "company": "Dell",
        "exam_id": "DEA-7TT2"
    },
    {
        "title": "DECA-PowerEdge",
        "function": "Technology / Associate",
        "exam_name": "Associate PowerEdge Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DEA41T1-2018Q2&apass=ASOPOWREDGE",
        "exams": [
            "Associate PowerEdge Exam ; DEA-41T1"
        ],
        "level": "Associate",
        "training": "PowerEdge",
        "company": "Dell",
        "exam_id": "DEA-41T1"
    },
    {
        "title": "DECE",
        "function": "Design",
        "exam_name": "Expert - Isilon Solutions Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DEE1421-2018Q2&apass=EXPISILONSOLUTIONS",
        "exams": [
            "Expert - Isilon Solutions Exam ; DEE-1421"
        ],
        "level": "Expert",
        "training": "Isilon Solutions",
        "company": "Dell",
        "exam_id": "DEE-1421"
    },
    {
        "title": "DECE",
        "function": "Manage",
        "exam_name": "Expert - Isilon Solutions Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DEE1421-2018Q2&apass=EXPISILONSOLUTIONS",
        "exams": [
            "Expert - Isilon Solutions Exam ; DEE-1421"
        ],
        "level": "Expert",
        "training": "Isilon Solutions",
        "company": "Dell",
        "exam_id": "DEE-1421"
    },
    {
        "title": "DECE",
        "function": "Deploy",
        "exam_name": "Expert - Isilon Solutions Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DEE1421-2018Q2&apass=EXPISILONSOLUTIONS",
        "exams": [
            "Expert - Isilon Solutions Exam ; DEE-1421"
        ],
        "level": "Expert",
        "training": "Isilon Solutions",
        "company": "Dell",
        "exam_id": "DEE-1421"
    },
    {
        "title": "DECS-SA",
        "function": "Manage",
        "exam_name": "Specialist Systems Administrator VxRack System FLEX Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DES6231-2018Q3&apass=SPSAVXRACKFLEXSYS",
        "exams": [
            "Specialist Systems Administrator VxRack System FLEX Exam ; DES-6231"
        ],
        "level": "Specialist",
        "training": "VxRack Systems FLEX",
        "company": "Dell",
        "exam_id": "DES-6231"
    },
    {
        "title": "DECS-SA",
        "function": "",
        "exam_name": "Specialist Systems Administrator VxRack System SDDC Exam",
        "exams": [
            "Specialist Systems Administrator VxRack System SDDC Exam ; DES-6431"
        ],
        "level": "Specialist",
        "training": "VxRack System SDDC",
        "company": "Dell",
        "exam_id": "DES-6431"
    },
    {
        "title": "DECS-SA",
        "function": "Manage",
        "exam_name": "Specialist Systems Administrator VxRail Appliance Exam",
        "url": "https://secure.testcraft.com/emc/assess.aspx?aid=MR-1DP-DES6332-2018Q3&apass=SPSAVXRAILAPP",
        "exams": [
            "Specialist Systems Administrator VxRail Appliance Exam ; DES-6332"
        ],
        "level": "Specialist",
        "training": "VxRail Appliance",
        "company": "Dell",
        "exam_id": "DES-6332"
    }
]

const JUNIPER_CERTIFICATIONS = [
    {
        "title": "SPECIALIST WAN Design, Specialist (JNCDS-WAN)",
        "decription": "Designed for networking professionals and designers with intermediate knowledge of wide area network (WAN) design, theory, and best practices, this written exam verifies the candidate’s understanding of WAN design principles.",
        "examDetails": [
            "Exam code: JN0-1360",
            "Written exam",
            "Administered by Pearson VUE",
            "Exam length: 90 minutes",
            "Exam type: 65 multiple-choice questions",
            "Pass/fail status is available immediately"
        ],
        "prerequisites": [
            "JNCDA"
        ],
        "company": "Juniper",
        "acronym": "JNCDS-WAN"
    },
    {
        "title": "PROFESSIONAL Security, Professional (JNCIP-SEC)",
        "decription": "Designed for experienced networking professionals with advanced knowledge of the Juniper Networks Junos OS for SRX Series devices, this written exam verifies the candidate’s understanding of advanced security technologies and related platform configuration and troubleshooting skills.",
        "examDetails": [
            "Exam code: JN0-634",
            "Written exam",
            "Administered by Pearson VUE",
            "Exam length: 120 minutes",
            "Exam type: 65 multiple choice questions",
            "Pass/fail status is available immediately",
            "Junos Software Release: 15.1"
        ],
        "prerequisites": [
            "JNCIS-SEC"
        ],
        "company": "Juniper",
        "acronym": "JNCIP-SEC"
    },
    {
        "title": "SPECIALIST Enterprise Routing and Switching, Specialist (JNCIS-ENT)",
        "decription": "Designed for experienced networking professionals with beginner to intermediate knowledge of routing and switching implementations in Junos, this written exam verifies the candidate’s basic understanding of routing and switching technologies and related platform configuration and troubleshooting skills.",
        "examDetails": [
            "Exam code: JN0-347",
            "Written exam",
            "Administered by Pearson VUE",
            "Exam length: 90 minutes",
            "Exam type: 65 multiple-choice questions",
            "Pass/fail status is available immediately",
            "Software Release: "
        ],
        "prerequisites": [
            "JNCIA-Junos"
        ],
        "company": "Juniper",
        "acronym": "JNCIS-ENT"
    },
    {
        "title": "SPECIALIST Automation and DevOps, Specialist (JNCIS-DevOps)",
        "decription": "Designed for networking professionals with intermediate knowledge of automation tools and best practices, this written exam verifies the candidate's understanding of the application of scripting tools such as PyEZ, Python, and Ansible to Junos devices and networks.",
        "examDetails": [
            "Exam code: JN0-420",
            "Administered by Pearson VUE",
            "Exam length: 90 minutes",
            "Exam type: 65 multiple-choice questions",
            "Pass/fail status is available immediately",
            "Software Release: 17.3"
        ],
        "prerequisites": [
            "JNCIA-Junos"
        ],
        "company": "Juniper",
        "acronym": "JNCIS-DevOps"
    },
    {
        "title": "PROFESSIONAL Service Provider Routing and Switching, Professional (JNCIP-SP)",
        "decription": "Designed for experienced networking professionals with advanced knowledge of the Juniper Networks Junos OS, this written exam verifies the candidate’s understanding of advanced routing technologies and related platform configuration and troubleshooting skills.",
        "examDetails": [
            "Exam code: JN0-662",
            "Written exam",
            "Administered by Pearson VUE",
            "Exam length: 120 minutes",
            "Exam type: 65 multiple-choice questions",
            "Pass/fail status is available immediately",
            "Junos Software Release: 17.1"
        ],
        "prerequisites": [
            "JNCIS-SP"
        ],
        "company": "Juniper",
        "acronym": "JNCIP-SP"
    },
    {
        "title": "PROFESSIONAL Enterprise Routing and Switching, Professional (JNCIP-ENT)",
        "decription": "JNCIP-ENT exam topics are based on the content of the recommended instructor-led training courses, as well as the additional resources.",
        "examDetails": [
            "Exam code: JN0-647",
            "Written exam",
            "Administered by Pearson VUE",
            "Exam length: 120 minutes",
            "Exam type: 65 multiple-choice questions",
            "Pass/fail status is available immediately",
            "Software Release: "
        ],
        "prerequisites": [
            "JNCIS-ENT"
        ],
        "company": "Juniper",
        "acronym": "JNCIP-ENT"
    },
    {
        "title": "SPECIALIST Service Provider Design, Specialist (JNCDS-SP)",
        "decription": "Designed for networking professionals and designers with intermediate knowledge of service provider design, theory, and best practices, this written exam verifies the candidate’s understanding of service provider design principles.",
        "examDetails": [
            "Exam code: JN0-1361",
            "Written exam",
            "Administered by Pearson VUE",
            "Exam length: 90 minutes",
            "Exam type: 65 multiple-choice questions",
            "Pass/fail status is available immediately"
        ],
        "prerequisites": [
            "JNCDA"
        ],
        "company": "Juniper",
        "acronym": "JNCDS-SP"
    },
    {
        "title": "SPECIALIST Cloud, Specialist (JNCIS-Cloud)",
        "decription": "Designed for networking professionals with intermediate knowledge of software-defined networking, theory, and best practices, this written exam verifies the candidate’s understanding of software-defined networking principles and technologies.",
        "examDetails": [
            "Exam code: JN0-411",
            "Written exam",
            "Administered by Pearson VUE",
            "Exam length: 90 minutes",
            "Exam type: 65 multiple-choice questions",
            "Pass/fail status is available immediately",
            "Contrail Release: 3.2"
        ],
        "prerequisites": [
            "JNCIA-Cloud"
        ],
        "company": "Juniper",
        "acronym": "JNCIS-Cloud"
    },
    {
        "title": "PROFESSIONAL Data Center, Professional (JNCIP-DC)",
        "decription": "Designed for experienced data center networking professionals with advanced knowledge of the Juniper Networks Junos software and data center devices, this written exam verifies the candidate’s understanding of data center technologies, related platform configuration, and troubleshooting skills.",
        "examDetails": [
            "Exam code: JN0-680",
            "Written exam",
            "Administered by Pearson VUE",
            "Exam length: 120 minutes",
            "Exam type: 65 multiple-choice questions",
            "Pass/fail status is available immediately",
            "Junos Software Release: 15.1"
        ],
        "prerequisites": [
            "JNCIS-ENT"
        ],
        "company": "Juniper",
        "acronym": "JNCIP-DC"
    },
    {
        "title": "SPECIALIST Security, Specialist (JNCIS-SEC)",
        "decription": "Designed for experienced networking professionals with intermediate knowledge of the Juniper Networks Junos OS for SRX Series devices, this written exam verifies the candidate’s understanding of security technologies and related platform configuration and troubleshooting skills.",
        "examDetails": [
            "Exam code: JN0-333",
            "Written exam",
            "Administered by Pearson VUE",
            "Exam length: 90 minutes",
            "Exam type: 65 multiple-choice questions",
            "Pass/fail status is available immediately",
            "Junos Software Release: 15.1"
        ],
        "prerequisites": [
            "JNCIA-Junos"
        ],
        "company": "Juniper",
        "acronym": "JNCIS-SEC"
    },
    {
        "title": "ASSOCIATE Automation and DevOps, Associate (JNCIA-DevOps)",
        "decription": "This certification is designed for networking professionals with introductory-level knowledge of automation tools and best practices. The written exam for the certification verifies the candidate’s understanding of DevOps and automation concepts as they pertain to Juniper devices and solutions.",
        "examDetails": [
            "Exam code: JN0-220",
            "Administered by Pearson VUE",
            "Exam length: 90 minutes",
            "Exam type: 65 multiple-choice questions",
            "Scoring and pass/fail status is available immediately",
            "Junos Software Release: 18.1",
            "Python 2.7, and 3.4+",
            "Ansible 2.4"
        ],
        "prerequisites": [
            "None"
        ],
        "company": "Juniper",
        "acronym": "JNCIA-DevOps"
    },
    {
        "title": "ASSOCIATE Design, Associate (JNCDA)",
        "decription": "Designed for networking professionals and designers with beginner knowledge of network design, theory, and best practices, this written exam verifies the candidate's understanding of network design fundamentals.",
        "examDetails": [
            "Exam code: JN0-1100",
            "Administered by Pearson VUE",
            "Exam length: 90 minutes",
            "Exam type: 65 multiple-choice questions",
            "Pass/fail status is available immediately"
        ],
        "prerequisites": [
            "None"
        ],
        "company": "Juniper",
        "acronym": "JNCDA"
    },
    {
        "title": "EXPERT Security, Expert (JNCIE-SEC)",
        "decription": "At the pinnacle of the Junos Security certification track is the 1-day JNCIE-SEC practical exam. This exam is designed to validate the networking professionals’ ability to deploy, configure, manage and troubleshoot JUNOS-based security platforms. Throughout this 8-hour practical exam, candidates will build a secure enterprise network consisting of multiple firewall devices interconnected via IPsec VPNs. Successful candidates will perform system configuration on all devices, configure secure management capabilities, install complex policies and attack prevention features, HA capabilities, IPS features.",
        "examDetails": [
            "Exam code: JPR-932",
            "Lab Exam (Hands-on Lab)",
            "Held at selected Juniper Networks testing centers",
            "Exam length: 8 hours",
            "Junos Software Release: 12.1"
        ],
        "prerequisites": [
            "JNCIP-SEC"
        ],
        "company": "Juniper",
        "acronym": "JNCIE-SEC"
    },
    {
        "title": "SPECIALIST Data Center Design, Specialist (JNCDS-DC)",
        "decription": "Designed for networking professionals and designers with intermediate knowledge of data center network design, theory, and best practices, this written exam verifies the candidate’s understanding of data center network design principles.",
        "examDetails": [
            "Exam code: JN0-1301",
            "Written exam",
            "Administered by Pearson VUE",
            "Exam length: 90 minutes",
            "Exam type: 65 multiple-choice questions",
            "Pass/fail status is available immediately"
        ],
        "prerequisites": [
            "JNCDA"
        ],
        "company": "Juniper",
        "acronym": "JNCDS-DC"
    },
    {
        "title": "EXPERT Service Provider Routing and Switching, Expert (JNCIE-SP)",
        "decription": "At the pinnacle of the Service Provider Routing and Switching certification track is the 1-day JNCIE-SP Lab Exam. This exam is designed to validate the networking professionals’ ability to implement, troubleshoot and maintain Juniper Networks service provider networks. The 8-hour format of this exam requires that candidates build a service provider network consisting of multiple MX series routers. Successful candidates will perform system configuration on all devices, implement various protocols, policies and VPNs, HA capabilities, and Class of Services.",
        "examDetails": [
            "Exam code: JPR-960",
            "Lab Exam (Hands-on Lab)",
            "Held at selected Juniper Networks testing centers",
            "Exam length: 8 hours",
            "Junos Software Release: 12.3"
        ],
        "prerequisites": [
            "JNCIP-SP"
        ],
        "company": "Juniper",
        "acronym": "JNCIE-SP"
    },
    {
        "title": "EXPERT Enterprise Routing and Switching, Expert (JNCIE-ENT)",
        "decription": "At the pinnacle of the Enterprise Routing and Switching certification track is the 1-day JNCIE-ENT practical exam. This exam is designed to validate the networking professionals’ ability to deploy, configure, manage and troubleshoot Junos-based enterprise routing and switching platforms. Throughout this 8-hour practical exam, candidates will build an enterprise network infrastructure consisting of multiple routers and switching devices. Successful candidates will perform system configuration on all devices, configure protocols and features like IPV6, OSPF V2, OSPF V3, BGP, MSDP, PIM, SSM, RSTP, LLDP, 802.1x, CoS, routing policies.",
        "examDetails": [
            "Exam code: JPR-943",
            "Lab Exam (Hands-on Lab)",
            "Held at selected Juniper Networks testing centers",
            "Exam length: 8 hours",
            "Junos Software Release: 11.4"
        ],
        "prerequisites": [
            "JNCIP-ENT"
        ],
        "company": "Juniper",
        "acronym": "JNCIE-ENT"
    },
    {
        "title": "SPECIALIST Security Design, Specialist (JNCDS-SEC)",
        "decription": "Designed for networking professionals and designers with intermediate knowledge of network security, theory, and best practices, this written exam verifies the candidate’s understanding of designing secure networks.",
        "examDetails": [
            "Exam code: JN0-1330",
            "Written exam",
            "Administered by Pearson VUE",
            "Exam length: 90 minutes",
            "Exam type: 65 multiple-choice questions",
            "Pass/fail status is available immediately"
        ],
        "prerequisites": [
            "JNCDA"
        ],
        "company": "Juniper",
        "acronym": "JNCDS-SEC"
    },
    {
        "title": "ASSOCIATE Junos, Associate (JNCIA-Junos)",
        "decription": "Designed for networking professionals with beginner-intermediate knowledge of networking, this written exam verifies the candidate’s understanding of networking fundamentals, as well as core functionality of the Juniper Networks Junos OS.",
        "examDetails": [
            "Exam code: JN0-102",
            "Administered by Pearson VUE",
            "Exam length: 90 minutes",
            "Exam type: 65 multiple-choice questions",
            "Scoring and pass/fail status is available immediately",
            "Junos Software Release: 15.1"
        ],
        "prerequisites": [
            "None"
        ],
        "company": "Juniper",
        "acronym": "JNCIA-Junos"
    },
    {
        "title": "EXPERT Data Center, Expert (JNCIE-DC)",
        "decription": "At the pinnacle of the Data Center certification track is the 1-day JNCIE-DC practical exam. This exam is designed to validate the networking professionals’ ability to deploy, configure, manage, and troubleshoot Junos-based platforms. Throughout this 8-hour practical exam, candidates will build a data center network consisting of multiple MX Series and QFX Series devices. Successful candidates will perform system configuration on all devices including management capabilities, a Clos IP fabric, EVPN/VXVLAN, DCI, and CoS features.",
        "examDetails": [
            "Exam code: JPR-980",
            "Lab Exam (Hands-on Lab)",
            "Held at selected Juniper Networks testing centers",
            "Exam length: 8 hours",
            "Junos Software Release:"
        ],
        "prerequisites": [
            "JNCIP-DC"
        ],
        "company": "Juniper",
        "acronym": "JNCIE-DC"
    },
    {
        "title": "ASSOCIATE Cloud, Associate (JNCIA-Cloud)",
        "decription": "Designed for networking professionals with introductory-level knowledge of Juniper Networks cloud-based networking architectures, theory, and best practices, this written exam verifies the candidate’s understanding of cloud-based networking principles and technologies.",
        "examDetails": [
            "Exam code: JN0-210",
            "Administered by Pearson VUE",
            "Exam length: 90 minutes",
            "Exam type: 65 multiple-choice questions",
            "Pass/fail status is available immediately"
        ],
        "prerequisites": [
            "None"
        ],
        "company": "Juniper",
        "acronym": "JNCIA-Cloud"
    },
    {
        "title": "SPECIALIST Service Provider Routing and Switching, Specialist (JNCIS-SP)",
        "decription": "Designed for experienced networking professionals with beginner to intermediate knowledge of routing and switching implementations in Junos, this written exam verifies the candidate’s basic understanding of routing and switching technologies and related platform configuration and troubleshooting skills.",
        "examDetails": [
            "Exam code: JN0-361",
            "Written exam",
            "Administered by Pearson VUE",
            "Exam length: 90 minutes",
            "Exam type: 65 multiple-choice questions",
            "Pass/fail status is available immediately",
            "Junos Software Release: 17.3"
        ],
        "prerequisites": [
            "JNCIA-Junos"
        ],
        "company": "Juniper",
        "acronym": "JNCIS-SP"
    }
]

const ISTQB_CERTIFICATIONS = [
    {
      "title": "Certified Tester Foundation Level",
      "acronym": "CTFL"
    },
    {
      "title": "Test Analyst",
      "acronym": "CTAL-AT"
    },
    {
      "title": "Test Manager",
      "acronym": "CTAL-TM"
    },
    {
      "title": "Technical Test Analyst",
      "acronym": "CTAL-TA"
    },
    {
      "title": "Agile Test Leadership at Scale",
      "acronym": "ATLS"
    },
    {
      "title": "AI Testing",
      "acronym": "AIT"
    },
    {
      "title": "Game Testing",
      "acronym": "GT"
    },
    {
      "title": "Agile Tester (Foundation)",
      "acronym": "ATF"
    },
    {
      "title": "Agile Technical Tester (Advanced)",
      "acronym": "ATTA"
    },
    {
      "title": "Assessing Test Processes",
      "acronym": "ATP"
    },
    {
      "title": "Implementing Test Process Improvement",
      "acronym": "TPI"
    },
    {
      "title": "Strategic Test Management",
      "acronym": "STM"
    },
    {
      "title": "Operational Test Management",
      "acronym": "OTM"
    },
    {
      "title": "Managing the Test Team",
      "acronym": "MTT"
    }
  ]

const ISACA_CERTIFICATIONS = [
    {
      "title": "Certified Information Systems Auditor",
      "acronym": "CISA"
    },
    {
      "title": "Certified Information Security Manager",
      "acronym": "CISM"
    },
    {
      "title": "Certified in Risk and Information Systems Control",
      "acronym": "CRISC"
    },
    {
      "title": "Certified Data Privacy Solutions Engineer",
      "acronym": "CDPSE"
    },
    {
      "title": "Certified in the Governance of Enterprise IT",
      "acronym": "CGEIT"
    },
    {
      "title": "CSX Cybersecurity Practitioner",
      "acronym": "CSX-P"
    },
    {
      "title": "Information Technology Certified Associate",
      "acronym": "ITCA"
    },
    {
      "title": "Certified in Emerging Technology",
      "acronym": "CET"
    },
    {
      "title": "IT Audit Fundamentals Certificate",
      "acronym": "IAFC"
    },
    {
      "title": "Cloud Fundamentals Certificate",
      "acronym": "CFC"
    },
    {
      "title": "IT Risk Fundamentals Certificate",
      "acronym": "IRFC"
    },
    {
      "title": "IT Risk Fundamentals Certificate",
      "acronym": "IRFC"
    },
    {
      "title": "Blockchain Fundamentals Certificate",
      "acronym": "BFC"
    },
    {
      "title": "Certificate of Cloud Auditing Knowledge",
      "acronym": "CCAK"
    },
    {
      "title": "IoT Fundamentals Certificate",
      "acronym": "IFC"
    },
    {
      "title": "Cybersecurity Audit Certificate",
      "acronym": "CAC"
    },
    {
      "title": "Artificial Intelligence Fundamentals Certificate",
      "acronym": "AIFC"
    },
    {
      "title": "Computing Fundamentals Certificate",
      "acronym": "CFC"
    },
    {
      "title": "COBIT Design and Implementation",
      "acronym": "CDI"
    },
    {
      "title": "Networks and Infrastructure Fundamentals",
      "acronym": "NIF"
    },
    {
      "title": "Networks and Infrastructure Fundamentals Certificate",
      "acronym": "NIFFC"
    },
    {
      "title": "Implementing the NIST Cybersecurity Framework Using COBIT 2019",
      "acronym": "ICFC"
    },
    {
      "title": "Cybersecurity Fundamentals Certificate",
      "acronym": "CFC"
    },
    {
      "title": "COBIT Foundation",
      "acronym": "CFR"
    },
    {
      "title": "Software Development Fundamentals Certificate",
      "acronym": "SDFC"
    },
    {
      "title": "COBIT 5 Certificates",
      "acronym": "COBIT 5"
    },
    {
      "title": "Data Science Fundamentals Certificate",
      "acronym": "DSFC"
    }
  ]

const APM_CERTIFICATIONS = [
    {
      "title": "APM Project Fundamentals Qualification (PFQ)",
      "acronym": "PFQ"
    },
    {
      "title": "APM Project Management Qualification (PMQ)",
      "acronym": "PMQ"
    },
    {
      "title": "APM Project Professional Qualification (PPQ)",
      "acronym": "PPQ"
    },
    {
      "title": "Project Management Chartered Standard (ChPP)",
      "acronym": "ChPP"
    }
  ]

const PRINCE2_CERTIFICATIONS = [
    {
      "title": "PRINCE2 Foundation",
      "acronym": "PRINCE2-F"
    },
    {
      "title": "PRINCE2 Agile Foundation",
      "acronym": "PRINCE2-AF"
    },
    {
      "title": "PRINCE2 Practitioner",
      "acronym": "PRINCE2-P"
    },
    {
      "title": "PRINCE2 Agile Practitioner",
      "acronym": "PRINCE2-AP"
    }
  ]

const PMI_CERTIFICATIONS = [
    {
      "title": "Project Management Professional (PMP)® Certification",
      "acronym": "PMP"
    },
    {
      "title": "Certified Associate in Project Management (CAPM)® certification",
      "acronym": "CAPM"
    },
    {
      "title": "PMI Agile Certified Practitioner (PMI-ACP)®",
      "acronym": "PMI-ACP"
    },
    {
        "title": "Disciplined Agile Scrum Master (DASM) Certification",
        "acronym": "DASM"
    },
    {
      "title": "Disciplined Agile Scrum Master (DASM) Certification",
      "acronym": "DASSM"
    },
    {
      "title": "Disciplined Agile Value Stream Consultant (DAVSC) Certification",
      "acronym": "DAVSC"
    },
    {
      "title": "Disciplined Agile Coach (DAC) Certification",
      "acronym": "DAC"
    },
    {
      "title": "Agile Hybrid Project Pro",
      "acronym": "AHPP"
    },
    {
      "title": "Citizen Developer Practitioner",
      "acronym": "CDP"
    },
    {
      "title": "Citizen Developer Business Architect",
      "acronym": "CDBA"
    },
    {
      "title": "Organizational Transformation Foundation",
      "acronym": "OTF"
    },
    {
      "title": "Organizational Transformation Implementation",
      "acronym": "OTI"
    },
    {
      "title": "Organizational Transformation Orchestration",
      "acronym": "OTO"
    },
    {
      "title": "Agile Metrics Micro-Credential",
      "acronym": "AMC"
    },
    {
      "title": "Built Environment Project Communication Pro",
      "acronym": "BEPCP"
    },
    {
      "title": "Built Environment Performance and Materials Management Pro",
      "acronym": "BEPPMP"
    },
    {
      "title": "Built Environment Technology and Innovation Pro",
      "acronym": "BETIP"
    },
    {
      "title": "Value Stream Management Micro-Credential",
      "acronym": "VSMC"
    }
  ]

const IPM_CERTIFICATIONS = [
    {
      "title": "Certified Project Management Diploma",
      "acronym": "CPMD"
    },
    {
      "title": "Strategic Project Programme Management Diploma",
      "acronym": "SPPMD"
    },
    {
      "title": "Project Leadership & Management Diploma",
      "acronym": "PLMD"
    }
  ]

const IAPM_CERTIFICATIONS = [
    {
      "title": "Certified Projects Director",
      "acronym": "CPD"
    },
    {
      "title": "Certified Senior Project Manager",
      "acronym": "CSPM"
    },
    {
      "title": "Certified Project Manager",
      "acronym": "CPM"
    },
    {
      "title": "Certified Project Management Associate",
      "acronym": "CPMA"
    }
  ]

export const CERTIFICATIONS_LIST = CISCO_CERTIFICATIONS
                .concat(DELL_CERTIFICATIONS)
                .concat(MICROSOFT_CERTIFICATIONS)
                .concat(JUNIPER_CERTIFICATIONS)
                .concat(ORACLE_CERTIFICATIONS)
                .concat(IAPM_CERTIFICATIONS)
                .concat(PRINCE2_CERTIFICATIONS)
                .concat(PMI_CERTIFICATIONS)
                .concat(IPM_CERTIFICATIONS)
                .concat(APM_CERTIFICATIONS)
                .concat(ISACA_CERTIFICATIONS)
                .concat(ISTQB_CERTIFICATIONS)
