import React from "react"
import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  BarController
} from "chart.js"
import { Chart } from "react-chartjs-2"
ChartJS.defaults.font.family = "Inter, sans-serif"

ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  BarController
)

const LineBarChart = ({ labels, datasets }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index"
    },
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        type: "time",
        offsetAfterAutoskip: true,
        time: {
          unit: "month",
          displayFormats: {
            month: "MMM YY"
          },
          tooltipFormat: "MM-DD-YYYY",
          bounds: "data"
        },
        ticks: {
          source: "data"
        }
      },
      y: {
        grid: {
          color: "#F2F2F2"
        }
      }
    }
  }

  const data = {
    labels,
    datasets
  }
  return <Chart type="bar" options={options} data={data} />
}

export default LineBarChart
