import React from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import { routes } from "../../Router"
import CookiesModal from "../../components/AuthModals/CookiesModal"

const Auth = () => {
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth" || prop.layout === '/microsoft-oauth') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  }

  return (
    <>
      <Switch>
        {getRoutes(routes)}
        <Route exact path="/">
          <Redirect to="/auth/login" />
        </Route>
        <Route exact path="/auth">
          <Redirect to="/auth/login" />
        </Route>
      </Switch>
      <CookiesModal />
    </>
  )
}

export default React.memo(Auth)
