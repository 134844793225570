import React from "react"

import "./style.scss"

const AvatarBadge = ({ name }) => {
  const [firstName, lastName] = name?.split(" ") || []

  return (
    <div className="avatar flex-justify-center">
      {firstName?.length > 0 && firstName[0]}
      {lastName?.length > 0 && lastName[0]}
    </div>
  )
}

export default AvatarBadge
