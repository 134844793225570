import { all, call, put, takeLatest } from "redux-saga/effects";
import { BASE_URL } from "../../../../config/app";
import XHR from "../../../../utils/XHR";
import { POST_CONFIRM_PASSWORD } from "./type";
import {
  postConfirmPasswordSuccess,
  postConfirmPasswordFailure
} from "./action";
import toast from "react-hot-toast";
import { displayOTPModal } from "../../SignUp/redux/action";

async function postConfirmAPI(data) {
  const URL = `${BASE_URL}rest-auth/password/reset/confirm/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* postConfirmInfo({ data, history }) {
  try {
    const response = yield call(postConfirmAPI, data)
    yield put(postConfirmPasswordSuccess(response.data))
    history.push('/auth/login')
    toast.success('Password Reset Successful', {
      id: 'card fields',
    })
    yield put(displayOTPModal(false))
  } catch (e) {
    const { response } = e
    yield put(postConfirmPasswordFailure(response.data))
    if (response.status == 404) {
      yield put(postConfirmPasswordFailure({ token: "Invalid token" }))
    }
  }
}

export default all([
  takeLatest(POST_CONFIRM_PASSWORD, postConfirmInfo)
])