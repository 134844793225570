import { connect } from "react-redux"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"

import Button from "../../../Button"
import UCheckbox from "../../../UCheckbox"
import UDatePicker from "../../../UDatePicker"
import UInput from "../../../UInput"
import { useForm } from "../../../../hooks"
import {
  profileProjectFailure,
  profileProjectRequest,
  deleteProfileProjectRequest
} from "../../../../pages/CandidateScreens/Profile/redux/action"
import validator from "../../../../utils/validation"
import { validateStartEndDate } from "../../../../utils/helper"

const ProfileProjects = props => {
  const {
    profileProjectFailure,
    profileProjectRequest,
    BeError,
    requesting,
    editProfileProject,
    selectedId,
    deleteProfileProject,
    deleteRequesting
  } = props
  const selectedProject = editProfileProject?.find(
    item => item?.id === selectedId
  )

  const [stillWorking, setStillWorking] = useState(
    selectedProject
      ? selectedProject.still_working || !selectedProject.end_date
      : undefined
  )

  const handleCheckboxChange = () => {
    setStillWorking(!stillWorking)
  }

  const stateSchema = {
    projectName: {
      value: selectedProject?.project_name,
      error: ""
    },
    projectUrl: {
      value: selectedProject?.project_url,
      error: ""
    },
    description: {
      value: selectedProject?.description,
      error: ""
    },
    startDate: {
      value: selectedProject?.start_date,
      error: ""
    },
    endDate: {
      value: selectedProject?.end_date,
      error: ""
    }
  }

  const validationStateSchema = {
    projectName: {
      required: true
    },
    projectUrl: {
      required: false
    },
    description: {
      required: true
    },
    startDate: {
      required: true
    },
    endDate: {
      required: false,
      validator: validator.requiredIf(() => {
        return stillWorking === false
      })
    }
  }
  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  useEffect(() => {
    if (
      !stillWorking &&
      !validateStartEndDate(state.startDate?.value, state.endDate.value)
    ) {
      setState(prevState => ({
        ...prevState,
        startDate: {
          value: state?.startDate.value,
          error: "Start date must be earlier than end date"
        }
      }))
    } else {
      setState(prevState => ({
        ...prevState,
        startDate: {
          value: state?.startDate.value,
          error: ""
        }
      }))
    }
  }, [state?.startDate.value, state.endDate.value, setState, stillWorking])

  useEffect(() => {
    handleOnDateChange("endDate")(state?.endDate?.value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stillWorking, state?.endDate?.value])

  const handleOnDateChange = field => newValue => {
    handleOnChange(field, newValue)
  }

  const Submit = () => {
    const data = {
      id: selectedId,
      project_name: state.projectName.value,
      project_url: state.projectUrl.value || null,
      description: state.description.value || null,
      start_date: `${dayjs(state.startDate.value).format("YYYY-MM-DD")}`,
      end_date: `${dayjs(state.endDate.value).format("YYYY-MM-DD")}`,
      still_working: stillWorking
    }
    profileProjectRequest(data)
  }

  useEffect(() => {
    profileProjectFailure(false)
  }, [profileProjectFailure])

  const clearError = () => profileProjectFailure(false)

  const handleInputOnChange = field => e =>
    handleOnChange(field, e.target.value)

  const deleteProjects = () => {
    deleteProfileProject({ id: selectedId })
  }

  return (
    <div className="profile-body-input-wrapper profile-info-input-wrapper">
      <div className="input-wrapper">
        <div className="info-text">
          Please include any company, team, or personal projects you have been
          involved with to provide a better understanding of your wider
          experience.
        </div>
        <UInput
          placeholder="Project Name"
          onChange={handleInputOnChange("projectName")}
          onFocus={clearError}
          errorText={BeError?.name || state.projectName.error}
          errorClass={
            BeError?.name || state.projectName.error ? "opacity-1" : ""
          }
          inputError={BeError?.name || state.projectName.error}
          value={state.projectName.value}
        />
        <UInput
          placeholder="URL"
          onChange={handleInputOnChange("projectUrl")}
          onFocus={clearError}
          errorText={BeError?.project_url || state.projectUrl.error}
          errorClass={
            BeError?.project_url || state.projectUrl.error ? "opacity-1" : ""
          }
          inputError={BeError?.project_url || state.projectUrl.error}
          value={state.projectUrl.value}
        />
        <UDatePicker
          placeholderValue={"Start Date"}
          value={state.startDate.value || null}
          onChange={handleOnDateChange("startDate")}
          maxDate={dayjs()}
          views={["year", "month"]}
          inputFormat="MMM, YYYY"
        />
        <div
          className={`error-message
            ${
              BeError?.start_date || state.startDate?.error
                ? "opacity-1"
                : stillWorking && "display-hidden"
            }`}
        >
          {BeError?.start_date || state.startDate?.error}
        </div>
        {!stillWorking && (
          <>
            <UDatePicker
              placeholderValue={"End Date"}
              value={state.endDate.value || null}
              onChange={handleOnDateChange("endDate")}
              maxDate={dayjs()}
              views={["year", "month"]}
              inputFormat="MMM, YYYY"
            />
            <div
              className={`error-message
              ${
                BeError?.end_date || state.endDate?.error
                  ? "opacity-1"
                  : "display-hidden"
              }`}
            >
              {BeError?.end_date || state.endDate?.error}
            </div>
          </>
        )}
        <UCheckbox
          label="Use Current"
          onChange={handleCheckboxChange}
          checked={stillWorking}
          value={true}
        />
        <UInput
          placeholder="Description"
          multiline
          minRows={1}
          maxRows={6}
          value={state.description.value}
          onChange={handleInputOnChange("description")}
          onFocus={clearError}
        />
      </div>
      <div className="button-wrapper">
        {selectedId && (
          <Button
            title="Delete"
            className="delete-btn"
            onClick={deleteProjects}
            showSpinner={deleteRequesting}
          />
        )}
        <Button
          title="Save"
          className={`${disable ? "disabled-btn" : ""}`}
          onClick={Submit}
          showSpinner={requesting}
          disabled={disable ? true : false}
        />
      </div>
    </div>
  )
}
const mapStateToProps = state => ({
  requesting: state.profile.requesting,
  BeError: state.profile.error,
  deleteRequesting: state.profile.deleteRequesting
})

const mapDispatchToProps = dispatch => ({
  profileProjectRequest: data => dispatch(profileProjectRequest(data)),
  profileProjectFailure: data => dispatch(profileProjectFailure(data)),
  deleteProfileProject: data => dispatch(deleteProfileProjectRequest(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileProjects)
