import { GET_PROFILE_DATA_REQUEST, GET_PROFILE_DATA_SUCCESS } from "./type"

// POST_PAYMENT_INFORMATION

export const getProfileDataRequest = data => ({
  type: GET_PROFILE_DATA_REQUEST,
  data
})
export const getProfileDataSuccess = data => ({
  type: GET_PROFILE_DATA_SUCCESS,
  data
})
