import { all, call, put, takeLatest } from "redux-saga/effects"
// config
import { BASE_URL } from "../../../../config/app"
// utils
import XHR from "../../../../utils/XHR"

import { GET_ALL_USERS_REQUEST, DELETE_USER_REQUEST } from "./type"

import {
  getAllUsersSuccess,
  getAllUsersFailure,
  deleteUserSuccess,
  deleteUserFailure
} from "./action"
import toast from "react-hot-toast"

async function getAdminAllUsersAPI(
  data = { page: 1, limit: 10, searchParam: "", sortBy: "", sortDir: "desc" }
) {
  const { page, limit, searchParam, sortBy, sortDir } = data
  const searchString = searchParam ? `&searchParams=${searchParam}` : ""
  const queryString = `?page=${page}&limit=${limit}&sortBy=${sortBy}&sortDir=${sortDir}${searchString}`
  const URL = `${BASE_URL}api/v1/admin/allusers/${queryString}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getAdminUsersData({ data }) {
  try {
    const response = yield call(getAdminAllUsersAPI, { ...data })
    yield put(getAllUsersSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getAllUsersFailure(response.data))
  }
}

async function adminDeleteUserAPI(data) {
  const { user_id } = data
  const URL = `${BASE_URL}api/v1/admin/allusers/${user_id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "DELETE"
  }
  return XHR(URL, options)
}

function* deleteUserData({ data }) {
  try {
    const response = yield call(adminDeleteUserAPI, { ...data })
    toast.success(response?.data?.msg)
    yield put(deleteUserSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(deleteUserFailure(response.data))
    toast.success(response?.data?.error)
  }
}

export default all([
  takeLatest(GET_ALL_USERS_REQUEST, getAdminUsersData),
  takeLatest(DELETE_USER_REQUEST, deleteUserData)
])
