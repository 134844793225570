import ClearIcon from "@mui/icons-material/Clear"
import IconButton from "@mui/material/IconButton"
import InputBase from "@mui/material/InputBase"
import Paper from "@mui/material/Paper"
import React, { useState } from "react"
import SearchIcon from "@mui/icons-material/Search"

import Button from "../../components/Button"

import "./style.scss"

const USearch = ({
  onClick,
  searchRequesting,
  placeholder = "",
  title = "",
  hideButton = false
}) => {
  const [inputValue, setInputValue] = useState("")

  const handleInputChange = e => {
    setInputValue(e.target.value)
    hideButton && typeof onClick === "function" && onClick(e.target.value)
  }

  const handleOnSearch = () => {
    typeof onClick === "function" && onClick(inputValue)
  }

  const clearInput = () => {
    setInputValue("")
    typeof onClick === "function" && onClick("")
  }

  const handleKeyDown = e => {
    if (e?.key === "Enter") {
      e?.preventDefault()
      handleOnSearch()
    }
  }

  return (
    <div className="search-field-wrapper">
      <Paper
        component="form"
        className="paper-class"
        sx={{ display: "flex", alignItems: "center", width: 400 }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder={placeholder}
          onChange={handleInputChange}
          value={inputValue}
          onKeyDown={handleKeyDown}
        />
        <IconButton
          type="button"
          sx={{ p: "10px" }}
          aria-label="search"
          className={inputValue ? "clear-icon-btn" : "search-icon-btn"}
          disableRipple={inputValue ? false : true}
          onClick={inputValue ? clearInput : handleOnSearch}
        >
          {inputValue ? <ClearIcon /> : <SearchIcon />}
        </IconButton>
      </Paper>
      {!hideButton && (
        <Button
          title={title && title.length ? title : "Search"}
          className="custom-search"
          onClick={handleOnSearch}
          showSpinner={searchRequesting}
        />
      )}
    </div>
  )
}

export default USearch
