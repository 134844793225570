import React from "react"

// Components
import Button from "../../../Button"

// Redux
import { postApplyJobRequest } from "../../../../pages/CandidateScreens/Home/redux/action"
import { connect } from "react-redux"

const ApplyJob = props => {
  const { postApplyJobRequest, requesting, setShowModal, jobData } = props

  const Submit = async () => {
    postApplyJobRequest(jobData, closeModal)
  }

  const closeModal = () => setShowModal(false)

  return (
    <div className="candidate-modal-body-wrapper column-flex flex-justify-center">
      <br />
      <div className="main-text text-center">
        Are you sure you want to apply to the <strong>{jobData?.title}</strong>
        &nbsp; role?
      </div>
      <div className="button-wrapper flex-align-center">
        <Button
          title="Cancel"
          className="transparent-btn"
          onClick={closeModal}
        />
        <Button
          title="Apply"
          className="save-btn"
          onClick={Submit}
          showSpinner={requesting}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  postApplyJobInfo: state.candidateJobs.postApplyJobInfo,
  requesting: state.candidateJobs.applyJobRequesting
})

const mapDispatchToProps = dispatch => ({
  postApplyJobRequest: (data, callback) =>
    dispatch(postApplyJobRequest(data, callback))
})

export default connect(mapStateToProps, mapDispatchToProps)(ApplyJob)
