import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ArrowRightIcon from "@mui/icons-material/ArrowRight"
import MenuOpenIcon from "@mui/icons-material/MenuOpen"
import React, { useEffect, useState } from "react"
import {
  Menu,
  MenuItem,
  ProSidebarProvider,
  Sidebar,
  SubMenu,
  useProSidebar
} from "react-pro-sidebar"
import Image from "../Image"

// Routing
import { useLocation } from "react-router-dom"
import { routes } from "../../Router"

// Redux
import { logOut } from "../../pages/AuthScreens/Login/redux/action"
import { history } from "../../reduxStore/store"
import { connect } from "react-redux"

// Style
import "./style.scss"
import Logo from "../Logo"

const SidebarLayout = props => {
  const { userType } = props
  const { broken, toggleSidebar } = useProSidebar()
  const [activeElement, setActiveElement] = useState("")
  const [activeSection, setActiveSection] = useState("")
  const location = useLocation()

  useEffect(() => {
    setActiveElement(location.pathname)
    setActiveSection(location?.state?.activeSection?.section)
  }, [location])

  const goToPage = (page, section) => () => {
    setActiveSection(section)
    history.push(page, { activeSection: { activeElement, section } })
  }

  const renderSubMenu = (subMenuItem, item) => {
    const active =
      item.layout + item.path === activeElement &&
      activeSection === subMenuItem.section

    return (
      <MenuItem
        className={`sidebar__menu__sub-menu__item ${
          active ? "sidebar__menu__sub-menu__item--active" : ""
        }`}
        key={`${item.layout}-${item.path}-${subMenuItem.section}`}
        onClick={goToPage(item.layout + item.path, subMenuItem?.section)}
      >
        {subMenuItem?.name}
      </MenuItem>
    )
  }

  const renderMenuItem = item => {
    const isActive = item.layout + item.path === activeElement

    if (item.isShow === userType) {
      return item?.subMenu?.length > 0 ? (
        <SubMenu
          open={isActive}
          icon={
            <Image
              src={isActive ? item.activeIconSrc : item.iconSrc}
              alt={item.name}
            />
          }
          label={item.name}
          className={`sidebar__menu__sub-menu ${
            isActive ? "sidebar__menu__item--active" : ""
          }`}
          onClick={goToPage(item.layout + item.path, item?.subMenu[0]?.section)}
          key={`${item.layout}-${item.path}`}
        >
          {item?.subMenu?.map(subMenuItem => renderSubMenu(subMenuItem, item))}
        </SubMenu>
      ) : (
        <MenuItem
          className={`sidebar__menu__item ${
            isActive ? "sidebar__menu__item--active" : ""
          }`}
          icon={
            <Image
              src={isActive ? item.activeIconSrc : item.iconSrc}
              alt={item.name}
            />
          }
          onClick={goToPage(item.layout + item.path)}
          key={`${item.layout}-${item.path}`}
        >
          {item.name}
        </MenuItem>
      )
    }
    return null
  }

  const renderExpandIcon = ({ open }) => (
    <span>{open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}</span>
  )

  return (
    <>
      <Sidebar customBreakPoint="980px" className="sidebar">
        {
          //< Image src={Images.logo} className="sidebar__logo" />
        }
        <div className="sidebar__logo">
          <Logo fontSize="1.5rem" />
        </div>
        <div className="sidebar__menu">
          <Menu renderExpandIcon={renderExpandIcon}>
            {routes.map(renderMenuItem)}
          </Menu>
        </div>
      </Sidebar>
      {broken && (
        <div className="toggle-sidebar-icon">
          <MenuOpenIcon onClick={toggleSidebar} />
        </div>
      )}
    </>
  )
}

const SidebarMenu = ({ logOut, userType }) => {
  return (
    <ProSidebarProvider>
      {<SidebarLayout logOut={logOut} userType={userType} />}
    </ProSidebarProvider>
  )
}
const mapStateToProps = state => ({
  userType: state.login.userType
})

const mapDispatchToProps = dispatch => ({
  logOut: data => dispatch(logOut(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu)
