import {
  GET_JOB_SKILLS_SCORE_REQUEST,
  GET_JOB_SKILLS_SCORE_SUCCESS,
  FETCH_JOB_DATA_BY_ID_REQUEST,
  FETCH_JOB_DATA_BY_ID_REQUEST_SUCCESS,
  FETCH_JOB_DATA_BY_ID_REQUEST_FAILURE
} from "./type"

export const getSkillsScoreRequest = data => ({
  type: GET_JOB_SKILLS_SCORE_REQUEST,
  data
})

export const getSkillsScoreSuccess = data => ({
  type: GET_JOB_SKILLS_SCORE_SUCCESS,
  data
})

  // job data by id
export const getJobDataByIdRequest = data => ({
  type: FETCH_JOB_DATA_BY_ID_REQUEST,
  data
})

export const getJobDataByIdRequestSuccess = data => ({
  type: FETCH_JOB_DATA_BY_ID_REQUEST_SUCCESS,
  data
})

export const getJobDataByIdRequestFailure = data => ({
  type: FETCH_JOB_DATA_BY_ID_REQUEST_FAILURE,
  data
})
