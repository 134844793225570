import {
  POST_REQUEST_LOGIN,
  POST_REQUEST_LOGIN_SUCCESS,
  POST_REQUEST_LOGIN_FAILURE,
  SET_ACCESS_TOKEN,
  USER_STATE,
  LOGOUT,
  LOGIN_REQUEST_GOOGLE,
  LOGIN_REQUEST_MICROSOFT,
  LOGIN_REQUEST_GOOGLE_SUCCESS,
  LOGIN_REQUEST_MICROSOFT_SUCCESS,
  LOGIN_REQUEST_GOOGLE_FAILURE,
  LOGIN_REQUEST_MICROSOFT_FAILURE,
  POST_DELETE_ACCOUNT_REQUEST,
  POST_DELETE_ACCOUNT_REQUEST_SUCCESS,
  POST_DELETE_ACCOUNT_REQUEST_FAILURE,
  SET_USER_TYPE,
  GET_USER_TYPE
} from "./type"

// POST_PAYMENT_INFORMATION
export const loginRequest = data => ({
  type: POST_REQUEST_LOGIN,
  data
})

export const loginRequestSuccess = data => ({
  type: POST_REQUEST_LOGIN_SUCCESS,
  data
})

export const loginRequestFailure = data => ({
  type: POST_REQUEST_LOGIN_FAILURE,
  data
})
export const userState = data => ({
  type: USER_STATE,
  data
})

export const setAccessToken = accessToken => ({
  type: SET_ACCESS_TOKEN,
  accessToken
})

export const logOut = () => ({
  type: LOGOUT
})

export const googleLoginRequest = data => ({
  type: LOGIN_REQUEST_GOOGLE,
  data
})
export const googleLoginRequestSuccess = data => ({
  type: LOGIN_REQUEST_GOOGLE_SUCCESS,
  data
})
export const googleLoginRequestFailure = () => ({
  type: LOGIN_REQUEST_GOOGLE_FAILURE
})

//Microsoft Changes
export const microsoftLoginRequest = data => ({
  type: LOGIN_REQUEST_MICROSOFT,
  data
})
export const microsoftLoginRequestSuccess = data => ({
  type: LOGIN_REQUEST_MICROSOFT_SUCCESS,
  data
})
export const microsoftLoginRequestFailure = () => ({
  type: LOGIN_REQUEST_MICROSOFT_FAILURE
})

//Delete Account

export const deleteAccount = (data, history) => ({
  type: POST_DELETE_ACCOUNT_REQUEST,
  data,
  history
})

export const deleteAccountSuccess = data => ({
  type: POST_DELETE_ACCOUNT_REQUEST_SUCCESS,
  data
})

export const deleteAccountFailure = error => ({
  type: POST_DELETE_ACCOUNT_REQUEST_FAILURE,
  error
})

export const setUserType = data => ({
  type: SET_USER_TYPE,
  data
})

export const getUserType = data => ({
  type: GET_USER_TYPE,
  data
})
