import Modal from "react-bootstrap/Modal"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"

import Button from "../Button"
import useIdleTimeout from "../../hooks/IdleTimeout"
import { logOut } from "../../pages/AuthScreens/Login/redux/action"
import { IDLE_TIME_BEFORE_SESSION_EXPIRES_MINS } from '../../utils/constants'
import "./style.scss"

const IdleTimeoutModal = ({ logOutDispatch }) => {
  const [openModal, setOpenModal] = useState(false)

  const handleIdle = () => {
    setOpenModal(true)
  }

  const { idleTimer } = useIdleTimeout({
    onIdle: handleIdle,
    idleTime: IDLE_TIME_BEFORE_SESSION_EXPIRES_MINS,
    logout: logOutDispatch
  })

  useEffect(() => {
    if (localStorage.getItem("rememberMe")) {
      idleTimer.pause()
    }
  }, [idleTimer])

  const stay = () => {
    setOpenModal(false)
    idleTimer.reset()
  }
  const handleLogout = () => {
    logOutDispatch()
    setOpenModal(false)
  }

  return (
    <Modal
      show={openModal}
      onHide={stay}
      className="uiba-modal idle-timeout-modal"
    >
      <Modal.Header className="idle-timeout-modal-header" closeButton>
        <Modal.Title>Your session is about to expire</Modal.Title>
      </Modal.Header>
      <Modal.Body className="idle-timeout-modal-body-wrapper">
        <div className="main-title idle-timeout-modal-body-wrapper__title">
          <p>
            Your session is about to expire. You'll be automatically signed out.
            Do you want to stay signed in?
          </p>
        </div>
        <div className="button-wrapper flex-justify-center">
          <Button
            onClick={handleLogout}
            className="uiba-btn button-wrapper__confirm-btn"
            title="Sign out now"
          />
          <Button
            onClick={stay}
            title="Stay signed in"
            className="uiba-btn button-wrapper__update-confirm-btn"
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}

const mapDispatchToProps = dispatch => ({
  logOutDispatch: data => dispatch(logOut(data))
})

export default connect(null, mapDispatchToProps)(IdleTimeoutModal)
