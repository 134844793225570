import { all, call, put, takeLatest } from "redux-saga/effects"

import { BASE_URL } from "../../../../config/app"
import {
  GET_JOB_SKILLS_SCORE_REQUEST,
  FETCH_JOB_DATA_BY_ID_REQUEST,
} from './type'
import {
  getSkillsScoreSuccess,
  getJobDataByIdRequestSuccess,
  getJobDataByIdRequestFailure,
} from "./action"
import XHR from "../../../../utils/XHR"

const getSkillsScoreAPI = async data => {
  const URL = `${BASE_URL}api/v1/recruiter/getskillscore/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* getSkillsScoreData({ data }) {
  try {
    const response = yield call(getSkillsScoreAPI, data)
    yield put(getSkillsScoreSuccess(response.data))
  } catch (e) {
    yield put(getSkillsScoreSuccess([]))
  }
}

async function fetchJobInfoById(data) {
  const jobId = data?.jobId?? ""
  const URL = `${BASE_URL}api/v1/recruiter/job/?job_id=${jobId}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET",
    data
  }
  return XHR(URL, options)
}

function* getJobByID({ data }) {
  try {
    const response = yield call(fetchJobInfoById, data)
    yield put(getJobDataByIdRequestSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(getJobDataByIdRequestFailure(response?.data))
  }
}

export default all([
  takeLatest(GET_JOB_SKILLS_SCORE_REQUEST, getSkillsScoreData),
  takeLatest(FETCH_JOB_DATA_BY_ID_REQUEST, getJobByID)
])
