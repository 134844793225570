import React, { useEffect } from "react"

// Components
import Button from "../../../Button"
import USelect from "../../../USelect"
import Stack from "@mui/material/Stack"

import { Images } from "../../../../theme/Images"
import Image from "../../../Image"

import {
  profileHobbiesFailure,
  profileHobbiesRequest
} from "../../../../pages/CandidateScreens/Profile/redux/action"
import { connect } from "react-redux"
import { useMetaData } from "../../../../hooks"
import { useForm } from "../../../../hooks"

const ProfileHobbies = props => {
  const {
    profileHobbiesFailure,
    profileHobbiesRequest,
    requesting,
    editedHobbiesData,
    BeError
  } = props

  useEffect(() => {
    profileHobbiesFailure(false)
  }, [profileHobbiesFailure])

  const { hobbiesList } = useMetaData()

  const profileHobbies = editedHobbiesData?.map(item => ({
    key: item?.hobby,
    label: item?.hobby
  }))

  const stateSchema = {
    hobbies: {
      value: profileHobbies,
      error: ""
    }
  }

  const validationStateSchema = {
    hobbies: {
      required: false
    }
  }
  const { state, handleOnChange, disable } = useForm(
    stateSchema,
    validationStateSchema
  )

  const handleDelete = val => () => {
    const selectedHobbies = state?.hobbies?.value?.filter(
      item => item.key !== val.key
    )
    handleOnChange(
      "hobbies",
      selectedHobbies?.length > 0 ? selectedHobbies : null
    )
  }

  const handleSelectOption = (event, value) => {
    if (!value) return false
    value = typeof value === "string" ? { label: value, key: value } : value

    if (
      state?.hobbies?.value?.find(
        item => item?.key?.toLowerCase() === value?.key?.toLowerCase()
      )
    ) {
      return false
    }
    const selected = state?.hobbies?.value?.length
      ? [...state?.hobbies?.value, value]
      : [value]

    handleOnChange("hobbies", selected)
  }

  const Submit = () => {
    const hobbies = state?.hobbies?.value?.map(item => item.label)
    profileHobbiesRequest({ hobbies })
  }

  return (
    <div className="profile-body-input-wrapper profile-hobby-input-wrapper">
      <div className="input-wrapper">
        <div className="info-text">
          Please list personal interests (hobbies, activities, passions, etc.).
          Minimum of 3 requested.
        </div>
        <USelect
          placeholderLabel={"Type a Hobby"}
          options={hobbiesList}
          onChange={handleSelectOption}
          freeSolo
          autoSelect
        />
        <div
          className={`error-message
              ${BeError?.hobbies && state?.hobbies?.error ? "opacity-1" : ""}`}
        >
          {BeError?.hobbies || state?.hobbies?.error}
        </div>
        <Stack direction="row" flexWrap={"wrap"} spacing={1} overflow="auto">
          {state?.hobbies?.value?.length > 0 &&
            state?.hobbies?.value?.map((option, index) => (
              <div
                className="chip-tag flex-justify-center"
                key={`${option?.label}-${index}`}
                title={option?.label?.length > 40 ? option?.label : ""}
              >
                <div className="chip-tag__title">{option?.label}</div>
                <div className="chip-tag__edit-img flex-justify-center">
                  <Image
                    src={Images.bgDelIcon}
                    onClick={handleDelete(option)}
                  />
                </div>
              </div>
            ))}
        </Stack>
      </div>
      <div className="button-wrapper">
        <Button
          title="Save"
          className={`${disable ? "disabled-btn" : ""}`}
          onClick={Submit}
          showSpinner={requesting}
          disabled={disable ? true : false}
        />
      </div>
    </div>
  )
}
const mapStateToProps = state => ({
  requesting: state.profile.requesting,
  BeError: state.profile.error
})
const mapDispatchToProps = dispatch => ({
  profileHobbiesRequest: data => dispatch(profileHobbiesRequest(data)),
  profileHobbiesFailure: data => dispatch(profileHobbiesFailure(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileHobbies)
