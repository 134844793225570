import React from "react"

// Components
import TextField from "@mui/material/TextField"

// Style
import "./style.scss"

const UInput = ({
  placeholder = "",
  onChange,
  onFocus,
  onClick,
  value,
  id,
  inputError,
  errorText,
  errorClass,
  rows,
  multiline,
  minRows,
  maxRows,
  className,
  autoComplete = "off",
  inputProps,
  name,
  type,
  ...restProps
}) => {
  //inputProps = { inputMode: "numeric", pattern: "[0-9]*" }
  id = id || new Date().valueOf()

  return (
    <>
      <div className="input-field-wrapper">
        <TextField
          id={`outlined-basic-${id}`}
          label={placeholder}
          variant="outlined"
          fullWidth={true}
          onChange={onChange}
          onFocus={onFocus}
          onClick={onClick}
          value={value}
          error={Boolean(inputError)}
          type={type}
          name={name}
          rows={rows}
          multiline={multiline}
          minRows={minRows}
          maxRows={maxRows}
          className={className}
          autoComplete={autoComplete}
          inputProps={inputProps}
          InputLabelProps={value?.length > 0 ? { shrink: true } : null}
          {...restProps}
        />
      </div>
      <div className={`error-message ${errorClass}`}>{errorText}</div>
    </>
  )
}

export default UInput
