import UpdateOutlinedIcon from "@mui/icons-material/UpdateOutlined"
import Modal from "react-bootstrap/Modal"
import React from "react"

import Button from "../Button"

import "./style.scss"

const ConfirmModal = ({
  confirmText,
  itemName,
  onConfirm,
  onCancel,
  showModal,
  handleOnClose,
  loading
}) => {
  const handleConfirm = () => {
    typeof onConfirm === "function" && onConfirm()
  }

  const handleCancel = () => {
    typeof onCancel === "function" && onCancel()
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleOnClose}
        className="uiba-modal update-confirm-modal"
      >
        <Modal.Body>
          <div className="update-confirm-modal-body-wrapper">
            <div className="update-confirm-modal-body-wrapper__image-container">
              <UpdateOutlinedIcon className="update-confirm-modal-body-wrapper__image-container__icon" />
            </div>
            <div className="main-title update-confirm-modal-body-wrapper__title">
              {confirmText}
            </div>
            <div className="update-confirm-modal-body-wrapper__text">
              {itemName}
            </div>
            <div className="button-wrapper flex-justify-center">
              <Button
                title="Cancel"
                className="transparent-outline-btn button-wrapper__cancel-btn"
                onClick={handleCancel}
              />
              <Button
                title="Update"
                className="uiba-btn button-wrapper__update-confirm-btn"
                onClick={handleConfirm}
                showSpinner={loading}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ConfirmModal
