import React from "react"
import AddIcon from "@mui/icons-material/Add"

import IconButton from "../../IconButton"

import Image from "../../Image"
import { Images } from "../../../theme/Images"

// Style
import "../style.scss"

const HobbiesCard = ({ isEdited, getHobbiesInfo, isCandidate, onClick }) => {
  const handleOnClick = id => () => {
    onClick(id)
  }

  return (
    <>
      {isEdited ? (
        <div className="profile-details-card__body">
          {Boolean(getHobbiesInfo?.length) && (
            <ul className="profile-details-card__body__hobbies flex-align-center">
              {getHobbiesInfo.map((item, index) => (
                <li
                  className="profile-details-card__body__hobbies__tag flex-align-center"
                  key={item.id}
                >
                  <div className="profile-details-card__body__hobbies__tag__title">
                    {item?.hobby}
                  </div>
                  {isCandidate && (
                    <div className="profile-details-card__body__hobbies__tag__edit-img flex-justify-center">
                      <Image
                        src={Images.bgDelIcon}
                        onClick={handleOnClick(item.id)}
                      />
                    </div>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      ) : (
        <div className="profile-details-card__info-text">
          Please list personal interests (hobbies, activities, passions, etc.).
          Minimum of 3 requested.
        </div>
      )}
      {isCandidate && (
        <div className="profile-details-card__action">
          <IconButton
            icon={<AddIcon />}
            label="Add Hobbies"
            onClick={handleOnClick()}
          />
        </div>
      )}
    </>
  )
}

export default HobbiesCard
