export const POST_REQUEST_SIGNUP = "POST_REQUEST_SIGNUP";
export const POST_REQUEST_SIGNUP_SUCCESS = "POST_REQUEST_SIGNUP_SUCCESS";
export const POST_REQUEST_SIGNUP_FAILURE = "POST_REQUEST_SIGNUP_FAILURE";
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const SIGNUP = "SIGNUP";

export const SIGNUP_REQUEST_GOOGLE = "SIGNUP_REQUEST_GOOGLE";
export const SIGNUP_REQUEST_GOOGLE_SUCCESS = "SIGNUP_REQUEST_GOOGLE_SUCCESS";
export const SIGNUP_REQUEST_GOOGLE_FAILURE = "SIGNUP_REQUEST_GOOGLE_FAILURE";
export const SIGNUP_REQUEST_MICROSOFT = "SIGNUP_REQUEST_MICROSOFT";
export const SIGNUP_REQUEST_MICROSOFT_SUCCESS = "SIGNUP_REQUEST_MICROSOFT_SUCCESS";
export const SIGNUP_REQUEST_MICROSOFT_FAILURE = "SIGNUP_REQUEST_MICROSOFT_FAILURE";


export const POST_VALIDATE_OTP = "POST_VALIDATE_OTP";
export const POST_VALIDATE_OTP_SUCCESS = "POST_VALIDATE_OTP_SUCCESS";
export const POST_VALIDATE_OTP_FAILURE = "POST_VALIDATE_OTP_FAILURE";
export const DISPLAY_OTP_MODAL = 'DISPLAY_OTP_MODAL';
export const REGENERATE_EMAIL_OTP = "REGENERATE_EMAIL_OTP";
