import {
    GET_TEAMS_DATA_REQUEST,
    GET_TEAMS_DATA_REQUEST_SUCCESS,
    GET_TEAMS_DATA_REQUEST_FAILURE,
    CLOSE_MODAL,
    ADD_NEW_TEAM,
    ADD_NEW_TEAM_SUCCESS,
    ADD_NEW_TEAM_FAILURE,
    DELETE_TEAM,
    DELETE_TEAM_FAILURE,
    DELETE_TEAM_SUCCESS,
    DELETE_TEAM_RECORD,
    POST_EDIT_TEAM_REQUEST,
    POST_EDIT_TEAM_REQUEST_SUCCESS,
    POST_EDIT_TEAM_REQUEST_FAILURE,
    EDIT_TEAM_RECORD
} from './type'


export const getAllTeamsData = (data) => ({
    type: GET_TEAMS_DATA_REQUEST,
    data
})

export const getAllTeamsDataSuccess = data => ({
    type: GET_TEAMS_DATA_REQUEST_SUCCESS,
    data
})

export const getAllTeamsDataFailure = error => ({
    type: GET_TEAMS_DATA_REQUEST_FAILURE,
    error
})

export const closeModal = data => ({
    type: CLOSE_MODAL,
    data
})

export const postAddTeam = data => ({
    type: ADD_NEW_TEAM,
    data
})

export const postAddTeamSuccess = data => ({
    type: ADD_NEW_TEAM_SUCCESS,
    data
})

export const postAddTeamError = error => ({
    type: ADD_NEW_TEAM_FAILURE,
    error
})

export const deleteRecord = id => ({
    type: DELETE_TEAM,
    id
})

export const deleteRecordSuccess = data  => ({
    type: DELETE_TEAM_SUCCESS,
    data
})

export const deleteRecordFailure = error => ({
    type: DELETE_TEAM_FAILURE,
    error
})

export const teamRecordToBeDeleted = info => ({
    type: DELETE_TEAM_RECORD,
    info
})

export const editTeamRequest = (data, id) => ({
    type: POST_EDIT_TEAM_REQUEST,
    data,
    id
})

export const editTeamRequestSuccess = data => ({
    type: POST_EDIT_TEAM_REQUEST_SUCCESS,
    data,
})

export const editTeamRequestFailure = error => ({
    type: POST_EDIT_TEAM_REQUEST_FAILURE,
    error,
})

export const editTeamRecord = (data) => ({
    type: EDIT_TEAM_RECORD,
    data
})

