import { connect } from "react-redux"
import React from "react"

import {
  profileSalaryRequest,
  profileSalaryFailure
} from "../../../../pages/CandidateScreens/Profile/redux/action"
import Button from "../../../Button"
import UCheckbox from "../../../UCheckbox"
import UInput from "../../../UInput"
import USelect from "../../../USelect"
import useMetaData from "../../../../hooks/MetaData"
import validator from "../../../../utils/validation"
import { useForm } from "../../../../hooks"

const PaymentTerms = props => {
  const {
    selectedId,
    editedProfileSalary,
    postProfileSalaryRequest,
    dispatchProfileSalaryFailure,
    requesting,
    error,
    onSubmitCallback
  } = props
  const {
    currencyList,
    compensationPeriodList,
    OnboardingTypes,
    workTimezones
  } = useMetaData()

  const stateSchema = {
    onboardingType: {
      value: editedProfileSalary?.on_boarding_type
        ? {
            key: editedProfileSalary?.on_boarding_type,
            label: editedProfileSalary?.on_boarding_type
          }
        : null,
      error: ""
    },
    currency: {
      value: editedProfileSalary?.currency
        ? {
            key: editedProfileSalary?.currency,
            label: editedProfileSalary?.currency
          }
        : null,
      error: ""
    },
    payPeriod: {
      value: editedProfileSalary?.pay_period
        ? {
            key: editedProfileSalary?.pay_period,
            label: editedProfileSalary?.pay_period
          }
        : null,
      error: ""
    },
    workTimezone: {
      value: editedProfileSalary?.work_time_zones
        ? {
            key: editedProfileSalary?.work_time_zones,
            label: editedProfileSalary?.work_time_zones
          }
        : null,
      error: ""
    },
    expectedSalary: {
      value: editedProfileSalary?.expected_gross_salary,
      error: ""
    },
    remoteWork: {
      value: Boolean(editedProfileSalary?.remote_work_willingness),
      error: ""
    }
  }

  const validationStateSchema = {
    onboardingType: {
      required: true,
      error: ""
    },
    currency: {
      required: true
    },
    payPeriod: {
      required: true
    },
    expectedSalary: {
      required: true,
      validator: validator?.numeric
    },
    workTimezone: {
      required: false
    },
    remoteWork: {
      required: false
    }
  }

  const { state, handleOnChange, disable } = useForm(
    stateSchema,
    validationStateSchema
  )

  const handleDropdownOnChange = field => (event, value) => {
    handleOnChange(field, value)
  }

  const handleInputChange = field => event => {
    handleOnChange(field, event?.target?.value)
  }
  const handleCheckboxChange = () => {
    handleOnChange("remoteWork", !state.remoteWork.value)
  }

  const handleSubmit = () => {
    const postData = {
      id: selectedId,
      on_boarding_type: state.onboardingType.value?.key,
      currency: state.currency.value?.key,
      expected_gross_salary: state.expectedSalary.value,
      pay_period: state.payPeriod.value?.key,
      work_time_zones: state.workTimezone.value?.key,
      remote_work_willingness: state.remoteWork.value
    }
    postProfileSalaryRequest(postData)
    onSubmitCallback?.()
  }

  const clearError = () => dispatchProfileSalaryFailure(false)

  return (
    <div className="profile-body-input-wrapper payment-terms-input-wrapper">
      <div className="input-wrapper">
        <USelect
          placeholderLabel={"Onboarding Type"}
          options={OnboardingTypes}
          value={state.onboardingType.value}
          onChange={handleDropdownOnChange("onboardingType")}
          className={
            error?.on_boarding_type || state.onboardingType.error
              ? "error-field-wrapper"
              : ""
          }
          inputError={error?.on_boarding_type || state.onboardingType.error}
        />
        <div className="error-message">
          {error?.on_boarding_type || state.onboardingType.error}
        </div>
        <USelect
          placeholderLabel={"Currency"}
          options={currencyList}
          value={state.currency.value}
          onChange={handleDropdownOnChange("currency")}
          className={
            error?.currency || state.currency.error ? "error-field-wrapper" : ""
          }
          inputError={error?.currency || state.currency.error}
        />
        <div className="error-message">
          {error?.currency || state.currency.error}
        </div>
        <UInput
          placeholder="Expected Gross Salary "
          value={state.expectedSalary.value}
          onChange={handleInputChange("expectedSalary")}
          errorText={
            error?.expected_gross_salary || state.expectedSalary?.error
          }
          errorClass={
            error?.expected_gross_salary || state.expectedSalary?.error
              ? "opacity-1"
              : ""
          }
          onFocus={clearError}
          inputError={
            error?.expected_gross_salary || state.expectedSalary?.error
          }
        />
        <USelect
          placeholderLabel={"Pay Period"}
          options={compensationPeriodList}
          value={state.payPeriod.value}
          onChange={handleDropdownOnChange("payPeriod")}
          className={
            error?.pay_period || state.payPeriod.error
              ? "error-field-wrapper"
              : ""
          }
          inputError={error?.pay_period || state.payPeriod.error}
        />
        <div className="error-message">
          {error?.pay_period || state.payPeriod.error}
        </div>
        <USelect
          placeholderLabel={"Working Hours Territory"}
          options={workTimezones}
          value={state.workTimezone.value}
          onChange={handleDropdownOnChange("workTimezone")}
        />
      </div>
      <UCheckbox
        label="Willing to work remotely"
        checked={state.remoteWork.value}
        onChange={handleCheckboxChange}
      />
      <div className="button-wrapper">
        <Button
          title="Save"
          className={`${disable ? "disabled-btn" : ""}`}
          onClick={handleSubmit}
          showSpinner={requesting}
          disabled={disable ? true : false}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  requesting: state.profile.requesting,
  error: state.profile.error
})
const mapDispatchToProps = dispatch => ({
  postProfileSalaryRequest: data => dispatch(profileSalaryRequest(data)),
  dispatchProfileSalaryFailure: data => dispatch(profileSalaryFailure(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(PaymentTerms)
