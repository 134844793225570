import { all, call, put, takeLeading } from "redux-saga/effects"
import { GET_META_DATA_REQUEST } from "./type"
import { getMetaDataSuccess } from "./action"
import { prepareMetaData } from "../../../utils/helper"
import { METADATA } from "../../../utils/constants/metadata"

// TODO Get rid of the async function and the getMetaDataSuccess
const getMetaDataAPI = async () => {
  return { data: METADATA }
}

function* getMetaData({ data }) {
  try {
    const response = yield call(getMetaDataAPI, data)
    yield put(getMetaDataSuccess(prepareMetaData(response.data)))
  } catch (e) {
    yield put(getMetaDataSuccess(prepareMetaData(e?.response?.data || {})))
  }
}

export default all([takeLeading(GET_META_DATA_REQUEST, getMetaData)])
