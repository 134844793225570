import React from "react"
import AddIcon from "@mui/icons-material/Add"
import { getCurrencySymbol } from "../../../utils/helper"
import IconButton from "../../IconButton"
import Image from "../../Image"
import { Images } from "../../../theme/Images"

import "../style.scss"
import PlaceholderCard from "../../PlaceholderCard"

const SalaryCard = ({
  isEdited,
  salaryInfo,
  isCandidate,
  onClick,
  loading
}) => {
  const style = !isCandidate ? { width: "30%" } : {}
  const handleOnClick = id => () => {
    onClick(id)
  }

  return (
    <>
      {!loading && isEdited ? (
        <div className="profile-details-card__body">
          {Boolean(salaryInfo && Object.keys(salaryInfo)?.length) && (
            <div className="profile-details-card__body__salary">
              {isCandidate && (
                <div className="title flex-align-center">
                  <div className="text">Payment Terms</div>
                  {Boolean(salaryInfo?.id) && (
                    <div className="edit-img flex-justify-center">
                      <Image
                        src={Images.editIcon}
                        onClick={handleOnClick(salaryInfo?.id)}
                      />
                    </div>
                  )}
                </div>
              )}
              <div className="sub-title-fields flex-align-center">
                <div className="type" style={style}>
                  <p>Employment Type</p>
                  <h2>{salaryInfo?.on_boarding_type}</h2>
                </div>
                <div className="type type-with-pay" style={style}>
                  <p>Salary Expectations</p>
                  <div className="pay-type flex-align-center">
                    <h2>
                      {salaryInfo?.currency &&
                        getCurrencySymbol(salaryInfo?.currency)}
                      {salaryInfo?.expected_gross_salary}
                    </h2>
                  </div>
                </div>
                {!isCandidate && (
                  <div className="type type-with-pay" style={style}>
                    <p>Pay Period</p>
                    <div className="pay-period flex-align-center">
                      <h2>{salaryInfo?.pay_period}</h2>
                    </div>
                  </div>
                )}
                {isCandidate && (
                  <div className="tag flex-justify-center">
                    {salaryInfo?.pay_period}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="profile-details-card__body__salary_non-edited">
          {isCandidate && (
            <div className="title flex-align-center">
              <div className="text">Payment Terms</div>
            </div>
          )}
          {loading ? (
            <PlaceholderCard
              section="candidate-salary-section"
              rows="1"
              columns="3"
            />
          ) : (
            <>
              <div className="profile-details-card__info-text">
                What are your salary expectations?
              </div>
              <div className="profile-details-card__action">
                <IconButton
                  icon={<AddIcon />}
                  label="Add Payment Terms"
                  onClick={handleOnClick()}
                />
              </div>
            </>
          )}
        </div>
      )}
    </>
  )
}

export default SalaryCard
