import React, { useEffect, useState } from "react"
import Stack from "@mui/material/Stack"
import { connect } from "react-redux"

import Button from "../../../Button"
import RangeSlider from "../../../RangeSlider"
import USelect from "../../../USelect"
import {
  profileSkillRequest,
  profileSkillFailure,
  deleteProfileSkillRequest
} from "../../../../pages/CandidateScreens/Profile/redux/action"
import { useMetaData, useForm, useConcept } from "../../../../hooks"
import { Images } from "../../../../theme/Images"
import Image from "../../../Image"
import { ENTITY_URI_PREFIX, WIKIDATA_CONCEPT_URI_PREFIX} from '../../../../utils/constants'
import { MAX_SKILL_LABEL_LENGTH } from "../../../../utils/constants"

const ProfileSkills = props => {
  const {
    profileSkillFailure,
    profileSkillRequest,
    BeError,
    requesting,
    editedSkillsData,
    selectedId,
    handleOnSubmit,
    deleteProfileSkill,
    deleteRequesting,
    closeModal
  } = props
  const { skillProficiency } = useMetaData()

  const {
    conceptListRequesting,
    conceptOptions
  } = useConcept()

  const selectedSkillInfo =
    editedSkillsData?.find(item => item?.id === selectedId) || {}
  const selectedSkill = selectedSkillInfo?.label
    ? [
        {
          key: selectedSkillInfo?.label,
          label: selectedSkillInfo?.label
        }
      ]
    : null

  const [proficiency, setProficiency] = useState(
    selectedSkillInfo?.proficiency
      ? skillProficiency?.find(
          item => item?.code === selectedSkillInfo?.proficiency
        )?.value
      : 0
  )
  const [experience, setExperience] = useState(
    parseInt(selectedSkillInfo?.year_of_experience || 0, 10)
  )

  const stateSchema = {
    label: {
      value: selectedSkill,
      error: ""
    }
  }

  const validationStateSchema = {
    label: {
      required: true
    },
    skill: {
      required: false
    }
  }

  const { state, handleOnChange, disable } = useForm(
    stateSchema,
    validationStateSchema
  )

  const handleDelete = val => () => {
    handleOnChange("label", null)
  }

  const handleDropdownChange = field => (e, option) => {
    handleOnChange(field, option)
  }

  const handleProficiencyChange = (event, value) => {
    setProficiency(value)
    profileSkillFailure(false)
  }

  const handleExperienceChange = (event, value) => {
    setExperience(value)
    profileSkillFailure(false)
  }

  useEffect(() => {
    profileSkillFailure(false)
  }, [profileSkillFailure])

  const Submit = () => {
    // When updating a skill, state.label.value is cast as an array of objects {label: '...', key: '...'}
    const selected_concept = state.label
    const isSkillUpdate = selectedId
    const label = isSkillUpdate ? state.label.value[0].label : selected_concept?.label?? state.label.value

    const skillInfo = {
      id: selectedId,
      proficiency: skillProficiency?.find(item => item?.value === proficiency)
        ?.code,
      year_of_experience: experience,
      skill: label,
      label
    }

    if (!isSkillUpdate) {
      skillInfo.wikidata_id = state.skill?.value.id
      skillInfo.uri = state.skill?.value?.concepturi?.replace(WIKIDATA_CONCEPT_URI_PREFIX, ENTITY_URI_PREFIX)
    }

    if (typeof handleOnSubmit === "function") {
      return handleOnSubmit(skillInfo)
    }
    profileSkillRequest(skillInfo, closeModal)

  }

  const deleteSkills = () => {
    if (typeof handleOnSubmit === "function") {
      return handleOnSubmit({ deletedId: selectedId })
    }
    deleteProfileSkill({ id: selectedId, cb: closeModal })
  }

  const [, setSkillInput] = useState("")
  const handleSkillInputChange = (e, value) => {
    setSkillInput(value)
    handleOnChange('label', value)
  }

  const deleteSkill = () => {
    handleDelete(state.label.value)
  }

  const skillLabel = (selectedId > -1 || selectedId) ? state.label.value[0].label : state.label.value

  return (
    <div className="profile-body-input-wrapper skill-input-wrapper">
      <div className="input-wrapper">
        {selectedId === null || selectedId === undefined &&
          <USelect
            placeholderLabel={"Type a skill"}
            freeSolo={true}
            options={conceptOptions}
            onChange={handleDropdownChange("skill")}
            onInputChange={handleSkillInputChange}
            autoSelect
            disabled={selectedId ? true : false}
            value={
              selectedId && state.label?.value?.label?.length
                ? state.label?.value?.label
                : undefined
            }
            loading={conceptListRequesting}
            onInput = {(e) =>{
              e.target.value = e.target.value.slice(0,MAX_SKILL_LABEL_LENGTH)
          }}
          />
        }
        <div
          className={`error-message ${
            BeError?.label || state.label?.error ? "opacity-1" : ""
          }`}
        >
          {BeError?.label || state.label?.error}
        </div>
        <Stack direction="row" flexWrap={"wrap"} spacing={1} overflow="auto">
          {state.label.value?.length > 0 &&
            <div
                className="chip-tag flex-justify-center"
                key={`skill-label-${skillLabel}`}
                title={state.label.value?.length > 40 ? state.label.value[0].label : ""}
              >
                <div className="chip-tag__title">{skillLabel}</div>
                {(selectedId > -1 || selectedId) && (
                  <div className="chip-tag__edit-img flex-justify-center">
                    <Image
                      src={Images.bgDelIcon}
                      onClick={deleteSkill}
                    />
                  </div>
                )}
              </div>
          }
        </Stack>
        <div className="skill-slider">
          <h2>Proficiency:</h2>
          <RangeSlider
            value={proficiency}
            marks={skillProficiency}
            onChange={handleProficiencyChange}
            step={null}
          />
          <div
            className={`error-message ${
              BeError?.proficiency ? "opacity-1" : ""
            }`}
          >
            {BeError?.proficiency}
          </div>
        </div>

        <div className="skill-slider">
          <h2>Years of Experience</h2>
          <RangeSlider
            value={experience}
            onChange={handleExperienceChange}
            max={10}
          />
          <div
            className={`error-message ${
              BeError?.years_of_experience ? "opacity-1" : ""
            }`}
          >
            {BeError?.years_of_experience}
          </div>
        </div>
      </div>
      <div className="button-wrapper">
        {(selectedId > -1 || selectedId) && (
          <Button
            title="Delete"
            className="delete-btn"
            onClick={deleteSkills}
            showSpinner={deleteRequesting}
          />
        )}
        <Button
          title="Save"
          className={`${disable ? "disabled-btn" : ""}`}
          onClick={Submit}
          showSpinner={requesting}
          disabled={disable ? true : false}
        />
      </div>
    </div>
  )
}
const mapStateToProps = state => ({
  requesting: state.profile.requesting,
  BeError: state.profile.error,
  deleteRequesting: state.profile.deleteRequesting
})
const mapDispatchToProps = dispatch => ({
  profileSkillRequest: (data, cb) => dispatch(profileSkillRequest(data, cb)),
  profileSkillFailure: data => dispatch(profileSkillFailure(data)),
  deleteProfileSkill: (data, cb) => dispatch(deleteProfileSkillRequest(data, cb))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSkills)
