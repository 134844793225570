// Create cookie
export const setCookie = (name, value, expiryTime) => {
  const d = new Date()
  d.setTime(d.getTime() + expiryTime)
  let expires = "expires=" + d.toUTCString()
  document.cookie = name + "=" + value + ";" + expires + ";path=/"
}

// Delete cookie
export const deleteCookie = name => {
  const expiryTime = -24 * 60 * 60 * 1000
  setCookie(name, "", expiryTime)
}

// Read cookie
export const getCookie = name => {
  const cookieName = name + "="
  const decodedCookie = decodeURIComponent(document.cookie)
  const cookiesList = decodedCookie.split(";")
  for (let i = 0; i < cookiesList.length; i++) {
    let cookieItem = cookiesList[i]
    while (cookieItem.charAt(0) === " ") {
      cookieItem = cookieItem.substring(1)
    }
    if (cookieItem.indexOf(cookieName) === 0) {
      return cookieItem.substring(cookieName.length, cookieItem.length)
    }
  }
  return ""
}
