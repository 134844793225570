import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import Popover from "@mui/material/Popover"
import React, { useState } from "react"

import EditModal from "../CandidateProfileEditModal"
import LogoutModal from "../CandidateModals/LogoutModal"
import DeleteAccountModal from "../CandidateModals/DeleteAccountModal"
import AvatarBadge from "../AvatarBadge"

import "./style.scss"
import { isCandidate, isRecruiter } from "../../utils/helper"

const Header = props => {
  const { loginInfo, candidate_profile, userType } = props
  const history = useHistory()

  const [showModal, setShowModal] = useState(false)
  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false)
  const [currentModal, setCurrentModal] = useState("")
  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined
  const candidateName = isCandidate(userType) ? candidate_profile?.name : ""
  const name = candidateName || loginInfo.user?.name || loginInfo.user?.username

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopUpClose = () => {
    setAnchorEl(null)
  }

  const goToChangePassword = () => {
    handlePopUpClose()
    history.push(`/${userType}/change_password`)
  }

  const goToProfile = () => {
    handlePopUpClose()
    history.push(`/${userType}/profile`)
  }

  const handleLogoutClick = () => {
    setShowLogoutModal(true)
    handlePopUpClose()
  }

  const handleDeleteAccountClick = () => {
    setShowDeleteAccountModal(true)
    handlePopUpClose()
  }

  const selectedClass = path => {
    return history?.location?.pathname?.startsWith(`/${userType}/${path}`)
      ? "option-selected"
      : ""
  }

  return (
    <section className={`header-section ${props.className}`}>
      <div className="main-head">
        <div className="header-settings flex-align-center">
          <div className="title flex-align-center">
            <AvatarBadge name={name} />
          </div>
          <div className="header-options">
            <KeyboardArrowDownIcon onClick={handleClick} />
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handlePopUpClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
            >
              {isRecruiter(userType) && (
                <div
                  className={`option ${selectedClass("profile")}`}
                  onClick={goToProfile}
                >
                  Profile
                </div>
              )}
              <div
                className={`option ${selectedClass("change_password")}`}
                onClick={goToChangePassword}
              >
                Change Password
              </div>
              {isCandidate(userType) && (
                // <div
                //   className="option"
                //   onClick={() => {
                //     setShowModal(true)
                //     setCurrentModal("feedback")
                //     handlePopUpClose()
                //   }}
                // >
                //   Send Feedback
                // </div>
                <div className="option" onClick={handleDeleteAccountClick}>
                  Delete Account
                </div>
              )}
              <div className="option" onClick={handleLogoutClick}>
                Log Out
              </div>
            </Popover>
          </div>
        </div>
      </div>
      <EditModal
        showModal={showModal}
        setShowModal={setShowModal}
        currentModal={currentModal}
        setCurrentData={setCurrentModal}
      />
      <LogoutModal
        showLogoutModal={showLogoutModal}
        setShowLogoutModal={setShowLogoutModal}
      />
      <DeleteAccountModal
        showDeleteAccountModal={showDeleteAccountModal}
        setShowDeleteAccountModal={setShowDeleteAccountModal}
      />
    </section>
  )
}

const mapStateToProps = state => ({
  loginInfo: state.login.loginInfo,
  candidate_profile: state.profile.candidate_profile,
  userType: state.login.userType
})

export default connect(mapStateToProps, null)(Header)
