import {
  GET_PROFILE_EDUCATION_REQUEST,
  POST_PROFILE_EDUCATION_REQUEST,
  POST_PROFILE_EDUCATION_SUCCESS,
  POST_PROFILE_EDUCATION_FAILURE,
  DELETE_PROFILE_EDUCATION_REQUEST,
  GET_PROFILE_CERTIFICATION_REQUEST,
  POST_PROFILE_CERTIFICATION_REQUEST,
  POST_PROFILE_CERTIFICATION_SUCCESS,
  POST_PROFILE_CERTIFICATION_FAILURE,
  GET_PROFILE_EXPERIENCE_REQUEST,
  POST_PROFILE_EXPERIENCE_REQUEST,
  POST_PROFILE_EXPERIENCE_SUCCESS,
  POST_PROFILE_EXPERIENCE_FAILURE,
  DELETE_PROFILE_EXPERIENCE_REQUEST,
  GET_PROFILE_HOBBIES_REQUEST,
  POST_PROFILE_HOBBIES_REQUEST,
  POST_PROFILE_HOBBIES_SUCCESS,
  POST_PROFILE_HOBBIES_FAILURE,
  POST_PROFILE_INFO_REQUEST,
  POST_PROFILE_INFO_SUCCESS,
  POST_PROFILE_INFO_FAILURE,
  POST_PROFILE_LANGUAGE_REQUEST,
  POST_PROFILE_LANGUAGE_SUCCESS,
  POST_PROFILE_LANGUAGE_FAILURE,
  GET_PROFILE_LANGUAGE_REQUEST,
  DELETE_PROFILE_LANGUAGE_REQUEST,
  GET_PROFILE_PROJECT_REQUEST,
  POST_PROFILE_PROJECT_REQUEST,
  POST_PROFILE_PROJECT_SUCCESS,
  POST_PROFILE_PROJECT_FAILURE,
  DELETE_PROFILE_PROJECT_REQUEST,
  GET_PROFILE_SALARY_REQUEST,
  POST_PROFILE_SALARY_REQUEST,
  POST_PROFILE_SALARY_SUCCESS,
  POST_PROFILE_SALARY_FAILURE,
  GET_PROFILE_SKILL_REQUEST,
  GET_PROFILE_SKILL_SUCCESS,
  GET_PROFILE_SKILL_FAILURE,
  POST_PROFILE_SKILL_REQUEST,
  DELETE_PROFILE_SKILL_REQUEST,
  GET_CANDIDATE_PROFILE_REQUEST,
  GET_CANDIDATE_PROFILE_REQUEST_SUCCESS,
  GET_CANDIDATE_PROFILE_REQUEST_FAILURE,
  POST_UPLOAD_RESUME_REQUEST,
  POST_UPLOAD_RESUME_FAILURE,
  POST_UPLOAD_RESUME_SUCCESS,
  GET_PROFILE_SOCIAL_MEDIA_FAILURE,
  GET_PROFILE_SOCIAL_MEDIA_SUCCESS,
  GET_PROFILE_SOCIAL_MEDIA_REQUEST,
  POST_PROFILE_SOCIAL_MEDIA_REQUEST,
  POST_JOB_CONSENT_REQUEST,
  POST_JOB_CONSENT_SUCCESS,
  POST_JOB_CONSENT_FAILURE,
  GET_PROFILE_REQUIRED_INFO_REQUEST,
  GET_PROFILE_REQUIRED_INFO_SUCCESS,
  GET_PROFILE_REQUIRED_INFO_FAILURE
} from "./type"

// GET_CANDIDATE_PROFILE
export const getCandidateProfileRequest = data => ({
  type: GET_CANDIDATE_PROFILE_REQUEST,
  data
})

export const getCandidateProfileRequestSuccess = data => ({
  type: GET_CANDIDATE_PROFILE_REQUEST_SUCCESS,
  data
})

export const getCandidateProfileRequestFailure = data => ({
  type: GET_CANDIDATE_PROFILE_REQUEST_FAILURE,
  data
})

// POST_PAYMENT_INFORMATION
export const getProfileEducationRequest = data => ({
  type: GET_PROFILE_EDUCATION_REQUEST,
  data
})

export const profileEducationRequest = data => ({
  type: POST_PROFILE_EDUCATION_REQUEST,
  data
})

export const deleteProfileEducationRequest = data => ({
  type: DELETE_PROFILE_EDUCATION_REQUEST,
  data
})

export const profileEducationSuccess = data => ({
  type: POST_PROFILE_EDUCATION_SUCCESS,
  data
})

export const profileEducationFailure = data => ({
  type: POST_PROFILE_EDUCATION_FAILURE,
  data
})

export const getProfileCertificationRequest = data => ({
  type: GET_PROFILE_CERTIFICATION_REQUEST,
  data
})

export const profileCertificationRequest = data => ({
  type: POST_PROFILE_CERTIFICATION_REQUEST,
  data
})

export const profileCertificationSuccess = data => ({
  type: POST_PROFILE_CERTIFICATION_SUCCESS,
  data
})

export const profileCertificationFailure = data => ({
  type: POST_PROFILE_CERTIFICATION_FAILURE,
  data
})

export const getProfileExperienceRequest = data => ({
  type: GET_PROFILE_EXPERIENCE_REQUEST,
  data
})

export const profileExperienceRequest = data => ({
  type: POST_PROFILE_EXPERIENCE_REQUEST,
  data
})

export const profileExperienceSuccess = data => ({
  type: POST_PROFILE_EXPERIENCE_SUCCESS,
  data
})

export const profileExperienceFailure = data => ({
  type: POST_PROFILE_EXPERIENCE_FAILURE,
  data
})

export const deleteProfileExperienceRequest = data => ({
  type: DELETE_PROFILE_EXPERIENCE_REQUEST,
  data
})

export const getProfileHobbiesRequest = data => ({
  type: GET_PROFILE_HOBBIES_REQUEST,
  data
})

export const profileHobbiesRequest = data => ({
  type: POST_PROFILE_HOBBIES_REQUEST,
  data
})

export const profileHobbiesSuccess = data => ({
  type: POST_PROFILE_HOBBIES_SUCCESS,
  data
})

export const profileHobbiesFailure = data => ({
  type: POST_PROFILE_HOBBIES_FAILURE,
  data
})

export const profileInfoRequest = data => ({
  type: POST_PROFILE_INFO_REQUEST,
  data
})

export const profileInfoSuccess = data => ({
  type: POST_PROFILE_INFO_SUCCESS,
  data
})

export const profileInfoFailure = data => ({
  type: POST_PROFILE_INFO_FAILURE,
  data
})

export const profileLanguageRequest = data => ({
  type: POST_PROFILE_LANGUAGE_REQUEST,
  data
})

export const profileLanguageSuccess = data => ({
  type: POST_PROFILE_LANGUAGE_SUCCESS,
  data
})

export const profileLanguageFailure = data => ({
  type: POST_PROFILE_LANGUAGE_FAILURE,
  data
})

export const getProfileLanguageRequest = data => ({
  type: GET_PROFILE_LANGUAGE_REQUEST,
  data
})

export const deleteProfileLanguageRequest = data => ({
  type: DELETE_PROFILE_LANGUAGE_REQUEST,
  data
})

export const getProfileProjectRequest = data => ({
  type: GET_PROFILE_PROJECT_REQUEST,
  data
})

export const profileProjectRequest = data => ({
  type: POST_PROFILE_PROJECT_REQUEST,
  data
})

export const deleteProfileProjectRequest = data => ({
  type: DELETE_PROFILE_PROJECT_REQUEST,
  data
})

export const profileProjectSuccess = data => ({
  type: POST_PROFILE_PROJECT_SUCCESS,
  data
})

export const profileProjectFailure = data => ({
  type: POST_PROFILE_PROJECT_FAILURE,
  data
})

export const getProfileSalaryRequest = data => ({
  type: GET_PROFILE_SALARY_REQUEST,
  data
})

export const profileSalaryRequest = data => ({
  type: POST_PROFILE_SALARY_REQUEST,
  data
})

export const profileSalarySuccess = data => ({
  type: POST_PROFILE_SALARY_SUCCESS,
  data
})

export const profileSalaryFailure = data => ({
  type: POST_PROFILE_SALARY_FAILURE,
  data
})

export const getProfileSkillRequest = data => ({
  type: GET_PROFILE_SKILL_REQUEST
})

export const getProfileSkillSuccess = data => ({
  type: GET_PROFILE_SKILL_SUCCESS,
  data
})

export const getProfileSkillFailure = data => ({
  type: GET_PROFILE_SKILL_FAILURE,
  data
})

export const profileSkillRequest = (data, cb) => ({
  type: POST_PROFILE_SKILL_REQUEST,
  data,
  cb
})

export const deleteProfileSkillRequest = (data, cb) => ({
  type: DELETE_PROFILE_SKILL_REQUEST,
  data
})

export const profileSkillSuccess = data => ({
  type: GET_PROFILE_SKILL_SUCCESS,
  data
})

export const profileSkillFailure = data => ({
  type: GET_PROFILE_SKILL_FAILURE,
  data
})

export const getProfileSocialMediaRequest = data => ({
  type: GET_PROFILE_SOCIAL_MEDIA_REQUEST,
  data
})

export const profileSocialMediaRequest = data => ({
  type: POST_PROFILE_SOCIAL_MEDIA_REQUEST,
  data
})

export const profileSocialMediaSuccess = data => ({
  type: GET_PROFILE_SOCIAL_MEDIA_SUCCESS,
  data
})

export const profileSocialMediaFailure = data => ({
  type: GET_PROFILE_SOCIAL_MEDIA_FAILURE,
  data
})

export const uploadResumeSuccess = data => ({
  type: POST_UPLOAD_RESUME_SUCCESS,
  data
})

export const uploadResumeFailure = data => ({
  type: POST_UPLOAD_RESUME_FAILURE,
  data
})

export const uploadResumeRequest = data => ({
  type: POST_UPLOAD_RESUME_REQUEST,
  data
})

export const postJobConsentRequest = data => ({
  type: POST_JOB_CONSENT_REQUEST,
  data
})

export const postJobConsentSuccess = data => ({
  type: POST_JOB_CONSENT_SUCCESS,
  data
})

export const postJobConsentFailure = data => ({
  type: POST_JOB_CONSENT_FAILURE,
  data
})

export const getProfileRequiredInfoRequest = data => ({
  type: GET_PROFILE_REQUIRED_INFO_REQUEST,
  data
})

export const profileRequiredInfoSuccess = data => ({
  type: GET_PROFILE_REQUIRED_INFO_SUCCESS,
  data
})

export const profileRequiredInfoFailure = data => ({
  type: GET_PROFILE_REQUIRED_INFO_FAILURE,
  data
})
