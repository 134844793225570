import {
    GET_CONCEPT_LIST_REQUEST,
    GET_CONCEPT_LIST_SUCCESS
} from "./type"

const initialState = {
    conceptList: [],
    conceptListRequesting: false
}

const conceptDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CONCEPT_LIST_REQUEST:
        return {
            ...state,
            conceptListRequesting: true
        }

        case GET_CONCEPT_LIST_SUCCESS:
        return {
            ...state,
            conceptListRequesting: false,
            conceptList: action.data
        }

        default:
        return state
    }
}

export default conceptDataReducer
