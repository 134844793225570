import { connect } from "react-redux"
import React, { useEffect, useMemo, useState, useCallback } from "react"

import Button from "../../../Button"
import UInput from "../../../UInput"
import USelect from "../../../USelect"
import PaymentTerms from "../PaymentTerms"
import {
  profileInfoRequest,
  profileInfoFailure
} from "../../../../pages/CandidateScreens/Profile/redux/action"
import validator from "../../../../utils/validation"
import { useMetaData, useForm, useLocation } from "../../../../hooks"
import ResumeUpload from "../../../ResumeUpload"
import SkillCard from "../../../ProfileEditedCards/SkillCard"
import { getProfileSkillRequest } from "../../../../pages/CandidateScreens/Profile/redux/action"
import PlaceholderCard from "../../../PlaceholderCard"
import EditModal from "../.."

import "./style.scss"

const STEPS = ['address', 'payment_terms', 'resume', 'skills']

const stepTitles = {
  address: 'Contact Information',
  payment_terms: 'Payment Terms',
  resume: 'Resume',
  skills: 'Skills'
}

const ProfileMandatoryInfo = props => {
  const { phoneCodeList } = useMetaData()
  const {
    fetchLocationList,
    fetchLocationInfo,
    locationOptions,
    locationDetails,
    locationListRequesting,
    setLocationOptions
  } = useLocation()
  const [locationInput, setLocationInput] = useState("")

  const {
    profileInfoRequest,
    requesting,
    error,
    closeModal,
    profileInfoFailure,
    candidate_profile,
    editedPersonalInfoData,
    uploadedResume,
    setUploadedResume,
    descriptionText,
    withParser,
    skills,
    getCandidateSkills,
    skillsRequesting,
  } = props

  setUploadedResume(null)
  const [currentStep, setCurrentStep] = useState('address')
  // eslint-disable-next-line
  const [allSteps, setAllSteps] = useState(STEPS)

  useEffect(() => {
    const steps = []
    if (!editedPersonalInfoData.phone || !editedPersonalInfoData.locations || editedPersonalInfoData.locations.length === 0) {
      steps.push('address')
    }
    if (!editedPersonalInfoData.payment_terms) {
      steps.push('payment_terms')
    }
    if (!editedPersonalInfoData.resume) {
      steps.push('resume')
    }
    steps.push('skills')
    setAllSteps(steps)
    if (steps[0] === 'skills') {
      getCandidateSkills()
    }
    setCurrentStep(steps[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const stateSchema = useMemo(() => {
    const phoneNumberSplits = editedPersonalInfoData?.phone?.split(" ") || []
    const phoneCode = phoneNumberSplits[0]?.replace("+", "")
    const selectedPhoneCode = phoneCode
      ? phoneCodeList?.find(item => {
          return item.key === phoneCode
        }) || null
      : null

    return {
      phoneCode: {
        value: selectedPhoneCode,
        error: ""
      },
      phone: {
        value: phoneNumberSplits[1],
        error: ""
      },
      location: {
        value: editedPersonalInfoData?.locations?.length
        ? editedPersonalInfoData?.locations[0]?.formatted_address?? editedPersonalInfoData?.locations[0]?.description
        : "",
        error: ""
      },
      placeId: {
        value: editedPersonalInfoData?.place_id,
        error: ""
      }
    }
  }, [phoneCodeList, editedPersonalInfoData])

  const validationStateSchema = {
    phoneCode: {
      required: true
    },
    phone: {
      required: true,
      validator: validator.phone
    },
    location: {
      required: false
    },
    placeId: {
      required: true
    }
  }

  const { state, handleOnChange, disable } = useForm(
    stateSchema,
    validationStateSchema
  )

  const handleInputChange = field => e => handleOnChange(field, e.target.value)

  useEffect(() => {
    setLocationOptions([])
  }, [setLocationOptions])

  useEffect(() => {
    if (
      locationDetails?.length > 0 &&
      locationDetails[0]?.place_id !== state.placeId?.value
    ) {
      handleInputChange("placeId")({
        target: { value: locationDetails[0]?.place_id }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationDetails, state?.placeId?.value])

  useEffect(() => {
    profileInfoFailure(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileInfoFailure])

  useEffect(() => {
    if (
      editedPersonalInfoData?.place_id &&
      (!locationDetails?.length ||
        editedPersonalInfoData?.place_id !== locationDetails[0]?.place_id)
    ) {
      setLocationOptions([])
      fetchLocationInfo(editedPersonalInfoData?.place_id)
    }
  }, [fetchLocationInfo, editedPersonalInfoData?.place_id, locationDetails, setLocationOptions])

  const handleLocationInputChange = (e, value) => {
    setLocationInput(value)
    typeof value === "string" && value?.length >= 3
      ? fetchLocationList(value)
      : setLocationOptions([])
  }

  const handleSelectChange = field => (e, value) => {
    handleOnChange(field, value)
    field === "location" && fetchLocationInfo(value?.place_id)
  }

  const clearError = () => profileInfoFailure(false)

  const [hasSubmittedForm, setHasSubmittedForm] = useState(false)
  const submitAddress = () => {
    const data = {
      id: editedPersonalInfoData?.id,
      phone: `+${state.phoneCode.value?.key} ${state.phone.value}`,
      locations: locationDetails
    }
    profileInfoRequest(data)
    if (!editedPersonalInfoData.payment_terms) {
      setCurrentStep('payment_terms')
    } else {
      if (!editedPersonalInfoData.resume) {
        setCurrentStep('resume')
      } else {
        setHasSubmittedForm(true)
      }
    }
  }

  const submitResume = () => {
    getCandidateSkills()
    setCurrentStep('skills')
  }

  const submitPaymentTerms = () => {
    if (!editedPersonalInfoData.resume) {
      setCurrentStep('resume')
    } else {
      getCandidateSkills()
      setCurrentStep('skills')
    }
  }

  useEffect(() => {
    if (hasSubmittedForm) {
      closeModal?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidate_profile, hasSubmittedForm])

  const [isResumeUploaded, setIsResumeUploaded] = useState(editedPersonalInfoData.resume)
  useEffect(() => {
    if (uploadedResume?.[0].name) {
      setIsResumeUploaded(uploadedResume?.[0].name)
    }
  }, [uploadedResume])

  const loadSkills = useCallback(() => {
    getCandidateSkills()
  }, [getCandidateSkills])

  useEffect(() => {
    if (currentStep === 'skills') {
      loadSkills()
    }
  }, [loadSkills, currentStep])

  const [show, setShow] = useState(false)
  const [currentModal, setCurrentModal] = useState({})
  const handleSkillClick = (editData) => id => {
    setShow(true)
    setCurrentModal({ section: 'profile-skills', editData, id })
  }

  const submitSkills = () => {
    setHasSubmittedForm(true)
  }

  const [orderedSkills, setOrderedSkills] = useState(skills)
  useEffect(() => {
    const skillsCopy = skills.map((x) => x)
    skillsCopy.sort(orderByLabel)
    setOrderedSkills(skillsCopy)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skills])

  const getStepTitle = (step) => {
    return stepTitles[step]?? ''
  }

  const getStepSubtitle = (step) => {
    switch(step) {
      case 'address':
        return 'Please provide your contact information'
      case 'resume':
        return 'Please upload your resume'
      case 'payment_terms':
        return 'Please provide your payment terms'
      case 'skills':
        return skills.length < 8 ? 'Please provide at least 8 skills' : ''
      default:
        return
    }
  }

  return (
    <div className="profile-body-input-wrapper profile-required-info-form">
      <div className='modal-description'>
        {descriptionText?? `The following information is required to apply for this job`}
      </div>
      <div class='modal-title'>{getStepTitle(currentStep)}</div>
      <div className='modal-subtitle'>
        {getStepSubtitle(currentStep)?? ''}
      </div>
      {currentStep === 'address' && (
        <>
          <PersonalInfoInputsForm
            locationInput={locationInput}
            handleInputChange={handleInputChange}
            phoneCodeList={phoneCodeList}
            state={state}
            handleSelectChange={handleSelectChange}
            clearError={clearError}
            error={error}
            editedPersonalInfoData={editedPersonalInfoData}
            locationOptions={locationOptions}
            handleLocationInputChange={handleLocationInputChange}
            locationListRequesting={locationListRequesting}
          />
          <div className="button-wrapper">
            <Button
              title="Next"
              showSpinner={requesting}
              className={`${disable ? "disabled-btn" : ""}`}
              onClick={submitAddress}
              disabled={disable}
            />
          </div>
        </>
      )}
      {currentStep === 'resume' && (
        <>
          {!editedPersonalInfoData.resume && (
            <div>
              <ResumeUpload
                open={true}
                closeModal={() => {}}
                loadProfileData={() => {}}
                uploadedResume={uploadedResume}
                setUploadedResume={setUploadedResume}
                withParser={withParser}
              />
            </div>
          )}
          <div className="button-wrapper">
            <Button
              title="Next"
              showSpinner={requesting}
              className={`${!isResumeUploaded ? "disabled-btn" : ""}`}
              onClick={submitResume}
              disabled={!isResumeUploaded}
            />
          </div>
        </>
      )}
      {(currentStep === 'payment_terms' && !editedPersonalInfoData.payment_terms) && (
        <PaymentTerms
          onSubmitCallback={submitPaymentTerms}
        />
      )}
      {(currentStep === 'skills') && (
        <>
          <div className='skill-import-wrapper'>
              {skillsRequesting ? <div className='placeholder-container'><PlaceholderCard /></div> :
                <>
                  <div className='skill-card-wrapper'>
                    <SkillCard
                      isEdited={skills?.length > 0}
                      getSkillInfo={orderedSkills}
                      isCandidate={true}
                      hideChart={true}
                      onClick={handleSkillClick(skills)}
                    />
                  </div>

                  <div className="button-wrapper">
                    <Button
                      title="Done"
                      showSpinner={requesting}
                      className={`${(!skills || skills.length < 8)  ? "disabled-btn" : ""}`}
                      onClick={submitSkills}
                      disabled={(!skills || skills.length < 8)}
                    />
                  </div>
                </>
              }
            </div>
            <EditModal
              showModal={show}
              setShowModal={setShow}
              currentModal={currentModal?.section}
              setCurrentData={setCurrentModal}
              editData={currentModal?.editData}
              selectedId={currentModal?.id}
            />
        </>
      )}
    </div>
  )
}

const PersonalInfoInputsForm = ({
  locationInput,
  handleInputChange,
  phoneCodeList,
  state,
  handleSelectChange,
  clearError,
  error,
  editedPersonalInfoData,
  locationOptions,
  handleLocationInputChange,
  locationListRequesting
}) => {
  return (
    <div className="input-wrapper">
      {!editedPersonalInfoData.phone && (
        <div className="phone-code-number">
        <div className="phone-code">
          <USelect
            placeholderLabel="Country Code"
            options={phoneCodeList}
            value={state.phoneCode.value}
            onChange={handleSelectChange("phoneCode")}
            onFocus={clearError}
            className={
              error?.phoneCode || state.phoneCode.error
                ? "error-field-wrapper"
                : ""
            }
            inputError={error?.phoneCode || state.phoneCode.error}
            disableClearable
          />
          <div
            className={`error-message ${
              error?.phoneCode || state.phoneCode.error ? "opacity-1" : ""
            }`}
            style={{ marginLeft: "24px" }}
          >
            {error?.phoneCode || state.phoneCode.error}
          </div>
        </div>
        <div className="phone-number">
          <UInput
            id={error?.phone ? "outlined-error-helper-text" : ""}
            placeholder="Phone Number"
            value={state.phone.value || ''}
            onChange={handleInputChange("phone")}
            errorText={error?.phone || state.phone?.error}
            errorClass={error.phone || state.phone?.error ? "opacity-1" : ""}
            onFocus={clearError}
            inputError={error?.phone || state.phone?.error}
          />
        </div>
      </div>
      )}
      {(!editedPersonalInfoData.locations || editedPersonalInfoData.locations.length === 0) && (
        <>
          <USelect
            placeholderLabel="Start typing your address"
            options={locationOptions}
            value={state.location.value || ""}
            onChange={handleSelectChange("location")}
            onInputChange={handleLocationInputChange}
            onFocus={clearError}
            className={
              error?.location || state.placeId.error ? "error-field-wrapper" : ""
            }
            inputError={error?.location || state.placeId.error}
            noOptionsText={
              locationInput ? "No locations found" : "Type to search location"
            }
            locationData={true}
            loading={locationListRequesting}
          />
          <div
            className={`error-message
                ${error?.location || state.placeId.error ? "opacity-1" : ""}`}
          >
            {error?.location || state.placeId.error}
          </div>
        </>
      )}
    </div>
  )
}

function orderByLabel(a, b) {
  if (a.label < b.label) return -1;
  if (a.label > b.label) return 1;
  return 0;
}

const mapStateToProps = state => ({
  profileInfoData: state.profile.profileInfoData,
  requesting: state.profile.requesting,
  error: state.profile.error,
  candidate_profile: state.profile.candidate_profile,
  skillsRequesting: state.profile.skillsRequesting,
  skills: state.profile.skills
})

const mapDispatchToProps = dispatch => ({
  profileInfoRequest: data => dispatch(profileInfoRequest(data)),
  profileInfoFailure: data => dispatch(profileInfoFailure(data)),
  getCandidateSkills: data => dispatch(getProfileSkillRequest(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMandatoryInfo)
