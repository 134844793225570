import React from "react"
// Components
import Modal from "react-bootstrap/Modal"
import { Images } from "../../../theme/Images"
import Button from "../../Button"
import Image from "../../Image"
// Redux
import { connect } from "react-redux"
import { logOut } from "../../../pages/AuthScreens/Login/redux/action"
import { putChangePasswordRequestSuccess } from "../../../pages/CommonScreens/ChangePassword/redux/action"

const PasswordSuccessModal = ({
  showSuccessModal,
  setShowSuccessModal,
  ...props
}) => {
  const { putChangePasswordRequestSuccess, logOutUser } = props
  const logOut = () => {
    logOutUser()
  }
  const closeModal = () => {
    setShowSuccessModal(false)
    putChangePasswordRequestSuccess(false)
    logOut()
  }

  return (
    <Modal
      show={showSuccessModal}
      setShow={setShowSuccessModal}
      centered
      className="edit-modal logout-modal"
    >
      <Modal.Header>
        <Modal.Title>
          <div className="wrapper flex-justify-end w-100">
            <div className="close-icon" onClick={closeModal}>
              <Image src={Images.closeIcon} />
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="profile-body-input-wrapper password-success-input-wrapper">
          <div className="main-title">
            Password changed successfully. <br />
            Please login again with new password
          </div>
          <div className="button-wrapper">
            <Button title="OK" className="" onClick={closeModal} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const mapDispatchToProps = dispatch => ({
  putChangePasswordRequestSuccess: data =>
    dispatch(putChangePasswordRequestSuccess(data)),
  logOutUser: data => dispatch(logOut(data))
})

export default connect(null, mapDispatchToProps)(PasswordSuccessModal)
