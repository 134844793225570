import { useState } from "react"
import { useIdleTimer } from "react-idle-timer"

/**
 * @param onIdle   - function to notify user when idle timeout is close
 * @param idleTime - number of minutes to wait before user is logged out
 * @param logout   - function to logout the user after the idle timeout
 */
const useIdleTimeout = ({ onIdle, idleTime = 1, logout }) => {
  const oneMinute = 1000 * 60
  const idleTimeout = oneMinute * idleTime
  const [isIdle, setIdle] = useState(false)
  const handleIdle = () => {
    setIdle(true)
    logout()
  }

  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    promptBeforeIdle: idleTimeout > oneMinute ? oneMinute : idleTimeout / 2,
    onPrompt: onIdle,
    onIdle: handleIdle,
    debounce: 500,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200
  })

  return {
    isIdle,
    setIdle,
    idleTimer
  }
}
export default useIdleTimeout
