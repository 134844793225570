import { Route, Switch, useHistory } from "react-router-dom"
import { routes } from "../../Router"
import HomeLayout from "../HomeLayout"
import { connect } from "react-redux"
import { getProfileRequiredInfoRequest } from "../../pages/CandidateScreens/Profile/redux/action"
import { useCallback, useEffect, useState } from "react"
import ProfileMandatoryInfo from '../../components/CandidateProfileEditModal/components/ProfileMandatoryInfo'
import { profileInfoRequest, profileInfoFailure } from "../../pages/CandidateScreens/Profile/redux/action"
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"

const Candidate = (props) => {
  const { profileRequiredInfo, getProfileRequiredInfo, profileRequiredInfoRequesting } = props
  const [uploadedResume, setUploadedResume] = useState()
  const history = useHistory()

  const loadData = useCallback(() => {
    getProfileRequiredInfo()
  }, [getProfileRequiredInfo])

  const loadProfileData = useCallback(() => {
    profileInfoRequest()
  }, [])

  useEffect(() => loadData(), [loadData])
  useEffect(() => loadProfileData(), [loadProfileData])

  const [profileData, setProfileData] = useState({})
  useEffect(() => {
    setProfileData(profileRequiredInfo?.required_candidate_info)
  }, [profileRequiredInfo])

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/candidate") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  }

  const [updateInfoSuccess, setUpdateInfoSuccess] = useState(null)

  const onSave = () => {
    setUpdateInfoSuccess(true)
    history.push('/candidate/profile')
  }

  const [showSpinningWheel, setShowSpinningWheel] = useState(true)

  useEffect(() => {
    setShowSpinningWheel(profileRequiredInfoRequesting || Object.keys(profileData?? {}).length === 0)
  }, [profileData, profileRequiredInfoRequesting])

  return (
    <HomeLayout>
      {showSpinningWheel ? (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
            open={showSpinningWheel}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      ) : (
        <>
          {(isCandidateInformationIncomplete(profileData) && !updateInfoSuccess) ? (
          <>
            {!!profileData && (
              <div class='profile-required-info-container'>
                <ProfileMandatoryInfo
                  descriptionText={'The following information is required to apply to jobs'}
                  closeModal={onSave}
                  editedPersonalInfoData={profileData?? {}}
                  selectedId={profileData?._id}
                  uploadedResume={uploadedResume}
                  setUploadedResume={setUploadedResume}
                  withParser={true}
                ></ProfileMandatoryInfo>
              </div>
            )}
          </>
        ) : (
        <Switch>{getRoutes(routes)}</Switch>
      )}
        </>
      )}

    </HomeLayout>
  )
}

const isCandidateInformationIncomplete = (candidateInfo) => {
  const fields = ['resume', 'locations', 'phone', 'payment_terms', 'skill_count']
  if (!candidateInfo || Object.keys(candidateInfo).length === 0) {
    return true
  }

  return fields.some((field) => {
    const val = candidateInfo[field]
    if (!val) {
      return true
    }

    if (typeof val === 'string') {
      return !val
    }
    if (typeof val === 'object') {
      return Object.keys(val).length === 0
    }
    if (field === 'skill_count') {
      return val < 8
    }
    return false
  })
}

const mapStateToProps = state => ({
  accessToken: state.login.accessToken,
  userType: state.login.userType,
  profileRequiredInfo: state.profile.requiredCandidateInfo,
  profileRequiredInfoRequesting: state.profile.requiredCandidateInfoRequesting,
  profileInfoData: state.profile.profileInfoData
})

const mapDispatchToProps = dispatch => ({
  getProfileRequiredInfo: data => dispatch(getProfileRequiredInfoRequest(data)),
  profileInfoRequest: data => dispatch(profileInfoRequest(data)),
  profileInfoFailure: data => dispatch(profileInfoFailure(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Candidate)
