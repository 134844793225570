import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import React, { useEffect } from "react"

import { postForgotRequest, postForgotRequestFailure } from "./redux/action"
import Button from "../../../components/Button"
import UInput from "../../../components/UInput"
import { useForm } from "../../../hooks"

const ForgotPassword = props => {
  const {
    forgotPasswordRequest,
    error,
    postForgotRequestFailure,
    requesting,
    setEmail
  } = props

  const history = useHistory()

  const stateSchema = {
    email: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    email: {
      required: true
    }
  }

  const { state, handleOnChange } = useForm(stateSchema, validationStateSchema)

  const onSubmit = async () => {
    const data = {
      email: state.email.value
    }
    await forgotPasswordRequest(data, history)
  }

  useEffect(() => {
    postForgotRequestFailure(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goToSignIn = () => history.push("/auth/login")

  const handleInputChange = field => val => {
    handleOnChange(field, val.target.value)
    "email" === field && setEmail(val.target.value)
  }

  const clearErrors = () => postForgotRequestFailure(false)

  return (
    <div className="auth-form-wrapper column-flex right-inside">
      <div className="first-wrapper">
        <div className="auth-welcome-text">
          <h1>Need help logging in?</h1>
          <p>
            Enter your email and we'll send you a link to get back into your
            account
          </p>
        </div>
      </div>
      <div className="second-wrapper">
        <UInput
          placeholder="Email Address"
          value={state.email.value}
          onChange={handleInputChange("email")}
          onFocus={clearErrors}
          errorClass={"forgot-error"}
          required={false}
        />
        <div
          className={`forgot-error-message ${
            error?.email && error?.email ? "opacity-1" : ""
          }`}
        >
          {error?.email &&
          error?.email[0] ===
            "We couldn't find an account associated with that email. Please try a different e-mail address." ? (
            <>
              This email address does exist.
              <span onClick={() => history.push("signup")}>Sign Up</span>
            </>
          ) : (
            <div
              className={`error-message
                                ${
                                  error?.email && error?.email
                                    ? "opacity-1"
                                    : ""
                                }`}
            >
              {error?.email}
            </div>
          )}
        </div>
        <Button
          title="Reset Password"
          onClick={onSubmit}
          showSpinner={requesting}
          className="full-width"
        />
        <div className="remember">
          Remember your password? &nbsp;
          <span onClick={goToSignIn}>Sign In</span>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  requesting: state.forgotPassword.requesting,
  error: state.forgotPassword.error,
  displayOTPModal: state.signup.displayOTPModal
})

const mapDispatchToProps = dispatch => ({
  forgotPasswordRequest: (data, history) =>
    dispatch(postForgotRequest(data, history)),
  postForgotRequestFailure: (data, history) =>
    dispatch(postForgotRequestFailure(data, history))
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
