import React from "react"
import Skeleton from "@mui/material/Skeleton"
import CircularProgress from "@mui/material/CircularProgress"

import "./style.scss"

const PlaceholderCard = ({ section, columns = 3, rows = 1, width }) => {
  const renderChipPlaceholder = () =>
    Array.from({ length: 5 })?.map((_, index) => {
      return (
        <Skeleton
          key={`${section}-${index}`}
          variant="rounded"
          width={100}
          height={40}
          sx={{ borderRadius: "27px" }}
        />
      )
    })

  const renderCircularPlaceholder = () => {
    return Array.from({ length: 3 })?.map((_, index) => {
      return (
        <Skeleton
          key={`${section}-${index}`}
          variant="circular"
          width={24}
          height={24}
        />
      )
    })
  }

  const renderIconContentPlaceholder = () => (
    <div style={{ width: "100%" }}>
      <div className="icon-section">
        <Skeleton variant="rounded" width={70} height={70} />
      </div>
      <div className="content-section">
        <Skeleton
          variant="text"
          sx={{ fontSize: "1rem" }}
          animation="wave"
          width="75%"
        />
        <Skeleton
          variant="text"
          sx={{ fontSize: "0.875rem" }}
          animation="wave"
          width="50%"
        />
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100px"
          animation="wave"
        />
      </div>
    </div>
  )

  const renderTextContentPlaceholder = () => (
    <div style={{ width: "100%" }}>
      {Array.from({ length: rows }).map((_, rowIndex) => {
        return (
          <div className="row-content" key={`row-${rowIndex}`}>
            {Array.from({ length: columns }).map((_, index) => {
              return (
                <div className="text-content" key={`${section}-${index}`}>
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "1rem" }}
                    animation="wave"
                    width="100%"
                  />
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "1.25rem" }}
                    animation="wave"
                    width="100%"
                  />
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )

  const renderMultilineTextPlaceholder = () => (
    <div style={{ width: "100%" }}>
      {Array.from({ length: rows })?.map((_, index) => {
        return (
          <div className="row-content" key={`row-text-${section}-${index}`}>
            <Skeleton
              variant="text"
              sx={{ fontSize: "1rem" }}
              animation="wave"
              width="100%"
            />
          </div>
        )
      })}
    </div>
  )

  const placeholderBySection = {
    "candidate-skills-section": renderChipPlaceholder,
    "candidate-experience-section": renderIconContentPlaceholder,
    "candidate-certificate-section": renderChipPlaceholder,
    "candidate-education-section": renderIconContentPlaceholder,
    "candidate-language-section": renderChipPlaceholder,
    "candidate-projects-section": renderIconContentPlaceholder,
    "candidate-hobbies-section": renderChipPlaceholder,
    "candidate-salary-section": renderTextContentPlaceholder,
    "candidate-personal-info-section": renderTextContentPlaceholder,
    "candidate-social-media-section": renderCircularPlaceholder,
    multilineText: renderMultilineTextPlaceholder
  }
  return (
    <div className="placeholder-card" style={width ? { width } : {}}>
      {placeholderBySection[section] ? (
        placeholderBySection[section]()
      ) : (
        <CircularProgress />
      )}
    </div>
  )
}

export default PlaceholderCard
