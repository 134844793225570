import axios from "axios"
import { appendId } from "./helper"
import { store } from "../reduxStore/store"
import {
  loginRequestSuccess,
  setAccessToken
} from "../pages/AuthScreens/Login/redux/action"
import toast from "react-hot-toast"

// axios.defaults.headers.common['apiKey'] = appConfig.apiKey;
const getResponseJson = async response => {
  const isJsonBlob = apiResponse =>
    apiResponse instanceof Blob && apiResponse.type === "application/json"
  const responseData = isJsonBlob(response?.data)
    ? await response?.data?.text()
    : response || {}

  return typeof responseData === "string"
    ? { ...response, data: JSON.parse(responseData) }
    : responseData
}

function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null
  }
  return response.json ? response.json() : response
}

async function checkStatus(apiResponse) {
  if (apiResponse.status >= 200 && apiResponse.status < 300) {
    if (apiResponse?.data) {
      apiResponse.data = appendId(apiResponse?.data)
    }
    return apiResponse
  }

  if (apiResponse?.response?.data?.detail === "Invalid token.") {
    toast.error("Session expired. Please login again.", {
      id: 'session-expired', // Prevent duplicates using: https://react-hot-toast.com/docs/toast
    })
    localStorage.clear()
    store.dispatch(loginRequestSuccess(false))
    store.dispatch(setAccessToken(false))
  }
  const error = new Error(apiResponse?.response?.statusText)
  error.response = await getResponseJson(apiResponse?.response)
  error.status = apiResponse.status
  throw error
}

const XHR = (url, options) => {
  const isNgrokUrl = url.includes("ngrok-free.app") || url.includes("localhost")
  const headers =
    options?.headers && isNgrokUrl
      ? { ...options.headers, "ngrok-skip-browser-warning": true }
      : options?.headers
  return axios(url, {
    ...options,
    headers
  })
    .then(checkStatus)
    .then(parseJSON)
    .catch(checkStatus)
}

export default XHR
