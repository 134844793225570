import {
  GET_SELECTED_PROFILE_REQUEST,
  GET_SELECTED_PROFILE_SUCCESS,
  GET_SELECTED_PROFILE_FAILURE,
  GET_JOBS_APPLIED_REQUEST,
  GET_JOBS_APPLIED_SUCCESS,
  GET_JOBS_APPLIED_FAILURE,
  POST_JOB_STATUS_REQUEST,
  POST_JOB_STATUS_SUCCESS,
  POST_JOB_STATUS_FAILURE,
  GET_DOWNLOAD_PROFILE_REQUEST,
  GET_DOWNLOAD_PROFILE_SUCCESS,
  POST_CANDIDATE_TYPE_REQUEST,
  POST_CANDIDATE_TYPE_SUCCESS,
  GET_CALL_NOTES_REQUEST,
  POST_CALL_NOTES_REQUEST,
  CALL_NOTES_SUCCESS,
  CALL_NOTES_FAILURE,
  GET_CANDIDATE_APPLICATION_FAILURE,
  GET_CANDIDATE_APPLICATION_REQUEST,
  GET_CANDIDATE_APPLICATION_SUCCESS
} from "./type"

const initialState = {
  applicationInfo: false,
  candidateInfo: false,
  error: false,
  requesting: false,
  candidateJobsApplied: [],
  requestingJobs: false,
  requestingStatusUpdate: false,
  downloadRequesting: false,
  candidateTypeRequesting: false,
  callNotes: [],
  callNotesRequesting: false,
  postCallNotesRequesting: false,
  callNotesError: false
}

const selectedProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SELECTED_PROFILE_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case GET_SELECTED_PROFILE_SUCCESS:
      return {
        ...state,
        requesting: false,
        candidateInfo: action.data
      }

    case GET_SELECTED_PROFILE_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    case GET_JOBS_APPLIED_REQUEST:
      return {
        ...state,
        requestingJobs: true
      }

    case GET_JOBS_APPLIED_SUCCESS:
      return {
        ...state,
        requestingJobs: false,
        candidateJobsApplied: action.data
      }

    case GET_JOBS_APPLIED_FAILURE:
      return {
        ...state,
        requestingJobs: false,
        error: action.data
      }

    case POST_JOB_STATUS_REQUEST:
      return {
        ...state,
        requestingStatusUpdate: true
      }

    case POST_JOB_STATUS_SUCCESS:
      return {
        ...state,
        requestingStatusUpdate: false
      }

    case POST_JOB_STATUS_FAILURE:
      return {
        ...state,
        requestingStatusUpdate: false,
        error: action.data
      }

    case GET_DOWNLOAD_PROFILE_REQUEST:
      return {
        ...state,
        downloadRequesting: true
      }

    case GET_DOWNLOAD_PROFILE_SUCCESS:
      return {
        ...state,
        downloadRequesting: false
      }

    case POST_CANDIDATE_TYPE_REQUEST:
      return {
        ...state,
        candidateTypeRequesting: true
      }

    case POST_CANDIDATE_TYPE_SUCCESS:
      return {
        ...state,
        candidateTypeRequesting: false
      }

    case POST_CALL_NOTES_REQUEST:
      return {
        ...state,
        postCallNotesRequesting: true
      }

    case GET_CALL_NOTES_REQUEST:
      return {
        ...state,
        callNotesRequesting: true
      }

    case CALL_NOTES_SUCCESS:
      return {
        ...state,
        callNotesRequesting: false,
        postCallNotesRequesting: false,
        callNotes: action.data
      }

    case CALL_NOTES_FAILURE:
      return {
        ...state,
        callNotesRequesting: false,
        postCallNotesRequesting: false,
        callNotesError: action.data
      }

      case GET_CANDIDATE_APPLICATION_REQUEST:
        return {
          ...state,
          getCandidateApplicationRequesting: true,
          applicationInfo: null
        }

      case GET_CANDIDATE_APPLICATION_SUCCESS:
        return {
          ...state,
          getCandidateApplicationRequesting: false,
          applicationInfo: action.data
        }

      case GET_CANDIDATE_APPLICATION_FAILURE:
        return {
          ...state,
          getCandidateApplicationRequesting: false,
          applicationInfo: null,
          getCandidateApplicationError: action.data
        }

    default:
      return state
  }
}

export default selectedProfileReducer
