import React, { useCallback, useEffect, useMemo, useState } from "react"
import { connect } from "react-redux"

import Button from "../../../components/Button"
import UInput from "../../../components/UInput"
import { useForm, useLocation, useMetaData } from "../../../hooks"
import USelect from "../../../components/USelect"
import validator from "../../../utils/validation"
import {
  postRecruiterProfileRequest,
  postRecruiterProfileFailure
} from "./redux/action"

import "./style.scss"

// TODO when page is reloaded on /recruiter/profile/edit, the profileData object is incomplete (missing the id).
// TODO Save button disabled when form is complete before editing...

const RecruiterEditProfile = props => {
  const { phoneCodeList } = useMetaData()
  const {
    postRecruiterProfileRequest,
    postRecruiterProfileFailure,
    requesting,
    BeError,
    profileData,
    loginInfo,
    history
  } = props
  const {
    fetchLocationList,
    fetchLocationInfo,
    locationOptions,
    locationDetails,
    locationListRequesting,
    setLocationOptions
  } = useLocation()
  const [locationInput, setLocationInput] = useState("")
  profileData.place_id ||= profileData?.location?.length
    ? profileData?.location[0]?.place_id
    : ""

  const profileUpdated = useCallback(
    updatedProfile => {
      return Object.keys(updatedProfile).some(profileItem => {
        const profileItemData =
          profileData[profileItem] || loginInfo?.user[profileItem]

        return updatedProfile[profileItem] !== profileItemData
      })
    },
    [profileData, loginInfo]
  )

  useEffect(() => {
    postRecruiterProfileFailure(false)
  }, [postRecruiterProfileFailure])

  const stateSchema = useMemo(
    () => {
      const phoneNumberSplits = profileData?.phone?.split(" ") || []
    const phoneCode = phoneNumberSplits[0]?.replace("+", "")
    const selectedPhoneCode = phoneCode
      ? phoneCodeList?.find(item => {
          return item.key === phoneCode
        }) || null
      : null
      return ({
        company: {
          value: profileData?.company,
          error: ""
        },
        email: {
          value: profileData?.email || loginInfo?.user?.email,
          error: ""
        },
        industry: {
          value: profileData?.industry,
          error: ""
        },
        team: {
          value: profileData?.team?.join(", "),
          error: ""
        },
        phoneCode: {
          value: selectedPhoneCode,
          error: ""
        },
        phone: {
          value: phoneNumberSplits[1],
          error: ""
        },
        location: {
          value: profileData?.locations?.length
          ? profileData?.locations[0]?.formatted_address?? profileData?.locations[0]?.description
          : "",
          error: ""
        },
        placeId: {
          value: profileData?.place_id,
          error: ""
        }
      })
    },
    [profileData, loginInfo, phoneCodeList]
  )

  const validationStateSchema = {
    company: {
      required: true
    },
    email: {
      required: true,
      validator: validator.email
    },
    industry: {
      required: true
    },
    team: {
      required: false
    },
    phoneCode: {
      required: true
    },
    phone: {
      required: true,
      validator: validator.phone
    },
    location: {
      required: false
    },
    placeId: {
      required: true
    }
  }

  const { state, handleOnChange, disable } = useForm(
    stateSchema,
    validationStateSchema
  )
  const clearError = () => postRecruiterProfileFailure(false)

  const handleInputChange = field => e => handleOnChange(field, e.target.value)

  const Submit = () => {
    const data = {
      id: profileData?.id,
      company: state.company.value,
      email: state.email.value,
      industry: state.industry.value,
      phone: `+${state.phoneCode.value?.key} ${state.phone.value}`,
      locations: locationDetails
    }

    if (profileUpdated(data)) {
      postRecruiterProfileRequest(data, history)
    } else {
      history.push("/recruiter/profile")
    }
  }

  useEffect(() => {
    setLocationOptions([])
  }, [setLocationOptions])

  useEffect(() => {
    if (
      profileData?.place_id &&
      (!locationDetails?.length ||
        profileData?.place_id !== locationDetails[0]?.place_id)
    ) {
      setLocationOptions([])
      fetchLocationInfo(profileData?.place_id)
    }
  }, [fetchLocationInfo, profileData?.place_id])

  useEffect(() => {
    if (
      locationDetails?.length > 0 &&
      state.location?.value?.place_id &&
      locationDetails[0]?.place_id !== state.placeId.value
    ) {
      handleInputChange("placeId")({
        target: { value: locationDetails[0]?.place_id }
      })
    }
  }, [state.location, locationDetails])

  const handleSelectChange = field => (e, value) => {
    handleOnChange(field, value)
    field === "location" && fetchLocationInfo(value?.place_id)
  }

  const handleLocationInputChange = (e, value) => {
    setLocationInput(value)
    typeof value === "string" && value?.length >= 3
      ? fetchLocationList(value)
      : setLocationOptions([])
  }

  return (
    <div className="RecruiterProfile-section">
      <div className="heading-div">
        <h3>Edit Recruiter Profile</h3>
      </div>
      <div className="profile-details-section">
        <div className="profile-content card">
          <UInput
            placeholder="Company"
            value={state.company.value}
            onChange={handleInputChange("company")}
            errorClass={
              BeError?.company || state.company.error ? "opacity-1" : ""
            }
            errorText={BeError?.company || state.company.error}
            inputError={BeError?.company}
            onFocus={clearError}
          />
          <UInput
            placeholder="Email Address"
            value={state.email.value}
            onChange={handleInputChange("email")}
            errorClass={BeError?.email || state.email.error ? "opacity-1" : ""}
            errorText={BeError?.email || state.email.error}
            inputError={BeError?.email}
            onFocus={clearError}
          />
          <div className="phone-code-number">
            <div className="phone-code">
              <USelect
                placeholderLabel="Country Code"
                options={phoneCodeList}
                value={state.phoneCode.value}
                onChange={handleSelectChange("phoneCode")}
                onFocus={clearError}
                className={
                  BeError?.phoneCode || state.phoneCode.error
                    ? "error-field-wrapper"
                    : ""
                }
                inputError={BeError?.phoneCode || state.phoneCode.error}
                disableClearable
              />
              <div
                className={`error-message ${
                  BeError?.phoneCode || state.phoneCode.error ? "opacity-1" : ""
                }`}
                style={{ marginLeft: "24px" }}
              >
                {BeError?.phoneCode || state.phoneCode.error}
              </div>
            </div>
            <div className="phone-number">
              <UInput
                id={BeError?.phone ? "outlined-error-helper-text" : ""}
                placeholder="Phone Number"
                value={state.phone.value}
                onChange={handleInputChange("phone")}
                errorText={BeError?.phone || state.phone?.error}
                errorClass={BeError.phone || state.phone?.error ? "opacity-1" : ""}
                onFocus={clearError}
                inputError={BeError?.phone || state.phone?.error}
              />
            </div>
          </div>
          <UInput
            placeholder="Industry"
            value={state.industry.value}
            onChange={handleInputChange("industry")}
            errorClass={
              BeError?.industry || state.industry.error ? "opacity-1" : ""
            }
            errorText={BeError?.industry || state.industry.error}
            inputError={BeError?.industry}
            onFocus={clearError}
          />

          <UInput
            placeholder="Team"
            value={state.team.value}
            onChange={handleInputChange("team")}
            errorClass={BeError?.team || state.team.error ? "opacity-1" : ""}
            errorText={BeError?.team || state.team.error}
            inputError={BeError?.team}
            onFocus={clearError}
            disabled
          />
          <USelect
            placeholderLabel="Search a Location"
            options={locationOptions}
            value={state.location.value || ""}
            onChange={handleSelectChange("location")}
            onInputChange={handleLocationInputChange}
            onFocus={clearError}
            className={
              BeError?.location || state.placeId.error
                ? "error-field-wrapper"
                : ""
            }
            inputError={BeError?.location || state.placeId.error}
            noOptionsText={
              locationInput ? "No locations found" : "Type to search location"
            }
            locationData={true}
            loading={locationListRequesting}
          />
          <div
            className={`error-message
              ${BeError?.location || state.placeId.error ? "opacity-1" : ""}`}
          >
            {BeError?.location || state.placeId.error}
          </div>
          <div className="btn-wrapper">
            <Button
              title="Save"
              onClick={Submit}
              className={disable ? "disabled-btn" : "save-btn"}
              disabled={disable ? true : false}
              showSpinner={requesting}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = state => ({
  profileData: state.getprofileData.profileData,
  loginInfo: state.login.loginInfo,
  requesting: state.recruiterprofile.requesting,
  BeError: state.recruiterprofile.error
})
const mapDispatchToProps = dispatch => ({
  postRecruiterProfileRequest: (data, history) =>
    dispatch(postRecruiterProfileRequest(data, history)),
  postRecruiterProfileFailure: data =>
    dispatch(postRecruiterProfileFailure(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecruiterEditProfile)
