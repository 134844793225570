import { all, call, put, takeLatest } from "redux-saga/effects"
// import AsyncStorage from '@react-native-community/async-storage';
// config
import { BASE_URL } from "../../../../config/app"
// utils
import XHR from "../../../../utils/XHR"
// types
import { POST_RECRUITER_PROFILE_REQUEST } from "./type"
// actions
import {
  postRecruiterProfileSuccess,
  postRecruiterProfileFailure
} from "./action"

async function recruiterProfileAPI(data) {
  const URL = `${BASE_URL}api/v1/recruiter/profile/${
    data?.id ? data.id + "/" : ""
  }`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: data?.id ? "PUT" : "POST",
    data
  }
  return XHR(URL, options)
}

function* recruiterProfileData({ data, history }) {
  try {
    const response = yield call(recruiterProfileAPI, data)
    yield put(
      postRecruiterProfileSuccess(
        Array.isArray(response?.data) ? response?.data[0] : response?.data
      )
    )
    history.push("/recruiter/profile")
  } catch (e) {
    const { response } = e

    yield put(postRecruiterProfileFailure(response?.data))
  }
}

export default all([
  takeLatest(POST_RECRUITER_PROFILE_REQUEST, recruiterProfileData)
])
