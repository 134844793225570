import React from "react"
import Image from "../Image"
import { Images } from "../../theme/Images"

import EmailIcon from "@mui/icons-material/Email"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import AddIcon from "@mui/icons-material/Add"
import IconButton from "../IconButton"

import "./style.scss"
import PlaceholderCard from "../PlaceholderCard"

const ProfileDetailCard = ({
  isEdited,
  isCandidate,
  profileInfo,
  loginInfo,
  onClick,
  loading
}) => {
  const handleOnClick = id => () => {
    onClick(id)
  }

  return (
    <div className="profile-detail-card">
      {isEdited && !isCandidate && Boolean(profileInfo?.id) ? (
        <div className="profile-detail-card__body">
          <div className="sub-title-fields flex-align-center">
            <div className="type">
              <p>Email</p>
              <h2>{profileInfo?.email}</h2>
            </div>
            <div className="type">
              <p>Phone</p>
              <h2>{profileInfo?.phone}</h2>
            </div>
          </div>
          <div className="sub-title-fields flex-align-center">
            <div className="type">
              <p>Address</p>
              <h2>
                {profileInfo?.locations?.[0]?.formatted_address}
              </h2>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {isCandidate && (
        <div className="personal-details">
          <div className="title flex-align-center">
            <h1>
              {profileInfo?.name ||
                loginInfo.user?.name ||
                loginInfo.user?.username}
            </h1>
            {isEdited && profileInfo?.id && (
              <div className="edit-img flex-justify-center">
                <Image
                  src={Images.editIcon}
                  onClick={handleOnClick(profileInfo?.id)}
                />
              </div>
            )}
          </div>
          {!loading && isEdited && profileInfo?.id ? (
            <>
              <p className="email-phone">
                <span className="email-icon">
                  <EmailIcon sx={{ fontSize: "14px" }} />
                </span>
                <span className="email">
                  {profileInfo?.email || loginInfo?.user?.email}
                </span>
                <span className="phone">{profileInfo?.phone}</span>
              </p>
              {profileInfo?.locations && profileInfo?.locations[0]?.formatted_address && (
                <>
                  <p className="address">
                    <span className="location-icon">
                      <LocationOnIcon sx={{ fontSize: "14px" }} />
                    </span>
                    <span className="address1">
                      {profileInfo?.locations[0]?.formatted_address}&nbsp;
                    </span>
                  </p>
                </>
              )}
            </>
          ) : loading ? (
            <PlaceholderCard rows="3" section="multilineText" width="100%" />
          ) : (
            <div className="button-wrapper">
              <IconButton
                icon={<AddIcon />}
                label="Add Personal Info"
                onClick={handleOnClick()}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default ProfileDetailCard
