import { all, call, put, takeLatest } from "redux-saga/effects"

// config
import { BASE_URL } from "../../../../config/app"
// utils
import XHR from "../../../../utils/XHR"
import toast from "react-hot-toast"

import {
  GET_TEAMS_DATA_REQUEST,
  ADD_NEW_TEAM,
  DELETE_TEAM,
  POST_EDIT_TEAM_REQUEST
} from "./type"

import {
  getAllTeamsData,
  getAllTeamsDataSuccess,
  getAllTeamsDataFailure,
  postAddTeamSuccess,
  postAddTeamError,
  closeModal,
  deleteRecordSuccess,
  deleteRecordFailure,
  editTeamRequestSuccess,
  editTeamRequestFailure
} from "./action"

const getAdminTeamsData = async () => {
  const URL = `${BASE_URL}api/v1/teams/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getTeams() {
  try {
    const response = yield call(getAdminTeamsData)
    yield put(getAllTeamsDataSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(getAllTeamsDataFailure(response?.data))
  }
}

async function addNewTeam(data) {
  const URL = `${BASE_URL}api/v1/teams/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* addTeam({ data }) {
  try {
    const response = yield call(addNewTeam, data)
    yield put(postAddTeamSuccess(response?.data))
    toast.success(response?.data?.message)
    yield put(closeModal(false))
    yield put(getAllTeamsData({}))
  } catch (e) {
    const { response } = e
    yield put(postAddTeamError(response?.data))
    toast.error(response?.data?.error)
    yield put(closeModal(false))
  }
}

const deleteTeamsRecord = async id => {
  const URL = `${BASE_URL}api/v1/teams/${id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "DELETE"
  }
  return XHR(URL, options)
}

function* deleteTeam({ id }) {
  try {
    const response = yield call(deleteTeamsRecord, id)
    if (response && response?.data?.success) {
      toast.success(
        `Team with name ${response?.data?.team_name} deleted successfully`
      )
    }
    yield put(deleteRecordSuccess(response?.data))
    yield put(closeModal(false))
    yield put(getAllTeamsData({}))
  } catch (e) {
    const { response } = e
    yield put(deleteRecordFailure(response?.error_message))
    yield put(closeModal(false))
  }
}

const editTeamRecord = async (data, id) => {
  const URL = `${BASE_URL}api/v1/teams/${id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "PUT",
    data
  }
  return XHR(URL, options)
}

function* editTeam({ data, id }) {
  try {
    const response = yield call(editTeamRecord, data, id)
    if (response && response?.data?.message) {
      toast.success(response?.data?.message)
      yield put(editTeamRequestSuccess(response?.data))
      yield put(closeModal(false))
      yield put(getAllTeamsData({}))
    }
  } catch (e) {
    const { response } = e
    yield put(editTeamRequestFailure(response?.error_message))
    toast.error(response?.error_message)
    yield put(closeModal(false))
  }
}

export default all([
  takeLatest(GET_TEAMS_DATA_REQUEST, getTeams),
  takeLatest(ADD_NEW_TEAM, addTeam),
  takeLatest(DELETE_TEAM, deleteTeam),
  takeLatest(POST_EDIT_TEAM_REQUEST, editTeam)
])
