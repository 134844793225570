import React from "react"
import Button from "@mui/material/Button"

import "./style.scss"

const IconButton = ({ className, icon, endIcon, label, onClick }) => {
  return (
    <Button
      variant="contained"
      startIcon={icon}
      endIcon={endIcon}
      className={`icon-button ${className}`}
      onClick={onClick}
    >
      {label}
    </Button>
  )
}

export default IconButton
