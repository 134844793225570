import {
  GET_LOCATION_LIST_REQUEST,
  GET_LOCATION_LIST_SUCCESS,
  GET_LOCATION_INFO_REQUEST,
  GET_LOCATION_INFO_SUCCESS
} from "./type"

const initialState = {
  locationList: [],
  locationListRequesting: false,
  locationInfo: [],
  locationInfoRequesting: false
}

const locationDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOCATION_LIST_REQUEST:
      return {
        ...state,
        locationListRequesting: true
      }

    case GET_LOCATION_LIST_SUCCESS:
      return {
        ...state,
        locationListRequesting: false,
        locationList: action.data
      }

    case GET_LOCATION_INFO_REQUEST:
      return {
        ...state,
        locationInfoRequesting: true
      }

    case GET_LOCATION_INFO_SUCCESS:
      return {
        ...state,
        locationInfoRequesting: false,
        locationInfo: action.data
      }
    default:
      return state
  }
}

export default locationDataReducer
