import React, { useMemo } from "react"
import { useHistory } from "react-router-dom"
import SavedJobs from "./SavedJobs"
import { connect } from "react-redux"
import {
  getSavedJobsRequest,
  getApplyJobsRequest,
  getArchivedJobsRequest,
  getInterviewingJobsRequest,
  getOfferedJobsRequest,
  getHiredJobsRequest,
  getRejectedJobsRequest
} from "../Home/redux/action"

const Jobs = props => {
  const {
    getSavedJobsDispatch,
    getAppliedJobsDispatch,
    getArchivedJobsDispatch,
    getInterviewingStatus,
    getOfferedStatus,
    getHiredStatus,
    getRejectedStatus,
    savedJobsData,
    appliedJobsData,
    interviewingJobsData,
    offeredJobsData,
    hiredJobsData,
    rejectedJobsData,
    archivedJobsData,
    savedJobRequesting,
    applyJobRequesting,
    archivedJobRequesting,
    interviewingJobRequesting,
    offeredJobRequesting,
    hiredJobRequesting,
    rejectedJobRequesting
  } = props

  const history = useHistory()
  const { location } = history
  const activeMenuItem = location?.state?.activeSection
  if (location?.state?.activeSection?.section === undefined) {
    history.push("/candidate/jobs/my_jobs", {
      activeSection: {
        activeElement: "/candidate/jobs/my_jobs",
        section: "candidate-applied-jobs"
      }
    })
  }

  const sectionData = useMemo(() => {
    const sectionConf = {
      "candidate-applied-jobs": {
        header: "Applied Jobs",
        getMethod: getAppliedJobsDispatch,
        data: appliedJobsData,
        loading: applyJobRequesting,
        status: "Applied"
      },
      "candidate-saved-jobs": {
        header: "Saved Jobs",
        getMethod: getSavedJobsDispatch,
        data: savedJobsData,
        loading: savedJobRequesting,
        status: "Saved"
      },
      "candidate-interviewing-jobs": {
        header: "Interviewing Status",
        getMethod: getInterviewingStatus,
        data: interviewingJobsData,
        loading: interviewingJobRequesting,
        status: "Interviewing"
      },
      "candidate-offered-jobs": {
        header: "Offered Status",
        getMethod: getOfferedStatus,
        data: offeredJobsData,
        loading: offeredJobRequesting,
        status: "Offered"
      },
      "candidate-hired-jobs": {
        header: "Hired Status",
        getMethod: getHiredStatus,
        data: hiredJobsData,
        loading: hiredJobRequesting,
        status: "Hired"
      },
      "candidate-rejected-jobs": {
        header: "Rejected Status",
        getMethod: getRejectedStatus,
        data: rejectedJobsData,
        loading: rejectedJobRequesting,
        status: "Rejected"
      },
      "candidate-archived-jobs": {
        header: "Archived Jobs",
        getMethod: getArchivedJobsDispatch,
        data: archivedJobsData,
        loading: archivedJobRequesting,
        status: "Archived"
      }
    }

    return sectionConf[activeMenuItem?.section || "candidate-saved-jobs"]
  }, [
    activeMenuItem,
    getSavedJobsDispatch,
    getAppliedJobsDispatch,
    getArchivedJobsDispatch,
    getInterviewingStatus,
    getOfferedStatus,
    getHiredStatus,
    getRejectedStatus,
    savedJobsData,
    appliedJobsData,
    interviewingJobsData,
    offeredJobsData,
    hiredJobsData,
    rejectedJobsData,
    archivedJobsData,
    savedJobRequesting,
    applyJobRequesting,
    archivedJobRequesting,
    interviewingJobRequesting,
    offeredJobRequesting,
    hiredJobRequesting,
    rejectedJobRequesting
  ])

  return <SavedJobs sectionData={sectionData} />
}

const mapStateToProps = state => ({
  savedJobsData: state.candidateJobs.savedJobsData,
  appliedJobsData: state.candidateJobs.applyJobsData,
  archivedJobsData: state.candidateJobs.archivedJobsData,
  interviewingJobsData: state.candidateJobs.interviewingJobsData,
  offeredJobsData: state.candidateJobs.offeredJobsData,
  hiredJobsData: state.candidateJobs.hiredJobsData,
  rejectedJobsData: state.candidateJobs.rejectedJobsData,
  savedJobRequesting: state.candidateJobs.savedJobRequesting,
  applyJobRequesting: state.candidateJobs.applyJobRequesting,
  archivedJobRequesting: state.candidateJobs.archivedJobRequesting,
  interviewingJobRequesting: state.candidateJobs.interviewingJobRequesting,
  offeredJobRequesting: state.candidateJobs.offeredJobRequesting,
  hiredJobRequesting: state.candidateJobs.hiredJobRequesting,
  rejectedJobRequesting: state.candidateJobs.rejectedJobRequesting,
  jobInformation: state.candidateJobs.jobInformation,
  showJobsModal: state.candidateJobs.showModal
})

const mapDispatchToProps = dispatch => ({
  getSavedJobsDispatch: data => dispatch(getSavedJobsRequest(data)),
  getAppliedJobsDispatch: data => dispatch(getApplyJobsRequest(data)),
  getArchivedJobsDispatch: data => dispatch(getArchivedJobsRequest(data)),
  getInterviewingStatus: data => dispatch(getInterviewingJobsRequest(data)),
  getOfferedStatus: data => dispatch(getOfferedJobsRequest(data)),
  getHiredStatus: data => dispatch(getHiredJobsRequest(data)),
  getRejectedStatus: data => dispatch(getRejectedJobsRequest(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Jobs)
