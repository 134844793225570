import { all, call, put, takeLatest } from "redux-saga/effects"
// import AsyncStorage from '@react-native-community/async-storage';
// config
import { BASE_URL } from "../../../../config/app"
// utils
import XHR from "../../../../utils/XHR"
// types
import { PUT_CHANGE_PASSWORD_REQUEST } from "./type"
// actions
import {
  putChangePasswordRequestSuccess,
  putChangePasswordRequestFailure
} from "./action"
import toast from "react-hot-toast"

async function changePasswordAPI(data) {
  const URL = `${BASE_URL}api/v1/password-change/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* changePasswordRequest({ data }) {
  try {
    const response = yield call(changePasswordAPI, data)
    yield put(putChangePasswordRequestSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(putChangePasswordRequestFailure(response?.data))
    if (response?.data?.error) {
      toast.error(response?.data?.error)
    }
  }
}

export default all([
  takeLatest(PUT_CHANGE_PASSWORD_REQUEST, changePasswordRequest)
])
