import React, { useMemo } from "react"

const LevelBar = ({ level }) => {
  const barFillColors = useMemo(() => {
    const levels = Array.from({ length: 5 })

    return levels.map((_, index) => {
      return level > index ? "#1D8EE6" : "#DDE2E5"
    })
  }, [level])

  return (
    <svg
      width="46"
      height="18"
      viewBox="0 0 46 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="13.5"
        width="5"
        height="4"
        rx="2"
        fill={barFillColors[0]}
      />
      <rect
        x="10.5"
        y="10.5"
        width="5"
        height="7"
        rx="2"
        fill={barFillColors[1]}
      />
      <rect
        x="20.5"
        y="7.5"
        width="5"
        height="10"
        rx="2"
        fill={barFillColors[2]}
      />
      <rect
        x="30.5"
        y="3.5"
        width="5"
        height="14"
        rx="2"
        fill={barFillColors[3]}
      />
      <rect
        x="40.5"
        y="0.5"
        width="5"
        height="17"
        rx="2"
        fill={barFillColors[4]}
      />
    </svg>
  )
}

export default LevelBar
