import { connect } from "react-redux"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import React, { useEffect } from "react"
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"

import { getProfileDataRequest } from "./redux/action"
import { history } from "../../../reduxStore/store"
import { Images } from "../../../theme/Images"
import IconButton from "../../../components/IconButton"
import Image from "../../../components/Image"

import "./style.scss"

// TODO profileData might be missing after inviting a user that signed up as a candidate. This needs a fix.
const RecruiterProfile = props => {
  const { getProfileDataRequest, requesting, profileData, loginInfo } = props

  useEffect(() => {
    getProfileDataRequest()
  }, [getProfileDataRequest])

  const goEditProfile = () => {
    history.push("/recruiter/profile/edit")
  }

  // TODO fix list of teams the recruiter is in
  return (
    <div className="RecruiterProfile-section">
      <Backdrop
        sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
        open={requesting}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="heading-div flex-justify-between">
        <h3> Recruiter Profile</h3>
        <IconButton
          src={Images.editIcon}
          className="border-button"
          icon={<EditOutlinedIcon />}
          label="Edit"
          onClick={goEditProfile}
        />
      </div>
      <div className="profile-details-section">
        <div className="profile-content card">
          <div className="sub-text">
            <div className="sub-text-icon">
              <Image src={Images.businessIcon} />
            </div>
            <div className="sub-text-content">
              <label className="label">Company</label>
              <p className="sub-heading">{profileData?.company}</p>
            </div>
          </div>
          <div className="sub-text">
            <div className="sub-text-icon">
              <Image src={Images.mailIcon} />
            </div>
            <div className="sub-text-content">
              <label className="label">Email</label>
              <p className="sub-heading">
                {profileData?.email || loginInfo?.user?.email}
              </p>
            </div>
          </div>
          <div className="sub-text">
            <div className="sub-text-icon">
              <Image src={Images.phoneIcon} />
            </div>
            <div className="sub-text-content">
              <label className="label">Phone Number</label>
              <p className="sub-heading">{profileData?.phone}</p>
            </div>
          </div>
          <div className="sub-text">
            <div className="sub-text-icon">
              <Image src={Images.businessCenterIcon} />
            </div>
            <div className="sub-text-content">
              <label className="label">Industry</label>
              <p className="sub-heading">{profileData?.industry}</p>
            </div>
          </div>
          <div className="sub-text">
            <div className="sub-text-icon">
              <Image src={Images.peopleIcon} />
            </div>
            <div className="sub-text-content">
              <label className="label">Team</label>
              <p className="sub-heading">{profileData?.team?.join(", ")}</p>
            </div>
          </div>
          <div className="sub-text">
            <div className="sub-text-icon">
              <Image src={Images.locationIcon} />
            </div>
            <div className="sub-text-content">
              <label className="label">Address</label>
              <p className="sub-heading">
                {profileData?.locations?.[0]?.formatted_address}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = state => ({
  profileData: state.getprofileData.profileData,
  requesting: state.getprofileData.requesting,
  loginInfo: state.login.loginInfo
})
const mapDispatchToProps = dispatch => ({
  getProfileDataRequest: data => dispatch(getProfileDataRequest(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(RecruiterProfile)
