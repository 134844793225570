import React from "react"
import { ReactComponent as NewLogo } from "../../assets/images/newLogo.svg"

import "./style.scss"

const Logo = ({ fontSize = "2.5vw" }) => {
  return (
    <div className="app-logo" style={{ fontSize }}>
      <div className="app-logo__image">
        <NewLogo />
      </div>
      <div className="app-logo__text">
        <div className="app-logo__text__title">
          Experis<sup>&#174;</sup>
        </div>
        <div className="app-logo__text__subtitle">ManpowerGroup</div>
      </div>
    </div>
  )
}

export default Logo
