import {
  GET_ADMIN_DASHBOARD_REQUEST,
  GET_ADMIN_DASHBOARD_REQUEST_SUCCESS,
  GET_ADMIN_DASHBOARD_REQUEST_FAILURE,
  GET_ACCOUNT_CREATION_ACTIVITY_REQUEST,
  GET_ACCOUNT_CREATION_ACTIVITY_SUCCESS,
  GET_ACCOUNT_CREATION_ACTIVITY_FAILURE,
  GET_LOGIN_ACTIVITY_REQUEST,
  GET_LOGIN_ACTIVITY_SUCCESS,
  GET_LOGIN_ACTIVITY_FAILURE,
  GET_APPLICATION_ACTIVITY_REQUEST,
  GET_APPLICATION_ACTIVITY_SUCCESS,
  GET_APPLICATION_ACTIVITY_FAILURE,
  GET_REVIEW_ACTIVITY_REQUEST,
  GET_REVIEW_ACTIVITY_SUCCESS,
  GET_REVIEW_ACTIVITY_FAILURE,
  GET_AGGREGATE_COHORT_REQUEST,
  GET_AGGREGATE_COHORT_SUCCESS,
  GET_AGGREGATE_COHORT_FAILURE
} from "./type"

const initialState = {
  dashboardInfo: {},
  error: false,
  requesting: false,
  accountCreationActivity: {},
  accountCreationActivityRequesting: false,
  loginActivity: {},
  loginActivityRequesting: false,
  applicationActivity: {},
  applicationActivityRequesting: false,
  reviewActivity: {},
  reviewActivityRequesting: false,
  aggregateCohort: {},
  aggregateCohortRequesting: false
}

const adminDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMIN_DASHBOARD_REQUEST:
      return {
        ...state,
        requesting: true
      }
    case GET_ADMIN_DASHBOARD_REQUEST_SUCCESS:
      return {
        ...state,
        requesting: false,
        dashboardInfo: action.data
      }
    case GET_ADMIN_DASHBOARD_REQUEST_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.error
      }

    case GET_ACCOUNT_CREATION_ACTIVITY_REQUEST:
      return {
        ...state,
        accountCreationActivityRequesting: true
      }

    case GET_ACCOUNT_CREATION_ACTIVITY_SUCCESS:
      return {
        ...state,
        accountCreationActivityRequesting: false,
        accountCreationActivity: action.data
      }

    case GET_ACCOUNT_CREATION_ACTIVITY_FAILURE:
      return {
        ...state,
        accountCreationActivityRequesting: false,
        error: action.error
      }

    case GET_LOGIN_ACTIVITY_REQUEST:
      return {
        ...state,
        loginActivityRequesting: true
      }

    case GET_LOGIN_ACTIVITY_SUCCESS:
      return {
        ...state,
        loginActivityRequesting: false,
        loginActivity: action.data
      }

    case GET_LOGIN_ACTIVITY_FAILURE:
      return {
        ...state,
        loginActivityRequesting: false,
        error: action.error
      }

    case GET_APPLICATION_ACTIVITY_REQUEST:
      return {
        ...state,
        applicationActivityRequesting: true
      }

    case GET_APPLICATION_ACTIVITY_SUCCESS:
      return {
        ...state,
        applicationActivityRequesting: false,
        applicationActivity: action.data
      }

    case GET_APPLICATION_ACTIVITY_FAILURE:
      return {
        ...state,
        applicationActivityRequesting: false,
        error: action.error
      }

    case GET_REVIEW_ACTIVITY_REQUEST:
      return {
        ...state,
        reviewActivityRequesting: true
      }

    case GET_REVIEW_ACTIVITY_SUCCESS:
      return {
        ...state,
        reviewActivityRequesting: false,
        reviewActivity: action.data
      }

    case GET_REVIEW_ACTIVITY_FAILURE:
      return {
        ...state,
        reviewActivityRequesting: false,
        error: action.error
      }

    case GET_AGGREGATE_COHORT_REQUEST:
      return {
        ...state,
        aggregateCohortRequesting: true
      }

    case GET_AGGREGATE_COHORT_SUCCESS:
      return {
        ...state,
        aggregateCohortRequesting: false,
        aggregateCohort: action.data
      }

    case GET_AGGREGATE_COHORT_FAILURE:
      return {
        ...state,
        aggregateCohortRequesting: false,
        error: action.error
      }

    default:
      return state
  }
}

export default adminDashboardReducer
