import React from "react"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import { Doughnut } from "react-chartjs-2"

ChartJS.register(ArcElement, Tooltip, Legend)
ChartJS.defaults.font.family = "Inter, sans-serif"

const DoughnutChart = ({ datasets, labels }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false
      }
    }
  }

  const data = {
    labels,
    datasets
  }
  return <Doughnut options={options} data={data} />
}

export default DoughnutChart
