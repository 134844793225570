import closeIcon from "../assets/images/close-icon.svg"
import logo from "../assets/images/logo.svg"
import signLine from "../assets/images/sign-line.svg"
import googleIcon from "../assets/images/google-icon.svg"
import microsoftIcon from "../assets/images/microsoft-icon.svg"
import editIcon from "../assets/images/edit-icon.svg"
import jobsIcon from "../assets/images/jobs-icon.svg"
import jobsIconFilled from "../assets/images/jobs-icon-filled.svg"
import profileIcon from "../assets/images/profile-icon.svg"
import profileIconFilled from "../assets/images/profile-icon-filled.svg"
import teamsIcon from "../assets/images/teams-icon.svg"
import workIcon from "../assets/images/work-icon.svg"
import bgDelIcon from "../assets/images/bg-del-icon.svg"
import bgEditIcon from "../assets/images/bg-edit-icon.svg"
import exportIcon from "../assets/images/export-icon.svg"
import cookieIcon from "../assets/images/cookie-icon.svg"
import uploadIcon from "../assets/images/upload-icon.svg"
import educationIcon from "../assets/images/education-icon.svg"
import projectIcon from "../assets/images/project-icon.svg"
import adminTeams from "../assets/images/adminTeams.svg"
import adminTeamsFilled from "../assets/images/adminTeams-filled.svg"
import businessIcon from "../assets/images/business-icon.svg"
import mailIcon from "../assets/images/mail-outline-icon.svg"
import phoneIcon from "../assets/images/phone-in-talk.svg"
import businessCenterIcon from "../assets/images/business-center.svg"
import locationIcon from "../assets/images/location-on-icon.svg"
import peopleIcon from "../assets/images/people-icon.svg"
import boldIcon from "../assets/images/bold-icon.svg"
import italicIcon from "../assets/images/italic-icon.svg"
import underlineIcon from "../assets/images/underline-icon.svg"
import compare_arrows from "../assets/images/compare_arrows.svg"
import compare_txt from "../assets/images/compare_txt.svg"
import like from "../assets/images/like.svg"
import location from "../assets/images/location.svg"
import location_dark from "../assets/images/location_dark.svg"
import company_dark from "../assets/images/company_dark.svg"
import myJobsIcon from "../assets/images/my-jobs-icon.svg"
import myJobsIconFilled from "../assets/images/my-jobs-icon-filled.svg"
import dashboardIcon from "../assets/images/dashboard-icon.svg"
import dashboardIconFilled from "../assets/images/dashboard-icon-filled.svg"
import usersIconFilled from "../assets/images/users-icon-filled.svg"
import usersIcon from "../assets/images/users-icon.svg"
import dashboardUsersIcon from "../assets/images/dashboard-users-icon.svg"
import dashboardJobsIcon from "../assets/images/dashboard-jobs-icon.svg"
import dashboardTeamsIcon from "../assets/images/dashboard-teams-icon.svg"

export const Images = {
  dashboardIcon,
  dashboardIconFilled,
  myJobsIcon,
  myJobsIconFilled,
  location_dark,
  company_dark,
  location,
  compare_arrows,
  like,
  compare_txt,
  adminTeams,
  adminTeamsFilled,
  closeIcon,
  logo,
  signLine,
  googleIcon,
  microsoftIcon,
  editIcon,
  jobsIcon,
  jobsIconFilled,
  profileIcon,
  profileIconFilled,
  teamsIcon,
  workIcon,
  bgDelIcon,
  bgEditIcon,
  exportIcon,
  cookieIcon,
  uploadIcon,
  educationIcon,
  projectIcon,
  businessCenterIcon,
  businessIcon,
  mailIcon,
  phoneIcon,
  locationIcon,
  peopleIcon,
  usersIcon,
  usersIconFilled,
  boldIcon,
  underlineIcon,
  italicIcon,
  dashboardJobsIcon,
  dashboardTeamsIcon,
  dashboardUsersIcon
}
