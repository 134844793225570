import {
    PUT_CHANGE_PASSWORD_REQUEST,
    PUT_CHANGE_PASSWORD_REQUEST_SUCCESS,
    PUT_CHANGE_PASSWORD_REQUEST_FAILURE
} from './type'

const initialState = {
    changePasswordInfo: false,
    requesting: false,
    error: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case PUT_CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                requesting: true
            }
        case PUT_CHANGE_PASSWORD_REQUEST_SUCCESS:
            return {
                ...state,
                requesting: false,
                changePasswordInfo: action.data
            }
        case PUT_CHANGE_PASSWORD_REQUEST_FAILURE:
            return {
                ...state,
                requesting: false,
                error: action.data
            }

        default:
            return state
    }
}