import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import Modal from "react-bootstrap/Modal"
import React from "react"

import Button from "../Button"

import "./style.scss"

const DeleteConfirmModal = ({
  confirmText,
  itemName,
  onConfirm,
  onCancel,
  showModal,
  handleOnClose,
  loading,
  confirmLabel
}) => {
  const handleConfirm = () => {
    typeof onConfirm === "function" && onConfirm()
  }

  const handleCancel = () => {
    typeof onCancel === "function" && onCancel()
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleOnClose}
        className="uiba-modal delete-confirm-modal"
      >
        <Modal.Body>
          <div className="delete-confirm-modal-body-wrapper">
            <div className="delete-confirm-modal-body-wrapper__image-container">
              <DeleteOutlineIcon className="delete-confirm-modal-body-wrapper__image-container__icon" />
            </div>
            <div className="main-title delete-confirm-modal-body-wrapper__title">
              {confirmText}
            </div>
            <div className="delete-confirm-modal-body-wrapper__text">
              {itemName}
            </div>
            <div className="button-wrapper flex-justify-center">
              <Button
                title="Cancel"
                className="transparent-outline-btn button-wrapper__cancel-btn"
                onClick={handleCancel}
              />
              <Button
                title={confirmLabel || "Delete"}
                className="uiba-btn button-wrapper__confirm-btn"
                onClick={handleConfirm}
                showSpinner={loading}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default DeleteConfirmModal
