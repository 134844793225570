import {
  POST_FORGOT_PASSWORD,
  POST_FORGOT_PASSWORD_SUCCESS,
  POST_FORGOT_PASSWORD_FAILURE,
  FORGOT_OTP_VERIFICATION,
  FORGOT_OTP_VERIFICATION_SUCCESS,
  FORGOT_OTP_VERIFICATION_FAILURE
} from "./type"

export const postForgotRequest = (data, history) => ({
  type: POST_FORGOT_PASSWORD,
  data,
  history
})

export const postForgotRequestSuccess = (data) => ({
  type: POST_FORGOT_PASSWORD_SUCCESS,
  data
})

export const postForgotRequestFailure = (error) => ({
  type: POST_FORGOT_PASSWORD_FAILURE,
  error
})

export const forgotOTPVerification = (data, history) => ({
  type: FORGOT_OTP_VERIFICATION,
  data,
  history
})

export const forgotOTPVerificationSuccess = (data) => ({
  type: FORGOT_OTP_VERIFICATION_SUCCESS,
  data
})

export const forgotOTPVerificationFailure = (error) => ({
  type: FORGOT_OTP_VERIFICATION_FAILURE,
  error
})


