import AddIcon from "@mui/icons-material/Add"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import dayjs from "dayjs"
import React from "react"

import { Images } from "../../../theme/Images"
import IconButton from "../../IconButton"
import Image from "../../Image"
import { DEFAULT_DATE_FORMAT } from "../../../utils/constants"

// Style
import "../style.scss"

const ProjectsCard = ({ isEdited, getProjectInfo, onClick, isCandidate }) => {
  const handleOnClick = id => () => {
    onClick(id)
  }

  return (
    <>
      {isEdited ? (
        <div className="profile-details-card__body">
          {getProjectInfo &&
            getProjectInfo.map((item, index) => (
              <div className="profile-details-card__body__project" key={index}>
                <div className="profile-details-card__body__project__icon flex-justify-start">
                  <Image src={Images.projectIcon} />
                </div>
                <div className="profile-details-card__body__project__content column-flex">
                  <div className="profile-details-card__body__project__content__title flex-justify-between">
                    <div className="profile-details-card__body__project__content__title__text">
                      {item?.project_name}
                    </div>
                    {isCandidate && (
                      <div
                        className="profile-details-card__body__project__content__title__edit"
                        onClick={handleOnClick(item?.id)}
                      >
                        <EditOutlinedIcon />
                        &nbsp;&nbsp;Edit
                      </div>
                    )}
                  </div>
                  <div className="profile-details-card__body__project__content__url">
                    {item.project_url || "-"}
                  </div>
                  <div className="profile-details-card__body__project__content__date">
                    <span className="profile-details-card__body__project__content__date__start">
                      {item?.start_date &&
                        dayjs(item?.start_date).format(DEFAULT_DATE_FORMAT)}
                    </span>
                    <span className="profile-details-card__body__project__content__date__end">
                      {item?.currently_working
                        ? " - Present"
                        : item?.end_date &&
                          ` - ${dayjs(item?.end_date).format(DEFAULT_DATE_FORMAT)}`}
                    </span>
                  </div>
                  <div className="profile-details-card__body__project__content__description">
                    {item?.description ? item.description : ""}
                  </div>
                </div>
              </div>
            ))}
        </div>
      ) : (
        <div className="profile-details-card__info-text">
          Please include any company, team, or personal projects you have been
          involved with to provide a better understanding of your wider
          experience.
        </div>
      )}
      {isCandidate && (
        <div className="profile-details-card__action">
          <IconButton
            icon={<AddIcon />}
            label="Add Project"
            onClick={handleOnClick()}
          />
        </div>
      )}
    </>
  )
}

export default ProjectsCard
