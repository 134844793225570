import React, { useState } from 'react'

// Components
import UInput from '../../../UInput'
import Button from '../../../Button'
import USelect from '../../../USelect'
import { mile } from '../../../../utils/constants'

const JobsFilter = () => {

    const [addressValue, setAddressValue] = useState('');
    const [mileValue, setMileValue] = useState(null)

    const handleMileValue = (event, value) => {
        setMileValue(value)
    }

    return (
        <div className="profile-body-input-wrapper jobs-filter-wrapper">
            <div className="input-wrapper">
                <UInput
                    placeholder='Address, city or zipcode'
                    errorClass={'d-none'}
                    value={addressValue}
                    onChange={(e) => setAddressValue(e.target.value)}
                />
                <USelect
                    placeholderLabel={'Mile Radius'}
                    options={mile}
                    onChange={handleMileValue}
                    value={mileValue?.label}
                />
            </div>
            <div className="button-wrapper">
                <Button
                    title='Apply'
                    className={
                        `purple-btn
                        ${(!mileValue?.label && addressValue?.trim() === "") ? 'disabled-btn' : ''}`}
                />
            </div>
        </div>
    )
}

export default JobsFilter