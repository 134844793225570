import {
  POST_CREATE_NEW_ROLE,
  POST_CREATE_NEW_ROLE_FAILURE,
  POST_CREATE_NEW_ROLE_SUCCESS,
  GET_ASSIGNEES_REQUEST,
  GET_ASSIGNEES_SUCCESS,
  GET_SKILLS_SCORE_REQUEST,
  GET_SKILLS_SCORE_SUCCESS
} from "./type"

export const postCreateNewRole = (data, history) => ({
  type: POST_CREATE_NEW_ROLE,
  data,
  history
})

export const postCreateNewRoleSuccess = data => ({
  type: POST_CREATE_NEW_ROLE_SUCCESS,
  data
})

export const postCreateNewRoleFailure = data => ({
  type: POST_CREATE_NEW_ROLE_FAILURE,
  data
})

export const getAssigneesRequest = data => ({
  type: GET_ASSIGNEES_REQUEST,
  data
})

export const getAssigneesSuccess = data => ({
  type: GET_ASSIGNEES_SUCCESS,
  data
})

export const getSkillsScoreRequest = data => ({
  type: GET_SKILLS_SCORE_REQUEST,
  data
})

export const getSkillsScoreSuccess = data => ({
  type: GET_SKILLS_SCORE_SUCCESS,
  data
})
