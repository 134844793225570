import {
  GET_MY_JOBS_REQUEST,
  GET_MY_JOBS_SUCCESS,
  GET_MY_JOBS_FAILURE,
  GET_ALL_JOBS_REQUEST,
  GET_ALL_JOBS_SUCCESS,
  GET_ALL_JOBS_FAILURE,
  DELETE_JOB_REQUEST,
  DELETE_JOB_SUCCESS,
  CLOSE_JOB_REQUEST,
  CLOSE_JOB_SUCCESS
} from "./type"

const initialState = {
  jobsData: {
    myJobs: {},
    allJobs: {}
  },
  requesting: false,
  filterData: false,
  deleteRequesting: false,
  closeRequesting: false
}

const recruiterJobs = (state = initialState, action) => {
  switch (action.type) {
    case GET_MY_JOBS_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case GET_MY_JOBS_SUCCESS:
      return {
        ...state,
        requesting: false,
        jobsData: {
          ...state.jobsData,
          myJobs: action.data
        }
      }
    case GET_MY_JOBS_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
    case GET_ALL_JOBS_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case GET_ALL_JOBS_SUCCESS:
      return {
        ...state,
        requesting: false,
        jobsData: {
          ...state.jobsData,
          allJobs: action.data
        }
      }
    case GET_ALL_JOBS_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
    case DELETE_JOB_REQUEST:
      return {
        ...state,
        deleteRequesting: true
      }

    case DELETE_JOB_SUCCESS:
      return {
        ...state,
        deleteRequesting: false
      }

    case CLOSE_JOB_REQUEST:
      return {
        ...state,
        closeRequesting: true
      }

    case CLOSE_JOB_SUCCESS:
      return {
        ...state,
        closeRequesting: false
      }

    default:
      return state
  }
}

export default recruiterJobs
