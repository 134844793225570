
import {
    POST_REQUEST_SIGNUP,
    POST_REQUEST_SIGNUP_SUCCESS,
    POST_REQUEST_SIGNUP_FAILURE,
    SET_ACCESS_TOKEN,
    SIGNUP,
    SIGNUP_REQUEST_GOOGLE,
    SIGNUP_REQUEST_GOOGLE_FAILURE,
    SIGNUP_REQUEST_GOOGLE_SUCCESS,
    SIGNUP_REQUEST_MICROSOFT,
    SIGNUP_REQUEST_MICROSOFT_FAILURE,
    SIGNUP_REQUEST_MICROSOFT_SUCCESS,
    POST_VALIDATE_OTP,
    POST_VALIDATE_OTP_FAILURE,
    POST_VALIDATE_OTP_SUCCESS,
    DISPLAY_OTP_MODAL,
    REGENERATE_EMAIL_OTP
} from './type'

const initialState = {
    signupInfo: false,
    error: false,
    requesting: false,
};

// eslint-disable-next-line
export default (state = initialState, action) => {
    switch (action.type) {
        case POST_REQUEST_SIGNUP:
            return {
                ...state,
                requesting: true,
            };

        case POST_REQUEST_SIGNUP_SUCCESS:
            return {
                ...state,
                requesting: false,
                signupInfo: action.data,
            };
        case POST_REQUEST_SIGNUP_FAILURE:
            return {
                ...state,
                requesting: false,
                error: action.data,
            };
        case SET_ACCESS_TOKEN:
            return {
                ...state,
                accessToken: action.accessToken
            }
        case SIGNUP:
            return {
                ...state,
                requesting: false,
            }
        case SIGNUP_REQUEST_GOOGLE:
            return {
                ...state,
                requesting: true,
            }
        case SIGNUP_REQUEST_MICROSOFT:
            return {
                ...state,
                requesting: true
            }
        case SIGNUP_REQUEST_GOOGLE_SUCCESS:
            return {
                ...state,
                requesting: false,
                googleSignupInfo: action.data
            }
        case SIGNUP_REQUEST_GOOGLE_FAILURE:
            return {
                ...state,
                requesting: false,
                googleSignupError: action.data
            }
        case SIGNUP_REQUEST_MICROSOFT_SUCCESS:
            return {
                ...state,
                requesting: false,
                microsoftSignupInfo: action.data
            }
        case SIGNUP_REQUEST_MICROSOFT_FAILURE:
            return {
                ...state,
                requesting: false,
                microsoftSignupError: action.data
            }
        case POST_VALIDATE_OTP:
            return {
                ...state,
                requesting: true
            }
        case POST_VALIDATE_OTP_SUCCESS:
            return {
                ...state,
                requesting: false,
                otpSuccess: action.data
            }
        case POST_VALIDATE_OTP_FAILURE:
            return {
                ...state,
                requesting: false,
                otpError: action.data
            }
        case DISPLAY_OTP_MODAL:
            return {
                ...state,
                requesting: false,
                displayOTPModal: action.data
            }
        case REGENERATE_EMAIL_OTP:
            return {
                ...state,
                requesting: true
            }
        default:
            return state;
    }
};