import React from "react"

const PrivacyPolicy = () => (
  <div>
    <p>
      This Privacy Policy explains what you can expect us to do with your
      personal information when you use{" "}
      <strong>Experis Cross Border Community</strong> which provides a
      centralised talent ecosystem that utilises Artificial Intelligence and
      Machine Learning to provide a conceptual matching engine that connects our
      clients to global remote workers.
    </p>
    <h6>Controller Contact details</h6>
    <p>
      The Data Controller is Experis Limited with the registered office in
      Capital Court, 30 Windsor Street, Uxbridge, UB8 1AB.
    </p>
    <p>
      The personal data collected will be controlled and processed by the Data
      Controller. Additionally, personal data may be processed or jointly
      controlled by affiliates of the Data Controller listed &nbsp;
      <a href="https://www.manpowergroup.com/subsidiaries-affiliates">here</a>.
    </p>
    <h6>Personal Information we collect</h6>
    <p>
      <u>Personal data we collect directly from you:</u>
    </p>
    <p>
      When you sign up to the Experis Cross Border Community, we collect the
      following information which is provided directly by you to build your own
      profile and enable us to support your job matching process:
    </p>
    <ul>
      <li>Name, Address, Phone Number, email address</li>
      <li>Work Experience (companies, roles, tenure dates)</li>
      <li>Education (institutions, degrees, matriculation dates)</li>
      <li>Professional Memberships </li>
      <li>
        Knowledge, Skills, Abilities (level of proficiency, years of
        familiarity)
      </li>
      <li>Languages (level of proficiency, years of familiarity)</li>
      <li>Github, Stack Overflow Activity</li>
      <li>Technical Certifications (Microsoft, Cisco, Oracle, etc.)</li>
      <li>Hobbies and Interests</li>
      <li>Salary/Wage expectations</li>
      <li>Username/Password</li>
      <li> CVs/Resumes</li>
    </ul>
    <p>
      You may also ask us to use your social networking information if you give
      us access to your account on social network connection services (for
      example, LinkedIn).
    </p>
    <p>
      You may also provide additional information in the setup and ongoing use
      of the Service such as job postings.
    </p>
    <p>
      <u>Personal data we collect indirectly:</u>
    </p>
    <p>
      If you add data to your Experis Cross Border Community account that you or
      another user previously uploaded, we may associate all or a portion of the
      previous file with your account rather than storing a duplicate.
    </p>
    <p>
      When you use the Service, we automatically record the following
      information from your Device:
    </p>

    <ul>
      <li>
        Browser type, Operating System, screen resolution and user's unique ID
      </li>
      <li>
        The IP address on login, to keep track of the current client (browser)
        session, in geolocalized searches, so that the engine returns more
        geographically relevant results (excluding location)
      </li>
    </ul>
    <p>
      If we place cookies, we will do so in accordance with our Cookie Notice
    </p>
    <p>
      <u>Artificial Intelligence/Machine Learning</u>
    </p>
    <p>
      Machine Learning (ML) Algorithms that underpin the Experis Cross Border
      Community engine were modelled on the human ability to infer implicit
      knowledge from explicit knowledge to enable it to learn about
      relationships between topics and correctly identify relevance between
      knowledge or experience and a given topic e.g., a person proficient in
      physics will be inferred familiarity with linear algebra, group theory,
      differential geometry. This enables Experis Cross Border Community to
      assess both the explicit and implicit knowledge and experience of workers
      against a particular job opportunity, which aims to enhance the accuracy
      of the matching of candidates against relevant roles.
    </p>
    <p>
      When you share your personal data with Experis via the Cross Border
      Community, the following data points will be used to improve the engine’s
      quality of matching:
    </p>
    <ul className="dashed">
      <li>Knowledge, Skills and Languages</li>
      <li>Work experience, including job titles and descriptions</li>
      <li>Education achievements (diploma, major, minor, and school)</li>
      <li>Projects (title, description)</li>
      <li>Hobbies and interests</li>
      <li>Job titles you applied to, saved or searched for</li>
    </ul>
    <p>
      At any time, prior to or after registration in Experis Cross Border
      Community, you can object or opt out of your data being used to improve
      the functionality of the system by contacting{" "}
      <a href="mailto:dataprivacy@experis.co.uk">dataprivacy@experis.co.uk</a>.
    </p>
    <p>
      Artificial Intelligence (AI) algorithms will take both the explicit and
      learned implicit data and make proposals to recruiters on workers that
      appear suitable for roles the recruiter is looking to fill. The AI will
      present a suitability match score/rank for candidates registered in the
      community against the role/opportunity. The scoring is a mathematical
      function that compares knowledge, skills, abilities etc with the
      requirements of the role. If a match is presented and assessed by a
      recruiter to not be suitable a recruiter can re-run the search which will
      alter the individual’s score for similar roles in the future. The ML
      associated with this action aims to continuously assess and align an
      individual with appropriate roles/opportunities. No automated decisions
      are made without human intervention, all possible applicants will still be
      made available to recruiters who can use their professional expertise to
      match suitable roles to individuals.
    </p>
    <p>
      <u>Analytics</u>
    </p>
    <p>
      We also collect some information (ourselves or using third party services)
      using logging and cookies, such as IP address, which can sometimes be
      correlated with Personal Information. We use this information for the
      above purposes and to monitor and analyze use of the Service, for the
      Service’s technical administration, to increase our Service’s
      functionality and user-friendliness, and to verify users have the
      authorization needed for the Service to process their requests. As of the
      date this policy went into effect, we use Google Analytics and New Relic.
    </p>
    <h6>How we use your personal data </h6>
    <p>
      The Controller collects and uses the data gathered for the following
      purposes:
    </p>
    <ul>
      <li>
        Assessing your suitability and connecting you to work and job
        opportunities
      </li>
      <li>creating and managing your accounts. </li>
      <li> managing our client and vendor relationships.</li>
      <li> responding to individuals' inquiries and claims.</li>
      <li>
        operating, evaluating and improving our business (including developing,
        enhancing, analysing and improving our services; managing our
        communications; performing data analytics; and performing accounting,
        auditing and other internal functions).
      </li>
      <li>
        protecting against, identifying, and seeking to prevent fraud and other
        unlawful activity, claims and other liabilities.
      </li>
      <li>
        complying with and enforcing applicable legal requirements, relevant
        industry standards, contractual obligations, and our policies.
      </li>
      <li>
        where permitted under law, and only if you have given your consent, to
        send promotional materials, alerts regarding available positions and
        other communications.
      </li>
      <li>
        where permitted under, to communicate about, and administer
        participation in, , programs, surveys, and market research;
      </li>
      <li>
        performing data analytics, such as (i) analysing our job candidate and
        associate base; (ii) assessing individual performance and capabilities,
        including scoring on work-related skills; (iii) identifying skill
        shortages; (iv) using information to match individuals and potential
        opportunities, and (v) analysing pipeline data (trends regarding hiring
        practices) and provide insights.
      </li>
    </ul>
    <h6>Lawful basis for processing your personal data</h6>
    <p>
      All processing will be carried out based on adequate legal grounds which
      may fall into a number of categories, including:
    </p>
    <ul>
      <li>
        Your explicit consent by signing up the Experis Cross Border Community
        platform
      </li>
      <li>
        Legitimate interest in regards the following activities:
        <ul>
          <li>
            managing client, vendor, and business partner relationships and
            contractual obligations
          </li>
          <li>
            performing data analytics (such as those listed in the reporting
            elements above).
          </li>
          <li>
            operating, evaluating, and improving our business (including
            developing, enhancing, analysing, and improving our services and
            performing accounting, auditing and other internal functions.
          </li>
          <li>
            enhancing, modify, personalising, or otherwise improving our
            services for the benefit of our clients, candidates, and associates.
          </li>
          <li>
            protecting against, identifying, and seeking to prevent fraud and
            other unlawful activity, claims and other liabilities.
          </li>
          <li>
            complying with and enforcing applicable legal requirements, relevant
            industry standards, contractual obligations, and our policies.
          </li>
          <li>to enhance security of our network and information systems.</li>
          <li>to better understand how people interact with our websites.</li>
          <li>
            to determine the effectiveness of marketing and/or advertising
            campaigns
          </li>
        </ul>
      </li>
    </ul>
    <h6>Information we share</h6>
    <p>
      We do not disclose personal data that we collect about you, except as
      described in this privacy notice or in separate notices provided in
      connection with particular activities.
    </p>
    <p>
      We will share a portion of your personal data with vendors who perform
      services on our behalf based on our instructions to make the Experis Cross
      Border Community and its services available to you. We ensure this data is
      minimised to what is necessary to perform the specific services
      instructed. We ensure vendor due diligence takes place to assess their
      privacy and security measures for protecting your data. We also ensure
      that data processing agreements are in place with vendors, with robust
      requirements stipulating the technical and organisational measure we
      require them to comply with when handling your personal data.
    </p>
    <p>
      We do not authorise vendors to use or disclose the information except as
      necessary to perform services on our behalf or comply with legal
      requirements. Your personal data will never be sold, rented, distributed
      or made available to vendors for their own commercial purposes, including
      for their direct marketing purposes; sharing purposes are as described in
      this notice.
    </p>
    <p>
      When you use Experis Cross Border Community, we also may share a portion
      of your personal data with our subsidiaries and affiliated ManpowerGroup
      companies for administrative, legal or compliance purposes; where this is
      the case, we will ensure that there are appropriate safeguards for the
      protection of your data.
    </p>
    <p>
      In addition, we may disclose personal data about you on the following
      basis:
    </p>
    <ol>
      <li>If we are required to do so by law or legal process;</li>
      <li>
        To law enforcement authorities or other government officials based on a
        lawful disclosure request;
      </li>
      <li>
        When we believe disclosure is necessary or appropriate to prevent
        physical harm or financial loss, or in connection with an investigation
        of suspected or actual fraudulent or illegal activity; and,
      </li>
      <li>
        We also reserve the right to transfer personal data we have about you in
        the event we sell or transfer all or a portion of our business or assets
        (including in the event of a reorganization, dissolution or
        liquidation).
      </li>
    </ol>
    <h6>Information you may share</h6>
    <p>
      Any information you choose to provide on your Profile page should reflect
      how much you want others to know about you. Please consider carefully what
      information you disclose and your desired level of anonymity. You can
      review and revise your profile information at any time.
    </p>
    <p>
      Through certain features of the Service, you may also make some of your
      information public. Public information may be broadly and quickly
      disseminated.
    </p>
    <h6>Changing or Deleting Your Information </h6>
    <p>
      You may review, update, correct or delete the Personal Information
      provided in your registration or account profile .
    </p>
    <h6>Data Storage and Retention </h6>
    <p>
      Inactive accounts are retained no longer than two years. However, longer
      periods may be necessary to comply with our legal obligations, resolve
      disputes, or enforce our agreements. Consistent with these requirements,
      we will try to delete your information quickly upon request. Please note,
      however, that there might be latency in deleting information from our
      servers and backed-up versions might exist after deletion.
    </p>
    <h6>Your Data Protection Rights </h6>
    <p>
      When permitted by applicable law, a data subject can exercise under
      Articles 15 to 22 of the GDPR the following specific rights:
    </p>
    <p>
      Right of access: A data subject has the right to access his or her
      personal data concerning which in order to verify that his or her personal
      data is processed in accordance to the law. Should you wish to exercise
      you right of access please and complete the form.
    </p>
    <p>
      <u>Right to rectification:</u> A data subject has the right to request the
      rectification of any inaccurate or incomplete data held about him or her,
      in order to protect the accuracy of such information and to adapt it to
      the data processing.
    </p>
    <p>
      <u>Right to erasure:</u> A data subject has the right to request that the
      Data Controller erases information about him or her and to no longer
      process that data.
    </p>
    <p>
      <u>Right to restriction of processing:</u> A data subject has the right to
      request that the Data Controller restricts the processing of his or her
      data.
    </p>
    <p>
      <u>Right to data portability:</u> The data subject has the right to
      request the data portability meaning that the data subject can receive the
      originally provided personal data in a structured and commonly used format
      or that the data subject can request the transfer of the data to another
      Data Controller.
    </p>
    <p>
      <u>Right to object:</u> The data subject who provide a Data Controller
      with personal data has the right to object, at any time to the data
      processing on a number of grounds as set out under GDPR without needing to
      justify his or her decision.
    </p>
    <p>
      <u>Right not to be subject of automated individual decision-making:</u>
      &nbsp; The data subject has the right not to be subject to a decision
      based solely on automated processing, including profiling, if such
      profiling produces a legal effect concerning the data subject or similarly
      significantly affects him or her.
    </p>
    <p>
      <u>Right to lodge a complaint with a supervisory authority:</u> Every data
      subject has the right to lodge a complaint with a supervisory authority,
      in particular in the EU Member State of his or her habitual residence,
      place of work or place of the alleged infringement if the data subject
      considers that the processing of personal data relating to him or her
      infringes GDPR.
    </p>
    <p>
      Whenever the processing is based on the consent, as under art.7 of the
      GDPR, the data subject may withdraw their consent at any time. There may
      be circumstances where we will still need to process your data for legal
      or official reasons. We will inform you if this is the case. Where this is
      the case, we will restrict the data to only what is necessary for the
      purpose of meeting those specific requirements.
    </p>
    <p>
      If you believe that any of your data that we process is incorrect or
      incomplete, please contact us and we will take reasonable steps to check
      its accuracy and correct it where necessary.
    </p>
    <p>
      If you require more information about the processing of your personal
      data, please refer to the How to Contact Us section below.
    </p>
    <h6>How We Protect Personal Data</h6>
    <p>
      We maintain technical and organisational measures designed to protect the
      personal data we collect against accidental, unlawful or unauthorized
      destruction, loss, alteration, access, disclosure or use. Such measures
      ensure an appropriate level of security taking account, on one hand, the
      technical state of the art and, on the other hand, the sensitive nature of
      personal data and the evaluation of potential risks.
    </p>
    <p>
      To ensure the appropriate security and confidentiality of personal data,
      we apply to the following non-exhaustive list of measures:
    </p>
    <ul>
      <li>
        Encryption of data at rest and in transit using industry standard
        encryption algorithms with appropriate key lengths;
      </li>
      <li>Strong user authentication and role-based access controls;</li>
      <li>Network monitoring solutions with events logging;</li>
      <li>Hardened network infrastructure;</li>
      <li>
        Measures for ensuring physical security of locations at which personal
        data are processed
      </li>
      <li>
        Business continuity and disaster recovery plans with periodic testing
      </li>
      <li>Incident Management policy and processes</li>
      <li>Periodic vulnerability and penetration testing</li>
      <li>Certification/assurance of processes and products</li>
      <li>
        Continuous employee privacy and security training and awareness program
      </li>
      <li>Third party privacy and security assessments</li>
      <li>Robust data processing and confidentiality agreements</li>
      <li>
        Organizational measures for ensuring data minimization, purpose
        limitation, retention, data quality and accountability.
      </li>
    </ul>
    <h6>Children’s Policy </h6>
    <p>
      Our Services are not directed to persons under 13. We do not knowingly
      collect personally identifiable information from children under 13. If a
      parent or guardian becomes aware that his or her child has provided us
      with Personal Information without their consent, he or she should contact
      us at{" "}
      <a href="mailto:crossborder@experis.co.uk">crossborder@experis.co.uk</a>.
      If we become aware that a child under 13 has provided us with Personal
      Information, we will take steps to delete such information from our files.
    </p>
    <h6>Data Transfers</h6>
    <p>
      We also may transfer the personal data we collect about you to countries
      outside of the country in which the information originally was collected.
      Those countries may not have the same data protection laws as the country
      in which you initially provided the personal data. When we transfer your
      information to other countries, we will protect that data as described in
      this privacy notice and such transfers will be in compliance with
      applicable law.
    </p>
    <p>
      The countries to which we may transfer the personal data we collect about
      you may be:
    </p>
    <ul>
      <li>Within the European Union</li>
      <li>Outside the European Union</li>
    </ul>
    <p>
      When we transfer personal data from within the UK/European Union to
      countries or international organizations that are based outside the
      European Union the transfer takes place on the basis of:
    </p>
    <ol className="alpha-lower">
      <li>Adequacy decision by the ICO/European Commission;</li>
      <li>
        In absence of an adequacy decision other legally permitted grounds (a)
        legally binding and enforceable instrument between public authorities or
        bodies; (b) binding corporate rules; (c) standard data protection
        clauses (formerly called the Model Clauses) adopted by the ICO/EU
        Commission, etc.
      </li>
    </ol>

    <h6>How to contact us</h6>
    <p>
      If you have any questions or comments about this privacy notice, or if you
      would like to exercise your statutory subject rights, please write to:
    </p>
    <p>
      The Data Privacy Officer,
      <br />
      Experis Limited
      <br />
      30 Windsor St,
      <br />
      Capital Court,
      <br />
      Uxbridge
      <br />
      UB8 1AB
      <br />
      United Kingdom
    </p>
    <p>
      Email:&nbsp;
      <a href="mailto:dataprivacy@experis.co.uk">dataprivacy@experis.co.uk</a>
    </p>
    <h6>Changes to our Privacy Policy </h6>
    <p>
      This Privacy Policy may change from time to time. If we make a change to
      this privacy policy that we believe materially reduces your rights, we
      will provide you with notice (for example, by email). And we may provide
      notice of changes in other circumstances as well
    </p>
    <p>
      By continuing to use the Service after those changes become effective, you
      agree to be bound by the revised Privacy Policy.
    </p>
    <h6>How to complain</h6>
    <p>
      If you have any concerns about our use of your personal information, you
      can make a complaint to us at the Contact Us address above.
    </p>
    <p>
      You can also complain to the ICO if you are unhappy with how we have used
      your data.
    </p>
    <p>The ICO’s address:</p>
    <p>
      Information Commissioner’s Office
      <br />
      Wycliffe House
      <br />
      Water Lane
      <br />
      Wilmslow
      <br />
      Cheshire
      <br />
      SK9 5AF
    </p>
    <p>
      Helpline number: 0303 123 1113
      <br />
      ICO website: <a href="https://www.ico.org.uk">https://www.ico.org.uk</a>
    </p>
    <p>
      Please note: if a candidate is based Europe, they also have the right to
      lodge a complaint via the supervisory authority in any EU member state of
      their habitual residence or work.
    </p>
  </div>
)

export default PrivacyPolicy
