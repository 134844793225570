import React from "react"
import { connect } from "react-redux"
// Components
import Modal from "react-bootstrap/Modal"
import { Images } from "../../../theme/Images"
import Button from "../../Button"
import Image from "../../Image"
import { useHistory } from "react-router-dom"

import {
  deleteAccount,
  deleteAccountFailure
} from "../../../pages/AuthScreens/Login/redux/action"

const DeleteAccountModal = ({
  showDeleteAccountModal,
  setShowDeleteAccountModal,
  deleteCurrentUserAccount,
  deleteAccountFailure,
  requesting,
  error
}) => {
  const history = useHistory()

  const handleDeleteAccount = () => {
    deleteCurrentUserAccount({}, history)
  }

  const hideDeleteAccountModal = () => {
    setShowDeleteAccountModal(false)
  }

  return (
    <Modal
      show={showDeleteAccountModal}
      setShow={setShowDeleteAccountModal}
      centered
      className="edit-modal delete-modal"
    >
      <Modal.Header>
        <Modal.Title>
          <div className="wrapper flex-justify-end w-100">
            <div
              className="close-icon"
              onClick={hideDeleteAccountModal}
            >
              <Image src={Images.closeIcon} />
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="profile-body-input-wrapper delete-input-wrapper">
          <div className="main-title">
            Are you sure you want to
            <br />
            Delete this account?
          </div>
          <div className="text text-center">
            This option will permanently delete your account
          </div>
          <div className="button-wrapper justify-content-end">
            <Button
              title="Cancel"
              className="transparent-btn"
              onClick={hideDeleteAccountModal}
            />
            <Button
              title="Confirm"
              onClick={handleDeleteAccount}
              showSpinner={requesting}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const mapDispatchToProps = dispatch => ({
  deleteCurrentUserAccount: (data, history) =>
    dispatch(deleteAccount(data, history)),
  deleteAccountFailure: data => dispatch(deleteAccountFailure(data))
})

const mapStateToProps = state => ({
  requesting: state.login.requesting,
  error: state.login.accountDeleteError
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccountModal)
