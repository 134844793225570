import Modal from "react-bootstrap/Modal"
import React, { useCallback, useEffect, useState } from "react"
import { connect } from "react-redux"

import { Images } from "../../../theme/Images"
import Image from "../../Image"

import "../style.scss"
import UInput from "../../UInput"
import Button from "../../../components/Button"
import TextArea from "../../TextArea"
import {
  closeModal,
  postAddTeam,
  deleteRecord,
  editTeamRequest
} from "../../../pages/AdminScreens/Teams/redux/action"
import { useForm } from "../../../hooks"

const TeamsModal = ({
  deleteFlag,
  editFlag,
  addFlag,
  showTeamsModal,
  requesting,
  errorInfo,
  dispatchCloseModal,
  dispatchAddNewTeam,
  dispatchEditTeam,
  deleteTeamInfo,
  editTeamInfo,
  dispatchDeleteRecord
}) => {
  const [title, setTitle] = useState({
    value: "Add Teams"
  })

  const [validations, setValidations] = useState({
    name: false,
    description: false,
    industry: false,
  })

  const stateSchema = {
    name: {
      value: editTeamInfo ? editTeamInfo?.name : "",
      error: ""
    },
    description: {
      value: editTeamInfo ? editTeamInfo?.description : "",
      error: ""
    },
    industry: {
      value: editTeamInfo ? editTeamInfo?.industry : "",
      error: ""
    }
  }

  const validationStateSchema = {
    name: {
      required: true
    },
    description: {
      required: false
    },
    industry: {
      required: true
    }
  }

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  const loadModalInfo = useCallback(() => {
    if (addFlag) {
      setTitle({
        ...title,
        value: "Add Team"
      })
      setState(stateSchema)
    }
    if (editFlag) {
      setTitle({
        ...title,
        value: "Edit Team"
      })
    }
    if (deleteFlag) {
      setTitle({
        ...title,
        value: "Delete Team"
      })
    }
    if (editTeamInfo) {
      setState(stateSchema)
    }
  }, [addFlag, editFlag, deleteFlag, editTeamInfo, showTeamsModal])

  useEffect(() => {
    loadModalInfo()
  }, [loadModalInfo])

  const closeModal = () => {
    dispatchCloseModal(false)
  }

  const handleSave = () => {
    let data = {
      name: state.name.value,
      description: state.description.value,
      industry: state.industry.value
    }
    if (addFlag) {
      dispatchAddNewTeam(data)
    }
    if (editFlag) {
      dispatchEditTeam(data, editTeamInfo?.id)
    }
  }

  const deleteTeam = () => {
    if (deleteTeamInfo) {
      dispatchDeleteRecord(deleteTeamInfo?.id)
    }
  }

  const validateName = name => {
    if (name.length && !isNaN(name)) {
      setValidations({ ...validations, name: "Invalid Team Name Input" })
    } else {
      setValidations({ ...validations, name: false })
    }
  }

  const validateIndustry = industry => {
    if (industry.length && !isNaN(industry)) {
      setValidations({ ...validations, industry: "Invalid Industry Input" })
    } else {
      setValidations({ ...validations, industry: false })
    }
  }

  const handleInputChange = field => val => {
    handleOnChange(field, val.target.value)
    if (field === "name") {
      validateName(val.target.value)
    }
    if (field === "industry") {
      validateIndustry(val.target.value)
    }
  }

  return (
    <Modal
      show={showTeamsModal}
      setShow={closeModal}
      centered
      className="auth-modal team-modal"
    >
      <Modal.Header>
        <Modal.Title>
          <div className="teams-title">
            <div className="title">{title?.value}</div>
            <div className="close-icon" onClick={closeModal}>
              <Image src={Images.closeIcon} />
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!deleteFlag && (
          <div className="auth-modal-body-wrapper teams-modal-body-wrapper">
            <div className="teams-name">
              <UInput
                placeholder="Team Name"
                onChange={handleInputChange("name")}
                value={state?.name?.value || ''}
                id={errorInfo ? "outlined-error-helper-text" : ""}
                inputError={errorInfo?.name}
                errorText={
                  validations?.name || errorInfo?.name || state.name.error
                }
                errorClass={
                  validations?.name || errorInfo || state.name.error
                    ? "opacity-1"
                    : ""
                }
              ></UInput>
            </div>
            <div className="teams-desc">
              <TextArea
                className="teams-modal-text"
                placeholder="Description"
                onChange={handleInputChange("description")}
                value={state.description.value || ''}
              ></TextArea>
            </div>
            <div className="industry">
              <UInput
                placeholder="Team industry"
                onChange={handleInputChange("industry")}
                value={state.industry.value || ''}
                id={errorInfo ? "outlined-error-helper-text" : ""}
                inputError={errorInfo?.industry}
                errorText={
                  validations?.industry ||
                  errorInfo?.industry ||
                  state.industry.error
                }
                errorClass={
                  validations?.industry || errorInfo || state.industry.error
                    ? "opacity-1"
                    : ""
                }
              ></UInput>
            </div>
          </div>
        )}
        {!deleteFlag && (
          <div className="modal-footer">
            <Button
              title="Save"
              onClick={handleSave}
              className={
                validations.name?.length ||
                validations.industry?.length ||
                disable
                  ? "disabled-btn"
                  : ""
              }
              showSpinner={requesting}
            ></Button>
          </div>
        )}
        {deleteFlag && (
          <div className="auth-modal-body-wrapper teams-modal-body-wrapper">
            <div className="delete-info">
              Are your sure you want to delete&nbsp;
              <span className="team-name">{deleteTeamInfo?.name}</span>
              &nbsp;team ?
            </div>
            <div className="delete-btn">
              <Button title="Delete" onClick={deleteTeam}></Button>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  )
}

const mapStateToProps = state => ({
  requesting: state.adminTeams.requesting,
  errorInfo: state.adminTeams.error
})

const mapDispatchToProps = dispatch => ({
  dispatchCloseModal: data => dispatch(closeModal(data)),
  dispatchAddNewTeam: data => dispatch(postAddTeam(data)),
  dispatchDeleteRecord: id => dispatch(deleteRecord(id)),
  dispatchEditTeam: (data, id) => dispatch(editTeamRequest(data, id))
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamsModal)
