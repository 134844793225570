export const GET_ALL_JOBS_REQUEST = "GET_ALL_JOBS_REQUEST"
export const GET_ALL_JOBS_SUCCESS = "GET_ALL_JOBS_SUCCESS"
export const GET_ALL_JOBS_FAILURE = "GET_ALL_JOBS_FAILURE"
export const GET_MY_JOBS_REQUEST = "GET_MY_JOBS_REQUEST"
export const GET_MY_JOBS_SUCCESS = "GET_MY_JOBS_SUCCESS"
export const GET_MY_JOBS_FAILURE = "GET_MY_JOBS_FAILURE"
export const DELETE_JOB_REQUEST = "DELETE_JOB_REQUEST"
export const DELETE_JOB_SUCCESS = "DELETE_JOB_SUCCESS"
export const CLOSE_JOB_REQUEST = "CLOSE_JOB_REQUEST"
export const CLOSE_JOB_SUCCESS = "CLOSE_JOB_SUCCESS"
