import { connect } from "react-redux"
import Modal from "react-bootstrap/Modal"
import React, { useEffect, useState } from "react"
import "../style.scss"
import { Images } from "../../../theme/Images"
import Button from "../../Button"
import Image from "../../Image"
import {
  displayAddMemberModal,
  addTeamMember
} from "../../../pages/AdminScreens/TeamMembers/redux/action"
import { useForm } from "../../../hooks"
import UInput from "../../UInput"

const AdminMemberModal = ({
  showMemberModal,
  toggleAddMemberModal,
  error,
  requesting,
  history,
  dispatchAddMember
}) => {

  const stateSchema = {
    email: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    email: {
      required: true
    }
  }

  const { state, handleOnChange, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  useEffect(() => {
    if (!showMemberModal) {
      setState({
        email: {
          value: ""
        }
      })
    }
  }, [showMemberModal, setState])

  const [validations, setValidations] = useState({
    email: false
  })

  const validateEmail = email => {
    if (email.length && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setValidations({ ...validations, email: "Invalid Email Input" })
    } else {
      setValidations({ ...validations, email: false })
    }
  }

  const handleInputChange = field => val => {
    handleOnChange(field, val.target.value)
    if (field === "email") {
      validateEmail(val.target.value)
    }
  }

  const closeModal = () => {
    toggleAddMemberModal(false)
  }

  const handleAddMember = () => {
    let data = {
      team_id: history?.location?.state?.id,
      team_member_email: state.email.value,
      team_name: history?.location?.state?.name
    }
    dispatchAddMember(data)
  }

  return (
    <>
      <Modal
        show={showMemberModal}
        setShow={closeModal}
        centered
        className="auth-modal admin-member-modal"
      >
        <Modal.Header>
          <Modal.Title>
            <div className="teams-title">
              <div className="title">Add Member</div>
              <div className="close-icon" onClick={closeModal}>
                <Image src={Images.closeIcon} />
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="auth-modal-body-wrapper">
            <div className="member-modal-body">
              <UInput
                placeholder="Team Member"
                onChange={handleInputChange("email")}
                value={state?.email?.value}
                id={error ? "outlined-error-helper-text" : ""}
                inputError={error?.email}
                errorText={
                  validations?.email || error?.email || state.email.error
                }
                errorClass={
                  validations?.email || error || state.email.error
                    ? "opacity-1"
                    : ""
                }
              ></UInput>
            </div>
            <div className="modal-footer">
              <Button
                showSpinner={requesting}
                title="Add"
                className={
                  validations?.email?.length || !state.email.value
                    ? "disabled-btn"
                    : ""
                }
                onClick={handleAddMember}
              ></Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

const mapStateToProps = state => ({
  error: state.adminTeamMembers.error,
  requesting: state.adminTeamMembers.requesting
})
const mapDispatchToProps = dispatch => ({
  toggleAddMemberModal: data => dispatch(displayAddMemberModal(data)),
  dispatchAddMember: data => dispatch(addTeamMember(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminMemberModal)
