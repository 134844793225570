import {
    POST_REQUEST_SIGNUP,
    POST_REQUEST_SIGNUP_FAILURE,
    POST_REQUEST_SIGNUP_SUCCESS,
    SET_ACCESS_TOKEN,
    SIGNUP,
    SIGNUP_REQUEST_GOOGLE,
    SIGNUP_REQUEST_GOOGLE_SUCCESS,
    SIGNUP_REQUEST_GOOGLE_FAILURE,
    SIGNUP_REQUEST_MICROSOFT,
    SIGNUP_REQUEST_MICROSOFT_SUCCESS,
    SIGNUP_REQUEST_MICROSOFT_FAILURE,
    POST_VALIDATE_OTP,
    POST_VALIDATE_OTP_SUCCESS,
    POST_VALIDATE_OTP_FAILURE,
    DISPLAY_OTP_MODAL,
    REGENERATE_EMAIL_OTP
} from './type'

export const signupRequest = (data, history) => {
  return {
    type: POST_REQUEST_SIGNUP,
    data,
    history
  }
}
export const signupRequestSuccess = data => ({
  type: POST_REQUEST_SIGNUP_SUCCESS,
  data
})
export const signupRequestFailure = data => ({
  type: POST_REQUEST_SIGNUP_FAILURE,
  data
})
export const setAccessToken = accessToken => ({
  type: SET_ACCESS_TOKEN,
  accessToken
})
export const signup = () => ({
  type: SIGNUP
})

export const googleSignupRequest = (data, history) => ({
    type: SIGNUP_REQUEST_GOOGLE,
    data,
    history
})
export const googleSignupRequestSuccess = data => ({
  type: SIGNUP_REQUEST_GOOGLE_SUCCESS,
  data
})
export const googleSignupRequestFailure = () => ({
  type: SIGNUP_REQUEST_GOOGLE_FAILURE
})

//Microsoft Changes
export const microsoftSignupRequest = (data, history) => ({
    type: SIGNUP_REQUEST_MICROSOFT,
    data,
    history
})
export const microsoftSignupRequestSuccess = data => ({
  type: SIGNUP_REQUEST_MICROSOFT_SUCCESS,
  data
  
})
export const microsoftSignupRequestFailure = () => ({
  type: SIGNUP_REQUEST_MICROSOFT_FAILURE
})
export const validateOTP = (data, history, loginFlag) => ({
    type: POST_VALIDATE_OTP,
    data,
    history,
    loginFlag
})
export const validateOTPSuccess = (data) => ({
    type: POST_VALIDATE_OTP_SUCCESS,
    data
})
export const validateOTPFailure = (data) => ({
    type: POST_VALIDATE_OTP_FAILURE,
    data
})
export const displayOTPModal = data => ({
  type: DISPLAY_OTP_MODAL,
  data
})
export const signupRegenerateOTP = (data) => ({
    type: REGENERATE_EMAIL_OTP,
    data
})
