import {
  GET_ADMIN_DASHBOARD_REQUEST,
  GET_ADMIN_DASHBOARD_REQUEST_SUCCESS,
  GET_ADMIN_DASHBOARD_REQUEST_FAILURE,
  GET_ACCOUNT_CREATION_ACTIVITY_REQUEST,
  GET_ACCOUNT_CREATION_ACTIVITY_SUCCESS,
  GET_ACCOUNT_CREATION_ACTIVITY_FAILURE,
  GET_LOGIN_ACTIVITY_REQUEST,
  GET_LOGIN_ACTIVITY_SUCCESS,
  GET_LOGIN_ACTIVITY_FAILURE,
  GET_APPLICATION_ACTIVITY_REQUEST,
  GET_APPLICATION_ACTIVITY_SUCCESS,
  GET_APPLICATION_ACTIVITY_FAILURE,
  GET_REVIEW_ACTIVITY_REQUEST,
  GET_REVIEW_ACTIVITY_SUCCESS,
  GET_REVIEW_ACTIVITY_FAILURE,
  GET_AGGREGATE_COHORT_REQUEST,
  GET_AGGREGATE_COHORT_SUCCESS,
  GET_AGGREGATE_COHORT_FAILURE
} from "./type"

export const getAdminDashboardRequest = data => ({
  type: GET_ADMIN_DASHBOARD_REQUEST,
  data
})

export const getAdminDashboardRequestSuccess = data => ({
  type: GET_ADMIN_DASHBOARD_REQUEST_SUCCESS,
  data
})

export const getAdminDashboardRequestFailure = error => ({
  type: GET_ADMIN_DASHBOARD_REQUEST_FAILURE,
  error
})

export const getAccountCreationActivityRequest = data => ({
  type: GET_ACCOUNT_CREATION_ACTIVITY_REQUEST,
  data
})

export const getAccountCreationActivitySuccess = data => ({
  type: GET_ACCOUNT_CREATION_ACTIVITY_SUCCESS,
  data
})

export const getAccountCreationActivityFailure = error => ({
  type: GET_ACCOUNT_CREATION_ACTIVITY_FAILURE,
  error
})

export const getLoginActivityRequest = data => ({
  type: GET_LOGIN_ACTIVITY_REQUEST,
  data
})

export const getLoginActivitySuccess = data => ({
  type: GET_LOGIN_ACTIVITY_SUCCESS,
  data
})

export const getLoginActivityFailure = error => ({
  type: GET_LOGIN_ACTIVITY_FAILURE,
  error
})

export const getApplicationActivityRequest = data => ({
  type: GET_APPLICATION_ACTIVITY_REQUEST,
  data
})

export const getApplicationActivitySuccess = data => ({
  type: GET_APPLICATION_ACTIVITY_SUCCESS,
  data
})

export const getApplicationActivityFailure = error => ({
  type: GET_APPLICATION_ACTIVITY_FAILURE,
  error
})

export const getReviewActivityRequest = data => ({
  type: GET_REVIEW_ACTIVITY_REQUEST,
  data
})

export const getReviewActivitySuccess = data => ({
  type: GET_REVIEW_ACTIVITY_SUCCESS,
  data
})

export const getReviewActivityFailure = error => ({
  type: GET_REVIEW_ACTIVITY_FAILURE,
  error
})

export const getAggregateCohortRequest = data => ({
  type: GET_AGGREGATE_COHORT_REQUEST,
  data
})

export const getAggregateCohortSuccess = data => ({
  type: GET_AGGREGATE_COHORT_SUCCESS,
  data
})

export const getAggregateCohortFailure = error => ({
  type: GET_AGGREGATE_COHORT_FAILURE,
  error
})
